<script setup lang="ts">
import { useTaskTypes } from '@/composables/useTaskTypes';
import { TaskTypeAlias } from '@/helpers/constants';
import { Form } from 'vee-validate';
import { Ref, ref, watch } from 'vue';
import { useCollocations } from '@/composables/useCollocations';
import InfoInputGroup from '@/components/UI/Form/InfoInputGroup.vue';

let collocationId = ref()
let id = ref('')

const router = useRouter()
const toast = useToast()

const { getTaskTypes } = useTaskTypes()
const { taskTypes, loading: loadingTaskTypes } = getTaskTypes()

const { getCollocations } = useCollocations()
const { collocations } = getCollocations()

import { useClusters } from '@/composables/useClusters'
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
const { startClusterService } = useClusters()

const availableCollocationId: Ref<Array<string>> = ref([])

watch(taskTypes, () => {
  const taskType = taskTypes.value.find((taskType: any) => taskType && taskType.alias === TaskTypeAlias.ClusterService)
  if (taskType) {
    availableCollocationId.value = taskType?.capabilities.map((e: any) => e.collocation)
  }
})

const availableVolumeMountAliases: Ref<Array<{ mountAlias: string, name: string }>> = ref([])

watch(collocations, () => {
  const taskType = taskTypes.value.find((taskType: any) => taskType && taskType.alias === TaskTypeAlias.ClusterProvisioning)
  if (taskType) {
    let volumeAliases: { mountAlias: string, name: string }[] = []
    const capabilities = taskType.capabilities.filter((capability: any) => capability?.collocation.id === collocationId.value)
    for (let capability of capabilities) {
      for (let connection of capability?.collocation.connections || []) {
        for (let volume of connection?.storage.volumes || []) {
          if (!volumeAliases.find((_volume) => _volume.mountAlias === volume.mountAlias)) {
            volumeAliases.push({
              mountAlias: volume.mountAlias,
              name: volume.name || volume.mountAlias,
            })
          }
        }
      }
    }
    availableVolumeMountAliases.value = [...volumeAliases]
  }
})

let logVolumeDmMountAlias = ref()
let workdirVolumeDmMountAlias = ref()

watch(collocationId, () => {
  const taskType = taskTypes.value.find((taskType: any) => taskType && taskType.alias === TaskTypeAlias.ClusterService)
  if (taskType) {
    let volumeAliases: { mountAlias: string, name: string }[] = []
    const capabilities = taskType.capabilities.filter((capability: any) => capability?.collocation.id === collocationId.value)
    for (let capability of capabilities) {
      for (let connection of capability?.collocation.connections || []) {
        for (let volume of connection?.storage.volumes || []) {
          if (!volumeAliases.find((_volume) => _volume.mountAlias === volume.mountAlias)) {
            volumeAliases.push({
              mountAlias: volume.mountAlias,
              name: volume.name || volume.mountAlias,
            })
          }
        }
      }
    }
    availableVolumeMountAliases.value = [...volumeAliases]
  }
})


const onCreateTask = async () => {
  try {
    await startClusterService({
      id: id.value,
      dmCollocationId: collocationId.value,
      workdirDmMountAlias: workdirVolumeDmMountAlias.value,
      logVolumeDmMountAlias: logVolumeDmMountAlias.value,
    })
    router.go(-1)
  } catch (err) {
    toast.add({ severity: 'error', summary: 'Error', detail: 'Error while creating task', life: 3000 })
  }

}

function onCancelClick() {
  router.go(-1)
}
</script>

<template>
  <div class="grid">
    <div class="col-12 md:col-6">
      <div class="p-fluid">
        <Form class="grid p-fluid">
          <div class="col-12 col:md-12 formgrid">
            <InfoInputGroup>
              <InputText data-cy="cluserForm__cluserId" id="id" v-model="id" optionValue="id"
                         optionLabel="id"  :loading="loadingTaskTypes" placeholder="Cluster id">
              </InputText>
              <label for="collocationId">Id</label>
            </InfoInputGroup>

            <InfoInputGroup>
              <Dropdown data-cy="cluserForm__cluserCollocation" id="collocationId" v-model="collocationId" :options="collocations" optionValue="id"
                        optionLabel="name" :loading="loadingTaskTypes" placeholder="Select Collocation" scroll-height="400px">
              </Dropdown>
              <label for="collocationId">Collocation</label>
            </InfoInputGroup>

            <InfoInputGroup>
              <Dropdown data-cy="cluserForm__cluserLogVolume" id="logVolumeDmMountAlias" v-model="logVolumeDmMountAlias" :options="availableVolumeMountAliases" optionValue="mountAlias"
                        optionLabel="name" :loading="loadingTaskTypes" placeholder="Select log volume" scroll-height="400px">
              </Dropdown>
              <label for="logVolumeDmMountAlias">Log Volume</label>
            </InfoInputGroup>

            <InfoInputGroup>
              <Dropdown data-cy="cluserForm__cluserWorkdirVolume" id="workdirVolumeDmMountAlias" v-model="workdirVolumeDmMountAlias" :options="availableVolumeMountAliases" optionValue="mountAlias"
                        optionLabel="name" :loading="loadingTaskTypes" placeholder="Select workdir volume" scroll-height="400px">
              </Dropdown>
              <label for="workdirVolumeDmMountAlias">Workdir Volume</label>
            </InfoInputGroup>

            <br>
            <div class="flex flex-row flex-wrap justify-content-end">
              <Button data-cy="cluserForm__cluserCreateButton" @click="onCreateTask" label="Create" icon="fa-solid fa-check"
                      class="p-button-success mr-2 mb-2 mlwn-button-submit"></Button>

              <Button @click="onCancelClick" label="Cancel" icon="fa-solid fa-xmark"
                      class="p-button-secondary mr-2 mb-2 mlwn-button-submit" />
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>