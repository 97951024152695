import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, unref as _unref, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, isRef as _isRef, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_4 = { class: "flex align-items-center justify-content-center" }
const _hoisted_5 = { class: "center-space-between" }
const _hoisted_6 = { class: "action-buttons" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_9 = { class: "flex align-items-center justify-content-center" }
const _hoisted_10 = ["data-cy"]
const _hoisted_11 = { "data-cy": 'serviceTasks__LR_taskStatus' }
const _hoisted_12 = { class: "action-buttons" }

import { ref, watch } from 'vue';
import { useCdn } from '@/composables/useCdn';
import { useTables } from '@/composables/useTables';
import { FilterMatchMode } from 'primevue/api';
import { ApiTaskStatusAlias, taskStatusMapper, cdnOriginStatusMapper, cdnOriginTypeMapper, other } from './ServiceTasksConfig';
import { CdnOriginStatusAlias, CdnOriginTypeAlias, DataMgmtTaskStatusAlias } from '@/gql/graphql';
import { useToast } from 'primevue/usetoast';
import { useLongRunningServices } from '@/composables/useLongRunningServices';


export default /*@__PURE__*/_defineComponent({
  __name: 'ServiceTasks',
  setup(__props) {

const getCdnOriginTypeTag = (alias: CdnOriginTypeAlias) =>
  cdnOriginTypeMapper[alias] || other

const getCdnOriginStatusTag = (alias: CdnOriginStatusAlias) =>
  cdnOriginStatusMapper[alias] || other

const { getCdnOrigins, restartCdnOriginService, stopCdnOriginService } = useCdn()
const input = ref({ limit: 100, offset: 0 })
const {
  data: cdnOrigins,
  loading: loadingCdnOrigins,
  error: errorCdnOrigins,
} = getCdnOrigins(input)

const toast = useToast()

const errorDialogVisible = ref(false)
const errorMessage = ref("")

const {
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows,
  lazy
} = useTables({

  filters: {
    global: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "global"
    },
    id: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "id"
    },
    dmCollocationId: {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
      pretty: "dmCollocationId"
    }
  }
})

const onRestartClick = async (id: number) => {
  const { success } = await restartCdnOriginService(id)
  if (success) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully restarted service', life: 3000 })
  } else {
    toast.add({ severity: 'warn', summary: 'Error', detail: 'Cannot restart service.', life: 3000 })
  }
}

const onStopClick = async (id: number) => {
  const { success } = await stopCdnOriginService(id)
  if (success) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully stopped service', life: 3000 })
  } else {
    toast.add({ severity: 'warn', summary: 'Error', detail: 'Cannot stop service.', life: 3000 })
  }
}



// long running services


const { getLongRunningServices, stopDataBrowserService, restartDataBrowserService } = useLongRunningServices()
const inputLRS = ref({ limit: 100, offset: 0 })
const {
  data: longRunningServices,
  loading: loadingLongRunningServices,
  error: errorLongRunningServices
} = getLongRunningServices(inputLRS)

const onLRSRestartClick = async (id: string) => {
  const { success, error } = await restartDataBrowserService(id)
  if (success) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully restarted service', life: 3000 })
  } else {
    toast.add({ severity: 'warn', summary: 'Error', detail: 'Cannot restart service.', life: 3000 })
  }
}

const onLRSStopClick = async (id: string) => {
  const { success } = await stopDataBrowserService(id)
  if (success) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully stopped service', life: 3000 })
  } else {
    toast.add({ severity: 'warn', summary: 'Error', detail: 'Cannot stop service.', life: 3000 })
  }
}

// watches

watch(errorCdnOrigins, () => {
  if (errorCdnOrigins.value) {
    toast.add({ severity: 'error', summary: 'Error loading CDN origins', detail: 'Try to log in again or contact administrator.' })
  }
})
watch(errorLongRunningServices, () => {
  if (errorLongRunningServices.value) {
    toast.add({ severity: 'error', summary: 'Error loading long running services', detail: 'Try to log in again or contact administrator.' })
  }
})


return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "flex align-items-center justify-content-center" }, "CDN Origins", -1)),
            _createElementVNode("div", _hoisted_4, [
              _withDirectives((_openBlock(), _createBlock(_component_router_link, { to: {
                name: 'service-tasks-create'
              } }, {
                default: _withCtx(() => [
                  _createVNode(_component_Button, {
                    "data-cy": "serviceTasks__CreateServiceTaskButton",
                    type: "button",
                    icon: "fa-solid fa-plus",
                    label: "Create service task"
                  })
                ]),
                _: 1
              })), [
                [
                  _directive_tooltip,
                  'Create new service task',
                  void 0,
                  { bottom: true }
                ]
              ])
            ])
          ])
        ]),
        content: _withCtx(() => [
          _createVNode(_component_DataTable, {
            value: _unref(cdnOrigins),
            onPage: _cache[1] || (_cache[1] = ($event: any) => (_unref(onPage)($event))),
            onSort: _cache[2] || (_cache[2] = ($event: any) => (_unref(onSort)($event))),
            onFilter: _cache[3] || (_cache[3] = ($event: any) => (_unref(onFilter)($event))),
            sortField: _unref(defaultSortField),
            sortOrder: _unref(defaultSortOrder),
            lazy: _unref(lazy),
            class: "p-datatable-sm",
            rows: _unref(rows),
            dataKey: "id",
            rowHover: true,
            filters: _unref(tableFilters),
            "onUpdate:filters": _cache[4] || (_cache[4] = ($event: any) => (_isRef(tableFilters) ? (tableFilters).value = $event : null)),
            filterDisplay: "menu",
            loading: _unref(loadingCdnOrigins),
            paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
            rowsPerPageOptions: _unref(rowsPerPageOptions),
            currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords} entries',
            responsiveLayout: "scroll"
          }, {
            empty: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode(" No tasks found. ")
            ])),
            loading: _withCtx(() => _cache[11] || (_cache[11] = [
              _createElementVNode("i", { class: "fa-solid fa-spinner fa-spin" }, null, -1)
            ])),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "alias",
                header: "Alias",
                filterField: "alias",
                filterMatchMode: "contains",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.alias ?? 'Generating ...'), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "dmCollocationId",
                header: "Collocation",
                filterField: "dmCollocationId",
                filterMatchMode: "contains",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.dmCollocationId), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "cdnOriginType.alias",
                header: "CDN Origin Type",
                filterField: "cdnOriginTypeAlias",
                filterMatchMode: "contains",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createVNode(_component_Tag, {
                    severity: getCdnOriginTypeTag(data.cdnOriginType.alias).severity,
                    rounded: true,
                    icon: getCdnOriginTypeTag(data.cdnOriginType.alias).iconClass,
                    value: getCdnOriginTypeTag(data.cdnOriginType.alias).label,
                    "data-cy": 'serviceTasks__CDN_originType_' + data.cdnOriginType.alias
                  }, null, 8, ["severity", "icon", "value", "data-cy"])
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "status.alias",
                header: "Status",
                filterField: "status.alias",
                filterMatchMode: "contains",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_Tag, {
                      severity: getCdnOriginStatusTag(data.status.alias).severity,
                      rounded: true,
                      icon: getCdnOriginStatusTag(data.status.alias).iconClass,
                      value: getCdnOriginStatusTag(data.status.alias).label,
                      "data-cy": 'serviceTasks__CDN_originStatus'
                    }, null, 8, ["severity", "icon", "value"]),
                    (data.error)
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          icon: "pi pi-exclamation-circle",
                          severity: "danger",
                          size: "small",
                          text: "",
                          rounded: "",
                          onClick: ($event: any) => {errorDialogVisible.value = true; errorMessage.value = data.error}
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_Dialog, {
                    visible: errorDialogVisible.value,
                    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((errorDialogVisible).value = $event)),
                    modal: "",
                    header: "Error description",
                    style: { width: '50vw' }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(errorMessage.value), 1)
                    ]),
                    _: 1
                  }, 8, ["visible"])
                ]),
                filter: _withCtx(({ filterModel, filterCallback }) => [
                  _createVNode(_component_Dropdown, {
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                    onChange: ($event: any) => (filterCallback()),
                    options: Object.keys(_unref(taskStatusMapper)),
                    "scroll-height": "400px",
                    placeholder: "Filter Status...",
                    class: "p-column-filter",
                    style: {"min-width":"12rem"},
                    showClear: true
                  }, {
                    option: _withCtx((slotProps) => [
                      _createVNode(_component_Tag, {
                        value: _unref(taskStatusMapper)[slotProps.option as ApiTaskStatusAlias].label,
                        icon: _unref(taskStatusMapper)[slotProps.option as ApiTaskStatusAlias].iconClass,
                        severity: _unref(taskStatusMapper)[slotProps.option as ApiTaskStatusAlias].severity,
                        rounded: true
                      }, null, 8, ["value", "icon", "severity"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "options"])
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                headerStyle: "width: 8rem; text-align: center",
                bodyStyle: "text-align: right; overflow: visible"
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", _hoisted_6, [
                    (data.status.alias === _unref(CdnOriginStatusAlias).Running)
                      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          "data-cy": "serviceTasks__CDN_origin_stop_serviceButton",
                          type: "button",
                          severity: "danger",
                          icon: "pi pi-power-off",
                          size: "small",
                          onClick: ($event: any) => (onStopClick(data.id)),
                          label: "Stop"
                        }, null, 8, ["onClick"])), [
                          [
                            _directive_tooltip,
                            'Stop service',
                            void 0,
                            { top: true }
                          ]
                        ])
                      : _createCommentVNode("", true),
                    (data.status.alias === _unref(CdnOriginStatusAlias).Stopped)
                      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                          key: 1,
                          "data-cy": "serviceTasks__CDN_origin_restart_serviceButton",
                          type: "button",
                          severity: "success",
                          icon: "fa-solid fa-refresh",
                          size: "small",
                          onClick: ($event: any) => (onRestartClick(data.id)),
                          label: "Restart"
                        }, null, 8, ["onClick"])), [
                          [
                            _directive_tooltip,
                            'Restart service',
                            void 0,
                            { top: true }
                          ]
                        ])
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "sortField", "sortOrder", "lazy", "rows", "filters", "loading", "rowsPerPageOptions"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex align-items-center justify-content-center" }, "Long running services", -1)),
            _createElementVNode("div", _hoisted_9, [
              _withDirectives((_openBlock(), _createBlock(_component_router_link, { to: {
                name: 'service-tasks-create'
              } }, {
                default: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    icon: "fa-solid fa-plus",
                    label: "Create service task"
                  })
                ]),
                _: 1
              })), [
                [
                  _directive_tooltip,
                  'Create new service task',
                  void 0,
                  { bottom: true }
                ]
              ])
            ])
          ])
        ]),
        content: _withCtx(() => [
          _createVNode(_component_DataTable, {
            value: _unref(longRunningServices),
            onPage: _cache[5] || (_cache[5] = ($event: any) => (_unref(onPage)($event))),
            onSort: _cache[6] || (_cache[6] = ($event: any) => (_unref(onSort)($event))),
            onFilter: _cache[7] || (_cache[7] = ($event: any) => (_unref(onFilter)($event))),
            sortField: _unref(defaultSortField),
            sortOrder: _unref(defaultSortOrder),
            lazy: _unref(lazy),
            class: "p-datatable-sm",
            rows: _unref(rows),
            dataKey: "id",
            rowHover: true,
            filters: _unref(tableFilters),
            "onUpdate:filters": _cache[8] || (_cache[8] = ($event: any) => (_isRef(tableFilters) ? (tableFilters).value = $event : null)),
            filterDisplay: "menu",
            loading: _unref(loadingLongRunningServices),
            paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
            rowsPerPageOptions: _unref(rowsPerPageOptions),
            currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords} entries',
            responsiveLayout: "scroll"
          }, {
            empty: _withCtx(() => _cache[13] || (_cache[13] = [
              _createTextVNode(" No tasks found. ")
            ])),
            loading: _withCtx(() => _cache[14] || (_cache[14] = [
              _createElementVNode("i", { class: "fa-solid fa-spinner fa-spin" }, null, -1)
            ])),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "id",
                header: "Id",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.id), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "dmCollocationId",
                header: "Collocation",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createTextVNode(_toDisplayString(data.collocation.id), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "taskType.alias",
                header: "Task Type",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", {
                    "data-cy": 'serviceTasks__LR_taskType_' + data.taskType.alias
                  }, _toDisplayString(data.taskType.alias), 9, _hoisted_10)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                field: "taskStatus.alias",
                header: "Status",
                showFilterMatchModes: false,
                maxConstraints: 1,
                showFilterOperator: false,
                sortable: "",
                style: {"min-width":"10rem"}
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(data.taskStatus.alias), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Column, {
                headerStyle: "width: 8rem; text-align: center",
                bodyStyle: "text-align: right; overflow: visible"
              }, {
                body: _withCtx(({ data }) => [
                  _createElementVNode("span", _hoisted_12, [
                    (data.taskStatus.alias === _unref(DataMgmtTaskStatusAlias).Processing)
                      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          "data-cy": "serviceTasks__LR_task_stop_serviceButton",
                          type: "button",
                          severity: "danger",
                          icon: "pi pi-power-off",
                          size: "small",
                          onClick: ($event: any) => (onLRSStopClick(data.id)),
                          label: "Stop"
                        }, null, 8, ["onClick"])), [
                          [
                            _directive_tooltip,
                            'Stop service',
                            void 0,
                            { top: true }
                          ]
                        ])
                      : _createCommentVNode("", true),
                    ([_unref(DataMgmtTaskStatusAlias).Errored, _unref(DataMgmtTaskStatusAlias).Canceled].includes(data.taskStatus.alias))
                      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                          key: 1,
                          "data-cy": "serviceTasks__LR_task_restart_serviceButton",
                          type: "button",
                          severity: "success",
                          icon: "fa-solid fa-refresh",
                          size: "small",
                          onClick: ($event: any) => (onLRSRestartClick(data.id)),
                          label: "Restart"
                        }, null, 8, ["onClick"])), [
                          [
                            _directive_tooltip,
                            'Restart service',
                            void 0,
                            { top: true }
                          ]
                        ])
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "sortField", "sortOrder", "lazy", "rows", "filters", "loading", "rowsPerPageOptions"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})