import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "mlwn-multiple-input-list" }
const _hoisted_2 = { class: "flex align-items-center text-primary" }
const _hoisted_3 = { class: "slot-block" }
const _hoisted_4 = { class: "flex justify-content-end flex-wrap" }
const _hoisted_5 = { class: "col-12 formgrid grid save-preset-panel" }
const _hoisted_6 = { class: "field col-12 md:col-11" }
const _hoisted_7 = { class: "flex justify-content-start flex-wrap" }
const _hoisted_8 = {
  key: 0,
  class: "save-preset"
}
const _hoisted_9 = { class: "field col-12 md:col-1" }
const _hoisted_10 = { class: "flex justify-content-end flex-wrap" }
const _hoisted_11 = { key: 1 }

import { ref, Ref, watch, computed } from "vue"
import { v4 as uuidv4 } from 'uuid'


export const componentName = 'MultipleInputList'

const __default__ = {
  name: componentName,
}

type ItemDataType<Type> = {
  value?: Type | undefined,
  __key?: string | undefined;
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
  modelValue: {
    type: Array,
  },
  maxItems: {
    type: Number,
    default: -1,
    required: false
  },
  rowClass: {
    type: String,
    required: false,
    default: ''
  },
  title: {
    type: String,
    required: false,
    default: ''
  },
  showTitle: {
    type: Boolean,
    required: false,
    default: true
  },
  defaultRowValue: {
    type: Function,
    required: false,
    default: () => { return { } }
  },
  savePreset: {
    type: Function,
    required: false,
    default: () => { return { } }
  },
  minRowsCount: {
    type: Number,
    required: false,
    default: 0
  },
  savePresetEnable: {
    type: Boolean,
    required: false,
    default: false
  },
  toggleableRow: {
    type: Boolean,
    required: false,
    default: true
  },
  toggleableRowTitleCb: {
    type: Function,
    required: false,
    default: (value: any) => { return '' }
  },
  disableRowRemove: {
    type: Boolean,
    required: false,
    default: false
  }
},
  setup(__props, { emit: __emit }) {

const props = __props

const presetName = ref('')
const saveLabelVisible = ref(false)

const changeEmitName = 'update:modelValue'
const emits = __emit

const model = computed({
  get: () => props?.modelValue,
  set: (value: any) => {
    emits(changeEmitName, value)
  },
})

const addRow = () => {
  if (props.maxItems === -1 || items.value.length < props.maxItems) {
    items.value.push({__key: uuidv4(), value: props.defaultRowValue() as any}) 
  }
}

const onSavePreset = () => {
  saveLabelVisible.value = !saveLabelVisible.value
  if (saveLabelVisible.value) {
    presetName.value = ''
  }
}

const onSave = (name: string) => {
  if (name.length > 0) {    
    props.savePreset(name)
    onSavePreset()
  }
}

const items: Ref<ItemDataType<any>[]> = ref([])

if (props.modelValue) {
  for (const item of props.modelValue) {
    items.value.push({ __key: uuidv4(), value: item })
  }
}

if (props.minRowsCount > 0 && items.value.length < props.minRowsCount) {
  for (let i = 0; i < props.minRowsCount;i++) {
    addRow()
  }
}

const removeRow = (key: string) => {
  const index = items.value.findIndex(item => item.__key === key)
  if (index > -1) {
    items.value.splice(index, 1)
  }
}

watch(items, (newValue, oldValue) => {
  model.value = newValue.map(item => item.value)
}, { deep: true })
    
const layout = ref('list')

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_Fieldset = _resolveComponent("Fieldset")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_DataView = _resolveComponent("DataView")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataView, {
      value: items.value,
      layout: layout.value
    }, _createSlots({
      list: _withCtx((slotProps) => [
        _createElementVNode("div", {
          class: _normalizeClass(["flex flex-row flex-wrap row-data", __props.rowClass])
        }, [
          _createVNode(_component_Fieldset, {
            legend: __props.toggleableRowTitleCb(slotProps.data.value),
            toggleable: __props.toggleableRow,
            collapsed: false,
            class: "mlwn-row-toggle-fieldest"
          }, {
            legend: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", null, _toDisplayString(__props.toggleableRowTitleCb(slotProps.data.value)), 1),
                (!__props.disableRowRemove)
                  ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      class: "remove-button",
                      icon: "fa-solid fa-trash",
                      severity: "danger",
                      "aria-label": "Remove",
                      onClick: ($event: any) => (removeRow(slotProps.data.__key))
                    }, null, 8, ["onClick"])), [
                      [
                        _directive_tooltip,
                        'Remove item',
                        void 0,
                        { bottom: true }
                      ]
                    ])
                  : _createCommentVNode("", true)
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "form", {
                  value: slotProps.data.value,
                  key: slotProps.data.__key
                })
              ])
            ]),
            _: 2
          }, 1032, ["legend", "toggleable"])
        ], 2)
      ]),
      _: 2
    }, [
      (__props.title.length && __props.showTitle)
        ? {
            name: "header",
            fn: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.title), 1)
            ]),
            key: "0"
          }
        : undefined,
      (!__props.disableRowRemove)
        ? {
            name: "footer",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, [
                      (__props.savePresetEnable)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("div", null, [
                              (items.value.length > 0 && !saveLabelVisible.value)
                                ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                                    key: 0,
                                    severity: "secondary",
                                    icon: "fa-solid fa-save",
                                    class: "p-button-sm button-margin",
                                    onClick: onSavePreset
                                  }, null, 512)), [
                                    [
                                      _directive_tooltip,
                                      'Save preset',
                                      void 0,
                                      { left: true }
                                    ]
                                  ])
                                : _createCommentVNode("", true),
                              (saveLabelVisible.value && items.value.length > 0)
                                ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                                    key: 1,
                                    severity: "secondary",
                                    icon: "fa-solid fa-eye-slash",
                                    class: "p-button-sm",
                                    onClick: onSavePreset
                                  }, null, 512)), [
                                    [
                                      _directive_tooltip,
                                      'Hide',
                                      void 0,
                                      { left: true }
                                    ]
                                  ])
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", null, [
                              (saveLabelVisible.value)
                                ? (_openBlock(), _createBlock(_component_InputText, {
                                    key: 0,
                                    modelValue: presetName.value,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((presetName).value = $event)),
                                    placeholder: "New preset name"
                                  }, null, 8, ["modelValue"]))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", null, [
                              (saveLabelVisible.value && items.value.length > 0)
                                ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                                    key: 0,
                                    icon: "fa-solid fa-save",
                                    class: "p-button-sm",
                                    onClick: _cache[1] || (_cache[1] = ($event: any) => (onSave(presetName.value)))
                                  }, null, 512)), [
                                    [
                                      _directive_tooltip,
                                      'Save preset',
                                      void 0,
                                      { left: true }
                                    ]
                                  ])
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("span", _hoisted_10, [
                      (__props.maxItems === -1 || items.value.length < __props.maxItems)
                        ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            icon: "fa-solid fa-plus",
                            class: "p-button-sm",
                            onClick: addRow
                          }, null, 512)), [
                            [
                              _directive_tooltip,
                              'Add item',
                              void 0,
                              { left: true }
                            ]
                          ])
                        : (_openBlock(), _createElementBlock("span", _hoisted_11, "Can not add more lines. Maximum is " + _toDisplayString(__props.maxItems), 1))
                    ])
                  ])
                ])
              ])
            ]),
            key: "1"
          }
        : undefined
    ]), 1032, ["value", "layout"])
  ]))
}
}

})