<template>
  <div class="grid">
    <div class="col-12">
      <div class="p-fluid">
        <Message v-if="!update" severity="info" :closable="false"
          >After volume register, data in volume must not be changed outside of
          Honeydew system.</Message
        >
        <br v-if="!update" />
        <Form
          v-if="update || !volumeLoading"
          :initial-values="initialValues"
          :validation-schema="schema"
          @submit="(values) => onSubmit(values as VolumeCreateInput | VolumeUpdateInput)"
          class="grid p-fluid"
        >
          <div class="col-12 col:md-6 formgrid">
            <InputTextWithValidation
              name="name"
              data-cy="volumeForm__name"
              label="Name"
            ></InputTextWithValidation>

            <InputNumberWithValidation
              name="sizeReservedMB"
              data-cy="volumeForm__sizeReserved"
              label="Reserved size [MB]"
            ></InputNumberWithValidation>

            <InputTextAreaWithValidation
              name="remotePath"
              data-cy="volumeForm__remotePath"
              label="Remote path"
              :disabled="update"
              :hint-title="'Remote path example'"
              :hint-content="hint.remotePath"
            ></InputTextAreaWithValidation>

            <InputJSONWithValidation
              name="mountOptions"
              label="Mount options"
              :hint-title="'Mount options setup'"
              :hint-content="hint.mountOptions"
            ></InputJSONWithValidation>

            <div class="flex flex-row flex-wrap justify-content-end">
              <Button
                data-cy="volumeForm__registerButton"
                :label="update ? 'Save changes' : 'Register volume'"
                type="submit"
                icon="fa-solid fa-check"
                class="p-button-success mr-2 mb-2 mlwn-button-submit"
              ></Button>

              <Button
                @click="onCancelClick"
                label="Cancel"
                icon="fa-solid fa-xmark"
                class="p-button-secondary mr-2 mb-2 mlwn-button-submit"
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Form } from "vee-validate";
import { Ref, ref, watch } from "vue";
import { useToast } from "primevue/usetoast";
import {
  StorageTypeEnum,
  Volume,
  VolumeCreateInput,
  VolumeUpdateInput,
} from "@/gql/graphql";
import { useVolumes } from "@/composables/useVolumes";
import * as yup from "yup";
import { useStorages } from "@/composables/useStorages";
import { useRouter } from "vue-router";

import InputTextWithValidation from "@/components/UI/Form/components/InputTextWithValidation.vue";
import InputNumberWithValidation from "@/components/UI/Form/components/InputNumberWithValidation.vue";
import InputTextAreaWithValidation from "@/components/UI/Form/components/InputTextAreaWithValidation.vue";
import InputJSONWithValidation from "@/components/UI/Form/components/InputJSONWithValidation.vue";

import { VolumeFormHint } from "./VolumeFormHint";

const props = defineProps({
  storageId: {
    required: true,
    type: String,
  },
  volumeMountAlias: {
    required: false,
    type: String,
  },
  update: {
    required: false,
    default: true,
  },
});

const { getVolume, registerVolume, updateVolume } = useVolumes();

const initialValues: Ref<Partial<Volume> | Volume> = ref({
  name: "",
  sizeReservedMB: 0,
  remotePath: "",
  storageId: props.storageId,
});
const { volume, loading: volumeLoading } = props.update && props.volumeMountAlias
  ? getVolume(props?.volumeMountAlias)
  : {
    volume: initialValues,
    loading: false
  };

watch(volume, () => {
  initialValues.value = Object.assign({}, volume.value)
})

/**
 * Validation setup
 */
let schema = yup.object({
  name: yup.string().required().min(3).max(255).label("Name"),
  sizeReservedMB: yup.number().required().label("Reserved size [MB]"),
  remotePath: yup.string().required().min(1).max(500).label("Remote path"),
  mountOptions: yup.object().json().nullable().label("Mount options"),
});

const toast = useToast();
const router = useRouter();

const onRegisterVolume = async (volume: VolumeCreateInput) => {
  try {
    let res = await registerVolume(volume);

    if (res.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Succesfully registred",
        life: 3000,
      });
      // redirect logic
      router.push({
        name: "storage-volume-detail",
        params: {
          storageId: props.storageId,
          volumeMountAlias: res?.payload?.volume?.mountAlias,
        },
      });
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Error occured while saving 1",
        life: 3000,
      });
    }
  } catch (err) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error occured while saving 2",
      life: 3000,
    });
  }
};

const onUpdateVolume = async (volume: VolumeUpdateInput) => {
  try {
    if (props.volumeMountAlias === undefined) return
    let res = await updateVolume({
      mountAlias: props.volumeMountAlias,
      name: volume.name,
      sizeReservedMB: volume.sizeReservedMB,
      mountOptions: volume.mountOptions,
    });

    if (res.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Succesfully updated",
        life: 3000,
      });
      router.push({
        name: "storage-volume-detail",
        params: {
          storageId: props.storageId,
          volumeMountAlias: res?.payload?.volume?.mountAlias,
        },
      });
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Error occured while saving. ",
        life: 3000,
      });
    }
  } catch (err: any) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error occured while saving. " + err?.message,
      life: 3000,
    });
    console.error(err);
  }
};

const onSubmit = async (volume: VolumeCreateInput | VolumeUpdateInput) => {
  if (typeof volume.mountOptions === "string") {
    volume.mountOptions = JSON.parse(volume.mountOptions);
  }

  if (props.update) {
    onUpdateVolume(volume as VolumeUpdateInput);
  } else {
    onRegisterVolume(volume as VolumeCreateInput);
  }
};

function onCancelClick() {
  router.go(-1);
}

const { getStorage } = useStorages();

const { storage } = getStorage(props.storageId);
const hint = ref({
  remotePath: "",
  mountOptions: "",
});
watch(storage, () => {
  if (storage.value && storage.value.type) {
    switch (storage.value?.type) {
    case StorageTypeEnum.Nfs:
      hint.value.mountOptions = VolumeFormHint.nfs.mountOptions;
      hint.value.remotePath = VolumeFormHint.nfs.remotePath;
      break;
    case StorageTypeEnum.S3fs:
      hint.value.mountOptions = VolumeFormHint.s3.mountOptions;
      hint.value.remotePath = VolumeFormHint.s3.remotePath;
      break;
    }
  }
});
</script>

<style lang="scss" scoped>
form {
  margin-top: 0;
}
</style>
