import { ApolloClient, createHttpLink, DefaultOptions, InMemoryCache } from '@apollo/client/core'
import { ApolloLink, concat, } from '@apollo/client/core'
import router from '../router/index'
import Swal from 'sweetalert2'
import { useAuth } from '@/composables/useAuth'
import { GraphQLFormattedError } from 'graphql'

const GC_AUTH_TOKEN = 'gc-auth-token'

export const gcAuthToken = localStorage.getItem(GC_AUTH_TOKEN) || ''
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: `Bearer ${gcAuthToken}`
    }
  })

  return forward(operation)
})

// Cache implementation
const cache = new InMemoryCache({
  typePolicies: {
    Volume: {
      keyFields: ["mountAlias"],
    },
  },
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

import { onError } from "@apollo/client/link/error"

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      if (err?.extensions?.code === 'RESOURCE_NOT_FOUND') {
        if(err.path && err.path[0] !== 'getDataBrowserList') {
          router.replace({ name: '404' }) 
        } else {
          console.log(
            `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}, Code: ${err.extensions.code}`
          )    
        }
      } else if (err?.extensions?.code === "UNAUTHENTICATED") {
        if (err.path && err.path[0] !== 'loginMicrosoft') {
          const { logoutWithoutDataLose } = useAuth()
          logoutWithoutDataLose()
        }
      } else {
        console.log(
          `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}, Code: ${err?.extensions?.code}`
        )  
      }
    })
  }

  if (networkError) {
    let timerInterval: string
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Connection to server lost, try again later...',
      timer: 10000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
        clearInterval(timerInterval)
        // window.location.reload()
      }
    })
    console.log(`[Network error]: ${networkError}`)
  }
})

export const initApolloClient = (apiUrl: string): ApolloClient<unknown> => {
  // HTTP connection to the API
  const httpLink = createHttpLink({
    uri: apiUrl,
    credentials: "same-origin"
  })

  const apollo = new ApolloClient({
    link: concat(errorLink, concat(authMiddleware, httpLink)),
    cache,
    defaultOptions
  })

  return apollo
}
