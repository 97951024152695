<template>
  <div class="p-grid">
    <div v-if="loading"><ProgressSpinner /></div>

    <div v-else-if="users">
      <DataTable 
          :value="users" 
          :paginator="true"
          @page="onPage($event)"
          @sort="onSort($event)" 
          @filter="onFilter()" 
          :sortField="defaultSortField" 
          :sortOrder="defaultSortOrder" 
          class="p-datatable-presets p-datatable-sm" 
          :rows="rows"
          dataKey="id" 
          :rowHover="true" 
          v-model:filters="tableFilters" 
          filterDisplay="menu" 
          :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
          :rowsPerPageOptions="rowsPerPageOptions"
          :currentPageReportTemplate="'Showing {first} to {last} of {totalRecords} entries'"
          responsiveLayout="scroll">
        <template #empty>
            No users found.
        </template>
        <template #loading>
            <ProgressSpinner />
        </template>
        <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
        <Column field="name" header="Name" sortable style="min-width: 10rem">
            <template #body="{data}">
                {{data.name}}
            </template>
            <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
            </template>
        </Column>
        <Column field="surname" header="Surname" sortable style="min-width: 10rem">
            <template #body="{data}">
                {{data.surname}}
            </template>
            <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by surname"/>
            </template>
        </Column>
        <Column field="role.name" header="Role" sortable style="min-width: 3rem">
          <template #body="{data}">
              {{data.role.name}}
          </template>
          <template #filter="{ filterModel }">
            <Dropdown v-model="filterModel.value" :options="roles" optionValue="alias" optionLabel="name" placeholder="Select roles" />
          </template>
        </Column>
        <Column field="flagActive" header="Active" dataType="boolean" bodyClass="p-text-center" style="min-width:8rem">
          <template #body="{data}">
              <i class="pi" :class="{'true-icon pi-check-circle': data.flagActive, 'false-icon pi-times-circle': !data.flagActive}"></i>
          </template>
          <template #filter={filterModel}>
              <TriStateCheckbox v-model="filterModel.value" /> active
          </template>
        </Column>
        <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
          <template #body="{data}">
              <Button type="button" icon="fa-solid fa-pencil" @click="onEditClick(data)"></Button>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { FilterMatchMode } from "primevue/api"
import { useRouter } from 'vue-router';
import { User } from '@/gql/graphql';
import { useUsers } from '@/composables/useUsers';

const toast = useToast()
const { getUsers, getRoles } = useUsers()
const { users, loading, error: errorUsers } = getUsers()
const { roles } = getRoles()


const router = useRouter()
const _query = router.currentRoute.value.query

import { useTables } from '@/composables/useTables';
const {
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows
} = useTables({
  filters: {
    name: {
      value: _query.name ? _query.name : null,
      matchMode: FilterMatchMode.CONTAINS, 
      pretty: "name"
    },
    surname: {
      value: _query.surname ? _query.surname : null,
      matchMode: FilterMatchMode.CONTAINS, 
      pretty: "surname"
    },
    flagActive: {
      value: _query.flagActive ? _query.flagActive : null,
      matchMode: FilterMatchMode.CONTAINS, 
      pretty: "flagActive"
    },
    'role.name': {
      value: _query.role ? _query.role : null,
      matchMode: FilterMatchMode.EQUALS, 
      pretty: "role"
    },
  }
})

// watches
watch(errorUsers, () => {
  if (errorUsers.value) {
    toast.add({ severity: 'error', summary: 'Error loading users', detail: 'Try to log in again or contact administrator.' })
  }
})

const onEditClick = (user: User) => {
  router.push({
    name: 'user-detail',
    params: {
      userId: user.id
    }
  })
}
</script>

<style lang="scss" scoped>
	@media screen and (max-width: 960px) {
		::v-deep(.p-datatable) {
			&.p-datatable-customers {
				.p-datatable-thead > tr > th,
				.p-datatable-tfoot > tr > td {
					display: none !important;
				}

				.p-datatable-tbody > tr {
					border-bottom: 1px solid #dee2e6;
					> td {
						text-align: left;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 0 none !important;
						width: 100% !important;
						float: left;
						clear: left;
						border: 0 none;

						.p-column-title {
							padding: .4rem;
							min-width: 30%;
							display: inline-block;
							margin: -.4rem 1rem -.4rem -.4rem;
							font-weight: bold;
						}

						.p-progressbar {
							margin-top: .5rem;
						}
					}
				}
			}
		}
	}
</style>
