import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }

import SceneMap from '@/components/Admin/Project/Scene/Map/SceneMap.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SceneInTechnicalQcReview',
  props: {
  sceneId: {
    required: true,
    type: String
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(SceneMap, { "scene-id": __props.sceneId }, null, 8, ["scene-id"])
    ])
  ]))
}
}

})