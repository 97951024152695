import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 md:col-6" }
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = { class: "col-12 col:md-6 formgrid" }
const _hoisted_5 = { class: "flex flex-row flex-wrap justify-content-end" }

import { Form } from 'vee-validate';
import * as yup from 'yup';
import { ComputedRef, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { useProject } from '@/composables/useProject';
import { Project } from '@/gql/graphql'
import InputTextWithValidation from '@/components/UI/Form/components/InputTextWithValidation.vue';
import InputTextAreaWithValidation from '@/components/UI/Form/components/InputTextAreaWithValidation.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectForm',
  props: {
  projectId: {
    type: String,
    required: false,
    default: ''
  },
  update: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {

/**
 * Load validation references, router, vue
 */
const router = useRouter()
const toast = useToast()

/**
 * Setup props
 */
const props = __props


/**
 * Load project composable
 */
const { getProject, createProject, updateProject } = useProject()
let project: Ref<Project|{}> | ComputedRef<Project> | null = null
if (props.update) {
  project = getProject(parseInt(props.projectId)).project
} else {
  project = ref({
    name: '',
    externalUri: ''
  })
}

const initialValues = project

/**
 * Validation setup
 */
const schema = yup.object({
  name: yup.string().required().min(5).max(255).label('Name'),
  externalUri: yup.string().url().label('External uri'),
  // notes: yup.label('Notes'),
});

const onUpdateProject = async (projectForm: Project) => {
  try {
    let res = await updateProject(Object.assign(project?.value ?? {}, projectForm))
    if(res.success){
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully updated', life: 3000 })
      router.push({
        name: 'project-detail',
        params: {
          projectId: res?.payload?.project?.id
        }
      })
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while saving', life: 3000})
    }
  } catch (err) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while saving', life: 3000})
    console.error(err)
  }
}

const onCreateProject = async (project: Project) => {
  try {
    const res = await createProject(project)

    if (res.success) {
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully created', life: 3000 })
      router.push({
        name: 'project-detail',
        params: {
          projectId: res?.payload?.project?.id
        }
      })
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating', life: 3000})
    }
  } catch (err: any) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating. ' + err?.message, life: 3000})
    console.error(err)
  }
}

function onSubmit(project: Project) {
  if (props.update) {
    onUpdateProject(project)
  } else {
    onCreateProject(project)
  }
}

function onCancelClick() {
  router.go(-1)
}

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Form), {
          "initial-values": _unref(initialValues),
          "validation-schema": _unref(schema),
          onSubmit: onSubmit,
          class: "grid p-fluid"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(InputTextWithValidation, {
                name: "name",
                label: "Name",
                "data-cy": "projectForm__newProjectName"
              }),
              _createVNode(InputTextWithValidation, {
                name: "externalUri",
                label: "External uri",
                "data-cy": "projectForm__newProjectUri"
              }),
              _createVNode(InputTextAreaWithValidation, {
                name: "notes",
                label: "Notes",
                "data-cy": "projectForm__newProjectNotes"
              }),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Button, {
                  label: "Save",
                  type: "submit",
                  icon: "fa-solid fa-check",
                  "data-cy": "projectForm__newProjectCreate",
                  class: "p-button-success mr-2 mb-2 mlwn-button-submit"
                }),
                _createVNode(_component_Button, {
                  "data-cy": "projectForm__newProjecCancel",
                  onClick: onCancelClick,
                  label: "Cancel",
                  icon: "fa-solid fa-xmark",
                  class: "p-button-secondary mr-2 mb-2 mlwn-button-submit"
                })
              ])
            ])
          ]),
          _: 1
        }, 8, ["initial-values", "validation-schema"])
      ])
    ])
  ]))
}
}

})