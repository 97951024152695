<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>
          <div class="flex justify-content-between flex-wrap">
            <div class="flex align-items-center justify-content-center">CDN Origins</div>
            <div class="flex align-items-center justify-content-center">
              <router-link :to="{
                name: 'service-tasks-create'
              }" v-tooltip.bottom="'Create new service task'">
                <Button data-cy="serviceTasks__CreateServiceTaskButton" type="button" icon="fa-solid fa-plus" label="Create service task"></Button>
              </router-link>
            </div>
          </div>
        </template>
        <template #content>


          <DataTable :value="cdnOrigins" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)"
            :sortField="defaultSortField" :sortOrder="defaultSortOrder" :lazy="lazy" class="p-datatable-sm" :rows="rows"
            dataKey="id" :rowHover="true" v-model:filters="tableFilters" filterDisplay="menu" :loading="loadingCdnOrigins"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            :currentPageReportTemplate="'Showing {first} to {last} of {totalRecords} entries'" responsiveLayout="scroll">
            <template #empty>
              No tasks found.
            </template>
            <template #loading>
              <i class="fa-solid fa-spinner fa-spin"></i>
            </template>

            <Column field="alias" header="Alias" filterField="alias" filterMatchMode="contains"
              :showFilterMatchModes="false" :maxConstraints="1" :showFilterOperator="false" sortable
              style="min-width: 10rem">
              <template #body="{ data }">
                {{ data.alias ?? 'Generating ...' }}
              </template>
            </Column>
            <Column field="dmCollocationId" header="Collocation" filterField="dmCollocationId" filterMatchMode="contains"
              :showFilterMatchModes="false" :maxConstraints="1" :showFilterOperator="false" sortable
              style="min-width: 10rem">
              <template #body="{ data }">
                {{ data.dmCollocationId }}
              </template>
            </Column>
            <Column field="cdnOriginType.alias" header="CDN Origin Type" filterField="cdnOriginTypeAlias"
              filterMatchMode="contains" :showFilterMatchModes="false" :maxConstraints="1" :showFilterOperator="false"
              sortable style="min-width: 10rem">
              <template #body="{ data }">
                <Tag :severity="getCdnOriginTypeTag(data.cdnOriginType.alias).severity" :rounded="true"
                  :icon="getCdnOriginTypeTag(data.cdnOriginType.alias).iconClass"
                  :value="getCdnOriginTypeTag(data.cdnOriginType.alias).label"
                  :data-cy="'serviceTasks__CDN_originType_' + data.cdnOriginType.alias">
                </Tag>
              </template>
              <!--<template #filter="{ filterModel, filterCallback }">
                <Dropdown v-model="filterModel.value" @change="filterCallback()" scroll-height="400px"
                  :options="Object.keys(cdnOriginTypeMapper)" placeholder="Filter Type..." class="p-column-filter"
                  style="min-width: 12rem" :showClear="true">
                  <template #option="slotProps">
                    <Tag :value="getCdnOriginTypeTag(slotProps.option).label"
                      :icon="getCdnOriginTypeTag(slotProps.option).iconClass"
                      :severity="getCdnOriginTypeTag(slotProps.option).severity" :rounded="true" />
                  </template>
                </Dropdown>
              </template>-->
            </Column>
            <Column field="status.alias" header="Status" filterField="status.alias" filterMatchMode="contains"
              :showFilterMatchModes="false" :maxConstraints="1" :showFilterOperator="false" sortable
              style="min-width: 10rem">
              <template #body="{ data }">
                <div class="center-space-between">
                  <Tag :severity="getCdnOriginStatusTag(data.status.alias).severity" :rounded="true"
                    :icon="getCdnOriginStatusTag(data.status.alias).iconClass"
                    :value="getCdnOriginStatusTag(data.status.alias).label"
                    :data-cy="'serviceTasks__CDN_originStatus'">
                  </Tag>
                  <Button icon="pi pi-exclamation-circle" severity="danger" size="small" text rounded
                    @click='errorDialogVisible = true; errorMessage = data.error' v-if="data.error" />
                </div>
                <Dialog v-model:visible="errorDialogVisible" modal header="Error description" :style="{ width: '50vw' }">
                  <p>{{ errorMessage }}</p>
                </Dialog>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="Object.keys(taskStatusMapper)" scroll-height="400px"
                  placeholder="Filter Status..." class="p-column-filter" style="min-width: 12rem" :showClear="true">
                  <template #option="slotProps">
                    <Tag :value="taskStatusMapper[slotProps.option as ApiTaskStatusAlias].label"
                      :icon="taskStatusMapper[slotProps.option as ApiTaskStatusAlias].iconClass"
                      :severity="taskStatusMapper[slotProps.option as ApiTaskStatusAlias].severity" :rounded="true" />
                  </template>
                </Dropdown>
              </template>
            </Column>

            <Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: right; overflow: visible">
              <template #body="{ data }">
                <span class="action-buttons">
                  <Button data-cy="serviceTasks__CDN_origin_stop_serviceButton" type="button" severity="danger" icon="pi pi-power-off" size="small"
                    @click="onStopClick(data.id)" v-tooltip.top="'Stop service'" label="Stop"
                    v-if="data.status.alias === CdnOriginStatusAlias.Running">
                  </Button>
                  <Button data-cy="serviceTasks__CDN_origin_restart_serviceButton" type="button" severity="success" icon="fa-solid fa-refresh" size="small"
                    @click="onRestartClick(data.id)" v-tooltip.top="'Restart service'" label="Restart"
                    v-if="data.status.alias === CdnOriginStatusAlias.Stopped">
                  </Button>
                </span>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
  </div>

    <div class="col-12">
      <Card>
        <template #title>
          <div class="flex justify-content-between flex-wrap">
            <div class="flex align-items-center justify-content-center">Long running services</div>
            <div class="flex align-items-center justify-content-center">
              <router-link :to="{
                name: 'service-tasks-create'
              }" v-tooltip.bottom="'Create new service task'">
                <Button type="button" icon="fa-solid fa-plus" label="Create service task"></Button>
              </router-link>
            </div>
          </div>
        </template>
        <template #content>


          <DataTable :value="longRunningServices" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)"
            :sortField="defaultSortField" :sortOrder="defaultSortOrder" :lazy="lazy" class="p-datatable-sm" :rows="rows"
            dataKey="id" :rowHover="true" v-model:filters="tableFilters" filterDisplay="menu"
            :loading="loadingLongRunningServices"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            :currentPageReportTemplate="'Showing {first} to {last} of {totalRecords} entries'" responsiveLayout="scroll">
            <template #empty>
              No tasks found.
            </template>
            <template #loading>
              <i class="fa-solid fa-spinner fa-spin"></i>
            </template>

            <Column field="id" header="Id" :showFilterMatchModes="false" :maxConstraints="1" :showFilterOperator="false"
              sortable style="min-width: 10rem">
              <template #body="{ data }">
                {{ data.id }}
              </template>
            </Column>
            <Column field="dmCollocationId" header="Collocation" :showFilterMatchModes="false" :maxConstraints="1"
              :showFilterOperator="false" sortable style="min-width: 10rem">
              <template #body="{ data }">
                {{ data.collocation.id }}
              </template>
            </Column>
            <Column field="taskType.alias" header="Task Type" :showFilterMatchModes="false" :maxConstraints="1"
              :showFilterOperator="false" sortable style="min-width: 10rem">
              <template #body="{ data }">
                <span :data-cy="'serviceTasks__LR_taskType_' + data.taskType.alias">{{ data.taskType.alias }}</span>
              </template>
            </Column>
            <Column field="taskStatus.alias" header="Status" :showFilterMatchModes="false" :maxConstraints="1"
              :showFilterOperator="false" sortable style="min-width: 10rem">
              <template #body="{ data }">
                <span :data-cy="'serviceTasks__LR_taskStatus'">{{ data.taskStatus.alias }}</span>
              </template>
            </Column>
            <Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: right; overflow: visible">
              <template #body="{ data }">
                <span class="action-buttons">
                  <Button data-cy="serviceTasks__LR_task_stop_serviceButton" type="button" severity="danger" icon="pi pi-power-off" size="small"
                    @click="onLRSStopClick(data.id)" v-tooltip.top="'Stop service'" label="Stop"
                    v-if="data.taskStatus.alias === DataMgmtTaskStatusAlias.Processing">
                  </Button>
                  <Button data-cy="serviceTasks__LR_task_restart_serviceButton" type="button" severity="success" icon="fa-solid fa-refresh" size="small"
                    @click="onLRSRestartClick(data.id)" v-tooltip.top="'Restart service'" label="Restart"
                    v-if="[DataMgmtTaskStatusAlias.Errored, DataMgmtTaskStatusAlias.Canceled].includes(data.taskStatus.alias)">
                  </Button>
                </span>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useCdn } from '@/composables/useCdn';
import { useTables } from '@/composables/useTables';
import { FilterMatchMode } from 'primevue/api';
import { ApiTaskStatusAlias, taskStatusMapper, cdnOriginStatusMapper, cdnOriginTypeMapper, other } from './ServiceTasksConfig';
import { CdnOriginStatusAlias, CdnOriginTypeAlias, DataMgmtTaskStatusAlias } from '@/gql/graphql';
import { useToast } from 'primevue/usetoast';
import { useLongRunningServices } from '@/composables/useLongRunningServices';

const getCdnOriginTypeTag = (alias: CdnOriginTypeAlias) =>
  cdnOriginTypeMapper[alias] || other

const getCdnOriginStatusTag = (alias: CdnOriginStatusAlias) =>
  cdnOriginStatusMapper[alias] || other

const { getCdnOrigins, restartCdnOriginService, stopCdnOriginService } = useCdn()
const input = ref({ limit: 100, offset: 0 })
const {
  data: cdnOrigins,
  loading: loadingCdnOrigins,
  error: errorCdnOrigins,
} = getCdnOrigins(input)

const toast = useToast()

const errorDialogVisible = ref(false)
const errorMessage = ref("")

const {
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows,
  lazy
} = useTables({

  filters: {
    global: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "global"
    },
    id: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "id"
    },
    dmCollocationId: {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
      pretty: "dmCollocationId"
    }
  }
})

const onRestartClick = async (id: number) => {
  const { success } = await restartCdnOriginService(id)
  if (success) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully restarted service', life: 3000 })
  } else {
    toast.add({ severity: 'warn', summary: 'Error', detail: 'Cannot restart service.', life: 3000 })
  }
}

const onStopClick = async (id: number) => {
  const { success } = await stopCdnOriginService(id)
  if (success) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully stopped service', life: 3000 })
  } else {
    toast.add({ severity: 'warn', summary: 'Error', detail: 'Cannot stop service.', life: 3000 })
  }
}



// long running services


const { getLongRunningServices, stopDataBrowserService, restartDataBrowserService } = useLongRunningServices()
const inputLRS = ref({ limit: 100, offset: 0 })
const {
  data: longRunningServices,
  loading: loadingLongRunningServices,
  error: errorLongRunningServices
} = getLongRunningServices(inputLRS)

const onLRSRestartClick = async (id: string) => {
  const { success, error } = await restartDataBrowserService(id)
  if (success) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully restarted service', life: 3000 })
  } else {
    toast.add({ severity: 'warn', summary: 'Error', detail: 'Cannot restart service.', life: 3000 })
  }
}

const onLRSStopClick = async (id: string) => {
  const { success } = await stopDataBrowserService(id)
  if (success) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully stopped service', life: 3000 })
  } else {
    toast.add({ severity: 'warn', summary: 'Error', detail: 'Cannot stop service.', life: 3000 })
  }
}

// watches

watch(errorCdnOrigins, () => {
  if (errorCdnOrigins.value) {
    toast.add({ severity: 'error', summary: 'Error loading CDN origins', detail: 'Try to log in again or contact administrator.' })
  }
})
watch(errorLongRunningServices, () => {
  if (errorLongRunningServices.value) {
    toast.add({ severity: 'error', summary: 'Error loading long running services', detail: 'Try to log in again or contact administrator.' })
  }
})

</script>

<style>
.center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
