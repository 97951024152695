import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = { class: "grid col-12 formgrid" }
const _hoisted_5 = { class: "grid col-12 formgrid" }
const _hoisted_6 = { class: "field col-12 md:col-3" }
const _hoisted_7 = { class: "grid col-12 formgrid" }
const _hoisted_8 = { class: "field col-12 md:col-3" }
const _hoisted_9 = { class: "field col-12 md:col-3" }
const _hoisted_10 = { class: "field col-12 md:col-3" }
const _hoisted_11 = { class: "grid col-12 formgrid" }
const _hoisted_12 = { class: "field col-12 md:col-3" }
const _hoisted_13 = { class: "field col-12 md:col-3" }
const _hoisted_14 = { class: "grid col-12 formgrid" }
const _hoisted_15 = { class: "field col-12 md:col-3" }
const _hoisted_16 = { class: "field col-12 md:col-3" }
const _hoisted_17 = { class: "field col-12 md:col-6" }
const _hoisted_18 = { class: "formgroup-inline" }
const _hoisted_19 = { class: "grid col-12 formgrid" }
const _hoisted_20 = { class: "field col-12 md:col-3" }
const _hoisted_21 = { class: "field col-12 md:col-3" }
const _hoisted_22 = { class: "field col-12 md:col-6" }
const _hoisted_23 = { class: "formgroup-inline" }
const _hoisted_24 = { class: "grid col-12 formgrid" }
const _hoisted_25 = { class: "field col-12 md:col-3" }
const _hoisted_26 = { class: "field col-12 md:col-3" }
const _hoisted_27 = { class: "field col-12 md:col-6" }
const _hoisted_28 = { class: "formgroup-inline" }
const _hoisted_29 = { class: "field col-12 md:col-12 mt-6" }
const _hoisted_30 = { class: "flex flex-row flex-wrap justify-content-between" }

import { Form } from 'vee-validate';
import * as yup from 'yup';
import { ComputedRef, Ref, nextTick, ref, watch } from 'vue';
import { computed } from 'vue';

import InputTextWithValidation from '@/components/UI/Form/components/InputTextWithValidation.vue';
import ListWithValidation from '@/components/UI/Form/components/ListWithValidation.vue';
import DropdownWithValidation from '@/components/UI/Form/components/DropdownWithValidation.vue';
import DataBrowseSelectWithValidation from '@/components/UI/Form/components/DataBrowseSelectWithValidation.vue';
import { LidarDefModelType, LidarRule } from '@/helpers/types/PresetTypes'
import { CameraDefModelType, CameraRule } from '@/helpers/types/PresetTypes'
import { TrajectoryDefModelType, TrajectoryRule } from '@/helpers/types/PresetTypes'
import { useRouter } from 'vue-router';
import { Preset } from '@/gql/graphql';
import { PRESET_TYPES } from '@/helpers/constants';
import { usePresets } from '@/composables/usePresets';
import { useToast } from 'primevue/usetoast';

// Define the event emitter

export default /*@__PURE__*/_defineComponent({
  __name: 'InputConfigurations',
  props: {
  formData: {
    type: Object,
    required: true
  }
},
  emits: ['next', 'prevPage', 'onCancel'],
  setup(__props, { emit: __emit }) {

/**
 * Load validation references, router, vue
 */
const emit = __emit

const onCancel = () => {
  emit('onCancel', router.currentRoute.value.name)
}

const stepBack = () => {
  emit('prevPage', router.currentRoute.value.name)
}

const props = __props

const getLidarRules = () => {
  const lr = props.formData?.populationConfig?.LidarDef?.map((item: LidarDefModelType) => { return { name: item.name, rule: '' } }) ?? []
  if (Array.isArray(props.formData?.populationConfig?.LidarRules)) {
    for (const rule of props.formData.populationConfig.LidarRules) {
      const i = lr.findIndex((item: LidarRule) => item.name === rule.name)
      if(i >= 0)
        lr[i].rule = rule.rule
    }
  }
  return lr
}

const getTrajectoryRules = () => {
  const tr = props.formData?.populationConfig?.TrajectoryDef?.map((item: TrajectoryDefModelType) => { return { name: item.name, rule: '' } }) ?? []
  if (Array.isArray(props.formData?.populationConfig?.TrajectoryRules)) {
    for (const rule of props.formData.populationConfig.TrajectoryRules) {
      const i = tr.findIndex((item: TrajectoryRule) => item.name === rule.name)
      if(i >= 0)
        tr[i].rule = rule.rule
    }
  }
  return tr
}

const getCameraRules = () => {
  const cr = props.formData?.populationConfig?.CameraDef?.map((item: CameraDefModelType) => { return { name: item.name, rule: '' } }) ?? []
  if (Array.isArray(props.formData?.populationConfig?.CameraRules)) {
    for (const rule of props.formData.populationConfig.CameraRules) {
      const i = cr.findIndex((item: CameraRule) => item.name === rule.name)
      if(i >= 0)
        cr[i].rule = rule.rule
    }
  }
  return cr
}

const initialValues = ref({
  srs: props.formData?.populationConfig?.srs ?? {
    path: '',
    volumeMountAlias: ''
  },
  eopsAngleUnit: props.formData?.populationConfig?.eopsAngleUnit ?? 'deg',
  //@ts-ignore
  eopsDir: props.formData?.populationConfig?.eopsDir?.map(item => { return { _browser: item } }) ?? [{
    path: '',
    volumeMountAlias: '',
  }],
  //@ts-ignore
  lidarDir: props.formData?.populationConfig?.lidarDir?.map(item => { return { _browser: item } }) ?? [{
    path: '',
    volumeMountAlias: '',
  }],
  //@ts-ignore
  trajectoryDir: props.formData?.populationConfig?.trajectoryDir?.map(item => { return { _browser: item } }) ?? [{
    path: '',
    volumeMountAlias: '',
  }],
  //@ts-ignore
  imageDir: props.formData?.populationConfig?.imageDir?.map(item => { return { _browser: item } }) ?? [{
    path: '',
    volumeMountAlias: '',
  }],
  dem: props.formData?.populationConfig?.dem ?? {
    path: '',
    volumeMountAlias: '',
  },
  demResolution: props.formData?.populationConfig?.demResolution ?? 10,

  camera: props.formData?.populationConfig?.Camera ? true : false,
  lidar: props.formData?.populationConfig?.Lidar ? true : false,
  trajectory: props.formData?.populationConfig?.Trajectory ? true : false,

  LidarRules: getLidarRules(),
  TrajectoryRules: getTrajectoryRules(),
  CameraRules: getCameraRules()
})

const eopsAngleUnitOptions = [{
  value: 'deg',
  label: 'Degrees'
}, {
  value: 'rad',
  label: 'Radians'
}, {
  value: 'gon',
  label: 'Gons (gradians)'
}]

/**
 * Validation setup
 */
const schema = yup.object({
  srs: yup.object().shape({
    _browser: yup.object().shape({
      volumeMountAlias: yup.string().required().label('Volume Mount Alias'),
      path: yup.string().required().label('Value')
    }).strict()
  }).label('srs'),
  eopsAngleUnit: yup.string().required().label('Eops angle unit'),
  eopsDir: yup.array().of(yup.object().shape({
    _browser: yup.object().shape({
      volumeMountAlias: yup.string().required().label('Volume Mount Alias'),
      path: yup.string().required().label('Value')
    }).strict()
  })).strict(),
  lidarDir: yup.array().of(yup.object().shape({
    _browser: yup.object().shape({
      volumeMountAlias: yup.string().required().label('Volume Mount Alias'),
      path: yup.string().required().label('Value')
    }).strict()
  })).strict(),
  trajectoryDir: yup.array().of(yup.object().shape({
    _browser: yup.object().shape({
      volumeMountAlias: yup.string().required().label('Volume Mount Alias'),
      path: yup.string().required().label('Value')
    }).strict()
  })).strict(),
  imageDir: yup.array().of(yup.object().shape({
    _browser: yup.object().shape({
      volumeMountAlias: yup.string().required().label('Volume Mount Alias'),
      path: yup.string().required().label('Value')
    }).strict()
  })).strict(),
  dem: yup.object().shape({
    _browser: yup.object().shape({
      volumeMountAlias: yup.string().required().label('Volume Mount Alias'),
      path: yup.string().required().label('Value')
    }).strict()
  }).label('dem'),
  demResolution: yup.number().required().min(1).max(20).label('demResolution'),
  LidarRules: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required().label('Name'),
        rule: yup.string().required().label('Rule'),
      })
    )
    .strict(),
  TrajectoryRules: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required().label('Name'),
        rule: yup.string().required().label('Rule'),
      })
    )
    .strict(),
  CameraRules: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required().label('Name'),
        rule: yup.string().required().label('Rule'),
      })
    )
    .strict(),
});

const router = useRouter()
// Function
const onSubmit = (values: any) => {
  let _values = JSON.parse(JSON.stringify(values))

  //@ts-ignore
  _values.imageDir = values.imageDir.map(item => item?._browser)
  //@ts-ignore
  _values.lidarDir = values.lidarDir.map(item => item?._browser)
  //@ts-ignore
  _values.eopsDir = values.eopsDir.map(item => item?._browser)
  //@ts-ignore
  _values.trajectoryDir = values.trajectoryDir.map(item => item?._browser)

  _values.LidarRulesPresetAlias = LidarRulesPresetAlias.value
  _values.CameraRulesPresetAlias = CameraRulesPresetAlias.value
  _values.TrajectoryRulesPresetAlias = TrajectoryRulesPresetAlias.value

  emit('next', _values, router.currentRoute.value.name);
}


const { getPresets } = usePresets()
const { presets: demPresets } = getPresets({
  filter: {
    fields: [
      {
        type: "equals",
        name: "presetTypeAlias",
        value: PRESET_TYPES.DEM_DEF
      }
    ]
  }
})

const DemDefPresetAlias: Ref<Preset | null | undefined> = ref(
  props.formData?.populationConfig?.DemDefPresetAlias ?? null
)
const DemDef: Ref<any> = ref(
  {
    DemDef: props.formData?.populationConfig?.DemDef ?? null
  }
)

const showDemPreset = ref(props.formData?.populationConfig?.DemDefPresetAlias ? true : false)


const demRef = ref(null)


const onPresetDemValueChange = (value: any) => {
  if (value && value.value) {
    showLidarPreset.value = false

    const presetValue = Object.assign({}, demPresets.value.find(item => item.key === value.value))
    DemDef.value = presetValue.value
    if (DemDef.value.DemDef) {
      let dem:any = {}
      for (const item of DemDef.value.DemDef) {
        dem[item.key] = item.value
      }
      demRef?.value?.fillData(dem)
    }

    nextTick(() => {
      showLidarPreset.value = true
    })
  }
}


const { presets: lidarPresets } = getPresets({
  filter: {
    fields: [
      {
        type: "equals",
        name: "presetTypeAlias",
        value: PRESET_TYPES.LIDAR_RULES
      }
    ]
  }
})

watch(lidarPresets, () => {
  if (lidarPresets?.value) {
    const removeList:any = []
    lidarPresets?.value.forEach(item => {
      const presetRules = item.value.LidarRules
      const definitonRules = initialValues.value.LidarRules
      if (presetRules.length != definitonRules.length) {
        if (!removeList.includes(item)) {
          removeList.push(item)
        }
      }
      else {
        presetRules.forEach((rule:any) => {
          if (!definitonRules.find((f:any) => f.name === rule.key)){
            if (!removeList.includes(item))
              removeList.push(item)
          }
        })
        definitonRules.forEach((rule:any) => {
          if (!presetRules.find((f:any) => f.key === rule.name)){
            if (!removeList.includes(item))
              removeList.push(item)
          }
        })

      }
    })
    if (removeList && removeList.length > 0) {
      for (let i = 0; i < removeList.length; i++) {
        const index = lidarPresets?.value?.findIndex(x => x.key === removeList[i].key)
        if (index || index === 0) {
          lidarPresets?.value?.splice(index, 1)
        }
      }
    }
  }
})


const LidarRulesPresetAlias: Ref<Preset | null | undefined> = ref(
  props.formData?.populationConfig?.LidarRulesPresetAlias ?? null
)
const LidarRules: Ref<any> = ref(
  {
    LidarRules: props.formData?.populationConfig?.LidarRules ?? null
  }
)

const showLidarPreset = ref(props.formData?.populationConfig?.LidarRulesPresetAlias ? true : false)

const lidarRef = ref(null)

const onPresetLidarValueChange = (value: any) => {
  if (value && value.value) {
    showLidarPreset.value = false

    const presetValue = Object.assign({}, lidarPresets.value.find(item => item.key === value.value))
    LidarRules.value = presetValue.value
    if (LidarRules.value.LidarRules) {
      lidarRef?.value?.fillData(LidarRules.value.LidarRules)
    }

    nextTick(() => {
      showLidarPreset.value = true
    })
  }
}

const { presets: cameraPresets } = getPresets({
  filter: {
    fields: [
      {
        type: "equals",
        name: "presetTypeAlias",
        value: PRESET_TYPES.CAMERA_RULES
      }
    ]
  }
})

watch(cameraPresets, () => {
  if (cameraPresets?.value) {
    const removeList:any = []
    cameraPresets?.value.forEach(item => {
      const presetRules = item.value.CameraRules
      const definitonRules = initialValues.value.CameraRules
      if (presetRules.length != definitonRules.length) {
        if (!removeList.includes(item)) {
          removeList.push(item)
        }
      }
      else {
        presetRules.forEach((rule:any) => {
          if (!definitonRules.find((f:any) => f.name === rule.key)){
            if (!removeList.includes(item)){
              removeList.push(item)
            }
          }
        })
        definitonRules.forEach((rule:any) => {
          if (!presetRules.find((f:any) => f.key === rule.name)){
            if (!removeList.includes(item)) {
              removeList.push(item)
            }
          }
        })

      }
    })
    if (removeList && removeList.length > 0) {
      for (let i = 0; i < removeList.length; i++) {
        const index = cameraPresets?.value?.findIndex(x => x.key === removeList[i].key)
        if (index || index === 0) {
          cameraPresets?.value?.splice(index, 1)
        }
      }
    }
  }
})

const CameraRulesPresetAlias: Ref<Preset | null | undefined> = ref(
  props.formData?.populationConfig?.CameraRulesPresetAlias ?? null
)
const CameraRules: Ref<any> = ref(
  {
    CameraRules: props.formData?.populationConfig?.CameraRules ?? null
  }
)

const showCameraPreset = ref(props.formData?.populationConfig?.CameraRulesPresetAlias ? true : false)

const cameraRef = ref(null)

const onPresetCameraValueChange = (value: any) => {
  if (value && value.value) {
    showCameraPreset.value = false

    const presetValue = Object.assign({}, cameraPresets.value.find(item => item.key === value.value))
    CameraRules.value = presetValue.value

    if (CameraRules.value.CameraRules) {
      cameraRef?.value?.fillData(CameraRules.value.CameraRules)
    }

    nextTick(() => {
      showCameraPreset.value = true
    })
  }
}

const { presets: trajectoryPresets } = getPresets({
  filter: {
    fields: [
      {
        type: "equals",
        name: "presetTypeAlias",
        value: PRESET_TYPES.TRAJECTORY_RULES
      }
    ]
  }
})

watch(trajectoryPresets, () => {
  if (trajectoryPresets?.value) {
    const removeList:any = []
    trajectoryPresets?.value.forEach(item => {
      const presetRules = item.value.TrajectoryRules
      const definitonRules = initialValues.value.TrajectoryRules
      if (presetRules.length != definitonRules.length) {
        if (!removeList.includes(item)) {
          removeList.push(item)
        }
      }
      else {
        presetRules.forEach((rule:any) => {
          if (!definitonRules.find((f:any) => f.name === rule.key)){
            if (!removeList.includes(item))
              removeList.push(item)
          }
        })
        definitonRules.forEach((rule:any) => {
          if (!presetRules.find((f:any) => f.key === rule.name)){
            if (!removeList.includes(item))
              removeList.push(item)
          }
        })

      }
    })
    if (removeList && removeList.length > 0) {
      for (let i = 0; i < removeList.length; i++) {
        const index = trajectoryPresets?.value?.findIndex(x => x.key === removeList[i].key)
        if (index || index === 0) {
          trajectoryPresets?.value?.splice(index, 1)
        }
      }
    }
  }
})

const TrajectoryRulesPresetAlias: Ref<Preset | null | undefined> = ref(
  props.formData?.populationConfig?.TrajectoryRulesPresetAlias ?? null
)
const TrajectoryRules: Ref<any> = ref(
  {
    TrajectoryRules: props.formData?.populationConfig?.TrajectoryRules ?? null
  }
)

const showTrajectoryPreset = ref(props.formData?.populationConfig?.TrajectoryRulesPresetAlias ? true : false)

const trajectoryRef = ref(null)

const onPresetTrajectoryValueChange = (value: any) => {
  if (value && value.value) {
    showTrajectoryPreset.value = false

    const presetValue = Object.assign({}, trajectoryPresets.value.find(item => item.key === value.value))
    TrajectoryRules.value = presetValue.value
    if (TrajectoryRules.value.TrajectoryRules) {
      trajectoryRef?.value?.fillData(TrajectoryRules.value.TrajectoryRules)
    }

    nextTick(() => {
      showTrajectoryPreset.value = true
    })
  }
}

const { createPreset } = usePresets()
const toast = useToast()

const savePreset = async (name: string, data: object, type: PRESET_TYPES) => {
  try {
    let _value = JSON.parse(JSON.stringify(data))

    let res = await createPreset({
      key: name,
      presetTypeAlias: type,
      value: {
        [type]: _value
      },
      presetType: {
        __typename: undefined,
        alias: '',
        attributes: undefined,
        name: undefined
      }
    })

    if(res.success){
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully created', life: 3000 })
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating', life: 3000})
    }
  } catch (err) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating. ' + err.message, life: 3000})
    console.error(err)
  }
}

const onSave = (name: string, type: PRESET_TYPES) => {
  switch (type) {
  case PRESET_TYPES.LIDAR_RULES:
    savePreset(name, LidarRules.value.LidarRules, type)
    break
  case PRESET_TYPES.TRAJECTORY_RULES:
    savePreset(name, TrajectoryRules.value.TrajectoryRules, type)
    break
  case PRESET_TYPES.CAMERA_RULES:
    savePreset(name, CameraRules.value.CameraRules, type)
    break
  }
}

const selectedVolume = ref('')

const setBrowserVolume = (volumeMountAlias: string, browserRef: any) => {
  browserRef?.setBrowserVolume(volumeMountAlias)
}

const setVolume = (volumeMountAlias: string) => {
  selectedVolume.value = volumeMountAlias
  dataBrowseSelectRefs.value.forEach((el:any) => {
    setBrowserVolume(selectedVolume.value, el)
  })
}

const dataBrowseSelectRefs:any = ref([])
const setDataBrowseSelectRef = (el:any) => {
  if (el) {
    dataBrowseSelectRefs.value.push(el)
    setBrowserVolume(selectedVolume.value, el)
  }
}

const cameraInputVisible = () => {
}


const update = ref(router.currentRoute.value.path.indexOf('/edit/') > -1)


return (_ctx: any,_cache: any) => {
  const _component_Fieldset = _resolveComponent("Fieldset")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Form), {
          "initial-values": initialValues.value,
          "validation-schema": _unref(schema),
          onSubmit: onSubmit,
          class: "grid p-fluid"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Fieldset, {
                legend: `SRS`,
                toggleable: true,
                collapsed: false,
                class: "field col-12 md:col-12"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _cache[4] || (_cache[4] = _createElementVNode("p", null, "SRS PRJ file", -1)),
                      _createVNode(DataBrowseSelectWithValidation, {
                        leaf: 'file',
                        name: "srs",
                        label: "SRS PRJ file",
                        "data-cy": "inputConfigurations__srsPrjFile",
                        "set-parent-volume": setVolume,
                        ref: setDataBrowseSelectRef,
                        minimize: true
                      })
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_Fieldset, {
                legend: `DEM`,
                toggleable: true,
                collapsed: false,
                class: "field col-12 md:col-12"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[5] || (_cache[5] = _createElementVNode("p", { class: "label" }, "Select preset", -1)),
                      _createVNode(_component_Dropdown, {
                        "data-cy": "inputConfigurations__demSelectPreset",
                        "scroll-height": "400px",
                        id: "presetTypeAliasDem",
                        class: "full-width",
                        modelValue: DemDefPresetAlias.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((DemDefPresetAlias).value = $event)),
                        options: _unref(demPresets),
                        optionLabel: "key",
                        optionValue: "key",
                        placeholder: "Select preset",
                        onChange: onPresetDemValueChange
                      }, null, 8, ["modelValue", "options"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _cache[6] || (_cache[6] = _createElementVNode("p", null, "DEM configuration", -1)),
                      _createVNode(DataBrowseSelectWithValidation, {
                        ref_key: "demRef",
                        ref: demRef,
                        leaf: 'file',
                        name: "dem",
                        label: "DEM configuration",
                        minimize: true
                      }, null, 512)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _cache[7] || (_cache[7] = _createElementVNode("p", null, "Resolution [m]", -1)),
                      _createVNode(InputTextWithValidation, {
                        name: "demResolution",
                        label: "DEM resolution [m]",
                        class: "input",
                        minimize: true
                      })
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_Fieldset, {
                legend: `EOPs`,
                toggleable: true,
                collapsed: false,
                class: "field col-12 md:col-12"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _cache[9] || (_cache[9] = _createElementVNode("p", null, "Directory (multiple)", -1)),
                      _createVNode(ListWithValidation, {
                        name: "eopsDir",
                        minimize: true,
                        label: ""
                      }, {
                        form: _withCtx(({ fieldName }) => [
                          _createVNode(DataBrowseSelectWithValidation, {
                            "data-cy": "inputConfigurations__eopsDir",
                            leaf: 'dir',
                            ref: setDataBrowseSelectRef,
                            "set-parent-volume": setVolume,
                            name: `${fieldName}._browser`,
                            "selected-volume": selectedVolume.value,
                            minimize: true,
                            label: "EOPs directory"
                          }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode(" data-cy=\"inputConfigurations__eopsDirectory\" ")
                            ])),
                            _: 2
                          }, 1032, ["name", "selected-volume"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _cache[10] || (_cache[10] = _createElementVNode("p", null, "Unit of Omega, Phi and Kappa angles", -1)),
                      _createVNode(DropdownWithValidation, {
                        name: "eopsAngleUnit",
                        label: "Unit of Omega, Phi and Kappa angles",
                        "data-cy": "inputConfigurations__angles",
                        options: eopsAngleUnitOptions,
                        optionValue: "value",
                        optionLabel: "label",
                        class: "input",
                        minimize: true
                      })
                    ]),
                    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "field col-12 md:col-6" }, null, -1))
                  ])
                ]),
                _: 1
              }),
              (initialValues.value.camera && initialValues.value.CameraRules && initialValues.value.CameraRules.length > 0)
                ? (_openBlock(), _createBlock(_component_Fieldset, {
                    key: 0,
                    legend: `Camera/Image`,
                    toggleable: true,
                    collapsed: false,
                    class: "field col-12 md:col-12"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createVNode(ListWithValidation, {
                            name: "imageDir",
                            label: "Directory (multiple)",
                            minimize: true
                          }, {
                            form: _withCtx(({ fieldName }) => [
                              _createVNode(DataBrowseSelectWithValidation, {
                                "data-cy": "inputConfigurations__cameraDir",
                                leaf: 'dir',
                                name: `${fieldName}._browser`,
                                label: "Image dir",
                                "set-parent-volume": setVolume,
                                minimize: true,
                                ref: setDataBrowseSelectRef
                              }, null, 8, ["name"])
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("div", _hoisted_16, [
                          _cache[12] || (_cache[12] = _createElementVNode("p", { class: "label" }, "Select preset", -1)),
                          _createVNode(_component_Dropdown, {
                            "data-cy": "inputConfigurations__cameraPresetDropdown",
                            "scroll-height": "400px",
                            id: "presetTypeAliasCamera",
                            class: "full-width",
                            modelValue: CameraRulesPresetAlias.value,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((CameraRulesPresetAlias).value = $event)),
                            options: _unref(cameraPresets),
                            optionLabel: "key",
                            optionValue: "key",
                            placeholder: "Select preset",
                            onChange: onPresetCameraValueChange
                          }, null, 8, ["modelValue", "options"])
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _createVNode(ListWithValidation, {
                            disableRowRemove: true,
                            name: "CameraRules",
                            label: "Rules",
                            "save-preset-enable": update.value,
                            "save-preset": (name) => onSave(name, _unref(PRESET_TYPES).CAMERA_RULES),
                            "max-items": initialValues.value.CameraRules.length,
                            ref_key: "cameraRef",
                            ref: cameraRef
                          }, {
                            form: _withCtx(({ fieldName }) => [
                              _createElementVNode("div", _hoisted_18, [
                                _createVNode(InputTextWithValidation, {
                                  name: `${fieldName}.name`,
                                  label: "Camera name",
                                  disabled: true,
                                  minimize: true
                                }, null, 8, ["name"]),
                                _createVNode(InputTextWithValidation, {
                                  class: "flex-1 mr-0",
                                  "data-cy": "inputConfigurations__cameraRule",
                                  size: 40,
                                  name: `${fieldName}.rule`,
                                  label: "Camera rule",
                                  minimize: true
                                }, null, 8, ["name"])
                              ])
                            ]),
                            _: 1
                          }, 8, ["save-preset-enable", "save-preset", "max-items"])
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (initialValues.value.lidar && initialValues.value.LidarRules && initialValues.value.LidarRules.length > 0)
                ? (_openBlock(), _createBlock(_component_Fieldset, {
                    key: 1,
                    legend: `LiDAR`,
                    toggleable: true,
                    collapsed: false,
                    class: "field col-12 md:col-12"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _createVNode(ListWithValidation, {
                            name: "lidarDir",
                            minimize: true,
                            label: "Directory (multiple)"
                          }, {
                            form: _withCtx(({ fieldName }) => [
                              _createVNode(DataBrowseSelectWithValidation, {
                                "data-cy": "inputConfigurations__lidarDir",
                                leaf: 'dir',
                                name: `${fieldName}._browser`,
                                label: "Lidar directory",
                                "set-parent-volume": setVolume,
                                minimize: true,
                                ref: setDataBrowseSelectRef
                              }, null, 8, ["name"])
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("div", _hoisted_21, [
                          _cache[13] || (_cache[13] = _createElementVNode("p", { class: "label" }, "Select preset", -1)),
                          _createVNode(_component_Dropdown, {
                            "data-cy": "inputConfigurations__lidarPresetDropdown",
                            "scroll-height": "400px",
                            id: "presetTypeAliasLidar",
                            class: "full-width",
                            modelValue: LidarRulesPresetAlias.value,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((LidarRulesPresetAlias).value = $event)),
                            options: _unref(lidarPresets),
                            optionLabel: "key",
                            optionValue: "key",
                            placeholder: "Select preset",
                            onChange: onPresetLidarValueChange
                          }, null, 8, ["modelValue", "options"])
                        ]),
                        _createElementVNode("div", _hoisted_22, [
                          _createVNode(ListWithValidation, {
                            name: "LidarRules",
                            disableRowRemove: true,
                            label: "Rules",
                            "max-items": initialValues.value.LidarRules.length,
                            ref_key: "lidarRef",
                            ref: lidarRef,
                            minimize: true,
                            "save-preset-enable": update.value,
                            "save-preset": (name) => onSave(name, _unref(PRESET_TYPES).LIDAR_RULES)
                          }, {
                            form: _withCtx(({ fieldName }) => [
                              _createElementVNode("div", _hoisted_23, [
                                _createVNode(InputTextWithValidation, {
                                  name: `${fieldName}.name`,
                                  "data-cy": "inputConfigurations__lidarRule",
                                  label: "Lidar name",
                                  minimize: true,
                                  disabled: true
                                }, null, 8, ["name"]),
                                _createVNode(InputTextWithValidation, {
                                  class: "flex-1 mr-0",
                                  size: 40,
                                  minimize: true,
                                  name: `${fieldName}.rule`,
                                  label: "Lidar rule"
                                }, null, 8, ["name"])
                              ])
                            ]),
                            _: 1
                          }, 8, ["max-items", "save-preset-enable", "save-preset"])
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (initialValues.value.trajectory && initialValues.value.TrajectoryRules && initialValues.value.TrajectoryRules.length > 0)
                ? (_openBlock(), _createBlock(_component_Fieldset, {
                    key: 2,
                    legend: `Trajectory`,
                    toggleable: true,
                    collapsed: false,
                    class: "field col-12 md:col-12"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _createVNode(ListWithValidation, {
                            name: "trajectoryDir",
                            minimize: true,
                            label: "Directory (multiple)"
                          }, {
                            form: _withCtx(({ fieldName }) => [
                              _createVNode(DataBrowseSelectWithValidation, {
                                "data-cy": "inputConfigurations__trajectoryDir",
                                leaf: 'dir',
                                name: `${fieldName}._browser`,
                                label: "Trajectory dir",
                                "set-parent-volume": setVolume,
                                minimize: true,
                                ref: setDataBrowseSelectRef
                              }, null, 8, ["name"])
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("div", _hoisted_26, [
                          _cache[14] || (_cache[14] = _createElementVNode("p", { class: "label" }, "Select preset", -1)),
                          _createVNode(_component_Dropdown, {
                            "data-cy": "inputConfigurations__trajectoryPresetDropdown",
                            "scroll-height": "400px",
                            id: "presetTypeAliasTrajectory",
                            class: "full-width",
                            modelValue: TrajectoryRulesPresetAlias.value,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((TrajectoryRulesPresetAlias).value = $event)),
                            options: _unref(trajectoryPresets),
                            optionLabel: "key",
                            optionValue: "key",
                            placeholder: "Select preset",
                            onChange: onPresetTrajectoryValueChange
                          }, null, 8, ["modelValue", "options"])
                        ]),
                        _createElementVNode("div", _hoisted_27, [
                          _createVNode(ListWithValidation, {
                            disableRowRemove: true,
                            name: "TrajectoryRules",
                            label: "Trajectory rules",
                            "max-items": initialValues.value.TrajectoryRules.length,
                            minimize: true,
                            ref_key: "trajectoryRef",
                            ref: trajectoryRef,
                            "save-preset-enable": update.value,
                            "save-preset": (name) => onSave(name, _unref(PRESET_TYPES).TRAJECTORY_RULES)
                          }, {
                            form: _withCtx(({ fieldName }) => [
                              _createElementVNode("div", _hoisted_28, [
                                _createVNode(InputTextWithValidation, {
                                  name: `${fieldName}.name`,
                                  label: "Trajectory name",
                                  disabled: true,
                                  minimize: true
                                }, null, 8, ["name"]),
                                _createVNode(InputTextWithValidation, {
                                  class: "flex-1 mr-0",
                                  "data-cy": "inputConfigurations__trajectoryRule",
                                  size: 40,
                                  name: `${fieldName}.rule`,
                                  label: "Trajectory rule",
                                  minimize: true
                                }, null, 8, ["name"])
                              ])
                            ]),
                            _: 1
                          }, 8, ["max-items", "save-preset-enable", "save-preset"])
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_component_Button, {
                    label: "Back",
                    icon: "fa-solid fa-arrow-left",
                    class: "p-button-info mr-2 mb-2 mlwn-button-submit",
                    onClick: stepBack
                  }),
                  false
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        "data-cy": "inputConfigurations__nextButton",
                        label: "Cancel",
                        icon: "fa-solid fa-xmark",
                        onClick: onCancel,
                        class: "p-button-secondary mr-2 mb-2 mlwn-button-submit"
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_Button, {
                    "data-cy": "inputConfigurations__nextButton",
                    label: "Next",
                    type: "submit",
                    icon: "fa-solid fa-arrow-right",
                    class: "p-button-success mr-2 mb-2 mlwn-button-submit"
                  })
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["initial-values", "validation-schema"])
      ])
    ])
  ]))
}
}

})