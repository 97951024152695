<template>
  <div class="grid">			
		<div class="col-12 p-fluid">
			<Card>
				<template #title>
          <span>Update volume</span>
				</template>
				<template #content>
          <volume-form
            :storageId="props.storageId"
            :volumeMountAlias="props.volumeMountAlias"
            :update="true"
          ></volume-form>
				</template>
			</Card>
		</div>
	</div>            
</template>


<script setup lang="ts">
import VolumeForm from './components/VolumeForm.vue'

const props = defineProps({
  storageId: {
    required: true,
    type: String
  },
  volumeMountAlias: {
    required: true,
    type: String
  }
})
</script>

