<template>
  <div class="flex align-items-center justify-content-center gap-1">
    <LogDownloadButtons :scene="scene"></LogDownloadButtons>
    <SceneActionButton :scene="scene" @scene-updated="onSceneUpdated" :show-open-button="props.showOpenButton"></SceneActionButton>
  </div>
</template>

<script setup lang="ts">
// imports
import { Scene } from '@/gql/graphql';
import { PropType } from 'vue';
import LogDownloadButtons from '@/components/Admin/Project/Scene/Log/LogDownloadButtons.vue';
import SceneActionButton from './SceneActionButton.vue';

// component
const props = defineProps({
  scene: {
    required: true,
    type: Object as PropType<Scene>,
  },
  showOpenButton: {
    required: false,
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['scene-updated'])

// composables


// functions
const onSceneUpdated = () => {
  emit('scene-updated')
}

</script>