import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }

import { useProject } from '@/composables/useProject';


export default /*@__PURE__*/_defineComponent({
  __name: 'Collocations',
  setup(__props) {

const { getProjects } = useProject()
const { projects } = getProjects()

return (_ctx: any,_cache: any) => {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" Collocations ")
        ])),
        content: _withCtx(() => [
          _cache[1] || (_cache[1] = _createTextVNode(" - to be implemented ")),
          _cache[2] || (_cache[2] = _createElementVNode("strong", null, "ingest workflow", -1)),
          _createTextVNode(" " + _toDisplayString(_unref(projects)), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})