import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-fluid formgrid grid" }
const _hoisted_2 = { class: "field col-12 md:col-9" }
const _hoisted_3 = { class: "p-float-label" }
const _hoisted_4 = { class: "field col-12 md:col-3" }
const _hoisted_5 = { class: "p-float-label" }
const _hoisted_6 = { class: "field col-12 md:col-2" }
const _hoisted_7 = { class: "p-float-label" }
const _hoisted_8 = { class: "mlwn-col-input" }
const _hoisted_9 = { class: "field col-12 md:col-2" }
const _hoisted_10 = { class: "p-float-label" }
const _hoisted_11 = { class: "mlwn-col-input" }
const _hoisted_12 = { class: "field col-12 md:col-2" }
const _hoisted_13 = { class: "p-float-label" }
const _hoisted_14 = { class: "mlwn-col-input" }
const _hoisted_15 = { class: "field col-12 md:col-3" }
const _hoisted_16 = { class: "p-float-label" }
const _hoisted_17 = { class: "mlwn-col-input" }
const _hoisted_18 = { class: "field col-12 md:col-3" }
const _hoisted_19 = { class: "p-float-label" }
const _hoisted_20 = { class: "mlwn-col-input" }
const _hoisted_21 = {
  key: 0,
  class: "field col-12"
}
const _hoisted_22 = {
  key: 1,
  class: "field col-12"
}
const _hoisted_23 = { class: "p-fluid formgrid grid" }
const _hoisted_24 = { class: "field col-12 md:col-6" }
const _hoisted_25 = {
  key: 0,
  for: "key"
}
const _hoisted_26 = { class: "field col-12 md:col-6" }
const _hoisted_27 = {
  key: 0,
  for: "value"
}

import { reactive, ref, computed, watch, Ref } from 'vue'
import MultipleInputList from '@/components/UI/Form/MultipleInputList.vue'
import MultipleInputListSimple from '@/components/UI/Form/MultipleInputListSimple.vue'
import { PRESET_TYPES } from '@/helpers/constants';
import { CameraDefModelType, CameraOptionDefinition, CameraDefinitions } from '@/helpers/types/PresetTypes'

const changeEmitName = 'update:modelValue'

export default /*@__PURE__*/_defineComponent({
  __name: 'CameraDefPresetForm',
  props: {
  modelValue: {
    type: Object,
    default: () => { }
  },
  showTitle: {
    type: Boolean,
    required: false,
    default: true
  },
  savePresetEnable: {
    type: Boolean,
    required: false,
    default: false
  }
},
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props

const emits = __emit

const model = computed({
  get: () => props?.modelValue,
  set: (value: any) => {
    emits(changeEmitName, value)
  },
})

const toggleableRowTitleCb = (value: CameraDefModelType) => {
  return `Camera: ${value.name}`
}

const toggleableOptionRowTitleCb = (value: CameraOptionDefinition) => {
  return `Option: ${value.key}`
}

const state = reactive(model.value ?? {
  name: '',
  px_width_um: 0,
  px_height_um: 0,
  focalLenght_mm: 0,
  image_width_px: 0,
  image_height_px: 0,
  up_x_m: 0,
  up_y_m: 1,
  up_z_m: 0,
  look_x_m: 0,
  look_y_m: 0,
  look_z_m: -1,
  lensShift_x_mm: 0,
  lensShift_y_mm: 0,
  options: []
})

const defaultRowValue = () => {
  return {
    name: '',
    px_width_um: 0,
    px_height_um: 0,
    focalLenght_mm: 0,
    image_width_px: 0,
    image_height_px: 0,
    up_x_m: 0,
    up_y_m: 1,
    up_z_m: 0,
    look_x_m: 0,
    look_y_m: 0,
    look_z_m: -1,
    lensShift_x_mm: 0,
    lensShift_y_mm: 0,
    options: []
  }
}

const defaultOptionRowValue = () => {
  return {
    key: '',
    value: ''
  }
}

const readonlyFlag = ref(false)
const setReadonly = (readonly:boolean) => {
  readonlyFlag.value = readonly
}

__expose({
  setReadonly,
})

watch(state, (newValue) => {
  model.value = newValue
}, { deep: true })

const data: Ref<any> = ref(model.value ?? {
  [PRESET_TYPES.CAMERA_DEF]: []
})
watch(data, (newValue) => {
  model.value = newValue as CameraDefinitions
}, { deep: true })

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createBlock(MultipleInputList, {
    title: "Definition of cameras",
    toggleableRow: true,
    toggleableRowTitleCb: toggleableRowTitleCb,
    "save-preset-enable": __props.savePresetEnable && !readonlyFlag.value,
    defaultRowValue: defaultRowValue,
    modelValue: data.value[_unref(PRESET_TYPES).CAMERA_DEF],
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((data.value[_unref(PRESET_TYPES).CAMERA_DEF]) = $event)),
    minRowsCount: 1,
    rowClass: 'float-label',
    "show-title": __props.showTitle,
    disableRowRemove: readonlyFlag.value
  }, {
    form: _withCtx(({ value }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_InputText, {
              id: "name",
              modelValue: value.name,
              "onUpdate:modelValue": ($event: any) => ((value.name) = $event),
              disabled: readonlyFlag.value
            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
            _cache[1] || (_cache[1] = _createElementVNode("label", { for: "name" }, "Name", -1))
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("small", { class: "mlwn-label-ellipsis block" }, "User-defined camera name, images can be later mapped to a camera rule based on this name.", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_InputNumber, {
              maxFractionDigits: 20,
              id: "focalLenght_mm",
              modelValue: value.focalLenght_mm,
              "onUpdate:modelValue": ($event: any) => ((value.focalLenght_mm) = $event),
              "use-grouping": false,
              disabled: readonlyFlag.value
            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
            _cache[4] || (_cache[4] = _createElementVNode("label", {
              class: "mlwn-label-ellipsis",
              for: "focalLenght_mm"
            }, [
              _createTextVNode("Focal Length "),
              _createElementVNode("strong", null, "[mm]")
            ], -1))
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, [
              _createVNode(_component_InputNumber, {
                maxFractionDigits: 10,
                id: "image_width_px",
                modelValue: value.image_width_px,
                "onUpdate:modelValue": ($event: any) => ((value.image_width_px) = $event),
                "use-grouping": false,
                disabled: readonlyFlag.value
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
              _createVNode(_component_InputNumber, {
                id: "image_height_px",
                modelValue: value.image_height_px,
                "onUpdate:modelValue": ($event: any) => ((value.image_height_px) = $event),
                "use-grouping": false,
                disabled: readonlyFlag.value
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
              _cache[6] || (_cache[6] = _createElementVNode("label", {
                class: "mlwn-label-ellipsis",
                for: "image_width_px"
              }, [
                _createTextVNode("Image Size Width, Height "),
                _createElementVNode("strong", null, "[px]")
              ], -1))
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, [
              _createVNode(_component_InputNumber, {
                maxFractionDigits: 20,
                id: "px_width_um",
                modelValue: value.px_width_um,
                "onUpdate:modelValue": ($event: any) => ((value.px_width_um) = $event),
                "use-grouping": false,
                disabled: readonlyFlag.value
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
              _createVNode(_component_InputNumber, {
                maxFractionDigits: 20,
                id: "px_height_um",
                modelValue: value.px_height_um,
                "onUpdate:modelValue": ($event: any) => ((value.px_height_um) = $event),
                "use-grouping": false,
                disabled: readonlyFlag.value
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
              _cache[8] || (_cache[8] = _createElementVNode("label", {
                class: "mlwn-label-ellipsis",
                for: "px_width_um"
              }, [
                _createTextVNode("Pixel Size Width, Height "),
                _createElementVNode("strong", null, "[µm]")
              ], -1))
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("span", _hoisted_13, [
            _createElementVNode("span", _hoisted_14, [
              _createVNode(_component_InputNumber, {
                maxFractionDigits: 20,
                id: "lensShift_x_mm",
                modelValue: value.lensShift_x_mm,
                "onUpdate:modelValue": ($event: any) => ((value.lensShift_x_mm) = $event),
                disabled: readonlyFlag.value,
                "use-grouping": false
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
              _createVNode(_component_InputNumber, {
                maxFractionDigits: 20,
                id: "lensShift_y_mm",
                modelValue: value.lensShift_y_mm,
                "onUpdate:modelValue": ($event: any) => ((value.lensShift_y_mm) = $event),
                disabled: readonlyFlag.value,
                "use-grouping": false
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
              _cache[10] || (_cache[10] = _createElementVNode("label", {
                class: "mlwn-label-ellipsis",
                for: "lensShift_x_mm"
              }, [
                _createTextVNode("Principal Point Offset X, Y "),
                _createElementVNode("strong", null, "[mm]")
              ], -1))
            ]),
            _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("span", _hoisted_16, [
            _createElementVNode("span", _hoisted_17, [
              _createVNode(_component_InputNumber, {
                maxFractionDigits: 20,
                id: "up_x_m",
                modelValue: value.up_x_m,
                "onUpdate:modelValue": ($event: any) => ((value.up_x_m) = $event),
                disabled: readonlyFlag.value,
                "use-grouping": false
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
              _createVNode(_component_InputNumber, {
                maxFractionDigits: 20,
                id: "up_y_m",
                modelValue: value.up_y_m,
                "onUpdate:modelValue": ($event: any) => ((value.up_y_m) = $event),
                disabled: readonlyFlag.value,
                "use-grouping": false
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
              _createVNode(_component_InputNumber, {
                maxFractionDigits: 20,
                id: "up_z_m",
                modelValue: value.up_z_m,
                "onUpdate:modelValue": ($event: any) => ((value.up_z_m) = $event),
                disabled: readonlyFlag.value,
                "use-grouping": false
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
              _cache[12] || (_cache[12] = _createElementVNode("label", {
                class: "mlwn-label-ellipsis",
                for: "up_x_m"
              }, [
                _createTextVNode("Initial Up Vector "),
                _createElementVNode("strong", null, "X"),
                _createTextVNode(", "),
                _createElementVNode("strong", null, "Y"),
                _createTextVNode(", "),
                _createElementVNode("strong", null, "Z")
              ], -1))
            ]),
            _cache[13] || (_cache[13] = _createElementVNode("small", null, "Must be a unit vector", -1)),
            _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("span", _hoisted_19, [
            _createElementVNode("span", _hoisted_20, [
              _createVNode(_component_InputNumber, {
                maxFractionDigits: 20,
                id: "look_x_m",
                modelValue: value.look_x_m,
                "onUpdate:modelValue": ($event: any) => ((value.look_x_m) = $event),
                disabled: readonlyFlag.value,
                "use-grouping": false
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
              _createVNode(_component_InputNumber, {
                maxFractionDigits: 20,
                id: "look_y_m",
                modelValue: value.look_y_m,
                "onUpdate:modelValue": ($event: any) => ((value.look_y_m) = $event),
                disabled: readonlyFlag.value,
                "use-grouping": false
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
              _createVNode(_component_InputNumber, {
                maxFractionDigits: 20,
                id: "look_z_m",
                modelValue: value.look_z_m,
                "onUpdate:modelValue": ($event: any) => ((value.look_z_m) = $event),
                disabled: readonlyFlag.value,
                "use-grouping": false
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
              _cache[15] || (_cache[15] = _createElementVNode("label", {
                class: "mlwn-label-ellipsis",
                for: "look_x_m"
              }, [
                _createTextVNode("Initial Look Vector "),
                _createElementVNode("strong", null, "X"),
                _createTextVNode(", "),
                _createElementVNode("strong", null, "Y"),
                _createTextVNode(", "),
                _createElementVNode("strong", null, "Z")
              ], -1))
            ]),
            _cache[16] || (_cache[16] = _createElementVNode("small", { class: "mlwn-label-ellipsis block" }, "Must be a unit vector, perpendicular to Up Vector", -1))
          ])
        ]),
        (readonlyFlag.value && false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
              _createElementVNode("div", null, [
                _cache[18] || (_cache[18] = _createTextVNode("Camera attributes: ")),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.options, (layer) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: layer.key
                  }, [
                    _createTextVNode(_toDisplayString(layer.key) + "=", 1),
                    _createElementVNode("strong", null, _toDisplayString(layer.value), 1),
                    _cache[17] || (_cache[17] = _createTextVNode("; "))
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (!readonlyFlag.value || true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
              _createVNode(MultipleInputListSimple, {
                title: "Camera attributes",
                defaultRowValue: defaultOptionRowValue,
                modelValue: value.options,
                "onUpdate:modelValue": ($event: any) => ((value.options) = $event),
                rowClass: 'float-label',
                toggleableRow: true,
                toggleableRowTitleCb: toggleableOptionRowTitleCb,
                "disable-row-remove": readonlyFlag.value
              }, {
                form: _withCtx(({ value }) => [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("span", {
                        class: _normalizeClass({ 'p-float-label': !readonlyFlag.value })
                      }, [
                        _createVNode(_component_InputText, {
                          modelValue: value.key,
                          "onUpdate:modelValue": ($event: any) => ((value.key) = $event),
                          disabled: readonlyFlag.value
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
                        (!readonlyFlag.value)
                          ? (_openBlock(), _createElementBlock("label", _hoisted_25, "Key"))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("span", {
                        class: _normalizeClass({ 'p-float-label': !readonlyFlag.value })
                      }, [
                        _createVNode(_component_InputText, {
                          modelValue: value.value,
                          "onUpdate:modelValue": ($event: any) => ((value.value) = $event),
                          disabled: readonlyFlag.value
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
                        (!readonlyFlag.value)
                          ? (_openBlock(), _createElementBlock("label", _hoisted_27, "Value"))
                          : _createCommentVNode("", true)
                      ], 2)
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "disable-row-remove"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["save-preset-enable", "modelValue", "show-title", "disableRowRemove"]))
}
}

})