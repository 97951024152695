import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-inputgroup flex-1" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]

import { ref, toRef } from 'vue';
import { useField } from 'vee-validate';
import DataBrowserSelect from '@/components/DataBrowser/DataBrowseSelect.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DataBrowseSelectWithValidation',
  props: {
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fieldClass: {
    type: String,
    required: false,
    default: ''
  },
  setParentVolume: {
    type: Function,
    required: false,
    default: () => { return { } }
  },  
  selectedVolume: {
    type: Number,
    required: false
  },
  leaf: {
    type: String,
    required: false,
    default: ''
  },
  minimize: {
    type: Boolean,
    required: false,
    default: false
  }
},
  setup(__props, { expose: __expose }) {

const props = __props;

const { errorMessage, value } = useField(toRef(props, 'name'));

let _path = value?.value?.path ?? null
if (!_path && value?.value?.volumeMountAlias) {
  _path = ''
}

const path = ref(_path)

// Function
const onSelectFile = (values: any) => {
  value.value = values

  if (values && values.volumeMountAlias && values.path === '') {
    path.value = './'
  } else {
    path.value = values.path
  }  
  if (values.volumeMountAlias) {
    props.setParentVolume(values.volumeMountAlias)
  }
}

const fillData = (dem: any) => {
  for (const key of Object.keys(dem)) {
    switch(key){
    case 'volumeMountAlias': 
    
      break
    case 'totalRecords':
    case 'offset':
    case 'limit':
      if (typeof dem[key] === 'string') {
        dem[key] = Number(dem[key])
      }
      break
    }
  }
  path.value = dem.path
  value.value = dem  
}

const browser = ref(null)
const setBrowserVolume = (volumeMountAlias: string) => {
  browser?.value?.setBrowserVolume(volumeMountAlias)
}

const showDataBrowserSelect = (event:any) => {
  browser?.value.showBrowseSelect()
  event.preventDefault()
}

__expose({
  fillData,
  setBrowserVolume,
})

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field", __props.fieldClass])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass({ 'p-float-label': true, 'p-float-label-margin': __props.minimize } )
      }, [
        _createVNode(_component_InputText, {
          modelValue: path.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((path).value = $event)),
          "aria-describedby": `${__props.name}-help`,
          disabled: true,
          onContextmenu: showDataBrowserSelect,
          onDblclick: showDataBrowserSelect,
          class: _normalizeClass({ 'p-invalid': _unref(errorMessage) }),
          type: __props.type,
          readonly: true,
          id: __props.name
        }, null, 8, ["modelValue", "aria-describedby", "class", "type", "id"]),
        _createVNode(DataBrowserSelect, {
          onSelectFile: onSelectFile,
          selected: _unref(value),
          title: __props.label,
          ref_key: "browser",
          ref: browser,
          leaf: __props.leaf
        }, null, 8, ["selected", "title", "leaf"]),
        (_unref(value) && !__props.minimize)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              for: __props.name
            }, [
              _createElementVNode("small", null, _toDisplayString(__props.label), 1)
            ], 8, _hoisted_2))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _createElementVNode("small", {
      id: `${__props.name}-help`,
      class: "p-error"
    }, _toDisplayString(_unref(errorMessage)), 9, _hoisted_3)
  ], 2))
}
}

})