import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  class: "pl-0",
  style: {"list-style-type":"none"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "text-right" }
const _hoisted_9 = { class: "p-input-icon-left" }
const _hoisted_10 = {
  key: 0,
  class: "pi pi-file"
}
const _hoisted_11 = {
  key: 1,
  class: "pi pi-folder-open"
}
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  class: "fa fa-level-down",
  type: "text"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  class: "fa fa-info-circle",
  type: "text"
}
const _hoisted_18 = {
  key: 1,
  class: "fa fa-exclamation-triangle",
  type: "text"
}
const _hoisted_19 = { class: "flex flex-wrap gap-2 justify-content-end" }
const _hoisted_20 = ["href"]

import { ref, watch } from 'vue'
import { useConfig } from '@/composables/useConfig'
import { useDataBrowser } from '@/composables/useDataBrowser'
import { DataBrowserEntity, DataBrowserEntityType, DataBrowserSortOption, DataBrowserSortInput, DataBrowserSortColumn } from '@/gql/graphql'
import type { Ref } from 'vue'
import { MenuItem } from 'primevue/menuitem';
import { TreeTablePageEvent, TreeTableSortEvent } from 'primevue/treetable';
import debounce from 'lodash.debounce'
import prettyBytes from 'pretty-bytes';
import { LocationQueryValue, useRouter } from 'vue-router'

// custom classes

type DataBrowserTreeNode = {
  key: number
  leaf: boolean
  data: {
    name: string,
    size: string,
    isSymlink: boolean,
    isValidSymlink: boolean,
    symlinkAttributes: {
      size: string
      path: string
    } | null,
    type: string | undefined,
    downloadURL: string
  }
}

// composable imports


export default /*@__PURE__*/_defineComponent({
  __name: 'DataBrowser',
  props: {
  storageId: {
    required: true,
    type: String,
  },
  volumeMountAlias: {
    required: true,
    type: String,
  }
},
  setup(__props) {


// props

const props = __props

// imports

const { getDataBrowserStatus, getDataBrowserList } = useDataBrowser()
const { getConfig } = useConfig()

// router

const router = useRouter()
const query = router.currentRoute.value.query

// helpers

const validateString = (value: LocationQueryValue | LocationQueryValue[], defaultValue: string): string => {
  if (Array.isArray(value) || !value) return defaultValue;
  return value;
}
const validateInt = (value: LocationQueryValue | LocationQueryValue[], defaultValue: number): number => {
  if (Array.isArray(value) || !value) return defaultValue;
  return parseInt(value)
}
const validateDataBrowserSortColumn = (value: LocationQueryValue | LocationQueryValue[], defaultValue: DataBrowserSortColumn): DataBrowserSortColumn => {
  if (Array.isArray(value) || !value || !Object.values(DataBrowserSortColumn).includes(value as unknown as DataBrowserSortColumn)) return defaultValue;
  return value as DataBrowserSortColumn
}
const validateDataBrowserSortOption = (value: LocationQueryValue | LocationQueryValue[], defaultValue: DataBrowserSortOption): DataBrowserSortOption => {
  if (Array.isArray(value) || !value || !Object.values(DataBrowserSortOption).includes(value as unknown as DataBrowserSortOption)) return defaultValue;
  return value as DataBrowserSortOption
}


const nodes = ref()
const volumeMountAliasRef = ref(props.volumeMountAlias)
const dataBrowserErrors: Ref<string[]> = ref([])
const dataBrowserReady = ref(false)
const dataBrowserServiceCreated = ref(false)
const dataBrowserServiceReady = ref(false)
const taskVolumeCreated = ref(false)
const taskVolumeReady = ref(false)
const loading = ref(false)
const totalRecords = ref(0)
const limit = ref(validateInt(query.limit, 50))
const offset = ref(validateInt(query.offset, 0))
const dirPath = ref('')
const search = ref(validateString(query.search, ''))
const sortColumn = ref(validateDataBrowserSortColumn(query.sortColumn, DataBrowserSortColumn.Name))
const sortOrder = ref(validateDataBrowserSortOption(query.sortOrder, DataBrowserSortOption.Asc))
const sort: Ref<DataBrowserSortInput | null> = ref({
  column: sortColumn,
  order: sortOrder
})
const queryList = ref({
  volumeMountAlias: volumeMountAliasRef.value,
  limit: 50,
  offset: 0,
  dirPath: '',
  search: '',
  sort: {
    column: DataBrowserSortColumn.Name,
    order: DataBrowserSortOption.Asc
  }
})
const home: Ref<MenuItem> = ref({
  icon: 'pi pi-home',
  // label: 'root',
  to: '#',
  command: () => {
    refetchBrowser('')
  }
});
const items: Ref<MenuItem[]> = ref([]);

// query filter builder

const makeStrong = (text: string, search: string) => {
  if (search === "") return text

  const regExp = new RegExp(search, "gi")
  let html = ''
  let stringCursor = 0
  const matches = text.matchAll(regExp);

  for (const match of matches) {
    if (match.index === undefined) continue
    // text from last position to this match
    html += text.slice(stringCursor, match.index)
    // match in bold
    html += `<strong>${match[0]}</strong>`
    // set cursor to end of this match
    stringCursor = match.index + match[0].length
  }
  // add rest of text
  html += text.slice(stringCursor)
  return html
}
const serializeQuery = () => {
  let fetchQuery: any = {
    limit: limit.value,
    offset: offset.value,
    search: search.value,
    sortColumn: sort.value?.column,
    sortOrder: sort.value?.order,
  }
  router.replace({ query: fetchQuery })
}


// composable utils

const {
  status: dataBrowserStatus,
  error: dataBrowserStatusError,
  load: dataBrowserStatusLoad,
  refetch: dataBrowserStatusRefetch,
} = getDataBrowserStatus(volumeMountAliasRef)
dataBrowserStatusLoad()
const {
  list: dataBrowserList,
  // loading: dataBrowserListLoading,
  // error: dataBrowserListError,
  load: dataBrowserListLoad,
  refetch: dataBrowserListRefetch
} = getDataBrowserList(queryList)

// config

const config = getConfig();

// watches 

watch(dirPath, (newValue) => {
  queryList.value.dirPath = dirPath.value
  items.value = newValue.replace(/^\/(.+)$/, '$1').split('/').reduce((acc, current) => {
    acc.relativePath.push(current)
    const relativePath = acc.relativePath.join('/')
    acc.items.push({
      label: current,
      relativePath,
      to: '#',
      command: () => {
        refetchBrowser(relativePath)
      }
    })
    return acc
  }, {
    relativePath: [] as String[],
    items: [] as MenuItem[]
  }).items
})

watch(dataBrowserStatus, (newValue) => {
  dataBrowserServiceCreated.value = newValue.dataBrowserServiceCreated
  dataBrowserServiceReady.value = newValue.dataBrowserServiceReady
  taskVolumeCreated.value = newValue.taskVolumeCreated
  taskVolumeReady.value = newValue.taskVolumeReady

  dataBrowserReady.value = dataBrowserServiceCreated.value &&
    dataBrowserServiceReady.value &&
    taskVolumeCreated.value &&
    taskVolumeReady.value
  if (dataBrowserReady.value) {
    refetchBrowser();
  } else {
    setTimeout(() => {
      dataBrowserStatusRefetch()
    }, 5000)
  }
})

watch(dataBrowserStatusError, (newValue) => {
  if (newValue !== null) {
    dataBrowserErrors.value = []
    newValue.graphQLErrors.forEach(error => {
      dataBrowserErrors.value = [...dataBrowserErrors.value, error.message]
    });
  }
})

watch(dataBrowserList, (newValue) => {
  if (newValue.data) {
    nodes.value = mapDataBrowserList(newValue.data)
    totalRecords.value = newValue.pageInfo.total
  }
})

watch(search, debounce(() => {
  refetchBrowser();
}, 250))

// utils

const mapDataBrowserList = (list: DataBrowserEntity[]) => {
  return list.map((entity, i): DataBrowserTreeNode => {
    const size = entity.type === DataBrowserEntityType.File ? prettyBytes(entity.attributes?.size || 0) : ''
    const isSymlink = !!entity.attributes?.symlink
    const isValidSymlink = entity.attributes?.symlink?.valid || false
    const symlinkAttributes = entity.attributes?.symlink?.valid ? {
      size: entity.attributes?.symlink.size ? prettyBytes(entity.attributes.symlink.size) : '',
      path: entity.attributes?.symlink.path || ''
    } : null
    return  {
      key: offset.value + i,
      data: {
        name: entity.name,
        size,
        isSymlink,
        isValidSymlink,
        symlinkAttributes,
        type: entity.attributes?.extension || '',
        downloadURL: config ? config.API_URL +
          `/download?volumeMountAlias=${props.volumeMountAlias}&path=` +
          encodeURIComponent([props.volumeMountAlias, dirPath.value, entity.name].join("/")) : "#"
      },
      leaf: entity.type === DataBrowserEntityType.File
    }
  })
}

const refetchBrowser = (dirPathToChange: string = dirPath.value) => {
  dirPath.value = dirPathToChange
  serializeQuery()

  dataBrowserListLoad() || dataBrowserListRefetch(queryList.value)
}

const sortBrowser = (event: TreeTableSortEvent) => {
  sortColumn.value = event.sortField as DataBrowserSortColumn
  sortOrder.value = !event.sortOrder ? DataBrowserSortOption.Asc : (
    event.sortOrder > 0 ? DataBrowserSortOption.Asc : DataBrowserSortOption.Desc
  )
  queryList.value.sort.column = sortColumn.value
  queryList.value.sort.order = sortOrder.value
  refetchBrowser()
}

// events

const onExpand = (node: DataBrowserTreeNode) => {
  dirPath.value = dirPath.value + '/' + node.data.name
  refetchBrowser()
}
const onPage = (event: TreeTablePageEvent) => {
  queryList.value.limit = event.rows
  queryList.value.offset = event.first
  refetchBrowser()
}

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TreeTable = _resolveComponent("TreeTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (dataBrowserErrors.value.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("p", null, "Errors", -1)),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataBrowserErrors.value, (error, i) => {
            return (_openBlock(), _createElementBlock("li", { key: i }, _toDisplayString(error), 1))
          }), 128))
        ])
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (!dataBrowserReady.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("ul", _hoisted_3, [
                (!dataBrowserServiceCreated.value)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_4, _cache[2] || (_cache[2] = [
                      _createElementVNode("i", { class: "fa fa-exclamation-triangle" }, null, -1),
                      _createTextVNode(" Data Browser service not available. Contact your administrator. ")
                    ])))
                  : _createCommentVNode("", true),
                (!dataBrowserServiceReady.value)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_5, _cache[3] || (_cache[3] = [
                      _createElementVNode("i", { class: "fa fa-exclamation-triangle" }, null, -1),
                      _createTextVNode(" Data Browser service not ready yet. Wait or contact your administrator. ")
                    ])))
                  : _createCommentVNode("", true),
                (!taskVolumeCreated.value || !taskVolumeReady.value)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_6, _cache[4] || (_cache[4] = [
                      _createElementVNode("i", { class: "fa fa-spin fa-spinner" }, null, -1),
                      _createTextVNode(" Volume is mounting... ")
                    ])))
                  : _createCommentVNode("", true)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("p", null, [
                _createVNode(_component_Breadcrumb, {
                  home: home.value,
                  model: items.value
                }, null, 8, ["home", "model"])
              ]),
              _createVNode(_component_TreeTable, {
                value: nodes.value,
                lazy: true,
                paginator: true,
                rows: limit.value,
                loading: loading.value,
                onPage: onPage,
                totalRecords: totalRecords.value,
                class: _normalizeClass(`p-treetable-small`),
                onSort: sortBrowser,
                removableSort: "",
                rowsPerPageOptions: [5, 10, 25, 50, 100],
                "sort-field": sortColumn.value,
                "sort-order": sortOrder.value === _unref(DataBrowserSortOption).Asc ? 1 : -1
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _cache[5] || (_cache[5] = _createElementVNode("i", { class: "pi pi-search" }, null, -1)),
                      _createVNode(_component_InputText, {
                        modelValue: search.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
                        placeholder: "Regexp Search"
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_Column, { headerStyle: "width: 2rem" }, {
                    body: _withCtx(({ node }) => [
                      (node.leaf)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_10))
                        : (_openBlock(), _createElementBlock("i", _hoisted_11))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: _unref(DataBrowserSortColumn).Name,
                    header: "Name",
                    sortable: ""
                  }, {
                    body: _withCtx(({ node }) => [
                      (node.leaf)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            innerHTML: makeStrong(node.data.name, search.value)
                          }, null, 8, _hoisted_12))
                        : (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: "#",
                            onClick: ($event: any) => (onExpand(node))
                          }, [
                            _createElementVNode("span", {
                              innerHTML: makeStrong(node.data.name, search.value)
                            }, null, 8, _hoisted_14)
                          ], 8, _hoisted_13)),
                      (node.data.isSymlink)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _cache[6] || (_cache[6] = _createTextVNode("  ")),
                            _withDirectives(_createElementVNode("i", _hoisted_15, null, 512), [
                              [
                                _directive_tooltip,
                                'Symbolic link',
                                void 0,
                                { top: true }
                              ]
                            ])
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["field"]),
                  _createVNode(_component_Column, {
                    field: "size",
                    header: "Size",
                    headerStyle: "width: 10rem"
                  }, {
                    body: _withCtx(({ node }) => [
                      (node.data.isSymlink)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (node.data.isValidSymlink)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                                  _createTextVNode(_toDisplayString(node.data.symlinkAttributes.size) + " ", 1),
                                  _withDirectives(_createElementVNode("i", _hoisted_17, null, 512), [
                                    [
                                      _directive_tooltip,
                                      node.data.symlinkAttributes.path,
                                      void 0,
                                      { top: true }
                                    ]
                                  ])
                                ]))
                              : _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_18, null, 512)), [
                                  [
                                    _directive_tooltip,
                                    'Broken symlink',
                                    void 0,
                                    { top: true }
                                  ]
                                ])
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(node.data.size), 1)
                          ], 64))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    field: _unref(DataBrowserSortColumn).Extension,
                    header: "Type",
                    sortable: "",
                    headerStyle: "width: 10rem"
                  }, {
                    body: _withCtx(({ node }) => [
                      _createTextVNode(_toDisplayString(node.data.type), 1)
                    ]),
                    _: 1
                  }, 8, ["field"]),
                  _createVNode(_component_Column, { headerStyle: "width: 10rem;" }, {
                    body: _withCtx(({ node }) => [
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("a", {
                          href: node.data.downloadURL,
                          title: "Download"
                        }, [
                          _withDirectives(_createVNode(_component_Button, {
                            type: "button",
                            icon: "pi pi-download",
                            severity: "success",
                            label: "",
                            size: "small"
                          }, null, 512), [
                            [
                              _directive_tooltip,
                              'Download',
                              void 0,
                              { top: true }
                            ]
                          ])
                        ], 8, _hoisted_20)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "rows", "loading", "totalRecords", "sort-field", "sort-order"])
            ]))
      ], 64))
}
}

})