import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = { class: "col-12 formgrid grid" }
const _hoisted_5 = {
  key: 0,
  class: "field col-12 md:col-1"
}
const _hoisted_6 = { class: "p-float-label" }
const _hoisted_7 = { class: "mlwn-col-input" }
const _hoisted_8 = {
  key: 1,
  class: "field col-12 md:col-3"
}
const _hoisted_9 = { class: "p-float-label" }
const _hoisted_10 = {
  key: 0,
  class: "mlwn-col-input"
}
const _hoisted_11 = {
  key: 2,
  class: "field col-12 md:col-1"
}
const _hoisted_12 = { class: "p-float-label" }
const _hoisted_13 = { class: "mlwn-col-input" }
const _hoisted_14 = {
  key: 3,
  class: "field col-12 md:col-3"
}
const _hoisted_15 = { class: "p-float-label" }
const _hoisted_16 = {
  key: 0,
  class: "mlwn-col-input"
}
const _hoisted_17 = {
  key: 4,
  class: "field col-12 md:col-1"
}
const _hoisted_18 = { class: "p-float-label" }
const _hoisted_19 = { class: "mlwn-col-input" }
const _hoisted_20 = {
  key: 5,
  class: "field col-12 md:col-3"
}
const _hoisted_21 = { class: "p-float-label" }
const _hoisted_22 = {
  key: 0,
  class: "mlwn-col-input"
}
const _hoisted_23 = { class: "col-12 md:col-12" }
const _hoisted_24 = { class: "flex flex-row flex-wrap justify-content-between mt-6" }

import { Form } from 'vee-validate';
import { Ref, nextTick, ref, watch } from 'vue';

import { usePresets } from '@/composables/usePresets';
import CameraDefPresetForm from '@/components/Admin/Settings/Presets/forms/CameraDefPresetForm.vue';
import LidarDefPresetForm from '@/components/Admin/Settings/Presets/forms/LidarDefPresetForm.vue';
import TrajectoryDefPresetForm from '@/components/Admin/Settings/Presets/forms/TrajectoryDefPresetForm.vue';

import { Preset } from '@/gql/graphql';
import { PRESET_TYPES } from '@/helpers/constants';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

// Define the event emitter
const update = false//ref(router.currentRoute.value.path.indexOf('/edit/') > -1)


export default /*@__PURE__*/_defineComponent({
  __name: 'SensorDefinitions',
  props: {
  formData: {
    type: Object,
    required: true
  }
},
  emits: ['next', 'prevPage', 'onCancel'],
  setup(__props, { emit: __emit }) {

/**
 * Load validation references, router, vue
 */
const emit = __emit

const onCancel = () => {
  emit('onCancel', router.currentRoute.value.name)
}

const stepBack = () => {
  emit('prevPage', router.currentRoute.value.name)
}

const router = useRouter()

const props = __props

const { getPresets } = usePresets()
const { presets: cameraPresets } = getPresets({
  filter: {
    fields: [
      {
        type: "equals",
        name: "presetTypeAlias",
        value: PRESET_TYPES.CAMERA_DEF
      }
    ]
  }
})

watch(cameraPresets, () => {
  if (cameraPresets?.value) {
    cameraPresets.value.unshift({key: 'New empty preset'})
  }
})

const { presets: lidarPresets } = getPresets({
  filter: {
    fields: [
      {
        type: "equals",
        name: "presetTypeAlias",
        value: PRESET_TYPES.LIDAR_DEF
      }
    ]
  }
})

watch(lidarPresets, () => {
  if (lidarPresets?.value) {
    lidarPresets.value.unshift({key: 'New empty preset'})
  }
})

const { presets: trajectoryPresets } = getPresets({
  filter: {
    fields: [
      {
        type: "equals",
        name: "presetTypeAlias",
        value: PRESET_TYPES.TRAJECTORY_DEF
      }
    ]
  }
})

watch(trajectoryPresets, () => {
  if (trajectoryPresets?.value) {
    trajectoryPresets.value.unshift({key: 'New empty preset'})
  }
})

const CameraDefPresetAlias: Ref<Preset | null | undefined> = ref(
  props.formData?.populationConfig?.CameraDefPresetAlias ?? null
)
const CameraDef: Ref<any> = ref(
  {
    CameraDef: props.formData?.populationConfig?.CameraDef ?? null
  }
)
const cameraDefPresetForm = ref(null)
watch(cameraDefPresetForm, () => {
  if (cameraDefPresetForm?.value) {
    cameraDefPresetForm?.value?.setReadonly(CameraDefPresetAlias.value != 'New empty preset')
  }
})

const LidarDefPresetAlias: Ref<Preset | null | undefined> = ref(
  props.formData?.populationConfig?.LidarDefPresetAlias ?? null
)
const LidarDef: Ref<any> = ref(
  {
    LidarDef: props.formData?.populationConfig?.LidarDef ?? null
  }
)

const lidarDefPresetForm = ref(null)
watch(lidarDefPresetForm, () => {
  if (lidarDefPresetForm?.value) {
    lidarDefPresetForm?.value?.setReadonly(LidarDefPresetAlias.value != 'New empty preset')
  }
})

const TrajectoryDefPresetAlias: Ref<Preset | null | undefined> = ref(
  props.formData?.populationConfig?.TrajectoryDefPresetAlias ?? null
)
const TrajectoryDef: Ref<any> = ref(
  {
    TrajectoryDef: props.formData?.populationConfig?.TrajectoryDef ?? null
  }
)

const trajectoryDefPresetForm = ref(null)
watch(trajectoryDefPresetForm, () => {
  if (trajectoryDefPresetForm?.value) {
    trajectoryDefPresetForm?.value?.setReadonly(TrajectoryDefPresetAlias.value != 'New empty preset')
  }
})

const showCameraPreset = ref(props.formData?.populationConfig?.CameraDefPresetAlias ? true : false)
const showLidarPreset = ref(props.formData?.populationConfig?.LidarDefPresetAlias ? true : false)
const showTrajectoryPreset = ref(props.formData?.populationConfig?.TrajectoryDefPresetAlias ? true : false)

const onPresetCameraValueChange = (value: any) => {
  if (value && value.value) {
    showCameraPreset.value = false

    const presetValue = Object.assign({}, cameraPresets.value.find(item => item.key === value.value))
    CameraDef.value = presetValue.value

    nextTick(() => {
      showCameraPreset.value = true
      setTimeout(() => cameraDefPresetForm?.value?.setReadonly(CameraDefPresetAlias.value != 'New empty preset'), 10)
    })
  }
}

const onPresetLidarValueChange = (value: any) => {
  if (value && value.value) {
    showLidarPreset.value = false

    const presetValue = Object.assign({}, lidarPresets.value.find(item => item.key === value.value))
    LidarDef.value = presetValue.value

    nextTick(() => {
      showLidarPreset.value = true
    })
  }
}

const onPresetTrajectoryValueChange = (value: any) => {
  if (value && value.value) {
    showTrajectoryPreset.value = false

    const presetValue = Object.assign({}, trajectoryPresets.value.find(item => item.key === value.value))
    TrajectoryDef.value = presetValue.value

    nextTick(() => {
      showTrajectoryPreset.value = true
    })
  }
}

// Function
const onSubmit = (values: any) => {
  //Object.assign({CameraDefPresetAlias: CameraDefPresetAlias.value}, CameraDef.value )

  if (cameraCheck.value && !CameraDef?.value?.CameraDef) {
    toast.add({severity:'error', summary: 'Camera definition missing', detail:'Please fill in or select camera definition', life: 3000})
    return
  }

  if (lidarCheck.value && !LidarDef?.value?.LidarDef) {
    toast.add({severity:'error', summary: 'LiDAR definition missing', detail:'Please fill in or select lidar definition', life: 3000})
    return
  }

  if (trajectoryCheck.value && !TrajectoryDef?.value?.TrajectoryDef) {
    toast.add({severity:'error', summary: 'Trajectory definition missing', detail:'Please fill in or select trajectory definition', life: 3000})
    return
  }

  let _values = JSON.parse(JSON.stringify(values))
  _values.Camera = cameraCheck
  _values.CameraDef = CameraDef.value.CameraDef
  _values.CameraDefPresetAlias = CameraDefPresetAlias.value
  _values.Lidar = lidarCheck
  _values.LidarDef = LidarDef.value.LidarDef
  _values.LidarDefPresetAlias = LidarDefPresetAlias.value
  _values.Trajectory = trajectoryCheck
  _values.TrajectoryDef = TrajectoryDef.value.TrajectoryDef
  _values.TrajectoryDefPresetAlias = TrajectoryDefPresetAlias.value
  emit('next', _values,  router.currentRoute.value.name);
}

const collapsed: Ref<any> = ref(
  {
    camera: true,
    lidar: true,
    trajectory: true
  }
)

const cameraCheck = ref(props.formData?.populationConfig?.Camera ? true : false)
const lidarCheck = ref(props.formData?.populationConfig?.Lidar ? true : false)
const trajectoryCheck = ref(props.formData?.populationConfig?.Trajectory ? true : false)

const toggle = (id: string, event: any) => {
  Object.keys(collapsed.value).forEach(element => {
    collapsed.value[element] = true
  })
  collapsed.value[id] = event.value
}

const { createPreset } = usePresets()
const toast = useToast()

const savePreset = async (name: string, data: object, type: PRESET_TYPES) => {
  try {
    let _value = JSON.parse(JSON.stringify(data))

    let res = await createPreset({
      key: name,
      presetTypeAlias: type,
      value: {
        [type]: _value
      },
      presetType: {
        __typename: undefined,
        alias: '',
        attributes: undefined,
        name: undefined
      }
    })

    if(res.success){
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully created', life: 3000 })
      switch (type) {
      case PRESET_TYPES.LIDAR_DEF:
        LidarDefPresetAlias.value = name
        break
      case PRESET_TYPES.TRAJECTORY_DEF:
        TrajectoryDefPresetAlias.value = name
        break
      case PRESET_TYPES.CAMERA_DEF:
        CameraDefPresetAlias.value = name
        break
      }
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating', life: 3000})
    }
  } catch (err) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating. ' + err.message, life: 3000})
    console.error(err)
  }
}

const onSave = (name: string, type: PRESET_TYPES) => {
  switch (type) {
  case PRESET_TYPES.LIDAR_DEF:
    savePreset(name, LidarDef.value.LidarDef, type)
    break
  case PRESET_TYPES.TRAJECTORY_DEF:
    savePreset(name, TrajectoryDef.value.TrajectoryDef, type)
    break
  case PRESET_TYPES.CAMERA_DEF:
    savePreset(name, CameraDef.value.CameraDef, type)
    break
  }
}


return (_ctx: any,_cache: any) => {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Fieldset = _resolveComponent("Fieldset")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Form), {
          onSubmit: onSubmit,
          class: "grid p-fluid"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              (!update)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, [
                        _createVNode(_component_Checkbox, {
                          "data-cy": "sensorsDefinitions__cameraCheckbox",
                          modelValue: cameraCheck.value,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((cameraCheck).value = $event)),
                          binary: true,
                          name: "camera",
                          class: "mt-2"
                        }, null, 8, ["modelValue"]),
                        _cache[12] || (_cache[12] = _createElementVNode("label", {
                          for: "camera",
                          class: "ml-4 checkbox-label"
                        }, "Camera", -1))
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!update)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, [
                      (cameraCheck.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                            _createVNode(_component_Dropdown, {
                              "data-cy": "sensorsDefinitions__cameraDropdown",
                              "scroll-height": "400px",
                              id: "presetTypeAliasCamera",
                              class: "full-width",
                              modelValue: CameraDefPresetAlias.value,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((CameraDefPresetAlias).value = $event)),
                              options: _unref(cameraPresets),
                              optionLabel: "key",
                              optionValue: "key",
                              placeholder: "Select preset",
                              onChange: onPresetCameraValueChange,
                              "v-if": !update
                            }, null, 8, ["modelValue", "options", "v-if"]),
                            _cache[13] || (_cache[13] = _createElementVNode("label", { for: "presetTypeAliasCamera" }, "Select camera preset", -1))
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!update)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("span", _hoisted_12, [
                      _createElementVNode("span", _hoisted_13, [
                        _createVNode(_component_Checkbox, {
                          "data-cy": "sensorsDefinitions__lidarCheckbox",
                          modelValue: lidarCheck.value,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((lidarCheck).value = $event)),
                          binary: true,
                          name: "lidar",
                          class: "mt-2"
                        }, null, 8, ["modelValue"]),
                        _cache[14] || (_cache[14] = _createElementVNode("label", {
                          for: "lidar",
                          class: "ml-4 checkbox-label"
                        }, "LiDAR", -1))
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!update)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("span", _hoisted_15, [
                      (lidarCheck.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                            _createVNode(_component_Dropdown, {
                              "data-cy": "sensorsDefinitions__lidarDropdown",
                              "scroll-height": "400px",
                              id: "presetTypeAliasLidar",
                              class: "full-width",
                              modelValue: LidarDefPresetAlias.value,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((LidarDefPresetAlias).value = $event)),
                              options: _unref(lidarPresets),
                              optionLabel: "key",
                              optionValue: "key",
                              placeholder: "Select preset",
                              onChange: onPresetLidarValueChange
                            }, null, 8, ["modelValue", "options"]),
                            _cache[15] || (_cache[15] = _createElementVNode("label", { for: "presetTypeAliasLidar" }, "Select LiDAR preset", -1))
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!update)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("span", _hoisted_18, [
                      _createElementVNode("span", _hoisted_19, [
                        _createVNode(_component_Checkbox, {
                          "data-cy": "sensorsDefinitions__trajectoryCheckbox",
                          modelValue: trajectoryCheck.value,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((trajectoryCheck).value = $event)),
                          binary: true,
                          name: "trajectory",
                          class: "mt-2"
                        }, null, 8, ["modelValue"]),
                        _cache[16] || (_cache[16] = _createElementVNode("label", {
                          for: "trajectory",
                          class: "ml-4 checkbox-label"
                        }, "Trajectory", -1))
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!update)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createElementVNode("span", _hoisted_21, [
                      (trajectoryCheck.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_22, [
                            _createVNode(_component_Dropdown, {
                              "data-cy": "sensorsDefinitions__trajectoryDropdown",
                              "scroll-height": "400px",
                              id: "presetTypeAliasTrajectory",
                              class: "full-width",
                              modelValue: TrajectoryDefPresetAlias.value,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((TrajectoryDefPresetAlias).value = $event)),
                              options: _unref(trajectoryPresets),
                              optionLabel: "key",
                              optionValue: "key",
                              placeholder: "Select preset",
                              onChange: onPresetTrajectoryValueChange
                            }, null, 8, ["modelValue", "options"]),
                            _cache[17] || (_cache[17] = _createElementVNode("label", { for: "presetTypeAliasTrajectory" }, "Select trajectory preset", -1))
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("span", null, [
                  (cameraCheck.value && CameraDefPresetAlias.value)
                    ? (_openBlock(), _createBlock(_component_Fieldset, {
                        key: 0,
                        "data-cy": "sensorsDefinitions__cameraPresetTab",
                        legend: `Camera preset${CameraDefPresetAlias.value && !update ? ': ' + CameraDefPresetAlias.value : ''}`,
                        toggleable: true,
                        collapsed: collapsed.value.camera,
                        onToggle: _cache[7] || (_cache[7] = ($event: any) => (toggle('camera', $event))),
                        class: "row-toggle-fieldest"
                      }, {
                        default: _withCtx(() => [
                          (!CameraDefPresetAlias.value || showCameraPreset.value)
                            ? (_openBlock(), _createBlock(CameraDefPresetForm, {
                                key: 0,
                                ref_key: "cameraDefPresetForm",
                                ref: cameraDefPresetForm,
                                "data-cy": "sensorsDefinitions__cameraPresetSection",
                                modelValue: CameraDef.value,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((CameraDef).value = $event)),
                                showTitle: false,
                                "save-preset": (name) => onSave(name, _unref(PRESET_TYPES).CAMERA_DEF),
                                "save-preset-enable": true
                              }, null, 8, ["modelValue", "save-preset"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["legend", "collapsed"]))
                    : _createCommentVNode("", true),
                  (lidarCheck.value && LidarDefPresetAlias.value)
                    ? (_openBlock(), _createBlock(_component_Fieldset, {
                        key: 1,
                        "data-cy": "sensorsDefinitions__lidarPresetTab",
                        legend: `LiDAR preset${LidarDefPresetAlias.value && !update ? ': ' + LidarDefPresetAlias.value : ''}`,
                        toggleable: true,
                        collapsed: collapsed.value.lidar,
                        onToggle: _cache[9] || (_cache[9] = ($event: any) => (toggle('lidar', $event))),
                        class: "row-toggle-fieldest"
                      }, {
                        default: _withCtx(() => [
                          (!LidarDefPresetAlias.value || showLidarPreset.value)
                            ? (_openBlock(), _createBlock(LidarDefPresetForm, {
                                key: 0,
                                ref_key: "lidarDefPresetForm",
                                ref: lidarDefPresetForm,
                                "data-cy": "sensorsDefinitions__lidarPresetSection",
                                modelValue: LidarDef.value,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((LidarDef).value = $event)),
                                showTitle: false,
                                "save-preset": (name) => onSave(name, _unref(PRESET_TYPES).LIDAR_DEF),
                                "save-preset-enable": true
                              }, null, 8, ["modelValue", "save-preset"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["legend", "collapsed"]))
                    : _createCommentVNode("", true),
                  (trajectoryCheck.value && TrajectoryDefPresetAlias.value)
                    ? (_openBlock(), _createBlock(_component_Fieldset, {
                        key: 2,
                        "data-cy": "sensorsDefinitions__trajectoryPresetTab",
                        legend: `Trajectory preset${TrajectoryDefPresetAlias.value && !update ? ': ' + TrajectoryDefPresetAlias.value : ''}`,
                        toggleable: true,
                        collapsed: collapsed.value.trajectory,
                        onToggle: _cache[11] || (_cache[11] = ($event: any) => (toggle('trajectory', $event))),
                        class: "row-toggle-fieldest"
                      }, {
                        default: _withCtx(() => [
                          (!TrajectoryDefPresetAlias.value || showTrajectoryPreset.value)
                            ? (_openBlock(), _createBlock(TrajectoryDefPresetForm, {
                                key: 0,
                                ref_key: "trajectoryDefPresetForm",
                                ref: trajectoryDefPresetForm,
                                "data-cy": "sensorsDefinitions__trajectoryPresetSection",
                                modelValue: TrajectoryDef.value,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((TrajectoryDef).value = $event)),
                                showTitle: false,
                                "save-preset": (name) => onSave(name, _unref(PRESET_TYPES).TRAJECTORY_DEF),
                                "save-preset-enable": true
                              }, null, 8, ["modelValue", "save-preset"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["legend", "collapsed"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_component_Button, {
                      "data-cy": "sensorsDefinitions__backButton",
                      label: "Back",
                      icon: "fa-solid fa-arrow-left",
                      class: "p-button-info mr-2 mb-2 mlwn-button-submit",
                      onClick: stepBack
                    }),
                    false
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          label: "Cancel",
                          icon: "fa-solid fa-xmark",
                          onClick: onCancel,
                          class: "p-button-secondary mr-2 mb-2 mlwn-button-submit"
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_Button, {
                      "data-cy": "sensorsDefinitions__nextButton",
                      label: "Next",
                      type: "submit",
                      icon: "fa-solid fa-arrow-right",
                      class: "p-button-success mr-2 mb-2 mlwn-button-submit"
                    })
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})