import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_4 = { class: "flex align-items-center justify-content-center" }
const _hoisted_5 = { class: "flex align-items-center justify-content-center" }

import { useMutation } from '@vue/apollo-composable'
import PresetForm from './components/PresetForm.vue'
import { DELETE_PRESET } from '@/apollo/mutations/presets'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'PresetDetail',
  props: {
  presetKey: {
    type: String,
    required: true
  }
},
  setup(__props) {

const toast = useToast()
const router = useRouter()

const props = __props

const { mutate: deletePresetMutations } = useMutation(DELETE_PRESET)

const onDeleteClick = () => {
  Swal.fire({
    title: 'Are you sure you want to delete preset "'+ props.presetKey +'"?',
    text: 'This can not be undone.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes'
  }).then(async function (result: any) {
    if (result.isConfirmed) {
      deletePreset(props.presetKey)
    }
  }.bind(this))
}

const deletePreset = async (presetKey: string) => {
  try {
    const res = await deletePresetMutations({
      key: presetKey
    })

    if (res && res.data && res.data.deletePreset.status === 200) {
      toast.add({ severity: 'success', summary: 'Success', detail: 'Class succesfully deleted', life: 3000 })
      router.push({
        name: 'presets'
      })
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while deleting', life: 3000})
    }
  } catch (err) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while deleting', life: 3000})
  }
}

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[0] || (_cache[0] = _createTextVNode(" Definition of preset: ")),
              _createElementVNode("strong", null, _toDisplayString(__props.presetKey), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createVNode(_component_Button, {
                icon: "fa-solid fa-trash",
                severity: "danger",
                "aria-label": "Delete preset",
                onClick: onDeleteClick
              }, null, 512), [
                [
                  _directive_tooltip,
                  'Delete preset',
                  void 0,
                  { left: true }
                ]
              ])
            ])
          ])
        ]),
        content: _withCtx(() => [
          _createVNode(PresetForm, {
            presetKey: __props.presetKey,
            update: true
          }, null, 8, ["presetKey"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})