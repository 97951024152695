import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = {
  key: 0,
  class: "col-12"
}
const _hoisted_3 = { class: "m-0 code-block" }
const _hoisted_4 = { key: 3 }

import { computed } from 'vue';

import { useScenes } from '@/composables/useScenes';
import { TaskStatusAlias } from '@/helpers/constants'
import ScenePopulationForm from './components/ScenePopulationForm.vue';

//@ts-expect-error

export default /*@__PURE__*/_defineComponent({
  __name: 'SceneCreated',
  props: {
  sceneId: {
    required: true,
    type: String
  }
},
  setup(__props) {

const props = __props

const { getSerializedPopulationConfig, getScene } = useScenes()
const { scene, refetch } = getScene(props.sceneId)
const { populationConfig } = getSerializedPopulationConfig(props.sceneId)

// 
const ingestTaskCreated = computed(() => scene?.value?.ingestTask)
//@ts-expect-error
const dmTaskStatus = computed(() => scene?.value?.ingestTask?.taskStatus?.alias)

return (_ctx: any,_cache: any) => {
  const _component_Message = _resolveComponent("Message")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(scene))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!ingestTaskCreated.value)
            ? (_openBlock(), _createBlock(_component_Message, {
                key: 0,
                closable: false,
                severity: "info"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("strong", null, "Scene was created, with type of population: " + _toDisplayString(_unref(scene).populationType.name), 1),
                  _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                  _cache[1] || (_cache[1] = _createTextVNode(" You can proceed to population with the following form.")),
                  _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                  _cache[3] || (_cache[3] = _createTextVNode(" Select ")),
                  _cache[4] || (_cache[4] = _createElementVNode("strong", null, "data center", -1)),
                  _cache[5] || (_cache[5] = _createTextVNode(" where population will be performed and ")),
                  _cache[6] || (_cache[6] = _createElementVNode("strong", null, "empty", -1)),
                  _cache[7] || (_cache[7] = _createTextVNode(" output volume. "))
                ]),
                _: 1
              }))
            : (ingestTaskCreated.value && dmTaskStatus.value === _unref(TaskStatusAlias).Created)
              ? (_openBlock(), _createBlock(_component_Message, {
                  key: 1,
                  closable: false,
                  severity: "success"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("strong", null, "Awaits " + _toDisplayString(_unref(scene).populationType.name) + " processing...", 1),
                    _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_unref(scene).populationType.name) + " processing in queue... ", 1)
                  ]),
                  _: 1
                }))
              : (ingestTaskCreated.value && dmTaskStatus.value === _unref(TaskStatusAlias).Processing)
                ? (_openBlock(), _createBlock(_component_Message, {
                    key: 2,
                    closable: false,
                    severity: "success",
                    icon: "fa-solid fa-cog"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("strong", null, _toDisplayString(_unref(scene).populationType.name) + " processing...", 1),
                      _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_unref(scene).populationType.name) + " is processing... ", 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
          _createVNode(_component_Panel, {
            toggleable: "",
            collapsed: ""
          }, {
            header: _withCtx(() => _cache[10] || (_cache[10] = [
              _createElementVNode("span", null, [
                _createTextVNode("Serialized "),
                _createElementVNode("strong", null, "config")
              ], -1)
            ])),
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_3, [
                _createElementVNode("pre", null, _toDisplayString(_unref(populationConfig)), 1)
              ])
            ]),
            _: 1
          }),
          (!ingestTaskCreated.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(ScenePopulationForm, {
                  sceneId: __props.sceneId,
                  afterSuccessCb: _unref(refetch)
                }, null, 8, ["sceneId", "afterSuccessCb"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})