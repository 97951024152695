<template>
  <span>
    <i class="fa-solid fa-triangle-exclamation mlwn-error-icon"  @click="visible = true" v-tooltip.bottom="'error'"/>
    <Dialog class="mlwn-error-dialog" v-model:visible="visible" modal :header="title" :style="{ width: '50vw' }">
      <!-- <v-md-editor :model-value="error" mode="preview" ></v-md-editor> -->

      <MdPreview v-model="error" />
    </Dialog>
  </span>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps({
  error: {
    required: false,
    default: 'unknown error'
  },
  title: {
    type: String,
    required: false,
    default: `Error`
  },
  showButton: {
    type: Boolean,
  }
})

const error = ref(props.error)
const title = ref(props.title)
const visible = ref(false)
</script>

<style lang="scss">
.mlwn-error-dialog {
  .vuepress-markdown-body {
    padding: 0 !important;
  }

  pre {
    background-color: #f0f0f0;
  }
}
.mlwn-error-icon {
  cursor: pointer;
  width: 1rem;
  text-align: center;
  color: red;
}



</style>
