<template>
  <div class="grid">
    <div class="col-12 md:col-6">
      <div class="p-fluid">
        <Form class="grid p-fluid">
          <div class="col-12 col:md-12 formgrid">

            <InfoInputGroup>
              <Dropdown id="taskTypeAlias" data-cy="serviceTaskForm__selectTaskType" v-model="taskTypeAlias" :options="availableTaskTypes" optionValue="alias"
                        optionLabel="alias" :loading="loadingTaskTypes" placeholder="Select Task type" scroll-height="400px">
              </Dropdown>
              <label for="taskTypeAlias">Task type</label>
            </InfoInputGroup>

            <template v-if="taskTypeAlias">

              <InfoInputGroup>
                <Dropdown id="collocationId" data-cy="serviceTaskForm__selectCollocationId" v-model="collocationId" :options="availableCollocationId" optionValue="id"
                          optionLabel="id" :loading="loadingTaskTypes" placeholder="Select Collocation" scroll-height="400px">
                </Dropdown>
                <label for="collocationId">Collocation</label>
              </InfoInputGroup>


              <InfoInputGroup>
                <Dropdown id="logVolumeMountAlias" data-cy="serviceTaskForm__selectLogVolume" v-model="logVolumeMountAlias" :options="availableVolumeMountAliases" optionValue="mountAlias"
                          optionLabel="name" :loading="loadingTaskTypes" placeholder="Select Log Volume" scroll-height="400px">
                </Dropdown>
                <label for="logVolumeMountAlias">Log Volume</label>
              </InfoInputGroup>

              <template v-if="taskTypeAlias === DataMgmtTaskTypeAlias.ImagePoseService">


                <InfoInputGroup>
                  <Dropdown id="outputVolumeMountAlias" data-cy="serviceTaskForm__selectOutputVolume" v-model="outputVolumeMountAlias" :options="availableVolumeMountAliases" optionValue="mountAlias"
                            optionLabel="name" :loading="loadingTaskTypes" placeholder="Select Output Volume" scroll-height="400px">
                  </Dropdown>
                  <label for="outputVolumeMountAlias">Outpout Volume</label>
                </InfoInputGroup>

                <fieldset class="mlwn-row-toggle-fieldset mlwn-multiple-input-list-with-validation-row">
                  <legend>Select ophoto file</legend>
                  <InfoInputGroup>
                    <DataBrowseSelect @select-file="onSelectFile" :selected="wmsVolumeMountAliasValue"
                                      :preselectedVolumeMountAliases="availableVolumeMountAliases" :button-text="'Select path'"></DataBrowseSelect>
                  </InfoInputGroup>

                  <InfoInputGroup>
                    <InputText data-cy="serviceTaskFormFieldset__wmsVolumeId" id="wmsVolumeMountAlias" v-model="wmsVolumeMountAlias" :disabled="true" type="text"
                               placeholder="WMS Volume Id" :readonly="true" />
                    <label for="wmsVolumeMountAlias">WMS Volume Id</label>
                  </InfoInputGroup>

                  <InfoInputGroup>
                    <InputText id="wmsOphotoPath" data-cy="serviceTaskFormFieldset__wmsOphotoPath" v-model="wmsOphotoPath" :disabled="true" type="text"
                               placeholder="WMS Ophoto Path" :readonly="true" />
                    <label for="wmsOphotoPath">WMS Ophoto Path</label>
                  </InfoInputGroup>
                </fieldset>

                <InfoInputGroup hint-content="800" hint-title="Example">
                  <InputNumber data-cy="serviceTasks__selectImageSize" id="imageSize" v-model="imageSize" placeholder="800">
                  </InputNumber>
                  <label for="imageSize">imageSize</label>
                </InfoInputGroup>
              </template>

              <br>
              <div class="flex flex-row flex-wrap justify-content-end">
                <Button @click="onCreateTask" data-cy="serviceTaskForm__createButton" label="Create" icon="fa-solid fa-check"
                        class="p-button-success mr-2 mb-2 mlwn-button-submit"></Button>

                <Button @click="onCancelClick" label="Cancel" icon="fa-solid fa-xmark"
                        class="p-button-secondary mr-2 mb-2 mlwn-button-submit" />
              </div>
            </template>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * Load validation references, router, vue
 */
import { Form } from 'vee-validate';
import { computed, Ref, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Dropdown from 'primevue/dropdown';
import InfoInputGroup from '@/components/UI/Form/InfoInputGroup.vue';
import DataBrowseSelect from '@/components/DataBrowser/DataBrowseSelect.vue';
import { useTasks } from '@/composables/useTasks';
import { DataMgmtTaskTypeAlias } from '@/gql/graphql'
import { useTaskTypes } from '@/composables/useTaskTypes';
import { taskTypeMapper } from './ServiceTasksConfig';

const router = useRouter()
const toast = useToast()

let taskTypeAlias = ref()
let collocationId = ref()
let logVolumeMountAlias = ref()
let outputVolumeMountAlias = ref()
let wmsVolumeMountAlias = ref()
let wmsVolumeMountAliasValue = ref()
let wmsOphotoPath = ref()
let imageSize = ref()
imageSize.value = 800

const { getTaskTypes } = useTaskTypes()
const { taskTypes, loading: loadingTaskTypes } = getTaskTypes()
const {
  createFileProviderServiceTask,
  createImagePoseServiceTask,
  createDataBrowserServiceTask,
  createGeoConversionServiceTask,
} = useTasks()

const availableCollocationId: Ref<Array<string>> = ref([])
const availableVolumeMountAliases: Ref<Array<{ mountAlias: string, name: string }>> = ref([])

watch(taskTypeAlias, () => {
  if (Object.keys(taskTypeMapper).includes(taskTypeAlias.value)) {
    // update available collocations
    const taskType = taskTypes.value.find((taskType: any) => taskType && taskType.alias === taskTypeAlias.value)
    if (taskType) {
      availableCollocationId.value = taskType?.capabilities.map((e: any) => e.collocation)
    }
  }
})

watch(collocationId, () => {
  const taskType = taskTypes.value.find((taskType: any) => taskType && taskType.alias === taskTypeAlias.value)
  if (taskType) {
    let volumeAliases: { mountAlias: string, name: string }[] = []
    const capabilities = taskType.capabilities.filter((capability: any) => capability?.collocation.id === collocationId.value)
    for (let capability of capabilities) {
      for (let connection of capability?.collocation.connections || []) {
        for (let volume of connection?.storage.volumes || []) {
          if (!volumeAliases.find((_volume) => _volume.mountAlias === volume.mountAlias)) {
            volumeAliases.push({
              mountAlias: volume.mountAlias,
              name: volume.name || volume.mountAlias,
            })
          }
        }
      }
    }
    availableVolumeMountAliases.value = [...volumeAliases]
  }
})

const availableTaskTypes = computed(() => {
  const availableTaskTypes = taskTypes.value.filter((taskType: any) => Object.keys(taskTypeMapper).includes(taskType!.alias))
  return availableTaskTypes
})

const onCreateTask = async () => {
  if (taskTypeAlias.value === DataMgmtTaskTypeAlias.FileProviderService) {
    try {
      await createFileProviderServiceTask({
        dmCollocationId: collocationId.value,
        logDmVolumeMountAlias: logVolumeMountAlias.value,
      })
      router.go(-1)
    } catch (err) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error while creating task', life: 3000 })
    }
  }
  if (taskTypeAlias.value === DataMgmtTaskTypeAlias.ImagePoseService) {
    try {
      await createImagePoseServiceTask({
        dmCollocationId: collocationId.value,
        outputDmVolumeMountAlias: outputVolumeMountAlias.value,
        logDmVolumeMountAlias: logVolumeMountAlias.value,
        wmsDmVolumeMountAlias: wmsVolumeMountAlias.value,
        wmsOphotoPath: wmsOphotoPath.value,
        imageSize: imageSize.value
      })
      router.go(-1)
    } catch (err) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error while creating task', life: 3000 })
    }
  }
  if (taskTypeAlias.value === DataMgmtTaskTypeAlias.DataBrowserService) {
    try {
      await createDataBrowserServiceTask({
        dmCollocationId: collocationId.value,
        logDmVolumeMountAlias: logVolumeMountAlias.value,
      })
      router.go(-1)
    } catch (err) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error while creating task', life: 3000 })
    }
  }
  if (taskTypeAlias.value === DataMgmtTaskTypeAlias.GeoConversionService) {
    try {
      await createGeoConversionServiceTask({
        dmCollocationId: collocationId.value,
        dmVolumeMountAlias: logVolumeMountAlias.value,
      })
      router.go(-1)
    } catch (err) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error while creating task', life: 3000 })
    }
  }
}

function onCancelClick() {
  router.go(-1)
}


function onSelectFile(target: any) {
  wmsVolumeMountAlias.value = target.volumeMountAlias
  wmsOphotoPath.value = target.volumeMountAlias + '/' + target.path
}
</script>
