<template>
  <div class="field" :class="fieldClass">
    <InfoInputGroup :hint-content="hintContent" :hint-title="hintTitle">
      <Checkbox
        v-model="value"
        :name="name"
        :label="label"
        :binary="true"
        @change="(e:any) => change"
        :disabled="disabled"
      ></Checkbox>
      <label :for="name" :style="{ left: '2em'}">{{ label }}</label>
    </InfoInputGroup>
    <small :id="`${name}-help`" class="p-error">{{ errorMessage }}</small>
  </div>
</template>

<script setup lang="ts">
import { toRef } from "vue";
import { useField } from "vee-validate";
import InfoInputGroup from "../InfoInputGroup.vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  fieldClass: {
    type: String,
    required: false,
    default: "",
  },
  hintContent: {
    type: String,
    required: false,
    default: null,
  },
  hintTitle: {
    type: String,
    required: false,
    default: `Hint`,
  },
  change: {
    type: Function,
    required: false,
    default: () => {},
  },
});

const { errorMessage, value } = useField(toRef(props, "name"));
</script>
