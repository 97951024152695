<template>
  <span>
    <Dialog v-model:visible="info.visible" :header="info.title" @hide="onDialogClose"
      :position="'center'" :modal="true" :style="{ width: info.data.menuTabs && info.data.menuTabs.length > 0 ? '900px' : '600px' }">
      <template #default>
        <div class="p-fluid p-grid p-formgrid">
          <div class="p-col-6 p-md-4">
            <div class="row" v-if="info.data.latLng">
              <div class="columnLeft">Lat, long</div>
              <div class="columnRight">{{ info.data.latLng }} (<a href="#" @click="openMap('google')">Google</a>, <a href="#" @click="openMap('mapy')">Mapy.cz</a>, <a href="#" @click="openMap('bing')">Bing</a>, <a href="#" @click="openMap('osm')">OSM</a>)</div>
            </div>   
            <TabView v-if="info.data.menuTabs && info.data.menuTabs.length > 0" :style="{ marginTop: '16px' }">
              <TabPanel :header="tab.filter((f:any) => f.key == 'ViewId')[0].value"  v-for="tab of info.data.menuTabs" :key="tab.ViewId">
                <div v-for="item of tab" :key="item.key" class="row">
                  <div class="columnLeft border">{{ item.key }}</div>
                  <div class="columnRight border">{{ item.value }}</div>
                </div>
              </TabPanel>
            </TabView>
            <div v-if="info.data.menuItems && info.data.menuItems.length > 0">
              <div v-for="item of info.data.menuItems" :key="item.key" class="row">
                <div class="columnLeft border">{{ item.key }}</div>
                <div class="columnRight border">{{ item.value }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <Button label="Close" class="mr-0" @click="onDialogClose"></Button>
      </template>
    </Dialog>
  </span>
</template>


<script setup lang="ts">

import { ref } from 'vue'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
    required: true
  },
  hideInfo: {
    required: true
  },
  data: {
    type: Object,
    required: true
  },
  zoom: {
    type: Number,
    required: true
  },
  title: {
    type: String,
    required: true
  }
})

const onDialogClose = () => {
  info.value.visible = false
  info.value.hideInfo()
}

const info: any = ref({
  hideInfo: props.hideInfo,
  data: props.data,
  visible: props.visible,
  zoom: props.zoom,
  title: props.title ? `${props.title}` : `Info`
})

const openMap = (name:any) => {
  const latLong = info.value.data.latLng.replace(' ','').split(',')
  switch (name) {
  case 'google':
    window.open(`https://www.google.com/maps/@${latLong[0]},${latLong[1]},${info.value.zoom}z`)
    break
  case 'mapy':   
    window.open(`https://mapy.cz/zakladni?x=${latLong[1]}&y=${latLong[0]}&z=${props.zoom}&pano=1`)
    break    
  case 'bing': 
    window.open(`https://bing.com/maps/default.aspx?cp=${latLong[0]}~${latLong[1]}&lvl=${props.zoom}`)
    break    
  case 'osm': 
    window.open(`https://www.openstreetmap.org/index.html?mlat=${latLong[0]}&mlon=${latLong[1]}&zoom=${props.zoom}`)    
    break    
  }     
  return false
}

</script>

<style lang="scss" scoped>
.row {
  display: flex;
}

.border {
  border-top: 1px solid rgba(34,34,48,.4);
}

.columnLeft {
  flex: 20%;
  text-align: right;
  padding: 3px;
  border-right: 1px solid rgba(34,34,48,.4);
}
.columnRight {
  flex: 80%;
  text-align: left;
  padding: 3px;
  font-weight: bold;
}
</style>