<template>
  <div>
    <multiple-input-list title="Scene options" :toggleableRowTitleCb="toggleableRowTitleCb" :minRowsCount="1" :defaultRowValue="defaultRowValue" v-model:modelValue="data[PRESET_TYPES.SCENE_OPTIONS]" :rowClass="'float-label'">
      <template #form="{ value }" >
          <div class="p-fluid formgrid grid">              
              <div class="field col-12 md:col-4">
                <span class="p-float-label">
                  <InputText v-model="value.utility_id" />
                  <label for="utility_id">Utility id</label>
                </span>
              </div>
              <div class="field col-12 md:col-4">
                <span class="p-float-label">
                  <InputText v-model="value.option" />
                  <label for="option">Option</label>
                </span>
              </div>
              <div class="field col-12 md:col-4">
                <span class="p-float-label">
                  <InputText v-model="value.value"/>
                  <label for="value">Value</label>
                </span>
              </div>
            </div> 
      </template>
    </multiple-input-list>
  </div>
</template>

<script lang="ts" setup>
import MultipleInputList from '@/components/UI/Form/MultipleInputList.vue'
import { computed, ref, watch, Ref } from 'vue'
import { PRESET_TYPES } from '@/helpers/constants';

interface SceneOptions {
  [PRESET_TYPES.SCENE_OPTIONS]: [ SceneOptionModelType ]
}

interface SceneOptionModelType {
  utility_id: string;
  option: string;
  value: string;
}

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  }
})

const defaultRowValue = () => {
  return {
    utility_id: '',
    option: '',
    value: ''
  }
}

const toggleableRowTitleCb = (value: SceneOptionModelType) => {
  return `Utility: ${value.utility_id}`
}

const changeEmitName = 'update:modelValue'
const emits = defineEmits<{(e: typeof changeEmitName, id: string): void }>()

const model = computed({
  get: () => props?.modelValue,
  set: (value: any) => {
    emits(changeEmitName, value)
  },
})

const data: Ref<any> = ref(model.value ?? {
  [PRESET_TYPES.SCENE_OPTIONS]: []
})
watch(data, (newValue ) => {
  model.value = newValue as SceneOptions
}, { deep: true })
</script>

<style lang="scss" scoped>
.formgrid {
  width: 100% !important;
  margin-left: 0;
}
</style>