import router from '@/router'
import { computed } from 'vue'
import { mutationResult } from './helpers/mutationResult'
import { useMutation } from '@vue/apollo-composable'
import { LOGIN_EMAIL_PASSWORD, USER_LOGIN_MICROSOFT, USER_REGISTER_MICROSOFT } from '@/apollo/mutations/auth'
import { LoginEmailPasswordMutation, LoginEmailPasswordMutationVariables, User_Register_MicrosoftMutation } from '@/gql/graphql'
import { ApolloError } from '@apollo/client'

interface gcAuthUser {
  id: number
  roleAlias: string
  isAdmin?: boolean
  permissions?: [string],
  flagActive?: boolean
}

interface AuthUser {
  id: number;
  roleAlias: string;
  flagActive: boolean;
  displayName: string;
}

/**
 * @description auth method
 * @export
 */
export function useAuth() {
  const { mutate: loginEmailPasswordMutation } = useMutation(LOGIN_EMAIL_PASSWORD)
  const { mutate: registerUserMicrosoftMutation } = useMutation(USER_REGISTER_MICROSOFT);
  const { mutate: loginUserMicrosoftMutation } = useMutation(USER_LOGIN_MICROSOFT);

  const isAppleDevice = (): boolean => {
    const userAgent: string = navigator.userAgent;
    return /iPad|iPhone|Mac/i.test(userAgent);
  }

  const user = computed(() => {
    if (window.localStorage.getItem('gc-auth-user')) {
      const usr: gcAuthUser = JSON.parse(window.localStorage.getItem('gc-auth-user') ?? '') as gcAuthUser
      if (usr) {
        usr.isAdmin = (usr.roleAlias === 'Admin')
        usr.permissions = [usr.roleAlias]
      }
      return usr
    } else {
      return null
    }
  })

  const logout = () => {
    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/") })
    localStorage.clear()
    sessionStorage.clear()
    localStorage.setItem("event-logout", 'logout-' + Math.random())
    router.push({ name: 'login' })
  }

  const logoutWithoutDataLose = () => {
    let gcUser: gcAuthUser | string | null = window.localStorage.getItem('gc-auth-user')
    if (typeof gcUser === 'string') {
      gcUser = JSON.parse(gcUser) as gcAuthUser
      if (typeof gcUser === 'string') {
        gcUser = JSON.parse(gcUser) as gcAuthUser
      }
    }

    window.localStorage.removeItem('gc-auth-token')
    window.localStorage.removeItem('gc-auth-user')
    window.localStorage.setItem('last-user-id', gcUser ? gcUser.id.toString(0) : '')
    window.localStorage.setItem('redirect-after-login', window.location.href)
    window.localStorage.setItem('logout-type', 'soft')

    localStorage.setItem("event-logout", 'logout-' + Math.random())
    router.push({ name: 'login' })
  }

  const saveJwtToken = (token: string, user?: AuthUser | null ) => {
    if (window.localStorage.getItem("gc-auth-user")) {
      window.localStorage.setItem("event-login", "login-" + Math.random());
    }
    window.localStorage.setItem('gc-auth-token', token)
    window.localStorage.setItem('gc-auth-user', JSON.stringify(user))
  }

  const loginWithEmailPassword = async (variables: LoginEmailPasswordMutationVariables) => {
    const result: mutationResult<{ login: LoginEmailPasswordMutation['loginEmailPassword'] }> = {}
    try {
      const res = await loginEmailPasswordMutation(variables)
      if(res?.data?.loginEmailPassword && res?.data?.loginEmailPassword.token) {
        result.success = true,
        result.payload = {
          login: res?.data.loginEmailPassword
        }
        // Login was successful, save the token and user data
        saveJwtToken(res?.data.loginEmailPassword.token, res?.data.loginEmailPassword.user)
      } else {
        result.success = false;
      }
    } catch (err) {
      result.success = false;
      result.error = err;
    }
    return result;
  }

  const loginWithMicrosoft = async (msAccessToken: string) => { 
    const result: mutationResult<{ login: LoginEmailPasswordMutation['loginEmailPassword'] }> = {}
    try {
      const res = await loginUserMicrosoftMutation({ accessToken: msAccessToken });
      if(res?.data?.loginMicrosoft && res?.data?.loginMicrosoft.token) {
        result.success = true,
        result.payload = {
          login: res?.data.loginMicrosoft
        }
        // Login was successful, save the token and user data
        saveJwtToken(res?.data.loginMicrosoft.token, res?.data.loginMicrosoft.user)
      } else {
        result.success = false;
      }
    } catch (err) {
      result.success = false;
      result.message = (
        err instanceof ApolloError && 
        err.graphQLErrors?.findIndex(e => e.extensions?.code === "RESOURCE_NOT_FOUND") >= 0
      ) ?  "Account not found. Sign up firstly." : "Unknown error occured..." 
      result.error = err;
    }
    return result;
  }

  const registerWithMicrosoft = async (msAccessToken: string) => { 
    const result: mutationResult<{ login: User_Register_MicrosoftMutation['registerUserMicrosoft'] }> = {}
    try {
      const res = await registerUserMicrosoftMutation({ accessToken: msAccessToken });
      if(res?.data?.registerUserMicrosoft) {
        result.success = true,
        result.payload = {
          login: res?.data.registerUserMicrosoft
        }
      } else {
        result.success = false;
      }
    } catch (err) {
      result.success = false;
      result.message = (
        err instanceof ApolloError && 
        err.graphQLErrors?.findIndex(e => e.extensions?.code === "RESOURCE_NOT_FOUND") >= 0
      ) ?  "Account not found. Sign up firstly." : "Unknown error occured..." 
      result.error = err;
    }
    return result;
  }

  const redirectAfterSuccessLogin = () => { 
    // force replace
    window.location.href = "/";
  }

  return { logout, logoutWithoutDataLose, user, loginWithEmailPassword, isAppleDevice, loginWithMicrosoft, registerWithMicrosoft, redirectAfterSuccessLogin }
}
