
<template>
  <Button 
    v-ripple class="relative overflow-hidden w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
    :disabled="!fileExistsValue" 
    icon="fa-solid fa-download" 
    :label="label"
    @click="download" :model="items"
    v-tooltip.top="(!dataBrowserReady)? 'Data browse service is not available. Check services setting.' : ((!fileExistsValue) ? 'The file is not yet available or does not exists.' : `Download file ${path}`)"  
  />
</template>

<script lang="ts" setup>
import { useToast } from "primevue/usetoast";
import { useDataBrowser } from '@/composables/useDataBrowser';

const toast = useToast();

const { getDownloadURL, fileExists } = useDataBrowser()

const props = defineProps({
  volumeMountAlias: {
    required: true,
    type: String,
  },
  path: {
    required: true,
    type: String,
  },
  label: {
    required: false,
    type: String,
    default: "Download file"
  }
});

const vadstenaIngestLogURL = getDownloadURL(
  props.volumeMountAlias,
  props.path
)

const { exists: fileExistsValue, ready: dataBrowserReady } = fileExists(props.volumeMountAlias, props.path)

const items = [
  {
    label: 'Update',
    command: () => {
      toast.add({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
    }
  }
]

const download = () => {
  const link = document.createElement('a');
  link.href = vadstenaIngestLogURL;
  link.click();

  toast.add({ severity: 'success', summary: 'Success', detail: 'Data download', life: 3000 });
};
</script>
