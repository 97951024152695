<template>
  <span>
    <i class="fa-solid fa-question mlwn-hint-icon"  @click="visible = true" v-tooltip.bottom="'hint'"/>
    <Dialog class="mlwn-hint-dialog" v-model:visible="visible" modal :header="title" :style="{ width: '50vw' }">
      <!-- <v-md-editor :model-value="hint" mode="preview" ></v-md-editor> -->

      <MdPreview v-model="hint" />
    </Dialog>
  </span>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps({
  hint: {
    type: String,
    required: false,
    default: `something important `
  },
  title: {
    type: String,
    required: false,
    default: `Hint`
  },
  showButton: {
    type: Boolean,
  }
})

const hint = ref(props.hint)
const title = ref(props.title)
const visible = ref(false)
</script>

<style lang="scss">
.mlwn-hint-dialog {
  .vuepress-markdown-body {
    padding: 0 !important;
  }
}
.mlwn-hint-icon {
  cursor: pointer;
  width: 1rem;
  text-align: center;
}
</style>
