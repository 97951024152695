<script setup lang="ts">
/**
 * Load validation references, router, vue
 */
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { ref, watch } from 'vue';

import DropdownWithValidation from '@/components/UI/Form/components/DropdownWithValidation.vue';

const props = defineProps({
  sceneId: {
    required: true,
    type: String
  },
  afterSuccessCb: {
    required: false,
    type: Function,
    default: () => {}
  },
  afterErrorCb: {
    required: false,
    type: Function,
    default: () => {}
  }
})

const initialValues = ref({
  collocationId: null,
  outputVolumeMountAlias: null,
})

/**
 * Validation setup
 */
const schema = yup.object({
  collocationId: yup.string().required().label('Collocation'),
  outputVolumeMountAlias: yup.string().required().label('Output volume'),
});

// Function
const onSubmit = (values: any) => {
  // TODO - add log volume possibility
  onPopulationScene(values.collocationId, values.outputVolumeMountAlias, values.outputVolumeMountAlias)
}

import { useScenes } from '@/composables/useScenes'
const { populateScene } = useScenes()
import { useToast } from 'primevue/usetoast';
const toast = useToast()

const onPopulationScene = async (collocationId: string, outputVolumeMountAlias: string, logVolumeMountAlias: string) => {
  try {
    const res = await populateScene(props.sceneId, collocationId, outputVolumeMountAlias, logVolumeMountAlias)

    console.log(res)

    if (res.success) {
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully created', life: 3000 })
      props.afterSuccessCb()
    } else {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error occured while creating', life: 3000 })
      props.afterErrorCb()
    }
  } catch (err: any) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating. ' + err?.message, life: 3000})
    console.error(err)
    props.afterErrorCb(err)
  }
}

// collocations
import { useCollocations } from '@/composables/useCollocations';
const { getCollocations } = useCollocations()
const { collocations } = getCollocations()

// storages
import { useStorages } from '@/composables/useStorages';
const { getStorages } = useStorages()
const storagesObject = getStorages()
const { storages } = storagesObject

// scenes
const { getScenes, getScene } = useScenes()

const { scene } = getScene(props.sceneId)

watch (storages, () => {
  if (storages) {
    const { scenes } = getScenes()
    watch(scenes, () => {
      if (scenes) {
        storages.value.forEach(s => {
          const list = s.volumes?.filter(v => v && scenes.value.find(f => f.vadstenaInputDmVolumeMountAlias === v.mountAlias))
          //const list = s.volumes?.filter(v => (v && scenes.value.find(f => f.vadstenaInputDMVolumeMountAlias === v.id)) || s.name === 'mlwn-ceph.sources' || (v && v?.mountAlias?.indexOf('cdn_file_provider') > -1) || (v && v.mountAlias?.indexOf('cdn_image_pose') > -1))
          if (list && list.length > 0) {
            for (let i = 0; i < list.length; i++) {
              const index = s.volumes?.findIndex(x => x.mountAlias === list[i].mountAlias)
              if (index || index === 0) {
                s.volumes?.splice(index, 1)
              }
            }
          }
        })
      }
    })
  }
})

</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div v-if="scene" class="p-fluid" >
        <Form :initial-values="initialValues" :validation-schema="schema" @submit="onSubmit" class="grid p-fluid">
          <div class="col-12 col:md-6 formgrid">
            <DropdownWithValidation
                data-cy="scenePopulationForm__collocationId"
                name="collocationId"
                label="Data center (collocation)"
                :options="collocations"
                optionValue="id"
                optionLabel="name"
                placeholder="Select data center"
            >
            </DropdownWithValidation>

            <DropdownWithValidation
                data-cy="scenePopulationForm__outputVolume"
                name="outputVolumeMountAlias"
                label="Output volume"
                :options="storages"
                optionValue="mountAlias"
                optionLabel="name"
                v-bind:loading="storagesObject.loading.value"
                placeholder="Select volume where to store ingested data"
                optionGroupLabel="name"
                optionGroupChildren="volumes"
            >
            </DropdownWithValidation>

            <!--
              <DropdownWithValidation
                name="logVolumeMountAlias"
                label="Log volume"
                :options="storages"
                optionValue="mountAlias"
                optionLabel="name"
                v-bind:loading="storagesObject.loading.value"
                placeholder="Select volume where to store ingested data"
                optionGroupLabel="name"
                optionGroupChildren="volumes"
              >
              </DropdownWithValidation>
            -->

            <div class="flex flex-row flex-wrap justify-content-end">
              <Button data-cy="scenePopulationForm__startPopulation" :label="`Start population: ${scene.populationType.name}`" type="submit" icon="fa-solid fa-trowel"
                      class="p-button-success mr-2 mb-2 mlwn-button-submit"></Button>
              <!-- <Button label="Cancel" icon="fa-solid fa-xmark" class="p-button-secondary mr-2 mb-2 mlwn-button-submit" /> -->
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
