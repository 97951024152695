import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }

import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { PopulationTypeAlias } from '@/gql/graphql';

import { useScenes } from '@/composables/useScenes';

import { useToast } from 'primevue/usetoast';
import { Scene, SceneStatusAlias } from '@/gql/graphql';

export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateScene',
  props: {
  sceneId: {
    required: true,
    type: String
  },
  projectId: {
    required: true,
    type: String
  }
},
  setup(__props) {

const { updateScene, getScene } = useScenes()

const props = __props

const { scene } = getScene(props.sceneId)

const router = useRouter();

const formData = scene;

const items = [
  { label: 'Scene details', routeName: 'StepOneEdit', step: 1, commandBack: () => { }, command: () => { router.push({ name: 'SensorDefinitionsEdit' })}, to: { name: 'StepOneEdit'} },
  { label: 'Sensor definitions', routeName: 'SensorDefinitionsEdit', step: 2, commandBack: () => { router.push({ name: 'StepOneEdit' })}, command: () => { router.push({ name: 'InputConfigurationsEdit' }) }, to: { name: 'SensorDefinitionsEdit'} },
  { label: 'Input Configurations', routeName: 'InputConfigurationsEdit', step: 3, commandBack: () => { router.push({ name: 'SensorDefinitionsEdit' })}, command: () => { router.push({ name: 'StepOverviewEdit' }) }, to: { name: 'InputConfigurationsEdit'} },
  { label: 'Review & Submit', routeName: 'StepOverviewEdit', step: 4, commandBack: () => { router.push({ name: 'InputConfigurationsEdit' })}, command: () => { }, to: { name: 'StepOverviewEdit'}}
];

const activeIndex = computed(() => items.findIndex(item => router?.currentRoute?.value?.name === item.routeName));

function nextStep(data: any, fromRouteName: String, populationConfig = true, inputVolumesConfiguration = false) {

  if (populationConfig) {
    formData.value.populationConfig = { ...formData.value.populationConfig, ...data }
  } else {
    formData.value = { ...formData.value, ...data }
  }

  activeIndex.value++;
  items.find(item => item.routeName === fromRouteName)?.command()
}

function complete(data: any) {
  onUpdateScene(JSON.parse(JSON.stringify({
    id: props.sceneId,
    name: data?.name,
    notes: data?.notes,
    ingestVadstenaVersion: data?.ingestVadstenaVersion,
    sceneStatusAlias: SceneStatusAlias.New,
    populationConfig: data?.populationConfig
  })))
}

const toast = useToast()

const onUpdateScene = async (scene: Scene) => {
  try {
    const res = await updateScene(scene)
    
    if (res.success) {
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully created', life: 3000 })
      window.localStorage.removeItem('mlwn-scene-form')
      router.push({
        name: 'project-scene-detail',
        params: {
          projectId: props.projectId,
          sceneId: res?.payload?.scene?.id
        }
      })
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating', life: 3000})
    }
  } catch (err: any) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating. ' + err?.message, life: 3000})
    console.error(err)
  }
}

const prevPage = (fromRouteName: String) => {
  activeIndex.value--;
  items.find(item => item.routeName === fromRouteName)?.commandBack()
}

const onCancel = () => {
  router.push({
    name: 'project-scene-detail',
    params: {
      projectId: props.projectId,
      sceneId: props.sceneId
    }
  })
}

return (_ctx: any,_cache: any) => {
  const _component_Message = _resolveComponent("Message")!
  const _component_Steps = _resolveComponent("Steps")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _cache[2] || (_cache[2] = _createTextVNode(" Update scene: ")),
          _createElementVNode("strong", null, _toDisplayString(_unref(scene)?.name), 1)
        ]),
        content: _withCtx(() => [
          (_unref(scene) && _unref(scene).status.alias !== _unref(SceneStatusAlias).New)
            ? (_openBlock(), _createBlock(_component_Message, {
                key: 0,
                closable: false,
                severity: "warn"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" The scene has been ingested. Changes to the scene configuration can no longer be made. ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_unref(scene) && _unref(scene).populationType.alias !== _unref(PopulationTypeAlias).Ingest)
            ? (_openBlock(), _createBlock(_component_Message, {
                key: 1,
                closable: false,
                severity: "warn"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" This scene was created from other scene, with population type " + _toDisplayString(_unref(scene).populationType.name) + ". Update will be implemented in feature versions. ", 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_Steps, {
            model: items,
            activeIndex: activeIndex.value,
            readonly: (_unref(scene) && _unref(scene).statusAlias === _unref(SceneStatusAlias).New && _unref(scene).populationType.alias === _unref(PopulationTypeAlias).Ingest)
          }, null, 8, ["activeIndex", "readonly"]),
          _createVNode(_component_Divider, { type: "dotted" }),
          (_unref(scene))
            ? (_openBlock(), _createBlock(_component_router_view, {
                key: 2,
                formData: _unref(formData),
                update: true,
                scene: _unref(scene),
                onPrevPage: _cache[0] || (_cache[0] = ($event: any) => (prevPage($event))),
                onOnCancel: _cache[1] || (_cache[1] = ($event: any) => (onCancel($event))),
                onNext: nextStep,
                onComplete: complete
              }, {
                default: _withCtx(({Component}) => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 1
              }, 8, ["formData", "scene"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})