<template>
  <top-progress-bar :show="loading_query || loading_mutation"></top-progress-bar>
  <Dialog v-model:visible="dialogLoginExpireVisible" modal header="Sorry, your session has expired!" :closable="false" :style="{ width: '25rem' }">
    <span class="p-text-secondary block mb-5">Please log out and log in again.</span>
    <div class="flex justify-content-center gap-2">
      <Button type="button" label="Logout" severity="secondary" @click="closeAndLogout"></Button>
    </div>
</Dialog>
  <router-view v-slot="{ Component }">
    <transition>
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script lang="ts">
import { useGlobalQueryLoading, useGlobalMutationLoading } from '@vue/apollo-composable'
import TopProgressBar from './components/UI/TopProgressBar.vue'
import { defineComponent } from 'vue'
import { gcAuthToken } from '@/apollo/ApolloClient'
import { jwtDecode } from "jwt-decode";
import Dialog from 'primevue/dialog';
import { useAuth } from './composables/useAuth';

export default defineComponent({
  name: 'App',
  setup() {
    const loading_query = useGlobalQueryLoading()
    const loading_mutation = useGlobalMutationLoading()
    const { logout } = useAuth()

    return {
      loading_query,
      loading_mutation,
      logout
    }
  },
  mounted() {
    this.removeLoginExpirationInterval()
    this.setLoginExpirationInterval()
  },
  data() {
    return {
      dialogLoginExpireVisible: false,
      loginExpirationInterval: setInterval(() => { }, 100000)
    }
  },
  components: {
    TopProgressBar,
    Dialog,
  },
  methods: {
    closeAndLogout() {
      this.removeLoginExpirationInterval()
      this.dialogLoginExpireVisible = false
      this.logout()
    },
    setLoginExpirationInterval() {
      if (!!gcAuthToken && gcAuthToken !== "") {
        try {
          const decoded = jwtDecode(gcAuthToken)
          if (decoded && decoded.exp) {
            const expirationMilis = decoded.exp * 1000
            if (expirationMilis > Date.now()) {
              this.loginExpirationInterval = setInterval(() => {
                if (expirationMilis <= Date.now()) {
                  this.dialogLoginExpireVisible = true
                }
              }, 10000)
            } else {
              this.dialogLoginExpireVisible = true
            }
          }
        } catch (err) {
          console.error("Error during JWT decode of gcAuthToken", err)
        }
      }
    },
    removeLoginExpirationInterval() {
      clearInterval(this.loginExpirationInterval)
    }

  }
})
</script>

<style>

</style>
