
<template>
  <template v-if="dataBrowserErrors.length > 0">
    <div>
      <p>Errors</p>
      <ul>
        <li v-for="(error, i) of dataBrowserErrors" v-bind:key="i">{{ error }}</li>
      </ul>
    </div>
  </template>
  <template v-else>
    <div v-if="!dataBrowserReady">
      <ul class="pl-0" style="list-style-type: none;">
        <li v-if="!dataBrowserServiceCreated">
          <i class="fa fa-exclamation-triangle"></i> Data Browser service not available. Contact your administrator.
        </li>
        <li v-if="!dataBrowserServiceReady">
          <i class="fa fa-exclamation-triangle"></i> Data Browser service not ready yet. Wait or contact your administrator.
        </li>
        <li v-if="!taskVolumeCreated || !taskVolumeReady">
          <i class="fa fa-spin fa-spinner"></i> Volume is mounting...
        </li>
      </ul>
    </div>
    <div v-else>
      <p>
        <Breadcrumb :home="home" :model="items" />
      </p>
      <TreeTable :value="nodes" :lazy="true" :paginator="true" :rows="limit" :loading="loading" @page="onPage"
        :totalRecords="totalRecords" :class="`p-treetable-small`" @sort="sortBrowser" removableSort
        :rowsPerPageOptions="[5, 10, 25, 50, 100]" :sort-field="sortColumn"
        :sort-order="sortOrder === DataBrowserSortOption.Asc ? 1 : -1">
        <template #header>
          <div class="text-right">
            <div class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <InputText v-model="search" placeholder="Regexp Search" />
            </div>
          </div>
        </template>
        <Column headerStyle="width: 2rem">
          <template #body="{ node }">
            <i v-if="node.leaf" class="pi pi-file"></i>
            <i v-else class="pi pi-folder-open"></i>
          </template>
        </Column>
        <Column :field="DataBrowserSortColumn.Name" header="Name" sortable>
          <template #body="{ node }">
            <template v-if="node.leaf">
              <span v-html="makeStrong(node.data.name, search)"></span>
            </template>
            <template v-else>
              <a href="#" @click="onExpand(node)">
                <span v-html="makeStrong(node.data.name, search)"></span>
              </a>
            </template>
            <template v-if="node.data.isSymlink">
              &nbsp;<i class="fa fa-level-down" v-tooltip.top="'Symbolic link'" type="text"></i>
            </template>
          </template>
        </Column>
        <Column field="size" header="Size" headerStyle="width: 10rem">
          <template #body="{ node }">
            <template v-if="node.data.isSymlink">
              <!-- <s>{{ node.data.size }}</s>&nbsp; -->
              <span v-if="node.data.isValidSymlink">
                {{ node.data.symlinkAttributes.size }}
                <i class="fa fa-info-circle" v-tooltip.top="node.data.symlinkAttributes.path" type="text"></i>
              </span>
              <i v-else class="fa fa-exclamation-triangle" v-tooltip.top="'Broken symlink'" type="text"></i>
            </template>
            <template v-else>{{ node.data.size }}</template>
          </template>
        </Column>
        <Column :field="DataBrowserSortColumn.Extension" header="Type" sortable headerStyle="width: 10rem">
          <template #body="{ node }">
            {{ node.data.type }}
          </template>
        </Column>
        <Column headerStyle="width: 10rem;">
          <template #body="{ node }">
            <div class="flex flex-wrap gap-2 justify-content-end">
              <a :href="node.data.downloadURL" title="Download">
                <Button type="button" icon="pi pi-download" severity="success" label="" size="small"
                  v-tooltip.top="'Download'" />
              </a>
            </div>
          </template>
        </Column>
      </TreeTable>
    </div>
  </template>
</template>

<style scoped>
:deep(.p-treetable .p-treetable-tbody > tr > td) {
  padding: .5rem 1rem;
}

:deep(.p-treetable .p-treetable-tbody > tr:nth-child(even)) {
  background: #fafafa;
}
</style>

<script lang="ts" setup>

// props

const props = defineProps({
  storageId: {
    required: true,
    type: String,
  },
  volumeMountAlias: {
    required: true,
    type: String,
  }
})

// imports

import { ref, watch } from 'vue'
import { useConfig } from '@/composables/useConfig'
import { useDataBrowser } from '@/composables/useDataBrowser'
import { DataBrowserEntity, DataBrowserEntityType, DataBrowserSortOption, DataBrowserSortInput, DataBrowserSortColumn } from '@/gql/graphql'
import type { Ref } from 'vue'
import { MenuItem } from 'primevue/menuitem';
import { TreeTablePageEvent, TreeTableSortEvent } from 'primevue/treetable';
import debounce from 'lodash.debounce'
import prettyBytes from 'pretty-bytes';
import { LocationQueryValue, useRouter } from 'vue-router'

// custom classes

type DataBrowserTreeNode = {
  key: number
  leaf: boolean
  data: {
    name: string,
    size: string,
    isSymlink: boolean,
    isValidSymlink: boolean,
    symlinkAttributes: {
      size: string
      path: string
    } | null,
    type: string | undefined,
    downloadURL: string
  }
}

// composable imports

const { getDataBrowserStatus, getDataBrowserList } = useDataBrowser()
const { getConfig } = useConfig()

// router

const router = useRouter()
const query = router.currentRoute.value.query

// helpers

const validateString = (value: LocationQueryValue | LocationQueryValue[], defaultValue: string): string => {
  if (Array.isArray(value) || !value) return defaultValue;
  return value;
}
const validateInt = (value: LocationQueryValue | LocationQueryValue[], defaultValue: number): number => {
  if (Array.isArray(value) || !value) return defaultValue;
  return parseInt(value)
}
const validateDataBrowserSortColumn = (value: LocationQueryValue | LocationQueryValue[], defaultValue: DataBrowserSortColumn): DataBrowserSortColumn => {
  if (Array.isArray(value) || !value || !Object.values(DataBrowserSortColumn).includes(value as unknown as DataBrowserSortColumn)) return defaultValue;
  return value as DataBrowserSortColumn
}
const validateDataBrowserSortOption = (value: LocationQueryValue | LocationQueryValue[], defaultValue: DataBrowserSortOption): DataBrowserSortOption => {
  if (Array.isArray(value) || !value || !Object.values(DataBrowserSortOption).includes(value as unknown as DataBrowserSortOption)) return defaultValue;
  return value as DataBrowserSortOption
}


const nodes = ref()
const volumeMountAliasRef = ref(props.volumeMountAlias)
const dataBrowserErrors: Ref<string[]> = ref([])
const dataBrowserReady = ref(false)
const dataBrowserServiceCreated = ref(false)
const dataBrowserServiceReady = ref(false)
const taskVolumeCreated = ref(false)
const taskVolumeReady = ref(false)
const loading = ref(false)
const totalRecords = ref(0)
const limit = ref(validateInt(query.limit, 50))
const offset = ref(validateInt(query.offset, 0))
const dirPath = ref('')
const search = ref(validateString(query.search, ''))
const sortColumn = ref(validateDataBrowserSortColumn(query.sortColumn, DataBrowserSortColumn.Name))
const sortOrder = ref(validateDataBrowserSortOption(query.sortOrder, DataBrowserSortOption.Asc))
const sort: Ref<DataBrowserSortInput | null> = ref({
  column: sortColumn,
  order: sortOrder
})
const queryList = ref({
  volumeMountAlias: volumeMountAliasRef.value,
  limit: 50,
  offset: 0,
  dirPath: '',
  search: '',
  sort: {
    column: DataBrowserSortColumn.Name,
    order: DataBrowserSortOption.Asc
  }
})
const home: Ref<MenuItem> = ref({
  icon: 'pi pi-home',
  // label: 'root',
  to: '#',
  command: () => {
    refetchBrowser('')
  }
});
const items: Ref<MenuItem[]> = ref([]);

// query filter builder

const makeStrong = (text: string, search: string) => {
  if (search === "") return text

  const regExp = new RegExp(search, "gi")
  let html = ''
  let stringCursor = 0
  const matches = text.matchAll(regExp);

  for (const match of matches) {
    if (match.index === undefined) continue
    // text from last position to this match
    html += text.slice(stringCursor, match.index)
    // match in bold
    html += `<strong>${match[0]}</strong>`
    // set cursor to end of this match
    stringCursor = match.index + match[0].length
  }
  // add rest of text
  html += text.slice(stringCursor)
  return html
}
const serializeQuery = () => {
  let fetchQuery: any = {
    limit: limit.value,
    offset: offset.value,
    search: search.value,
    sortColumn: sort.value?.column,
    sortOrder: sort.value?.order,
  }
  router.replace({ query: fetchQuery })
}


// composable utils

const {
  status: dataBrowserStatus,
  error: dataBrowserStatusError,
  load: dataBrowserStatusLoad,
  refetch: dataBrowserStatusRefetch,
} = getDataBrowserStatus(volumeMountAliasRef)
dataBrowserStatusLoad()
const {
  list: dataBrowserList,
  // loading: dataBrowserListLoading,
  // error: dataBrowserListError,
  load: dataBrowserListLoad,
  refetch: dataBrowserListRefetch
} = getDataBrowserList(queryList)

// config

const config = getConfig();

// watches 

watch(dirPath, (newValue) => {
  queryList.value.dirPath = dirPath.value
  items.value = newValue.replace(/^\/(.+)$/, '$1').split('/').reduce((acc, current) => {
    acc.relativePath.push(current)
    const relativePath = acc.relativePath.join('/')
    acc.items.push({
      label: current,
      relativePath,
      to: '#',
      command: () => {
        refetchBrowser(relativePath)
      }
    })
    return acc
  }, {
    relativePath: [] as String[],
    items: [] as MenuItem[]
  }).items
})

watch(dataBrowserStatus, (newValue) => {
  dataBrowserServiceCreated.value = newValue.dataBrowserServiceCreated
  dataBrowserServiceReady.value = newValue.dataBrowserServiceReady
  taskVolumeCreated.value = newValue.taskVolumeCreated
  taskVolumeReady.value = newValue.taskVolumeReady

  dataBrowserReady.value = dataBrowserServiceCreated.value &&
    dataBrowserServiceReady.value &&
    taskVolumeCreated.value &&
    taskVolumeReady.value
  if (dataBrowserReady.value) {
    refetchBrowser();
  } else {
    setTimeout(() => {
      dataBrowserStatusRefetch()
    }, 5000)
  }
})

watch(dataBrowserStatusError, (newValue) => {
  if (newValue !== null) {
    dataBrowserErrors.value = []
    newValue.graphQLErrors.forEach(error => {
      dataBrowserErrors.value = [...dataBrowserErrors.value, error.message]
    });
  }
})

watch(dataBrowserList, (newValue) => {
  if (newValue.data) {
    nodes.value = mapDataBrowserList(newValue.data)
    totalRecords.value = newValue.pageInfo.total
  }
})

watch(search, debounce(() => {
  refetchBrowser();
}, 250))

// utils

const mapDataBrowserList = (list: DataBrowserEntity[]) => {
  return list.map((entity, i): DataBrowserTreeNode => {
    const size = entity.type === DataBrowserEntityType.File ? prettyBytes(entity.attributes?.size || 0) : ''
    const isSymlink = !!entity.attributes?.symlink
    const isValidSymlink = entity.attributes?.symlink?.valid || false
    const symlinkAttributes = entity.attributes?.symlink?.valid ? {
      size: entity.attributes?.symlink.size ? prettyBytes(entity.attributes.symlink.size) : '',
      path: entity.attributes?.symlink.path || ''
    } : null
    return  {
      key: offset.value + i,
      data: {
        name: entity.name,
        size,
        isSymlink,
        isValidSymlink,
        symlinkAttributes,
        type: entity.attributes?.extension || '',
        downloadURL: config ? config.API_URL +
          `/download?volumeMountAlias=${props.volumeMountAlias}&path=` +
          encodeURIComponent([props.volumeMountAlias, dirPath.value, entity.name].join("/")) : "#"
      },
      leaf: entity.type === DataBrowserEntityType.File
    }
  })
}

const refetchBrowser = (dirPathToChange: string = dirPath.value) => {
  dirPath.value = dirPathToChange
  serializeQuery()

  dataBrowserListLoad() || dataBrowserListRefetch(queryList.value)
}

const sortBrowser = (event: TreeTableSortEvent) => {
  sortColumn.value = event.sortField as DataBrowserSortColumn
  sortOrder.value = !event.sortOrder ? DataBrowserSortOption.Asc : (
    event.sortOrder > 0 ? DataBrowserSortOption.Asc : DataBrowserSortOption.Desc
  )
  queryList.value.sort.column = sortColumn.value
  queryList.value.sort.order = sortOrder.value
  refetchBrowser()
}

// events

const onExpand = (node: DataBrowserTreeNode) => {
  dirPath.value = dirPath.value + '/' + node.data.name
  refetchBrowser()
}
const onPage = (event: TreeTablePageEvent) => {
  queryList.value.limit = event.rows
  queryList.value.offset = event.first
  refetchBrowser()
}
</script>