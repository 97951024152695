export const VolumeFormHint = {
  nfs: {
    remotePath:
    `remotePath is relative path in storage, for. example
    \`\`\`
    some/path/in/storage
    \`\`\``,
    mountOptions:
      `can be empty`
  },
  s3: {
    remotePath:
    `remotePath is relative path in storage, for. example
\`\`\`
some/path/in/storage
\`\`\``,
    mountOptions:
    `configuration of AWS access keys
\`\`\`
{
  "access-key-id": "<AWSACCESSKEYID>",
  "secret-access-key": "<AWSSECRETACCESSKEY>"
}
\`\`\`
      `
  },
}

