<template>
  <div class="grid">
		<div class="col-12 p-fluid">
			<Card>
				<template #title>
					<div class="flex justify-content-between flex-wrap">
						<div class="flex align-items-center justify-content-center">
							{{ project?.name ?? "Project" }}
						</div>
						<div class="flex align-items-center justify-content-center">
							<router-link type="button" :to="{name: 'project-edit', params: {projectId: project?.id}}"  v-tooltip.bottom="'Edit project'">
								<Button type="button" icon="fa-solid fa-pencil"></Button>	
							</router-link>
						</div>
					</div>
				</template>
				<template #subtitle>
					<attributes-list :item="project" :attributes="projectRenderAttributes"></attributes-list>
				</template>
				<template #content>
				</template>
			</Card>
		</div>
		<div class="col-12 p-fluid">
			<Card>
				<template #title>
					<div class="flex justify-content-between flex-wrap">
						<div class="flex align-items-center justify-content-center">
							Scenes
						</div>
						<div class="flex align-items-center justify-content-center">
							<router-link type="button" icon="fa-solid fa-plus" :to="{
								name: 'create-scene',
								params: {
									projectId: project?.id
								}
							}"  v-tooltip.bottom="'Create new scene'">
								<Button data-cy="projectDetail__createSceneButton" type="button" icon="fa-solid fa-plus"></Button>	
							</router-link>
						</div>
					</div>
				</template>
				<template #content>
					<SceneTable
            :showProjectColumn="false"
						:projectId="props.projectId.toString()"
					>
					</SceneTable>
				</template>
			</Card>
		</div>
	</div>
</template>

<script lang="ts" setup>
import SceneTable from '../Processing/Scene/components/SceneTable.vue'
import AttributesList from '@/components/UI/AttributesList.vue';

const props = defineProps({
  projectId: {
    type: String,
    required: true
  }
})

import { useProject } from '@/composables/useProject';
import { Project } from '@/gql/graphql';
import { ref } from 'vue';
const { getProject } = useProject()
const { project } = getProject(parseInt(props.projectId))

const projectRenderAttributes = ref({
  // externalUri: { label: "External URI", value: (item: Project) => item.externalUri?.length ? `<a href='${item.externalUri}' target="_blank">link</a>` : '-',  html: true},
  notes: { label: "Notes", value: (item: Project) => item?.notes},
  archived: { label: "Archived", value: (item: Project) => item?.archived}
})
</script>


<style lang="scss">
.mlwn-list {
	list-style: none;
	padding-left: 0;
}
</style>