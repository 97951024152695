<template>
  <div class="grid">
    <div class="col-12">
      <Message severity="warn" sticky>Technical Quality controll failed. Processing was canceled.</Message>
      <p>
        <br>
        <Panel toggleable collapsed>
          <template #header>
            <span>Serialized population <strong>ingest.conf</strong></span>
          </template>
          <p class="m-0 code-block">
            <pre>{{ populationConfig }}</pre>
          </p>
        </Panel>
        
      </p>
		</div>
	</div>  
</template>

<script lang="ts" setup>
const props = defineProps({
  sceneId: {
    required: true,
    type: String
  }
})

import { useScenes } from '@/composables/useScenes';
const { getSerializedPopulationConfig } = useScenes()
const { populationConfig } = getSerializedPopulationConfig(props.sceneId)
</script>

<style lang="scss" scoped>
.code-block {
  overflow: auto;
  border: 1px solid;
  pre {
    padding: 0.5rem;
  }
}
</style>
