import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "m-0 code-block" }

import { useScenes } from '@/composables/useScenes';

export default /*@__PURE__*/_defineComponent({
  __name: 'SceneTechnicalQcFailed',
  props: {
  sceneId: {
    required: true,
    type: String
  }
},
  setup(__props) {

const props = __props

const { getSerializedPopulationConfig } = useScenes()
const { populationConfig } = getSerializedPopulationConfig(props.sceneId)

return (_ctx: any,_cache: any) => {
  const _component_Message = _resolveComponent("Message")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Message, {
        severity: "warn",
        sticky: ""
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Technical Quality controll failed. Processing was canceled.")
        ])),
        _: 1
      }),
      _createElementVNode("p", null, [
        _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
        _createVNode(_component_Panel, {
          toggleable: "",
          collapsed: ""
        }, {
          header: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("span", null, [
              _createTextVNode("Serialized population "),
              _createElementVNode("strong", null, "ingest.conf")
            ], -1)
          ])),
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_3, [
              _createElementVNode("pre", null, _toDisplayString(_unref(populationConfig)), 1)
            ])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})