import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-fluid formgrid grid" }
const _hoisted_2 = { class: "field col-12 md:col-4" }
const _hoisted_3 = { class: "p-float-label" }
const _hoisted_4 = { class: "field col-12 md:col-4" }
const _hoisted_5 = { class: "p-float-label" }
const _hoisted_6 = { class: "field col-12 md:col-4" }
const _hoisted_7 = { class: "p-float-label" }

import MultipleInputList from '@/components/UI/Form/MultipleInputList.vue'
import { computed, ref, watch, Ref } from 'vue'
import { PRESET_TYPES } from '@/helpers/constants';

interface SceneOptions {
  [PRESET_TYPES.SCENE_OPTIONS]: [ SceneOptionModelType ]
}

interface SceneOptionModelType {
  utility_id: string;
  option: string;
  value: string;
}

const changeEmitName = 'update:modelValue'

export default /*@__PURE__*/_defineComponent({
  __name: 'SceneOptionsPresetForm',
  props: {
  modelValue: {
    type: Object,
    default: () => {}
  }
},
  setup(__props, { emit: __emit }) {

const props = __props

const defaultRowValue = () => {
  return {
    utility_id: '',
    option: '',
    value: ''
  }
}

const toggleableRowTitleCb = (value: SceneOptionModelType) => {
  return `Utility: ${value.utility_id}`
}

const emits = __emit

const model = computed({
  get: () => props?.modelValue,
  set: (value: any) => {
    emits(changeEmitName, value)
  },
})

const data: Ref<any> = ref(model.value ?? {
  [PRESET_TYPES.SCENE_OPTIONS]: []
})
watch(data, (newValue ) => {
  model.value = newValue as SceneOptions
}, { deep: true })

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(MultipleInputList, {
      title: "Scene options",
      toggleableRowTitleCb: toggleableRowTitleCb,
      minRowsCount: 1,
      defaultRowValue: defaultRowValue,
      modelValue: data.value[_unref(PRESET_TYPES).SCENE_OPTIONS],
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((data.value[_unref(PRESET_TYPES).SCENE_OPTIONS]) = $event)),
      rowClass: 'float-label'
    }, {
      form: _withCtx(({ value }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_component_InputText, {
                modelValue: value.utility_id,
                "onUpdate:modelValue": ($event: any) => ((value.utility_id) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              _cache[1] || (_cache[1] = _createElementVNode("label", { for: "utility_id" }, "Utility id", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_InputText, {
                modelValue: value.option,
                "onUpdate:modelValue": ($event: any) => ((value.option) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              _cache[2] || (_cache[2] = _createElementVNode("label", { for: "option" }, "Option", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_InputText, {
                modelValue: value.value,
                "onUpdate:modelValue": ($event: any) => ((value.value) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              _cache[3] || (_cache[3] = _createElementVNode("label", { for: "value" }, "Value", -1))
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})