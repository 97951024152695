<template>
  <div>
    <DataTable
        :value="projects"
        :paginator="true"
        @page="onPage($event)"
        @sort="onSort($event)"
        @filter="onFilter()"
        :sortField="defaultSortField"
        :sortOrder="defaultSortOrder"
        :lazy="lazy"
        class="p-datatable-sm"
        :rows="rows"
        dataKey="id"
        :rowHover="true"
        v-model:filters="tableFilters"
        filterDisplay="menu"
        :loading="loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="rowsPerPageOptions"
        :currentPageReportTemplate="'Showing {first} to {last} of {totalRecords} entries'"
        responsiveLayout="scroll"
    >
      <template #empty>
        No projects found.
      </template>
      <template #loading>
          <span v-if="projects.length">
            <ProgressSpinner />
          </span>
        <span v-else>
            No targets found.
          </span>
      </template>

      <Column
          field="name"
          header="Name" 
          filterField="name"
          filterMatchMode="contains"
          :showFilterMatchModes="false"
          :maxConstraints="1" :showFilterOperator="false"
          sortable style="min-width: 10rem">
        <template #body="{data}">
          <span v-html="makeStrong(data.name, 'name')" @click="onEditClick(data)" class="mlwn-pointer-cursor mlwn-hyperlink" data-cy="projectTable__projectName"></span>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by name"/>
        </template>
      </Column>

      <Column
          field="externalUri"
          header="External link" 
          filterField="externalUri"
          filterMatchMode="contains"
          :showFilterMatchModes="false"
          :maxConstraints="1" :showFilterOperator="false"
          sortable style="min-width: 10rem">
        <template #body="{data}">
          <a :href="makeStrong(data.externalUri, 'externalUri')" target="_blank" data-cy="projectTable__externalLink">{{data.externalUri}} </a>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by name"/>
        </template>
      </Column>

      <Column
          field="archived"
          header="Status"
          filterField="archived"
          filterMatchMode="like"
          :showFilterMatchModes="false"
          :maxConstraints="1" :showFilterOperator="false"
          sortable style="min-width: 10rem">
        <template #body="{data}">
          <span data-cy="projectTable__projectStatus">{{data.archived ? 'Archived' : 'Active'}}</span>
        </template>
      </Column>

      <Column headerStyle="width: 8rem; text-align: right" bodyStyle="text-align: right; overflow: visible">
        <template #body="{data}">
                <span class="action-buttons">
                  <Button type="button" icon="fa-solid fa-list" @click="onEditClick(data)" v-tooltip.top="'Project detail'" data-cy="projectTable__detailButton"></Button>
                </span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { useProject } from '@/composables/useProject';
import { useTables } from '@/composables/useTables';
import { useRouter } from 'vue-router';
import { FilterMatchMode } from 'primevue/api';
import { Project } from '@/gql/graphql';
import { useConfig } from '@/composables/useConfig';
import { useToast } from 'primevue/usetoast';

const toast = useToast()
const { getConfig } = useConfig()
const config = getConfig()

const { getProjects } = useProject()
const { projects, loading, error: errorProjects } = getProjects(undefined, config?.POLLINTERVAL)
watch(errorProjects, () => {
  if (errorProjects.value) {
    toast.add({ severity: 'error', summary: 'Error loading projects', detail: 'Try to log in again or contact administrator.' })
  }
})

const router = useRouter()
const _query = router.currentRoute.value.query

const {
  makeStrong,
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows,
  lazy
} = useTables({
  filters: {
    name: {
      value: _query.name ? _query.name : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "name"
    },
    externalUri: {
      value: _query.externalUri ? _query.externalUri : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "externalUri"
    },
    archived: {
      value: _query.archived ? _query.archived : null,
      matchMode: FilterMatchMode.EQUALS,
      pretty: "archived"
    },
  }
})

const onEditClick = (project: Project) => {
  router.push({
    name: 'project-detail',
    params: {
      projectId: project.id
    }
  })
}
</script>
