import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-content-center gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_progress_bar = _resolveComponent("top-progress-bar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_top_progress_bar, {
      show: _ctx.loading_query || _ctx.loading_mutation
    }, null, 8, ["show"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.dialogLoginExpireVisible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialogLoginExpireVisible) = $event)),
      modal: "",
      header: "Sorry, your session has expired!",
      closable: false,
      style: { width: '25rem' }
    }, {
      default: _withCtx(() => [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "p-text-secondary block mb-5" }, "Please log out and log in again.", -1)),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Button, {
            type: "button",
            label: "Logout",
            severity: "secondary",
            onClick: _ctx.closeAndLogout
          }, null, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, null, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ], 64))
}