<template>
  <div class="grid">
    <div class="col-12">
			<Card>
				<template #title>
          <div class="flex justify-content-between flex-wrap">
              <div class="flex align-items-center justify-content-center">
                <span v-if="scene">Scene: {{ scene.name }}</span>
                <Skeleton v-else height="2rem" class="mb-2"></Skeleton>
              </div>
              <div class="flex align-items-center justify-content-end">
                <SceneActionPanel v-if="scene" v-bind:scene="scene"  @scene-updated="onSceneUpdated" :show-open-button="false"></SceneActionPanel>
                <Skeleton v-else height="2rem" class="mb-2"></Skeleton>
              </div>
          </div>
				</template>
				<template #content v-if="scene">
          <div v-if="scene.automationIdle">
            <SceneCreated
              v-if="scene.status?.alias === SceneStatusAlias.New"
              :sceneId="sceneId"
            >
            </SceneCreated>

            <ScenePopulating
              v-else-if="scene.status?.alias === SceneStatusAlias.Populating || scene.status?.alias === SceneStatusAlias.InputQcCdnDatasetPublishing"
              :sceneId="sceneId"
            >
            </ScenePopulating>

            <SceneInTechnicalQcReview
              v-else-if="scene.status?.alias === SceneStatusAlias.InputQcReview"
              :sceneId="sceneId"
            >
            </SceneInTechnicalQcReview>

            <SceneTechnicalQcFailed
              v-else-if="scene.status?.alias === SceneStatusAlias.InputQcFailed"
              :sceneId="sceneId"
            >
            </SceneTechnicalQcFailed>
          </div>
          <div v-else>
            <div class="flex justify-content-center align-items-center">
              <div class="text-center">
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <p>Scene is waiting for automation</p>
              </div>
            </div>
          </div>
        </template>
			</Card>
		</div>
	</div>  
</template>

<script lang="ts" setup>
import SceneCreated from './SceneStatusViews/SceneCreated.vue'
import ScenePopulating from './SceneStatusViews/ScenePopulating.vue'
import SceneInTechnicalQcReview from './SceneStatusViews/SceneInTechnicalQcReview.vue'
import SceneTechnicalQcFailed from './SceneStatusViews/SceneTechnicalQcFailed.vue'
import { useScenes } from '@/composables/useScenes';
import SceneActionPanel from './SceneActions/SceneActionPanel.vue';
import { SceneStatusAlias } from '@/gql/graphql';
import { useConfig } from '@/composables/useConfig';

const { getConfig } = useConfig()
const config = getConfig()

const props = defineProps({
  projectId: {
    required: true,
    type: String
  },
  sceneId: {
    required: true,
    type: String
  }
})

const { getScene } = useScenes()
const { scene, refetch } = getScene(props.sceneId, config?.POLLINTERVAL) 

async function onSceneUpdated() {
  await refetch()
}

</script>
