import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withKeys as _withKeys, withCtx as _withCtx, toDisplayString as _toDisplayString, isRef as _isRef } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["innerHTML", "onClick"]
const _hoisted_4 = { class: "flex items-center gap-2" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML", "onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "action-buttons flex align-items-center justify-content-end gap-1" }

import { ref, watch } from 'vue'
import { useTables } from '@/composables/useTables';
import { useRouter } from 'vue-router';
import { FilterMatchMode } from 'primevue/api';
import { useScenes } from '@/composables/useScenes';
import { useVolumes } from '@/composables/useVolumes';
import { Scene, SceneStatusAlias } from '@/gql/graphql';
import { useConfig } from '@/composables/useConfig';
import { useProject } from '@/composables/useProject';
import SceneActionPanel from '@/components/Admin/Project/Scene/SceneActions/SceneActionPanel.vue';
import { useToast } from 'primevue/usetoast';

// const SceneStatus

// component

export default /*@__PURE__*/_defineComponent({
  __name: 'SceneTable',
  props: {
  showProjectColumn: {
    required: true,
    type: Boolean,
    default: true
  },
  projectId: {
    required: false,
    type: String
  }
},
  setup(__props) {

// imports
const props = __props

// state
const sceneData: any = ref({
  scenes: null,
  loading: null,
})

// composables
const { getConfig } = useConfig()
const toast = useToast()
const router = useRouter()
const { getScenes, getSceneStatuses } = useScenes()
const { getVolumes } = useVolumes()
const { getProjects } = useProject()

// initializations
const config = getConfig()
const { scenes, loading, refetch: refetchScenes } = getScenes({}, config?.POLLINTERVAL)
const { sceneStatuses, loading: loadingSceneStatuses, error: errorSceneStatuses } = getSceneStatuses()
const { volumes, error: errorVolumes } = getVolumes()
const { projects, loading: loadingProjects, error: errorProjects } = getProjects()
const _query = router.currentRoute.value.query
const {
  formatDate,
  makeStrong,
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows,
  lazy,
} = useTables({
  filters: {
    name: {
      value: _query.name ? _query.name : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "name"
    },
    "statusPredicted.alias": {
      value: _query.statusPredictedAlias ? _query.statusPredictedAlias : null,
      matchMode: FilterMatchMode.IN,
      pretty: "statusPredictedAlias"
    },
    "project.id": {
      value: props.projectId ? props.projectId.toString() : null,
      matchMode: props.projectId ? FilterMatchMode.EQUALS : FilterMatchMode.IN,
      pretty: "projectId",
    },
    vadstenaInputDmVolumeMountAlias: {
      value: _query.vadstenaInputDmVolumeMountAlias ? _query.vadstenaInputDmVolumeMountAlias : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "vadstenaInputDmVolumeMountAlias"
    }
  }
})

// watches

// errorsLoading
watch(errorProjects, () => {
  if (errorProjects.value) {
    toast.add({ severity: 'error', summary: 'Error loading projects', detail: 'Try to log in again or contact administrator.' })
  }
})
watch(errorVolumes, () => {
  if (errorVolumes.value) {
    toast.add({ severity: 'error', summary: 'Error loading volumes', detail: 'Try to log in again or contact administrator.' })
  }
})
watch(errorSceneStatuses, () => {
  if (errorSceneStatuses.value) {
    toast.add({ severity: 'error', summary: 'Error loading scene statuses', detail: 'Try to log in again or contact administrator.' })
  }
})

// success loading

watch(volumes, () => {
  if (volumes?.value) {
    //  
  }
})

watch(scenes, () => {
  scenes?.value.forEach(element => {
    element.vadstenaInputDmVolumeMountAlias = element.vadstenaInputDmVolumeMountAlias ?? ''
  });
  sceneData.value.scenes = scenes
  sceneData.value.loading = loading
})

// events

async function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text)
  toast.add({ severity: 'info', summary: 'Copied to clipboard', life: 3000 })
}

async function onSceneUpdated() {
  await refetchScenes()
  sceneData.value.scenes = scenes
}

const onEditClick = (scene: Scene) => {
  router.push({
    name: 'project-scene-detail',
    params: {
      projectId: scene.project.id,
      sceneId: scene.id
    }
  })
}

const onVolumeClick = (scene: Scene) => {
  router.push({
    name: 'storage-volume-detail',
    params: {
      storageId: scene.vadstenaInputDmVolumeMountAlias ? scene.vadstenaInputDmVolumeMountAlias.split('/')[0] : -1,
      volumeMountAlias: scene.vadstenaInputDmVolumeMountAlias ?? ''
    }
  })
}

return (_ctx: any,_cache: any) => {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      value: sceneData.value.scenes,
      paginator: true,
      onPage: _cache[1] || (_cache[1] = ($event: any) => (_unref(onPage)($event))),
      onSort: _cache[2] || (_cache[2] = ($event: any) => (_unref(onSort)($event))),
      sortField: _unref(defaultSortField),
      sortOrder: _unref(defaultSortOrder),
      lazy: _unref(lazy),
      class: "p-datatable-sm",
      rows: _unref(rows),
      dataKey: "id",
      rowHover: true,
      filters: _unref(tableFilters),
      "onUpdate:filters": _cache[3] || (_cache[3] = ($event: any) => (_isRef(tableFilters) ? (tableFilters).value = $event : null)),
      filterDisplay: "menu",
      loading: sceneData.value.loading,
      paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      rowsPerPageOptions: _unref(rowsPerPageOptions),
      currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords} entries',
      responsiveLayout: "scroll"
    }, {
      empty: _withCtx(() => _cache[4] || (_cache[4] = [
        _createTextVNode(" No scenes found. ")
      ])),
      loading: _withCtx(() => [
        (sceneData.value.scenes.length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createVNode(_component_ProgressSpinner)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, " No scenes found. "))
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "name",
          header: "Name",
          filterField: "name",
          filterMatchMode: "contains",
          showFilterMatchModes: false,
          maxConstraints: 1,
          showFilterOperator: false,
          sortable: "",
          headerStyle: "min-width: 3rem"
        }, {
          body: _withCtx(({data}) => [
            _withDirectives(_createElementVNode("span", {
              "data-cy": "sceneTable__nameCell",
              innerHTML: _unref(makeStrong)(data.name, 'name'),
              onClick: ($event: any) => (onEditClick(data)),
              class: "mlwn-pointer-cursor mlwn-hyperlink"
            }, null, 8, _hoisted_3), [
              [_directive_tooltip, data.status.alias === 'InputQcReview' ? 'Show QC' : (data.status.alias === _unref(SceneStatusAlias).New ? 'Start population': 'Scene detail')]
            ])
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              class: "p-column-filter",
              placeholder: "Search by name"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "Project",
          field: "project",
          filterField: "project.id",
          filterMatchMode: "in",
          showFilterMatchModes: false,
          maxConstraints: 1,
          showFilterOperator: false,
          sortable: "",
          headerStyle: props.showProjectColumn ? 'min-width: 3rem;' : 'display:none;',
          bodyStyle: props.showProjectColumn ? '' : 'display:none;'
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(data.project.name), 1)
          ]),
          filter: _withCtx(({ filterModel, filterCallback}) => [
            _createVNode(_component_MultiSelect, {
              loading: _unref(loadingProjects),
              modelValue: filterModel.value,
              "onUpdate:modelValue": [($event: any) => ((filterModel.value) = $event), ($event: any) => (filterCallback())],
              options: _unref(projects),
              optionLabel: "name",
              placeholder: "Any",
              optionValue: "id"
            }, {
              option: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", null, _toDisplayString(slotProps.option.name), 1)
                ])
              ]),
              _: 2
            }, 1032, ["loading", "modelValue", "onUpdate:modelValue", "options"])
          ]),
          _: 1
        }, 8, ["headerStyle", "bodyStyle"]),
        _createVNode(_component_Column, {
          field: "timeEdited",
          header: "Last change",
          filterField: "timeEdited",
          filterMatchMode: "contains",
          showFilterMatchModes: false,
          maxConstraints: 1,
          showFilterOperator: false,
          sortable: "",
          headerStyle: "min-width: 1rem"
        }, {
          body: _withCtx(({data}) => [
            _withDirectives(_createElementVNode("span", {
              innerHTML: _unref(formatDate)(data.timeEdited),
              class: "mlwn-pointer-cursor"
            }, null, 8, _hoisted_5), [
              [
                _directive_tooltip,
                'Last change',
                void 0,
                { top: true }
              ]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "vadstenaInputDmVolumeMountAlias",
          header: "Vadstena input",
          filterField: "vadstenaInputDmVolumeMountAlias",
          filterMatchMode: "contains",
          showFilterMatchModes: false,
          maxConstraints: 1,
          showFilterOperator: false,
          sortable: "",
          headerStyle: "min-width: 1rem",
          bodyStyle: "min-width: 1rem; overflow: hidden;"
        }, {
          body: _withCtx(({data}) => [
            _withDirectives(_createElementVNode("span", {
              innerHTML: _unref(makeStrong)(data.vadstenaInputDmVolumeMountAlias, 'vadstenaInputDmVolumeMountAlias'),
              onClick: ($event: any) => (onVolumeClick(data)),
              class: "mlwn-pointer-cursor mlwn-hyperlink",
              style: {"word-break":"break-all"}
            }, null, 8, _hoisted_6), [
              [
                _directive_tooltip,
                'Vadstena input',
                void 0,
                { top: true }
              ]
            ]),
            (data.vadstenaInputDmVolumeMountAlias)
              ? _withDirectives((_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  onClick: ($event: any) => (copyToClipboard(data.vadstenaInputDmVolumeMountAlias))
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("i", { class: "fa fa-solid fa-fw fa-copy" }, null, -1)
                ]), 8, _hoisted_7)), [
                  [
                    _directive_tooltip,
                    'Copy to clipboard',
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              class: "p-column-filter",
              placeholder: "Search by input volume path"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "statusPredicted.alias",
          header: "Status",
          filterField: "statusPredicted.alias",
          filterMatchMode: "in",
          showFilterMatchModes: false,
          maxConstraints: 1,
          showFilterOperator: false,
          sortable: "",
          headerStyle: "min-width: 5rem; text-align: right",
          bodyStyle: "min-width: 5rem; text-align: right; overflow: visible; white-space: nowrap;"
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("span", _hoisted_8, [
              _createVNode(SceneActionPanel, {
                scene: data,
                onSceneUpdated: _cache[0] || (_cache[0] = ($event: any) => (onSceneUpdated()))
              }, null, 8, ["scene"])
            ])
          ]),
          filter: _withCtx(({ filterModel, filterCallback}) => [
            _createVNode(_component_MultiSelect, {
              loading: _unref(loadingSceneStatuses),
              modelValue: filterModel.value,
              "onUpdate:modelValue": [($event: any) => ((filterModel.value) = $event), ($event: any) => (filterCallback())],
              options: _unref(sceneStatuses),
              optionLabel: "name",
              placeholder: "Any",
              optionValue: "alias"
            }, null, 8, ["loading", "modelValue", "onUpdate:modelValue", "options"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "sortField", "sortOrder", "lazy", "rows", "filters", "loading", "rowsPerPageOptions"])
  ]))
}
}

})