<script setup lang="ts">
/**
 * Load validation references, router, vue
 */
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { ref } from 'vue';

import InputTextWithValidation from '@/components/UI/Form/components/InputTextWithValidation.vue';
import InputTextAreaWithValidation from '@/components/UI/Form/components/InputTextAreaWithValidation.vue';
import DropdownWithValidation from '@/components/UI/Form/components/DropdownWithValidation.vue';

// Define the event emitter
const emit = defineEmits(['next', 'onCancel'])

const onCancel = () => {
  emit('onCancel', router.currentRoute.value.name)
}

const props = defineProps({
  formData: {
    type: Object,
    required: true
  },
  update: {
    type: Boolean,
    required: false,
    default: false
  }
})

const initialValues = ref({
  id: props.formData?.id ?? '',
  name: props.formData?.name ?? '',
  notes: props.formData?.notes ?? '',
  // Default population type - Ingest, to be set by combo
  populationTypeAlias: PopulationTypeAlias.Ingest
})

/**
 * Validation setup
 */
const schema = yup.object({
  id: yup.string().matches(/^[a-zA-Z0-9.\-\u00D8-\u00F6]+$/, 'Is not in correct format, allowed characters [a-zA-Z0-9.\\-]').required().min(3).max(255).label('Id'),
  name: yup.string().min(0).max(255).label('Name'),
  notes: yup.string().label('Notes')
});

const form: any = ref({
  idExist: false
})

const router = useRouter()
// Function
const onSubmit = (values: any) => {
  values.name = values.id
  emit('next', values, router.currentRoute.value.name, false);
}

import { useRouter } from 'vue-router';

import { useScenes } from '@/composables/useScenes';
const { sceneExist } = useScenes()
import { watch } from 'vue'
import { PopulationTypeAlias } from '@/gql/graphql';

const checkSceneIdExist = (id: string) => {
  form.value.idExist = false

  const { exist } = sceneExist(id)
  watch(exist, () => {
    if (exist.value) {
      form.value.idExist = exist.value
    }
  })
}

const onKeyDown = (event: KeyboardEvent) => {
  const key = event.key.toLowerCase();

  if (key.length !== 1) {
    return
  }

  checkSceneIdExist((event?.target?.value ?? '') + event.key)
}

const onChange = (event: KeyboardEvent) => {

}

checkSceneIdExist(initialValues.value.id)

</script>

<template>
  <div class="grid">
    <div class="col-12 formgrid">
      <div class="p-fluid">
        <Form :initial-values="initialValues" :validation-schema="schema" @submit="onSubmit" class="grid p-fluid">
          <div class="field col-12 md:col-4 input">
            <InputTextWithValidation name="id" label="Scene id" :disabled="update" @keydown="onKeyDown" @change="onChange" data-cy="stepOne__sceneId"></InputTextWithValidation>
            <label for="id" v-if="form.idExist && !update" class="p-error">Scene Id already exist</label>
            <InputTextWithValidation name="name" label="Name" v-if="false"></InputTextWithValidation>
          </div>

          <div class="field col-12 md:col-8 input">
            <InputTextAreaWithValidation name="notes" label="Notes" :rows="1" :cols="40" data-cy="stepOne__sceneNotes">
            </InputTextAreaWithValidation>

            <div class="flex flex-row flex-wrap justify-content-end">
              <Button label="Cancel" icon="fa-solid fa-xmark" @click="onCancel" class="p-button-secondary mr-2 mb-2 mlwn-button-submit" v-if="false" />
              <Button v-if="!form.idExist || update" label="Next" type="submit" icon="fa-solid fa-arrow-right" class="p-button-success mr-2 mb-2 mlwn-button-submit" data-cy="stepOne__nextButton"></Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input {
  margin: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
</style>
