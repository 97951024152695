import { GET_DATA_BROWSER_LIST, GET_DATA_BROWSER_STATUS } from '@/apollo/queries/data_mgmt'
import { DataBrowserStatus, DataBrowserList, DataBrowserSortInput, DataBrowserEntity } from '@/gql/graphql'
import { useLazyQuery } from '@vue/apollo-composable'

import { ComputedRef, computed, Ref, ref, watch } from 'vue'
import { useConfig } from './useConfig'
import { ApolloError } from '@apollo/client/errors'

export function useDataBrowser() {
  /**
   * @description returns filtered volumes
   * @param {nustringmber} volumeMountAlias
   * @return {*}
   */
  function getDataBrowserStatus(volumeMountAlias: Ref<string>, pollInterval?: number | undefined) {
    const ready = ref(false)
    const { loading, error, result, load, refetch } = useLazyQuery(GET_DATA_BROWSER_STATUS, { volumeMountAlias: volumeMountAlias.value }, {pollInterval: pollInterval})
    const status: ComputedRef<DataBrowserStatus> = computed(() => result?.value?.getDataBrowserStatus ?? {})

    watch(status, (newStatus) => {
      ready.value = (
        newStatus.dataBrowserServiceCreated === true && 
        newStatus.dataBrowserServiceReady === true && 
        newStatus.taskVolumeCreated === true && 
        newStatus.taskVolumeReady === true
      )
    })

    return { status, error, loading, load: () => { 
      load(GET_DATA_BROWSER_STATUS, {
        volumeMountAlias: volumeMountAlias.value
      })
    }, refetch: () => { 
      refetch({
        volumeMountAlias: volumeMountAlias.value
      })
    }, ready }
  }

  /**
   * @description get enum of possible volume statuses
   * @return {*}  
   */
  function getDataBrowserList(input: Ref<{
    volumeMountAlias: string
    dirPath: string,
    limit: number,
    offset: number,
    search?: string;
    sort?: DataBrowserSortInput;
  }>) {
    const { result, loading, error, load, refetch } = useLazyQuery(GET_DATA_BROWSER_LIST, input, { fetchPolicy: 'no-cache', errorPolicy: 'ignore' })

    const list: ComputedRef<DataBrowserList> = computed(() => result?.value?.getDataBrowserList ?? [])

    return { list, error, loading, load, refetch }
  }

  function getDownloadURL(
    volumeMountAlias: string,
    filePath: string,
  ) {
    const { getConfig } = useConfig()
    const config = getConfig();

    return `${config?.API_URL}/download?volumeMountAlias=${volumeMountAlias}&path=`+encodeURIComponent([volumeMountAlias, filePath].join('/'))
  }

  /*
  function getFileContent(
    volumeMountAlias: string,
    filePath: string,
  ): { content: Ref<string|null>, error: Ref<unknown> } {
    const content = ref('')
    const error: Ref<any> = ref(null)

    const url = getDownloadURL(volumeMountAlias, filePath)

    async function fetch(url: string, options: RequestInit): Promise<Response> {
      return fetch(url, options)
    }

    try {
      fetch(url, {
        cache: 'no-store',
        headers: {
          'Authorization': `Bearer ${gcAuthToken}`,
        } 
      }).then(async response => {
        content.value = await response.text()
      })
    } catch(error: any) {
      error.value = error
    }

    return { content, error }
  }
  */

  function fileExists(
    volumeMountAlias: string,
    filePath: string
  ): {exists: Ref<boolean>, error: Ref<ApolloError | null>, file: Ref<DataBrowserEntity | null>, ready: Ref<boolean>} {
    const exists = ref(false)
    const file: Ref<DataBrowserEntity | null> = ref(null)

    const dirPath = filePath.split('/').slice(0, -1).join('/')
    const fileName = filePath.split('/').slice(-1)[0]

    const { ready, load: loadStatus } = getDataBrowserStatus(ref(volumeMountAlias), 2000)
    loadStatus()
  
    const { error, list, load: loadList }  = getDataBrowserList(ref({
      volumeMountAlias: volumeMountAlias,
      dirPath,
      search: fileName,
      limit: 10,
      offset: 0,
    }))

    watch(ready, (isReady) => {
      if(isReady) {
        loadList()
      }
    })

    watch(list, (newList) => {
      if(newList && Array.isArray(newList.data) && newList.data.length > 0) {
        const found = newList.data.find((file) => file.path === `${volumeMountAlias}/${filePath}`)
        if(found) {
          exists.value = true
          file.value = found
        }
      } else {
        exists.value = false
      }
    })

    return { exists, error, file, ready }
  } 

  return {
    getDataBrowserStatus,
    getDataBrowserList,
    getDownloadURL,
    // getFileContent,
    fileExists
  }
}