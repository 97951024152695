import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 formgrid" }
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = { class: "field col-12 md:col-4 input" }
const _hoisted_5 = {
  key: 0,
  for: "id",
  class: "p-error"
}
const _hoisted_6 = { class: "field col-12 md:col-8 input" }
const _hoisted_7 = { class: "flex flex-row flex-wrap justify-content-end" }

import { Form } from 'vee-validate';
import * as yup from 'yup';
import { ref } from 'vue';

import InputTextWithValidation from '@/components/UI/Form/components/InputTextWithValidation.vue';
import InputTextAreaWithValidation from '@/components/UI/Form/components/InputTextAreaWithValidation.vue';
import DropdownWithValidation from '@/components/UI/Form/components/DropdownWithValidation.vue';

// Define the event emitter
import { useRouter } from 'vue-router';

import { useScenes } from '@/composables/useScenes';
import { watch } from 'vue'
import { PopulationTypeAlias } from '@/gql/graphql';


export default /*@__PURE__*/_defineComponent({
  __name: 'StepOne',
  props: {
  formData: {
    type: Object,
    required: true
  },
  update: {
    type: Boolean,
    required: false,
    default: false
  }
},
  emits: ['next', 'onCancel'],
  setup(__props, { emit: __emit }) {

/**
 * Load validation references, router, vue
 */
const emit = __emit

const onCancel = () => {
  emit('onCancel', router.currentRoute.value.name)
}

const props = __props

const initialValues = ref({
  id: props.formData?.id ?? '',
  name: props.formData?.name ?? '',
  notes: props.formData?.notes ?? '',
  // Default population type - Ingest, to be set by combo
  populationTypeAlias: PopulationTypeAlias.Ingest
})

/**
 * Validation setup
 */
const schema = yup.object({
  id: yup.string().matches(/^[a-zA-Z0-9.\-\u00D8-\u00F6]+$/, 'Is not in correct format, allowed characters [a-zA-Z0-9.\\-]').required().min(3).max(255).label('Id'),
  name: yup.string().min(0).max(255).label('Name'),
  notes: yup.string().label('Notes')
});

const form: any = ref({
  idExist: false
})

const router = useRouter()
// Function
const onSubmit = (values: any) => {
  values.name = values.id
  emit('next', values, router.currentRoute.value.name, false);
}

const { sceneExist } = useScenes()
const checkSceneIdExist = (id: string) => {
  form.value.idExist = false

  const { exist } = sceneExist(id)
  watch(exist, () => {
    if (exist.value) {
      form.value.idExist = exist.value
    }
  })
}

const onKeyDown = (event: KeyboardEvent) => {
  const key = event.key.toLowerCase();

  if (key.length !== 1) {
    return
  }

  checkSceneIdExist((event?.target?.value ?? '') + event.key)
}

const onChange = (event: KeyboardEvent) => {

}

checkSceneIdExist(initialValues.value.id)


return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Form), {
          "initial-values": initialValues.value,
          "validation-schema": _unref(schema),
          onSubmit: onSubmit,
          class: "grid p-fluid"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(InputTextWithValidation, {
                name: "id",
                label: "Scene id",
                disabled: __props.update,
                onKeydown: onKeyDown,
                onChange: onChange,
                "data-cy": "stepOne__sceneId"
              }, null, 8, ["disabled"]),
              (form.value.idExist && !__props.update)
                ? (_openBlock(), _createElementBlock("label", _hoisted_5, "Scene Id already exist"))
                : _createCommentVNode("", true),
              false
                ? (_openBlock(), _createBlock(InputTextWithValidation, {
                    key: 1,
                    name: "name",
                    label: "Name"
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(InputTextAreaWithValidation, {
                name: "notes",
                label: "Notes",
                rows: 1,
                cols: 40,
                "data-cy": "stepOne__sceneNotes"
              }),
              _createElementVNode("div", _hoisted_7, [
                false
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      label: "Cancel",
                      icon: "fa-solid fa-xmark",
                      onClick: onCancel,
                      class: "p-button-secondary mr-2 mb-2 mlwn-button-submit"
                    }))
                  : _createCommentVNode("", true),
                (!form.value.idExist || __props.update)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 1,
                      label: "Next",
                      type: "submit",
                      icon: "fa-solid fa-arrow-right",
                      class: "p-button-success mr-2 mb-2 mlwn-button-submit",
                      "data-cy": "stepOne__nextButton"
                    }))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        }, 8, ["initial-values", "validation-schema"])
      ])
    ])
  ]))
}
}

})