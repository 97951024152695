<template>
  <div class="field" :class="fieldClass">
    <InfoInputGroup :hint-content="hintContent" :hint-title="hintTitle" :float-label-class="!minimize">
      <InputText 
        :size="size"
        v-model="value"
        @keydown="(e:any) => keydown"
        @change="(e:any) => change"
        :aria-describedby="`${name}-help`"
        :disabled="disabled"
        :class="{ 'p-invalid': errorMessage }"
        :placeholder="minimize ? label : ''"
        :type="type" />
      <label :for="name" v-if="!minimize">{{ label }}</label>
    </InfoInputGroup>
    <small :id="`${name}-help`" class="p-error">{{ errorMessage }}</small>
  </div>
</template>

<script setup lang="ts">
import { toRef } from 'vue';
import { useField } from 'vee-validate';
import InfoInputGroup from '../InfoInputGroup.vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fieldClass: {
    type: String,
    required: false,
    default: ''
  },
  hintContent: {
    type: String,
    required: false,
    default: null
  },
  hintTitle: {
    type: String,
    required: false,
    default: `Hint`
  },
  keydown: {
    type: Function,
    required: false,
    default: () => {}
  },
  change: {
    type: Function,
    required: false,
    default: () => {}
  },
  size: {
    type: Number,
    required: false,
    default: 20
  },
  minimize: {
    type: Boolean,
    required: false,
    default: false
  }
});

const { errorMessage, value } = useField(toRef(props, 'name'));
</script>