<template>
  <div class="grid">
		<div class="col-12">
			<Card>
				<template #title>
          <div class="flex justify-content-between flex-wrap">
              <div class="flex align-items-center justify-content-center">Scenes</div>
              <div class="flex align-items-center justify-content-center">
                <Button type="button" icon="fa fa-plus" label="New Scene" @click="toggle" />
                <OverlayPanel ref="op">
                  <div class="flex w-100 mb-2">
                    <Dropdown class="flex w-100" :loading="loadingProjects" :options="projects" optionLabel="name" optionValue="id" placeholder="Select a Project" v-model="selectedProjectId" />
                  </div>
                  <div class="flex w-100">
                    <router-link  class="w-100"
                      v-if="selectedProjectId"
                      type="button" :to="{
                        name: 'create-scene',
                        params: { 
                          projectId: selectedProjectId
                        }
                      }"  v-tooltip.bottom="'Create new project'">
                      <Button type="button" label="Create scene" :disabled="!selectedProjectId" />	
                    </router-link>
                  </div>
                </OverlayPanel>
              </div>
          </div>
				</template>
				<template #content>
					<scene-table :showProjectColumn="true"></scene-table>
				</template>
      </Card>
		</div>
	</div>
</template>

<script setup lang="ts">
import OverlayPanel from 'primevue/overlaypanel';
import SceneTable from './components/SceneTable.vue'
import { ref, watch } from 'vue';
import { useProject } from '@/composables/useProject';
import { useToast } from 'primevue/usetoast';

const op = ref()
const selectedProjectId = ref()

const toast = useToast()
const { getProjects } = useProject()
const { projects, loading: loadingProjects, error: errorProjects } = getProjects()

watch(errorProjects, () => {
  if (errorProjects.value) {
    toast.add({ severity: 'error', summary: 'Error loading projects', detail: 'Try to log in again or contact administrator.' })
  }
})

watch(projects, () => {
  if (projects.value.length > 0) {
    selectedProjectId.value = projects.value[0].id
  }
})

const toggle = () => {
  op.value.toggle(event)
}

</script>

<style lang="scss" scoped>
	@media screen and (max-width: 960px) {
		::v-deep(.p-datatable) {
			&.p-datatable-customers {
				.p-datatable-thead > tr > th,
				.p-datatable-tfoot > tr > td {
					display: none !important;
				}

				.p-datatable-tbody > tr {
					border-bottom: 1px solid #dee2e6;
					> td {
						text-align: left;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 0 none !important;
						width: 100% !important;
						float: left;
						clear: left;
						border: 0 none;

						.p-column-title {
							padding: .4rem;
							min-width: 30%;
							display: inline-block;
							margin: -.4rem 1rem -.4rem -.4rem;
							font-weight: bold;
						}

						.p-progressbar {
							margin-top: .5rem;
						}
					}
				}
			}
		}
	}
</style>
