import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useToast } from "primevue/usetoast";
import { useDataBrowser } from '@/composables/useDataBrowser';


export default /*@__PURE__*/_defineComponent({
  __name: 'FileDownloadButton',
  props: {
  volumeMountAlias: {
    required: true,
    type: String,
  },
  path: {
    required: true,
    type: String,
  },
  label: {
    required: false,
    type: String,
    default: "Download file"
  }
},
  setup(__props) {

const toast = useToast();

const { getDownloadURL, fileExists } = useDataBrowser()

const props = __props;

const vadstenaIngestLogURL = getDownloadURL(
  props.volumeMountAlias,
  props.path
)

const { exists: fileExistsValue, ready: dataBrowserReady } = fileExists(props.volumeMountAlias, props.path)

const items = [
  {
    label: 'Update',
    command: () => {
      toast.add({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
    }
  }
]

const download = () => {
  const link = document.createElement('a');
  link.href = vadstenaIngestLogURL;
  link.click();

  toast.add({ severity: 'success', summary: 'Success', detail: 'Data download', life: 3000 });
};

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _directive_ripple = _resolveDirective("ripple")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_component_Button, {
    class: "relative overflow-hidden w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround",
    disabled: !_unref(fileExistsValue),
    icon: "fa-solid fa-download",
    label: __props.label,
    onClick: download,
    model: items
  }, null, 8, ["disabled", "label"])), [
    [_directive_ripple],
    [
      _directive_tooltip,
      (!_unref(dataBrowserReady))? 'Data browse service is not available. Check services setting.' : ((!_unref(fileExistsValue)) ? 'The file is not yet available or does not exists.' : `Download file ${__props.path}`),
      void 0,
      { top: true }
    ]
  ])
}
}

})