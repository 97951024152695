import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { key: 0 }

import DataBrowser from '@/components/DataBrowser/DataBrowser.vue';
import { useScenes } from '@/composables/useScenes';
import { useVolumes } from '@/composables/useVolumes';
import { Ref, ref, watch } from 'vue';
import { PopulationTypeAlias, Volume } from '@/gql/graphql'
import { useLogFiles } from '@/composables/helpers/useLogFiles';

export default /*@__PURE__*/_defineComponent({
  __name: 'ScenePopulating',
  props: {
  sceneId: {
    required: true,
    type: String
  }
},
  setup(__props) {


const props = __props

const { getScene } = useScenes()
const { scene } = getScene(props.sceneId) 

const { getVolume } = useVolumes()
let volume: Ref<Volume | null> = ref(null)

const { populationLogFilePath } = useLogFiles()

const logFilePath = ref('')
watch(scene, (sceneValue) => {
  if (sceneValue && sceneValue.vadstenaInputDmVolumeMountAlias) {
    volume = getVolume(sceneValue.vadstenaInputDmVolumeMountAlias).volume

    logFilePath.value = populationLogFilePath(sceneValue.populationType.alias as PopulationTypeAlias)
  }
})



return (_ctx: any,_cache: any) => {
  const _component_Message = _resolveComponent("Message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Message, {
        closable: false,
        severity: "info"
      }, {
        default: _withCtx(() => [
          _createElementVNode("strong", null, "Scene status: " + _toDisplayString(_unref(scene)?.status.alias), 1),
          _cache[0] || (_cache[0] = _createTextVNode(" This may take a while... ")),
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          _cache[2] || (_cache[2] = _createTextVNode(" You can see current vadstena input content in Data Browser below."))
        ]),
        _: 1
      }),
      _createElementVNode("h5", null, "Vadstena input is in volume " + _toDisplayString(_unref(volume)?.name), 1),
      (_unref(scene) && _unref(volume))
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
            (_unref(scene).vadstenaInputDmVolumeMountAlias)
              ? (_openBlock(), _createBlock(DataBrowser, {
                  key: 0,
                  volumeMountAlias: _unref(volume).mountAlias,
                  storageId: _unref(volume).storage?.id
                }, null, 8, ["volumeMountAlias", "storageId"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})