import Dashboard from "../components/Admin/Dashboard/Dashboard.vue";
import AppLayout from "@/layout/AppLayout.vue";
import Login from "../components/Public/Pages/Login.vue";
import AccountNotActivated from "../components/Public/Pages/AccountNotActivated.vue";
import NotFound from "../components/Public/Pages/NotFound.vue";

import { projectRoutes } from "./routes/project.routes";
import { processingRoutes } from "./routes/processing.routes";
import { settingRoutes } from "./routes/settings.routes";
import { dataVolumesRoutes } from "./routes/data-volumes.routes";
import { qualityControlRoutes } from "./routes/quality-controll.routes";
import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: AppLayout,
    redirect: "/admin/processing/scene",
    children: [],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      rule: "isPublic",
    },
  },
  {
    path: "/login/account-not-activated",
    name: "account-not-activated",
    component: AccountNotActivated,
    meta: {
      rule: "isPublic",
    },
  },
  {
    path: "/admin",
    component: AppLayout,
    redirect: "/admin/processing/scene",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
          can: "is-admin",
          breadcrumb: [{ label: "Dashboard" }],
        },
      },
      ...settingRoutes,
      ...processingRoutes,
      ...projectRoutes,
      ...dataVolumesRoutes,
      ...qualityControlRoutes,
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
    redirect: "/404",
    children: [
      {
        path: "404",
        name: "404",
        component: NotFound,
        meta: {
          rule: "isPublic",
        },
      },
    ],
  },
];

export default routes;
