<template>
  <span>
    <Dialog v-model:visible="upload.visible" header="AOI Upload" @hide="onDialogClose"
      :position="'center'" :modal="true" :style="{ width: '960px' }">      
      <template #default>
        <div class="card">
            <Toast />
            <h5>Please select AOI files (.shp,.shx,.dbf,.prj)</h5>
            <FileUpload name="demo[]" url="#" @upload="onTemplatedUpload()" :multiple="true" accept=".shp,.shx,.dbf,.prj" :maxFileSize="10000000" @select="onSelectedFiles">
                <template #header="{ chooseCallback, clearCallback, files }"><!--uploadCallback, -->
                    <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                        <div class="flex gap-2">
                            <Button @click="chooseCallback()" icon="pi pi-images" rounded outlined></Button>
                            <Button data-cy="dialogAOI__upload" @click="convert()" icon="pi pi-cloud-upload" rounded outlined severity="success" :disabled="!files || files.length === 0"></Button>
                            <!--
                            <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload" rounded outlined severity="success" :disabled="!files || files.length === 0"></Button>
                            -->
                            <Button @click="clearCallback()" icon="pi pi-times" rounded outlined severity="danger" :disabled="!files || files.length === 0"></Button>
                        </div>
                        <ProgressBar :value="totalSizePercent" :showValue="false" :class="['md:w-20rem h-1rem w-full md:ml-auto', { 'exceeded-progress-bar': totalSizePercent > 100 }]"
                            ><span class="white-space-nowrap">{{ totalSize }}B / 1Mb</span></ProgressBar
                        >
                    </div>
                </template>
                <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
                    <div v-if="files.length > 0">
                        <h5>Pending</h5>
                        <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                            <div v-for="(file, index) of files" :key="file.name + file.type + file.size" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                                <div v-if="false">
                                    <img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" />
                                </div>
                                <span class="font-semibold">{{ file.name }}</span>
                                <div>{{ formatSizeTemplate(file.size) }}</div>
                                <Badge value="Pending" severity="warning" />
                                <Button icon="pi pi-times" @click="onRemoveTemplatingFile(file, removeFileCallback, index)" outlined rounded  severity="danger" />
                            </div>
                        </div>
                    </div>

                    <div v-if="uploadedFiles.length > 0">
                        <h5>Completed</h5>
                        <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                            <div v-for="(file, index) of uploadedFiles" :key="file.name + file.type + file.size" class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                                <div>
                                    <img role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" />
                                </div>
                                <span class="font-semibold">{{ file.name }}</span>
                                <div>{{ formatSizeTemplate(file.size) }}</div>
                                <Badge value="Completed" class="mt-3" severity="success" />
                                <Button icon="pi pi-times" @click="removeUploadedFileCallback(index)" outlined rounded  severity="danger" />
                            </div>
                        </div>
                    </div>
                </template>
                <template #empty>
                    <div class="flex align-items-center justify-content-center flex-column">
                        <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" />
                        <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                    </div>
                </template>
            </FileUpload>
        </div>
      </template>
      <template #footer v-if="false">
        <Button label="OK" @click="onOk"></Button>
        <Button label="Close" @click="onDialogClose"></Button>
      </template>
    </Dialog>
  </span>
</template>


<script setup lang="ts">

import { ref, watch } from 'vue'
import { useScenes } from '@/composables/useScenes';
import { MutationUpdateSceneArgs } from '@/gql/graphql';
const { updateScene, getScene } = useScenes()

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
    required: true
  },
  hide: {
    required: true
  },
  sceneId: {
    type: String,
    required: true
  } 
})

/*
const onFileChanged = ($event: Event) => {
  const target = $event.target as HTMLInputElement;
  const name = target.files[0].name
  if (target && target.files) {
    const reader = new FileReader();
    reader.onload = (e) => {      
      upload.value.file = JSON.parse(e.target?.result)
      upload.value.fileName = name
    }
    reader.readAsBinaryString(target.files[0])
  }
}
*/

const onOk = () => {
  upload.value.visible = false
  upload.value.hide(upload.value.file)
}

const onDialogClose = () => {
  upload.value.visible = false
  upload.value.hide()
}

const upload: any = ref({
  hide: props.hide,
  visible: props.visible,
  file: null,
  fileName: ''
})

const totalSize = ref(0);
const totalSizePercent = ref(0);
const files = ref([]);
const aoiData:any = ref({
  files: {},
  type: 'shp2geojson'
})

const onRemoveTemplatingFile = (file:any, removeFileCallback:any, index:any) => {
  removeFileCallback(index);
  totalSize.value -= parseInt(formatSize(file.size));
  totalSizePercent.value = totalSize.value / 10;
};

/*
const onClearTemplatingUpload = (clear:any) => {
  clear();
  totalSize.value = 0;
  totalSizePercent.value = 0;
};
*/

const onSelectedFiles = (event:any) => {
  files.value = event.files;
  files.value.forEach((file) => {
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = function () {
      if (fileReader?.result) {        
        const result = fileReader.result.substr(fileReader.result.indexOf(',') + 1); 
        aoiData.value["files"][file?.name] = result
      }
    };
    totalSize.value += parseInt(formatSize(file?.size));
  });
};

const callConversion = async (updateSceneArgs: MutationUpdateSceneArgs) => {
  const res = await updateScene(updateSceneArgs)
  if (res.success) {
    upload.value.file = res.payload?.scene.aoiGeojson
    upload.value.fileName = "aoi.geojson"
    upload.value.visible = false
    upload.value.hide(null, 'aoi')
  }  
}

const getAoiInputFileByExtension = (extension: string) => {
  const keys = Object.keys(aoiData.value["files"])
  const key = keys.find(f => f.toLowerCase().indexOf(extension.toLowerCase()) > -1)
  if (key) {
    return aoiData.value["files"][key]
  }
  else {
    return null
  }
}

const convert = async () => {
  const { scene } = getScene(props.sceneId, 0) 
  watch(scene, async () => {
    if (scene?.value){
      const sceneData = scene?.value
      const updateSceneArgs: MutationUpdateSceneArgs = {
        id: sceneData.id,
        aoiInputFiles: {
          shapeFile: getAoiInputFileByExtension('shp') ?? '',
          indexFile: getAoiInputFileByExtension('shx') ?? '',
          dbFile: getAoiInputFileByExtension('dbf') ?? '',
          prjFile: getAoiInputFileByExtension('prj') ?? ''
        }
      }
      await callConversion(updateSceneArgs)
    }
  })
};

/*
const uploadEvent = (callback:any) => {
  totalSizePercent.value = totalSize.value / 10;
  //callback();
  console.log(files)
};
*/

const onTemplatedUpload = () => {
  console.log('a')
};

const formatSize = (bytes:any) => {
  const k = 1024;
  const dm = 3;

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${formattedSize}`;
}

const formatSizeTemplate = (bytes:any) => {
  const k = 1024;
  const dm = 3;

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  let unit = 'bytes'
  switch (i){
  case 0:
    unit = 'bytes'
    break
  case 1:
    unit = 'kB'
    break
  case 2:
    unit = 'MB'
    break
  case 3:
    unit = 'GB'
    break    
  }

  return `${formattedSize} ${unit}`;
}

</script>

<style lang="scss" scoped>

.fileName {
  margin-left: 12px;
}

</style>