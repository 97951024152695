import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "col-12 col:md-6 formgrid" }
const _hoisted_6 = { class: "flex flex-row flex-wrap justify-content-end" }

import { Form } from "vee-validate";
import { Ref, ref, watch } from "vue";
import { useToast } from "primevue/usetoast";
import {
  StorageTypeEnum,
  Volume,
  VolumeCreateInput,
  VolumeUpdateInput,
} from "@/gql/graphql";
import { useVolumes } from "@/composables/useVolumes";
import * as yup from "yup";
import { useStorages } from "@/composables/useStorages";
import { useRouter } from "vue-router";

import InputTextWithValidation from "@/components/UI/Form/components/InputTextWithValidation.vue";
import InputNumberWithValidation from "@/components/UI/Form/components/InputNumberWithValidation.vue";
import InputTextAreaWithValidation from "@/components/UI/Form/components/InputTextAreaWithValidation.vue";
import InputJSONWithValidation from "@/components/UI/Form/components/InputJSONWithValidation.vue";

import { VolumeFormHint } from "./VolumeFormHint";


export default /*@__PURE__*/_defineComponent({
  __name: 'VolumeForm',
  props: {
  storageId: {
    required: true,
    type: String,
  },
  volumeMountAlias: {
    required: false,
    type: String,
  },
  update: {
    required: false,
    default: true,
  },
},
  setup(__props) {

const props = __props;

const { getVolume, registerVolume, updateVolume } = useVolumes();

const initialValues: Ref<Partial<Volume> | Volume> = ref({
  name: "",
  sizeReservedMB: 0,
  remotePath: "",
  storageId: props.storageId,
});
const { volume, loading: volumeLoading } = props.update && props.volumeMountAlias
  ? getVolume(props?.volumeMountAlias)
  : {
    volume: initialValues,
    loading: false
  };

watch(volume, () => {
  initialValues.value = Object.assign({}, volume.value)
})

/**
 * Validation setup
 */
let schema = yup.object({
  name: yup.string().required().min(3).max(255).label("Name"),
  sizeReservedMB: yup.number().required().label("Reserved size [MB]"),
  remotePath: yup.string().required().min(1).max(500).label("Remote path"),
  mountOptions: yup.object().json().nullable().label("Mount options"),
});

const toast = useToast();
const router = useRouter();

const onRegisterVolume = async (volume: VolumeCreateInput) => {
  try {
    let res = await registerVolume(volume);

    if (res.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Succesfully registred",
        life: 3000,
      });
      // redirect logic
      router.push({
        name: "storage-volume-detail",
        params: {
          storageId: props.storageId,
          volumeMountAlias: res?.payload?.volume?.mountAlias,
        },
      });
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Error occured while saving 1",
        life: 3000,
      });
    }
  } catch (err) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error occured while saving 2",
      life: 3000,
    });
  }
};

const onUpdateVolume = async (volume: VolumeUpdateInput) => {
  try {
    if (props.volumeMountAlias === undefined) return
    let res = await updateVolume({
      mountAlias: props.volumeMountAlias,
      name: volume.name,
      sizeReservedMB: volume.sizeReservedMB,
      mountOptions: volume.mountOptions,
    });

    if (res.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Succesfully updated",
        life: 3000,
      });
      router.push({
        name: "storage-volume-detail",
        params: {
          storageId: props.storageId,
          volumeMountAlias: res?.payload?.volume?.mountAlias,
        },
      });
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Error occured while saving. ",
        life: 3000,
      });
    }
  } catch (err: any) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error occured while saving. " + err?.message,
      life: 3000,
    });
    console.error(err);
  }
};

const onSubmit = async (volume: VolumeCreateInput | VolumeUpdateInput) => {
  if (typeof volume.mountOptions === "string") {
    volume.mountOptions = JSON.parse(volume.mountOptions);
  }

  if (props.update) {
    onUpdateVolume(volume as VolumeUpdateInput);
  } else {
    onRegisterVolume(volume as VolumeCreateInput);
  }
};

function onCancelClick() {
  router.go(-1);
}

const { getStorage } = useStorages();

const { storage } = getStorage(props.storageId);
const hint = ref({
  remotePath: "",
  mountOptions: "",
});
watch(storage, () => {
  if (storage.value && storage.value.type) {
    switch (storage.value?.type) {
    case StorageTypeEnum.Nfs:
      hint.value.mountOptions = VolumeFormHint.nfs.mountOptions;
      hint.value.remotePath = VolumeFormHint.nfs.remotePath;
      break;
    case StorageTypeEnum.S3fs:
      hint.value.mountOptions = VolumeFormHint.s3.mountOptions;
      hint.value.remotePath = VolumeFormHint.s3.remotePath;
      break;
    }
  }
});

return (_ctx: any,_cache: any) => {
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!__props.update)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 0,
              severity: "info",
              closable: false
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("After volume register, data in volume must not be changed outside of Honeydew system.")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!__props.update)
          ? (_openBlock(), _createElementBlock("br", _hoisted_4))
          : _createCommentVNode("", true),
        (__props.update || !_unref(volumeLoading))
          ? (_openBlock(), _createBlock(_unref(Form), {
              key: 2,
              "initial-values": initialValues.value,
              "validation-schema": _unref(schema),
              onSubmit: _cache[0] || (_cache[0] = (values) => onSubmit(values as VolumeCreateInput | VolumeUpdateInput)),
              class: "grid p-fluid"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(InputTextWithValidation, {
                    name: "name",
                    "data-cy": "volumeForm__name",
                    label: "Name"
                  }),
                  _createVNode(InputNumberWithValidation, {
                    name: "sizeReservedMB",
                    "data-cy": "volumeForm__sizeReserved",
                    label: "Reserved size [MB]"
                  }),
                  _createVNode(InputTextAreaWithValidation, {
                    name: "remotePath",
                    "data-cy": "volumeForm__remotePath",
                    label: "Remote path",
                    disabled: __props.update,
                    "hint-title": 'Remote path example',
                    "hint-content": hint.value.remotePath
                  }, null, 8, ["disabled", "hint-content"]),
                  _createVNode(InputJSONWithValidation, {
                    name: "mountOptions",
                    label: "Mount options",
                    "hint-title": 'Mount options setup',
                    "hint-content": hint.value.mountOptions
                  }, null, 8, ["hint-content"]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_Button, {
                      "data-cy": "volumeForm__registerButton",
                      label: __props.update ? 'Save changes' : 'Register volume',
                      type: "submit",
                      icon: "fa-solid fa-check",
                      class: "p-button-success mr-2 mb-2 mlwn-button-submit"
                    }, null, 8, ["label"]),
                    _createVNode(_component_Button, {
                      onClick: onCancelClick,
                      label: "Cancel",
                      icon: "fa-solid fa-xmark",
                      class: "p-button-secondary mr-2 mb-2 mlwn-button-submit"
                    })
                  ])
                ])
              ]),
              _: 1
            }, 8, ["initial-values", "validation-schema"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})