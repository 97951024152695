<template>
  <div class="exception-body notfound-page">
    <div class="exception-top">
      <img src="/layout/images/icon-404.png" alt="404-layout" />
    </div>

    <div class="exception-bottom">
      <div class="exception-wrapper">
        <span class="exception-summary">Page Not Found</span>
        <span class="exception-detail"
          >The page you are looking for does not exists, or you do not have
          enough permissions.<br />If you think, this is an error, contact
          us.</span
        >
        <div class="exception-buttons">
          <Button
            v-if="$acl.user"
            icon="fa-solid fa-chevron-left"
            class="p-button-text"
            label="back"
            @click="backToLast"
          />
          <Button
            v-if="$acl.user"
            icon="fa-solid fa-home"
            class="p-button-text"
            label="Dashboard"
            @click="backToDashboard"
          />
          <Button
            icon="fa-solid fa-user"
            class="p-button-text"
            label="login"
            @click="backToLogin"
          />
        </div>
        <span class="exception-footer">Vadstena HoneyDew</span>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();

    return {
      backToLogin: () => router.push({ name: "login" }),
      backToDashboard: () => router.push({ name: "dashboard" }),
      backToLast: () => router.go(-1),
    };
  },
};
</script>

<style lang="scss" scoped>
.exception-body
  .exception-bottom
  .exception-wrapper
  .exception-buttons
  button:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
