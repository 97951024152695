import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import { watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { FilterMatchMode } from "primevue/api"
import { useRouter } from 'vue-router';
import { User } from '@/gql/graphql';
import { useUsers } from '@/composables/useUsers';

import { useTables } from '@/composables/useTables';

export default /*@__PURE__*/_defineComponent({
  __name: 'UsersTable',
  setup(__props) {

const toast = useToast()
const { getUsers, getRoles } = useUsers()
const { users, loading, error: errorUsers } = getUsers()
const { roles } = getRoles()


const router = useRouter()
const _query = router.currentRoute.value.query

const {
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows
} = useTables({
  filters: {
    name: {
      value: _query.name ? _query.name : null,
      matchMode: FilterMatchMode.CONTAINS, 
      pretty: "name"
    },
    surname: {
      value: _query.surname ? _query.surname : null,
      matchMode: FilterMatchMode.CONTAINS, 
      pretty: "surname"
    },
    flagActive: {
      value: _query.flagActive ? _query.flagActive : null,
      matchMode: FilterMatchMode.CONTAINS, 
      pretty: "flagActive"
    },
    'role.name': {
      value: _query.role ? _query.role : null,
      matchMode: FilterMatchMode.EQUALS, 
      pretty: "role"
    },
  }
})

// watches
watch(errorUsers, () => {
  if (errorUsers.value) {
    toast.add({ severity: 'error', summary: 'Error loading users', detail: 'Try to log in again or contact administrator.' })
  }
})

const onEditClick = (user: User) => {
  router.push({
    name: 'user-detail',
    params: {
      userId: user.id
    }
  })
}

return (_ctx: any,_cache: any) => {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(loading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ProgressSpinner)
        ]))
      : (_unref(users))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_DataTable, {
              value: _unref(users),
              paginator: true,
              onPage: _cache[0] || (_cache[0] = ($event: any) => (_unref(onPage)($event))),
              onSort: _cache[1] || (_cache[1] = ($event: any) => (_unref(onSort)($event))),
              onFilter: _cache[2] || (_cache[2] = ($event: any) => (_unref(onFilter)())),
              sortField: _unref(defaultSortField),
              sortOrder: _unref(defaultSortOrder),
              class: "p-datatable-presets p-datatable-sm",
              rows: _unref(rows),
              dataKey: "id",
              rowHover: true,
              filters: _unref(tableFilters),
              "onUpdate:filters": _cache[3] || (_cache[3] = ($event: any) => (_isRef(tableFilters) ? (tableFilters).value = $event : null)),
              filterDisplay: "menu",
              loading: _unref(loading),
              paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
              rowsPerPageOptions: _unref(rowsPerPageOptions),
              currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords} entries',
              responsiveLayout: "scroll"
            }, {
              empty: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" No users found. ")
              ])),
              loading: _withCtx(() => [
                _createVNode(_component_ProgressSpinner)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: "name",
                  header: "Name",
                  sortable: "",
                  style: {"min-width":"10rem"}
                }, {
                  body: _withCtx(({data}) => [
                    _createTextVNode(_toDisplayString(data.name), 1)
                  ]),
                  filter: _withCtx(({filterModel}) => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                      class: "p-column-filter",
                      placeholder: "Search by name"
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "surname",
                  header: "Surname",
                  sortable: "",
                  style: {"min-width":"10rem"}
                }, {
                  body: _withCtx(({data}) => [
                    _createTextVNode(_toDisplayString(data.surname), 1)
                  ]),
                  filter: _withCtx(({filterModel}) => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                      class: "p-column-filter",
                      placeholder: "Search by surname"
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "role.name",
                  header: "Role",
                  sortable: "",
                  style: {"min-width":"3rem"}
                }, {
                  body: _withCtx(({data}) => [
                    _createTextVNode(_toDisplayString(data.role.name), 1)
                  ]),
                  filter: _withCtx(({ filterModel }) => [
                    _createVNode(_component_Dropdown, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                      options: _unref(roles),
                      optionValue: "alias",
                      optionLabel: "name",
                      placeholder: "Select roles"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "flagActive",
                  header: "Active",
                  dataType: "boolean",
                  bodyClass: "p-text-center",
                  style: {"min-width":"8rem"}
                }, {
                  body: _withCtx(({data}) => [
                    _createElementVNode("i", {
                      class: _normalizeClass(["pi", {'true-icon pi-check-circle': data.flagActive, 'false-icon pi-times-circle': !data.flagActive}])
                    }, null, 2)
                  ]),
                  filter: _withCtx(({filterModel}) => [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                    _cache[5] || (_cache[5] = _createTextVNode(" active "))
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  headerStyle: "width: 4rem; text-align: center",
                  bodyStyle: "text-align: center; overflow: visible"
                }, {
                  body: _withCtx(({data}) => [
                    _createVNode(_component_Button, {
                      type: "button",
                      icon: "fa-solid fa-pencil",
                      onClick: ($event: any) => (onEditClick(data))
                    }, null, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "sortField", "sortOrder", "rows", "filters", "loading", "rowsPerPageOptions"])
          ]))
        : _createCommentVNode("", true)
  ]))
}
}

})