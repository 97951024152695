import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]

import { toRef } from "vue";
import { useField } from "vee-validate";
import InfoInputGroup from "../InfoInputGroup.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckboxWithValidation',
  props: {
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  fieldClass: {
    type: String,
    required: false,
    default: "",
  },
  hintContent: {
    type: String,
    required: false,
    default: null,
  },
  hintTitle: {
    type: String,
    required: false,
    default: `Hint`,
  },
  change: {
    type: Function,
    required: false,
    default: () => {},
  },
},
  setup(__props) {

const props = __props;

const { errorMessage, value } = useField(toRef(props, "name"));

return (_ctx: any,_cache: any) => {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field", __props.fieldClass])
  }, [
    _createVNode(InfoInputGroup, {
      "hint-content": __props.hintContent,
      "hint-title": __props.hintTitle
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Checkbox, {
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
          name: __props.name,
          label: __props.label,
          binary: true,
          onChange: _cache[1] || (_cache[1] = (e) => __props.change),
          disabled: __props.disabled
        }, null, 8, ["modelValue", "name", "label", "disabled"]),
        _createElementVNode("label", {
          for: __props.name,
          style: { left: '2em'}
        }, _toDisplayString(__props.label), 9, _hoisted_1)
      ]),
      _: 1
    }, 8, ["hint-content", "hint-title"]),
    _createElementVNode("small", {
      id: `${__props.name}-help`,
      class: "p-error"
    }, _toDisplayString(_unref(errorMessage)), 9, _hoisted_2)
  ], 2))
}
}

})