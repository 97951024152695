import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id"]

import { toRef } from 'vue';
import { useField } from 'vee-validate';
import InfoInputGroup from '../InfoInputGroup.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputTextWithValidation',
  props: {
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fieldClass: {
    type: String,
    required: false,
    default: ''
  },
  hintContent: {
    type: String,
    required: false,
    default: null
  },
  hintTitle: {
    type: String,
    required: false,
    default: `Hint`
  },
  keydown: {
    type: Function,
    required: false,
    default: () => {}
  },
  change: {
    type: Function,
    required: false,
    default: () => {}
  },
  size: {
    type: Number,
    required: false,
    default: 20
  },
  minimize: {
    type: Boolean,
    required: false,
    default: false
  }
},
  setup(__props) {

const props = __props;

const { errorMessage, value } = useField(toRef(props, 'name'));

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["field", __props.fieldClass])
  }, [
    _createVNode(InfoInputGroup, {
      "hint-content": __props.hintContent,
      "hint-title": __props.hintTitle,
      "float-label-class": !__props.minimize
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          size: __props.size,
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
          onKeydown: _cache[1] || (_cache[1] = (e) => __props.keydown),
          onChange: _cache[2] || (_cache[2] = (e) => __props.change),
          "aria-describedby": `${__props.name}-help`,
          disabled: __props.disabled,
          class: _normalizeClass({ 'p-invalid': _unref(errorMessage) }),
          placeholder: __props.minimize ? __props.label : '',
          type: __props.type
        }, null, 8, ["size", "modelValue", "aria-describedby", "disabled", "class", "placeholder", "type"]),
        (!__props.minimize)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              for: __props.name
            }, _toDisplayString(__props.label), 9, _hoisted_1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["hint-content", "hint-title", "float-label-class"]),
    _createElementVNode("small", {
      id: `${__props.name}-help`,
      class: "p-error"
    }, _toDisplayString(_unref(errorMessage)), 9, _hoisted_2)
  ], 2))
}
}

})