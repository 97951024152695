import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, withCtx as _withCtx, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, isRef as _isRef } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { "data-cy": "volumesTable__volumeStatus" }
const _hoisted_5 = { class: "action-buttons" }

import { FilterMatchMode } from 'primevue/api'
import { useRouter } from 'vue-router'
import { useVolumes } from '@/composables/useVolumes'
import { useTables } from '@/composables/useTables'


export default /*@__PURE__*/_defineComponent({
  __name: 'VolumesTable',
  props: {
  storageId: String,
},
  setup(__props) {

const props = __props


const { getVolumes, getVolumeStatuses } = useVolumes()
const { volumes } = getVolumes({
  filter: {
    fields: [
      {
        name: "storageId",
        type: "equals",
        value: props.storageId
      }
    ]
  }
})

const { volumeStatuses } = getVolumeStatuses()

const router = useRouter()
const _query = router.currentRoute.value.query
const {
  makeStrong,
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows
} = useTables({
  filters: {
    /*
    id: {
      value: _query.id ? _query.id : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "id"
    },
    */
    name: {
      value: _query.name ? _query.name : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "name"
    },
    volumeStatus: {
      value: _query.volumeStatus ? _query.volumeStatus : null,
      matchMode: FilterMatchMode.EQUALS,
      pretty: "volumeStatus"
    },
    "storage.name": {
      value: _query.storageName ? _query.storageName : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "storageName"
    }
  }
})


const onEditClick = (input: any) => {
  router.push({
    name: 'storage-volume-detail',
    params: {
      volumeMountAlias: input.mountAlias,
      storageId: input.storage.id
    }
  })
}

return (_ctx: any,_cache: any) => {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      class: "p-datatable-sm",
      value: _unref(volumes),
      paginator: true,
      filters: _unref(tableFilters),
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => (_isRef(tableFilters) ? (tableFilters).value = $event : null)),
      ref: "dt",
      loading: true,
      onPage: _cache[1] || (_cache[1] = ($event: any) => (_unref(onPage)($event))),
      onSort: _cache[2] || (_cache[2] = ($event: any) => (_unref(onSort)($event))),
      onFilter: _cache[3] || (_cache[3] = ($event: any) => (_unref(onFilter)($event))),
      filterDisplay: "menu",
      globalFilterFields: ['name'],
      responsiveLayout: "scroll",
      sortField: _unref(defaultSortField),
      sortOrder: _unref(defaultSortOrder),
      paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      rows: _unref(rows),
      rowsPerPageOptions: _unref(rowsPerPageOptions),
      currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} entries"
    }, {
      empty: _withCtx(() => _cache[4] || (_cache[4] = [
        _createTextVNode(" No volumes found. ")
      ])),
      loading: _withCtx(() => [
        (_unref(volumes).length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createVNode(_component_ProgressSpinner)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, " No volumes found. "))
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "name",
          header: "Name",
          filterField: "name",
          filterMatchMode: "contains",
          showFilterMatchModes: false,
          maxConstraints: 1,
          showFilterOperator: false,
          sortable: "",
          style: {"min-width":"10rem"}
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("span", {
              "data-cy": "volumesTable__volumeName",
              innerHTML: _unref(makeStrong)(data.name, 'name')
            }, null, 8, _hoisted_3)
          ]),
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              class: "p-column-filter",
              placeholder: "Search by name"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "volumeStatus",
          header: "Status",
          filterField: "volumeStatus",
          filterMatchMode: "equals",
          showFilterMatchModes: false,
          maxConstraints: 1,
          showFilterOperator: false,
          sortable: "",
          style: {"min-width":"10rem"}
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(data.volumeStatus?.alias), 1)
          ]),
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_Dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              options: _unref(volumeStatuses),
              optionValue: "alias",
              optionLabel: "alias",
              placeholder: "Select status"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "options"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          headerStyle: "width: 8rem; text-align: right",
          bodyStyle: "text-align: right; overflow: visible"
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("span", _hoisted_5, [
              _withDirectives(_createVNode(_component_Button, {
                "data-cy": "volumesTable__detailButton",
                type: "button",
                icon: "fa-solid fa-pencil",
                onClick: ($event: any) => (onEditClick(data))
              }, null, 8, ["onClick"]), [
                [
                  _directive_tooltip,
                  'Volume detail',
                  void 0,
                  { top: true }
                ]
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "filters", "sortField", "sortOrder", "rows", "rowsPerPageOptions"])
  ]))
}
}

})