import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 p-fluid" }
const _hoisted_3 = { class: "flex" }

import VolumeForm from './components/VolumeForm.vue'
import { useStorages } from '@/composables/useStorages';


export default /*@__PURE__*/_defineComponent({
  __name: 'VolumeRegister',
  props: {
  storageId: {
    required: true,
    type: String
  }
},
  setup(__props) {

const props = __props

const { getStorage } = useStorages()
const { storage } = getStorage(props.storageId)



return (_ctx: any,_cache: any) => {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createElementVNode("span", null, [
            _cache[0] || (_cache[0] = _createTextVNode("Register volume into storage ")),
            _createElementVNode("strong", null, _toDisplayString(_unref(storage)?.name), 1)
          ])
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(VolumeForm, {
              storageId: __props.storageId,
              update: false
            }, null, 8, ["storageId"])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})