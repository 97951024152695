import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 p-fluid" }
const _hoisted_3 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_4 = { class: "flex align-items-center justify-content-center" }
const _hoisted_5 = { class: "flex align-items-center justify-content-center" }
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = { class: "w-9" }
const _hoisted_8 = {
  key: 0,
  class: "col-12 p-fluid"
}

import { ref, watch } from 'vue'
import { useConfig } from '@/composables/useConfig'
import { useConfirm } from "primevue/useconfirm";
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { useVolumes } from '@/composables/useVolumes'
import { Volume, VolumeStatusEnum } from '@/gql/graphql'
import AttributesList from '@/components/UI/AttributesList.vue'
import DataBrowser from '@/components/DataBrowser/DataBrowser.vue'
import { ByteSize, useStrings } from '@/composables/helpers/useStrings';



export default /*@__PURE__*/_defineComponent({
  __name: 'VolumeDetail',
  props: {
  volumeMountAlias: {
    required: true,
    type: String
  },
  storageId: {
    required: true,
    type: String
  }
},
  setup(__props) {

const { getConfig } = useConfig()
const config = getConfig()

const props = __props
const { formatByteSize } = useStrings()
const router = useRouter()

const { getVolume, registerUnregisteredVolume, unregisterRegisteredVolume } = useVolumes()
const { volume } = getVolume(props.volumeMountAlias, config?.POLLINTERVAL)

const volumeFreeSpace = ref(0)
watch(volume, (newValue) => {
  if (newValue && newValue.storage?.id !== props.storageId) {
    router.push({
      name: '404'
    })
  }

  if (newValue) {
    try {
      if(typeof newValue.sizeReservedMB === 'number' && typeof newValue.sizeOccupiedMB === 'number')
        volumeFreeSpace.value = ((newValue.sizeReservedMB - newValue.sizeOccupiedMB) / newValue.sizeReservedMB) * 100
    } catch (e) {
      console.log(e)
    }
  }
})

/*
watch(volume, (newValue, oldValue) => {
  chartData.value = {
    labels: ['Occupied space', 'Free space'],
    datasets: [
      {
        data: [newValue.occupiedSpace, newValue.reservedSpace - newValue.occupiedSpace],
        backgroundColor: ["#FFA726", "#66BB6A"],
        hoverBackgroundColor: ["#FFB74D", "#81C784"]
      },
    ]
  }
})

const chartData = ref(null)

const lightOptions = ref({
  plugins: {
    legend: {
      labels: {
        color: '#495057'
      }
    }
  }
})
*/

const onUpdateClick = () => {
  router.push({
    name: 'storage-volume-update',
    params: {
      volumeMountAlias: props.volumeMountAlias,
      storageId: props.storageId
    }
  })
}

const toast = useToast()
const confirm = useConfirm()

const onUnregisterClick = () => {
  confirm.require({
    message: 'Are you sure you want to unregister volume? You can register this volume again.',
    header: 'Unregister volume ' + volume.value.name,
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      onUnregisterRegisteredVolume()
    },
    reject: () => {
      toast.add({ severity: 'info', summary: 'Rejected', detail: 'Do not unregister' });
    }
  })
}

const onUnregisterRegisteredVolume = async () => {
  const res = await unregisterRegisteredVolume(props.volumeMountAlias)

  if (res.success) {
    toast.add({ severity: 'success', summary: 'Success', detail: res.message ?? 'The volume will be unregistered soon', life: 3000 })
  } else {
    toast.add({ severity: 'error', summary: 'Error', detail: res.message ?? 'Error occured while unregistering', life: 3000 })
  }
}

const onRegisterClick = () => {
  confirm.require({
    message: 'Are you sure you want to register volume back?',
    header: 'Register volume ' + volume.value.name,
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      onRegisterUnregisteredVolume()
    },
    reject: () => {
      toast.add({ severity: 'info', summary: 'Rejected', detail: 'Do not unregister' });
    }
  })
}

const onRegisterUnregisteredVolume = async () => {
  const res = await registerUnregisteredVolume(props.volumeMountAlias)

  if (res.success) {
    toast.add({ severity: 'success', summary: 'Success', detail: res.message ?? 'Volume succesfully registred', life: 3000 })
  } else {
    toast.add({ severity: 'error', summary: 'Error', detail: res.message ?? 'Error occured while registering', life: 3000 })
  }
}


const renderAttributes = ref({
  sizeReservedBB:  { label: 'Reserved size',  value: (item: Volume) => formatByteSize(item?.sizeReservedMB, ByteSize.MB, ByteSize.GB) + ' GB' },
  sizeOccupiedGB:  { label: 'Occupied size',  value: (item: Volume) => formatByteSize(item?.sizeOccupiedMB, ByteSize.MB, ByteSize.GB) + ' GB'  },
  storageName: { label: 'Storage', value: (item: Volume) => item?.storage?.name },
  remotePath: { label: 'Remote path', value: (item: Volume) => item?.remotePath },
  mountAlias: { label: 'Mount alias', value: (item: Volume) => item?.mountAlias },
  volumeStatusAlias: { label: 'Status', value: (item: Volume) => item?.volumeStatus?.alias}
})

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(volume)?.name), 1),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createVNode(_component_Button, {
                "data-cy": "volumeDetail__editVolume",
                icon: "fa-solid fa-pen",
                severity: "info",
                "aria-label": "Edit volume",
                onClick: onUpdateClick
              }, null, 512), [
                [
                  _directive_tooltip,
                  'Edit volume',
                  void 0,
                  { bottom: true }
                ]
              ]),
              (_unref(volume) && _unref(volume).volumeStatus?.alias === _unref(VolumeStatusEnum).Registered)
                ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    "data-cy": "volumeDetail__unregisterVolume",
                    icon: "fa-solid fa-plug-circle-xmark",
                    severity: "danger",
                    "aria-label": "Unregister volume",
                    onClick: onUnregisterClick
                  }, null, 512)), [
                    [
                      _directive_tooltip,
                      'Unregister volume',
                      void 0,
                      { bottom: true }
                    ]
                  ])
                : _createCommentVNode("", true),
              (_unref(volume) && _unref(volume).volumeStatus?.alias === _unref(VolumeStatusEnum).Unregistered)
                ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    "data-cy": "volumeDetail__registerVolume",
                    severity: "success",
                    icon: "fa-solid fa-plug",
                    "aria-label": "Register volume",
                    onClick: onRegisterClick
                  }, null, 512)), [
                    [
                      _directive_tooltip,
                      'Register volume',
                      void 0,
                      { bottom: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(AttributesList, {
                "data-cy": "volumeDetail__volumeStatus",
                item: _unref(volume),
                attributes: renderAttributes.value
              }, null, 8, ["item", "attributes"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    (_unref(volume) && _unref(volume).volumeStatus?.alias === _unref(VolumeStatusEnum).Registered)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_Card, null, {
            title: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Data browse ")
            ])),
            content: _withCtx(() => [
              (_unref(volume))
                ? (_openBlock(), _createBlock(DataBrowser, {
                    key: 0,
                    "storage-id": __props.storageId,
                    "volume-mount-alias": __props.volumeMountAlias
                  }, null, 8, ["storage-id", "volume-mount-alias"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})