<template>
  <div class="layout-footer flex justify-content-end flex-wrap p-2">
    <div class="flex align-items-center justify-content-center">
    <span>
      <span class="footer-text" style="margin-right: 5px">HoneyDew v{{uiVersion}}, api v{{apiVersion}}</span>
        <a href="https://www.melowntech.com/">© {{year}} Melown Technologies SE</a>
        <span> part of </span>
        <a href="https://www.hexagongeospatial.com/">Hexagon AB.</a> All rights reserved.
      </span>
    </div>
	</div>
</template>

<script lang="ts" setup>
import moment from 'moment'
import { Ref, ref } from 'vue'
import { useVersion } from '@/composables/useVersion';

const { getApiVersion, getUIVersion } = useVersion()
const { apiVersion } = getApiVersion()
const { uiVersion } = getUIVersion()

const year: Ref<string> = ref(moment().format('YYYY'))
</script>

<style lang="scss" scoped>
.layout-footer {
  padding-right: 35px !important;
}
</style>
