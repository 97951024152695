import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-footer flex justify-content-end flex-wrap p-2" }
const _hoisted_2 = { class: "flex align-items-center justify-content-center" }
const _hoisted_3 = {
  class: "footer-text",
  style: {"margin-right":"5px"}
}
const _hoisted_4 = { href: "https://www.melowntech.com/" }

import moment from 'moment'
import { Ref, ref } from 'vue'
import { useVersion } from '@/composables/useVersion';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppFooter',
  setup(__props) {

const { getApiVersion, getUIVersion } = useVersion()
const { apiVersion } = getApiVersion()
const { uiVersion } = getUIVersion()

const year: Ref<string> = ref(moment().format('YYYY'))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, [
        _createElementVNode("span", _hoisted_3, "HoneyDew v" + _toDisplayString(_unref(uiVersion)) + ", api v" + _toDisplayString(_unref(apiVersion)), 1),
        _createElementVNode("a", _hoisted_4, "© " + _toDisplayString(year.value) + " Melown Technologies SE", 1),
        _cache[0] || (_cache[0] = _createElementVNode("span", null, " part of ", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("a", { href: "https://www.hexagongeospatial.com/" }, "Hexagon AB.", -1)),
        _cache[2] || (_cache[2] = _createTextVNode(" All rights reserved. "))
      ])
    ])
  ]))
}
}

})