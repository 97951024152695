import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, withCtx as _withCtx, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "action-buttons" }

import { watch } from 'vue';
import { usePresets } from '@/composables/usePresets';
import { useTables } from '@/composables/useTables';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { FilterMatchMode } from 'primevue/api';
import { Preset } from '@/gql/graphql';


export default /*@__PURE__*/_defineComponent({
  __name: 'PresetsTable',
  setup(__props) {

const toast = useToast()
const { getPresets, getPresetTypes } = usePresets()
const { presets, loading, error: errorPresets } = getPresets()
const { presetTypes } = getPresetTypes()

const router = useRouter()
const _query = router.currentRoute.value.query

const {
  makeStrong,
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows
} = useTables({
  filters: {
    presetTypeAlias: {
      value: _query.typeAlias ? _query.typeAlias : null,
      matchMode: FilterMatchMode.CONTAINS, 
      pretty: "typeAlias"
    },
    key: {
      value: _query.key ? _query.key : null,
      matchMode: FilterMatchMode.CONTAINS, 
      pretty: "key"
    },
    value: {
      value: _query.value ? _query.value : null,
      matchMode: FilterMatchMode.CONTAINS, 
      pretty: "value"
    }
  }
})

const onEditClick = (preset: Preset) => {
  router.push({
    name: 'preset-detail',
    params: {
      presetKey: preset.key
    }
  })
}

// watches
watch(errorPresets, () => {
  if (errorPresets.value) {
    toast.add({ severity: 'error', summary: 'Error loading presets', detail: 'Try to log in again or contact administrator.' })
  }
})

return (_ctx: any,_cache: any) => {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(loading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ProgressSpinner)
        ]))
      : (_unref(presets))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_DataTable, {
              value: _unref(presets),
              paginator: true,
              onPage: _cache[0] || (_cache[0] = ($event: any) => (_unref(onPage)($event))),
              onSort: _cache[1] || (_cache[1] = ($event: any) => (_unref(onSort)($event))),
              onFilter: _cache[2] || (_cache[2] = ($event: any) => (_unref(onFilter)($event))),
              sortField: _unref(defaultSortField),
              sortOrder: _unref(defaultSortOrder),
              class: "p-datatable-presets p-datatable-sm",
              rows: _unref(rows),
              dataKey: "id",
              rowHover: true,
              filters: _unref(tableFilters),
              "onUpdate:filters": _cache[3] || (_cache[3] = ($event: any) => (_isRef(tableFilters) ? (tableFilters).value = $event : null)),
              filterDisplay: "menu",
              loading: _unref(loading),
              paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
              rowsPerPageOptions: _unref(rowsPerPageOptions),
              currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords} entries',
              responsiveLayout: "scroll"
            }, {
              empty: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" No presets found. ")
              ])),
              loading: _withCtx(() => [
                _createVNode(_component_ProgressSpinner)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: "key",
                  header: "Unique preset key",
                  filterField: "key",
                  filterMatchMode: "contains",
                  showFilterMatchModes: false,
                  maxConstraints: 1,
                  showFilterOperator: false,
                  sortable: "",
                  style: {"min-width":"10rem"}
                }, {
                  body: _withCtx(({data}) => [
                    _createElementVNode("span", {
                      innerHTML: _unref(makeStrong)(data.key, 'key')
                    }, null, 8, _hoisted_4)
                  ]),
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                      onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
                      class: "p-column-filter",
                      placeholder: "Search by key"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "presetTypeAlias",
                  header: "Preset type",
                  filterField: "presetTypeAlias",
                  filterMatchMode: "contains",
                  showFilterMatchModes: false,
                  maxConstraints: 1,
                  showFilterOperator: false,
                  sortable: "",
                  style: {"min-width":"10rem"}
                }, {
                  body: _withCtx(({data}) => [
                    _createElementVNode("span", null, _toDisplayString(data.presetType?.name), 1)
                  ]),
                  filter: _withCtx(({filterModel, filterCallback}) => [
                    _createVNode(_component_Dropdown, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                      "scroll-height": "400px",
                      options: _unref(presetTypes),
                      onChange: ($event: any) => (filterCallback()),
                      optionValue: "alias",
                      optionLabel: "name",
                      placeholder: "Select preset type"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  headerStyle: "width: 8rem; text-align: right",
                  bodyStyle: "text-align: right; overflow: visible"
                }, {
                  body: _withCtx(({data}) => [
                    _createElementVNode("span", _hoisted_5, [
                      _withDirectives(_createVNode(_component_Button, {
                        type: "button",
                        icon: "fa-solid fa-pencil",
                        onClick: ($event: any) => (onEditClick(data))
                      }, null, 8, ["onClick"]), [
                        [
                          _directive_tooltip,
                          'Preset detail',
                          void 0,
                          { top: true }
                        ]
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "sortField", "sortOrder", "rows", "filters", "loading", "rowsPerPageOptions"])
          ]))
        : _createCommentVNode("", true)
  ]))
}
}

})