import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, withCtx as _withCtx, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, isRef as _isRef } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["innerHTML", "onClick"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { "data-cy": "projectTable__projectStatus" }
const _hoisted_6 = { class: "action-buttons" }

import { watch } from 'vue';
import { useProject } from '@/composables/useProject';
import { useTables } from '@/composables/useTables';
import { useRouter } from 'vue-router';
import { FilterMatchMode } from 'primevue/api';
import { Project } from '@/gql/graphql';
import { useConfig } from '@/composables/useConfig';
import { useToast } from 'primevue/usetoast';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectTable',
  setup(__props) {

const toast = useToast()
const { getConfig } = useConfig()
const config = getConfig()

const { getProjects } = useProject()
const { projects, loading, error: errorProjects } = getProjects(undefined, config?.POLLINTERVAL)
watch(errorProjects, () => {
  if (errorProjects.value) {
    toast.add({ severity: 'error', summary: 'Error loading projects', detail: 'Try to log in again or contact administrator.' })
  }
})

const router = useRouter()
const _query = router.currentRoute.value.query

const {
  makeStrong,
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows,
  lazy
} = useTables({
  filters: {
    name: {
      value: _query.name ? _query.name : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "name"
    },
    externalUri: {
      value: _query.externalUri ? _query.externalUri : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "externalUri"
    },
    archived: {
      value: _query.archived ? _query.archived : null,
      matchMode: FilterMatchMode.EQUALS,
      pretty: "archived"
    },
  }
})

const onEditClick = (project: Project) => {
  router.push({
    name: 'project-detail',
    params: {
      projectId: project.id
    }
  })
}

return (_ctx: any,_cache: any) => {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      value: _unref(projects),
      paginator: true,
      onPage: _cache[0] || (_cache[0] = ($event: any) => (_unref(onPage)($event))),
      onSort: _cache[1] || (_cache[1] = ($event: any) => (_unref(onSort)($event))),
      onFilter: _cache[2] || (_cache[2] = ($event: any) => (_unref(onFilter)())),
      sortField: _unref(defaultSortField),
      sortOrder: _unref(defaultSortOrder),
      lazy: _unref(lazy),
      class: "p-datatable-sm",
      rows: _unref(rows),
      dataKey: "id",
      rowHover: true,
      filters: _unref(tableFilters),
      "onUpdate:filters": _cache[3] || (_cache[3] = ($event: any) => (_isRef(tableFilters) ? (tableFilters).value = $event : null)),
      filterDisplay: "menu",
      loading: _unref(loading),
      paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      rowsPerPageOptions: _unref(rowsPerPageOptions),
      currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords} entries',
      responsiveLayout: "scroll"
    }, {
      empty: _withCtx(() => _cache[4] || (_cache[4] = [
        _createTextVNode(" No projects found. ")
      ])),
      loading: _withCtx(() => [
        (_unref(projects).length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createVNode(_component_ProgressSpinner)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, " No targets found. "))
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "name",
          header: "Name",
          filterField: "name",
          filterMatchMode: "contains",
          showFilterMatchModes: false,
          maxConstraints: 1,
          showFilterOperator: false,
          sortable: "",
          style: {"min-width":"10rem"}
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("span", {
              innerHTML: _unref(makeStrong)(data.name, 'name'),
              onClick: ($event: any) => (onEditClick(data)),
              class: "mlwn-pointer-cursor mlwn-hyperlink",
              "data-cy": "projectTable__projectName"
            }, null, 8, _hoisted_3)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              class: "p-column-filter",
              placeholder: "Search by name"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "externalUri",
          header: "External link",
          filterField: "externalUri",
          filterMatchMode: "contains",
          showFilterMatchModes: false,
          maxConstraints: 1,
          showFilterOperator: false,
          sortable: "",
          style: {"min-width":"10rem"}
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("a", {
              href: _unref(makeStrong)(data.externalUri, 'externalUri'),
              target: "_blank",
              "data-cy": "projectTable__externalLink"
            }, _toDisplayString(data.externalUri), 9, _hoisted_4)
          ]),
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              class: "p-column-filter",
              placeholder: "Search by name"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "archived",
          header: "Status",
          filterField: "archived",
          filterMatchMode: "like",
          showFilterMatchModes: false,
          maxConstraints: 1,
          showFilterOperator: false,
          sortable: "",
          style: {"min-width":"10rem"}
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("span", _hoisted_5, _toDisplayString(data.archived ? 'Archived' : 'Active'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          headerStyle: "width: 8rem; text-align: right",
          bodyStyle: "text-align: right; overflow: visible"
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("span", _hoisted_6, [
              _withDirectives(_createVNode(_component_Button, {
                type: "button",
                icon: "fa-solid fa-list",
                onClick: ($event: any) => (onEditClick(data)),
                "data-cy": "projectTable__detailButton"
              }, null, 8, ["onClick"]), [
                [
                  _directive_tooltip,
                  'Project detail',
                  void 0,
                  { top: true }
                ]
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "sortField", "sortOrder", "lazy", "rows", "filters", "loading", "rowsPerPageOptions"])
  ]))
}
}

})