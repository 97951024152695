<template>
  <div class="field" :class="fieldClass">
    <InfoInputGroup :hint-content="hintContent" :hint-title="hintTitle">
      <InputNumber
        v-model="value"
        :aria-describedby="`${name}-help`"
        :disabled="disabled"
        :class="{ 'p-invalid': errorMessage }"
        :minFractionDigits="minFractionDigits ?? undefined"
        :maxFractionDigits="maxFractionDigits ?? undefined"
        :min="min ?? undefined"
        :max="max ?? undefined"
        :type="type">
      </InputNumber>
      <label :for="name">{{ label }}</label>
    </InfoInputGroup>
    <small :id="`${name}-help`" class="p-error">{{ errorMessage }}</small>
    <small :id="`${name}-help`" class="p-error">{{ errorMessage }}</small>
  </div>
</template>

<script setup lang="ts">
import { toRef } from 'vue';
import { useField } from 'vee-validate';
import InfoInputGroup from '../InfoInputGroup.vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fieldClass: {
    type: String,
    required: false,
    default: ''
  },
  hintContent: {
    type: String,
    required: false,
    default: null
  },
  hintTitle: {
    type: String,
    required: false,
    default: `Hint`
  },
  minFractionDigits: {
    type: Number,
    required: false
  },
  maxFractionDigits: {
    type: Number,
    required: false
  },
  min: {
    type: Number,
    required: false
  },
  max: {
    type: Number,
    required: false
  }
});

const { errorMessage, value } = useField(toRef(props, 'name'));
</script>