<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>
          <div class="flex justify-content-between flex-wrap">
            <div class="flex align-items-center justify-content-center">Clusters</div>
            <div class="flex align-items-center justify-content-center">
              <router-link :to="{
                name: 'cluster-create'
              }" v-tooltip.bottom="'Create new cluster'">
                <Button data-cy="cluserList__createCluster" type="button" icon="fa-solid fa-plus" label="Create cluster"></Button>
              </router-link>
            </div>
          </div>
        </template>
        <template #content>
          <DataTable :value="clusters" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)"
                     :sortField="defaultSortField" :sortOrder="defaultSortOrder" :lazy="lazy" class="p-datatable-sm" :rows="rows"
                     dataKey="id" :rowHover="true" v-model:filters="tableFilters" filterDisplay="menu"
                     :loading="loadingClusters"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     :rowsPerPageOptions="rowsPerPageOptions"
                     :currentPageReportTemplate="'Showing {first} to {last} of {totalRecords} entries'" responsiveLayout="scroll">
            <template #empty>
              No cluster found.
            </template>
            <template #loading>
              <i class="fa-solid fa-spinner fa-spin"></i>
            </template>

            <Column field="id" header="Id" :showFilterMatchModes="false" :maxConstraints="1" :showFilterOperator="false"
                    sortable style="min-width: 10rem">
              <template #body="{ data }">
                <span :data-cy="'clusterList__clusterId'">{{ data.id }}</span>

                <ErrorDialog v-if="data.error && data.error !== 'null'" :error="data.error"></ErrorDialog>
              </template>
            </Column>
            <Column field="dmCollocationId" header="Collocation" :showFilterMatchModes="false" :maxConstraints="1"
              :showFilterOperator="false" sortable style="min-width: 10rem">
              <template #body="{ data }">
                <span :data-cy="'clusterList__clusterCollocation'">{{ data.dmCollocationId }}</span>
              </template>
            </Column>
            <Column field="status.alias" header="Status" :showFilterMatchModes="false" :maxConstraints="1"
              :showFilterOperator="false" sortable style="min-width: 10rem">
              <template #body="{ data }">
                <span :data-cy="'clusterList__clusterStatus'">{{ data.status.name }}</span>
              </template>
            </Column>
            <Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: right; overflow: visible">
              <template #body="{ data }">
                <span class="action-buttons">
                  <Button data-cy="clusterList__clusterStopButton" type="button" severity="danger" icon="pi pi-power-off" size="small"
                          @click="onStopClick(data.id)" v-tooltip.top="'Stop cluster'" label="Stop"
                          v-if="data.status.alias === 'Running'">
                  </Button>
                </span>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watch } from 'vue';
import { useTables } from '@/composables/useTables';
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import { useClusters } from '@/composables/useClusters'
import ErrorDialog from '@/components/UI/ErrorDialog.vue'

const { getClusters, stopClusterService } = useClusters()
const {
  data: clusters,
  loading: loadingClusters,
  error: errorClusters,
} = getClusters()

const toast = useToast()

const {
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows,
  lazy
} = useTables({

  filters: {
    global: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "global"
    },
    id: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "id"
    },
    dmCollocationId: {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
      pretty: "dmCollocationId"
    }
  }
})

// watches

watch(errorClusters, () => {
  if (errorClusters.value) {
    toast.add({ severity: 'error', summary: 'Error loading clusters', detail: 'Try to log in again or contact administrator.' })
  }
})

/*
const onRestartClick = async (id: number) => {
  const { success } = await restartCdnOriginService(id)
  if (success) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully restarted service', life: 3000 })
  } else {
    toast.add({ severity: 'warn', summary: 'Error', detail: 'Cannot restart service.', life: 3000 })
  }
}
*/

const onStopClick = async (id: string) => {
  const { success } = await stopClusterService(id)
  if (success) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully stopped service', life: 3000 })
  } else {
    toast.add({ severity: 'warn', summary: 'Error', detail: 'Cannot stop service.', life: 3000 })
  }
}

</script>

<style>
.center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
