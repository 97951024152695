import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mlwn-multiple-input-list-with-validation" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_4 = { class: "flex justify-content-start" }
const _hoisted_5 = {
  key: 0,
  class: "save-preset"
}
const _hoisted_6 = { class: "flex justify-content-end add-button" }

import { TrajectoryRule } from '@/helpers/types/PresetTypes';
import { useFieldArray } from 'vee-validate';
import { toRef, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ListWithValidation',
  props: {
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  maxItems: {
    type: Number,
    default: -1,
    required: false
  },
  defaultRowValue: {
    type: Function,
    required: false,
    default: () => { return { } }
  },
  disableRowRemove: {
    type: Boolean,
    required: false,
    default: false
  },
  savePreset: {
    type: Function,
    required: false,
    default: () => { return { } }
  }, 
  savePresetEnable: {
    type: Boolean,
    required: false,
    default: false
  },
  minimize: {
    type: Boolean,
    required: false,
    default: false
  }
},
  setup(__props, { expose: __expose }) {

const props = __props

const { remove, push, fields } = useFieldArray(toRef(props, 'name'))

const addRow = () => {
  if (props.maxItems === -1 ) {
    push(props.defaultRowValue()) 
  }
}
const removeRow = (index: number) => {
  remove(index)
}

const presetName = ref('')
const saveLabelVisible = ref(false)

const onShowPreset = () => {
  saveLabelVisible.value = !saveLabelVisible.value
  if (saveLabelVisible.value) {
    presetName.value = ''
  }
}

const onSave = (name: string) => {
  if (name.length > 0) {    
    props.savePreset(name)
    onShowPreset()
  }
}


const fillData = (data: any) => {
  const tr = fields.value
  data.forEach((element:any) => { 
    let i = tr.findIndex((item: TrajectoryRule) => item.value.name === element.key)
    if(i >= 0)
      tr[i].value.rule = element.value
  })
}

__expose({
  fillData,
})


return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.label && __props.label.length > 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(__props.label), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fields), (field, idx) => {
      return (_openBlock(), _createElementBlock("fieldset", {
        class: "mlwn-multiple-input-list-with-validation-row",
        key: field.key
      }, [
        _renderSlot(_ctx.$slots, "form", {
          field: field,
          fieldName: `${__props.name}[${idx}]`,
          index: idx,
          removeRow: removeRow
        }),
        (!__props.disableRowRemove)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              text: "",
              class: _normalizeClass({'mlwn-remove-item-button': !__props.minimize, 'mlwn-remove-item-button-minimize': __props.minimize}),
              severity: "danger",
              icon: "fa-solid fa-trash",
              onClick: ($event: any) => (removeRow(idx))
            }, null, 8, ["class", "onClick"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _renderSlot(_ctx.$slots, "footer", {
      addItem: addRow,
      class: "mlwn-multiple-input-list-with-validation-footer"
    }, () => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (__props.savePresetEnable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  (_unref(fields).length > 0 && !saveLabelVisible.value)
                    ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        severity: "secondary",
                        icon: "fa-solid fa-save",
                        class: "button-margin",
                        onClick: onShowPreset
                      }, null, 512)), [
                        [
                          _directive_tooltip,
                          'Save preset',
                          void 0,
                          { left: true }
                        ]
                      ])
                    : _createCommentVNode("", true),
                  (saveLabelVisible.value && _unref(fields).length > 0)
                    ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                        key: 1,
                        severity: "secondary",
                        icon: "fa-solid fa-eye-slash",
                        onClick: onShowPreset
                      }, null, 512)), [
                        [
                          _directive_tooltip,
                          'Hide',
                          void 0,
                          { left: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                  (saveLabelVisible.value)
                    ? (_openBlock(), _createBlock(_component_InputText, {
                        key: 0,
                        modelValue: presetName.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((presetName).value = $event)),
                        placeholder: "New preset name",
                        size: 30,
                        class: "input"
                      }, null, 8, ["modelValue"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                  (saveLabelVisible.value && _unref(fields).length > 0)
                    ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        icon: "fa-solid fa-save",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (onSave(presetName.value)))
                      }, null, 512)), [
                        [
                          _directive_tooltip,
                          'Save preset',
                          void 0,
                          { left: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (__props.maxItems === -1 || _unref(fields).length < __props.maxItems)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                icon: "fa-solid fa-plus",
                "aria-label": "Add",
                onClick: addRow,
                class: _normalizeClass({'addRowButton': _unref(fields).length == 0})
              }, null, 8, ["class"])), [
                [
                  _directive_tooltip,
                  'Add item',
                  void 0,
                  { bottom: true }
                ]
              ])
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})