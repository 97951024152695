import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "list-none p-0 m-0" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  style: {"margin-left":"10px"}
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AttributesList',
  props: {
  item: {
    required: true,
    type: Object,
  },
  attributes: {
    required: true,
    type: Object,
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(__props.attributes), (key) => {
      return (_openBlock(), _createElementBlock("li", {
        key: key,
        class: "flex align-items-center surface-border flex-wrap"
      }, [
        _createElementVNode("span", {
          class: "text-500 font-medium",
          style: _normalizeStyle({display: __props.attributes[key]?.hidden ? 'none' : ''})
        }, _toDisplayString(__props.attributes[key]?.label ?? key) + ": ", 5),
        _createElementVNode("span", {
          class: "text-900",
          style: _normalizeStyle({display: __props.attributes[key]?.hidden ? 'none' : ''})
        }, [
          (__props.attributes[key]?.html)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                innerHTML: typeof __props.attributes[key]?.value === 'function' ? __props.attributes[key]?.value(__props.item) : __props.item[key]
              }, null, 8, _hoisted_2))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(typeof __props.attributes[key]?.value === 'function' ? __props.attributes[key]?.value(__props.item) : __props.item[key]), 1))
        ], 4)
      ]))
    }), 128))
  ]))
}
}

})