<template>
  <div class="grid">
		<div class="col-12">
			<Card>
				<template #title>
					Update scene: <strong>{{ scene?.name }}</strong>
				</template>
				<template #content>
          <Message v-if="scene && scene.status.alias !== SceneStatusAlias.New" :closable="false" severity="warn">
            The scene has been ingested. Changes to the scene configuration can no longer be made.
          </Message>
          <Message v-if="scene && scene.populationType.alias !== PopulationTypeAlias.Ingest" :closable="false" severity="warn">
            This scene was created from other scene, with population type {{ scene.populationType.name }}. Update will be implemented in feature versions.
          </Message>
          <Steps :model="items" :activeIndex="activeIndex" :readonly="(scene && scene.statusAlias === SceneStatusAlias.New && scene.populationType.alias === PopulationTypeAlias.Ingest)"/>
          <Divider type="dotted"/>
          <router-view v-if="scene" v-slot="{Component}" :formData="formData" :update="true" :scene="scene" @prevPage="prevPage($event)" @onCancel="onCancel($event)"  @next="nextStep" @complete="complete">
            <component :is="Component"/>
          </router-view>
				</template>
			</Card>
		</div>
	</div>  
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { PopulationTypeAlias } from '@/gql/graphql';

import { useScenes } from '@/composables/useScenes';

const { updateScene, getScene } = useScenes()

const props = defineProps({
  sceneId: {
    required: true,
    type: String
  },
  projectId: {
    required: true,
    type: String
  }
})

const { scene } = getScene(props.sceneId)

const router = useRouter();

const formData = scene;

const items = [
  { label: 'Scene details', routeName: 'StepOneEdit', step: 1, commandBack: () => { }, command: () => { router.push({ name: 'SensorDefinitionsEdit' })}, to: { name: 'StepOneEdit'} },
  { label: 'Sensor definitions', routeName: 'SensorDefinitionsEdit', step: 2, commandBack: () => { router.push({ name: 'StepOneEdit' })}, command: () => { router.push({ name: 'InputConfigurationsEdit' }) }, to: { name: 'SensorDefinitionsEdit'} },
  { label: 'Input Configurations', routeName: 'InputConfigurationsEdit', step: 3, commandBack: () => { router.push({ name: 'SensorDefinitionsEdit' })}, command: () => { router.push({ name: 'StepOverviewEdit' }) }, to: { name: 'InputConfigurationsEdit'} },
  { label: 'Review & Submit', routeName: 'StepOverviewEdit', step: 4, commandBack: () => { router.push({ name: 'InputConfigurationsEdit' })}, command: () => { }, to: { name: 'StepOverviewEdit'}}
];

const activeIndex = computed(() => items.findIndex(item => router?.currentRoute?.value?.name === item.routeName));

function nextStep(data: any, fromRouteName: String, populationConfig = true, inputVolumesConfiguration = false) {

  if (populationConfig) {
    formData.value.populationConfig = { ...formData.value.populationConfig, ...data }
  } else {
    formData.value = { ...formData.value, ...data }
  }

  activeIndex.value++;
  items.find(item => item.routeName === fromRouteName)?.command()
}

function complete(data: any) {
  onUpdateScene(JSON.parse(JSON.stringify({
    id: props.sceneId,
    name: data?.name,
    notes: data?.notes,
    ingestVadstenaVersion: data?.ingestVadstenaVersion,
    sceneStatusAlias: SceneStatusAlias.New,
    populationConfig: data?.populationConfig
  })))
}

import { useToast } from 'primevue/usetoast';
import { Scene, SceneStatusAlias } from '@/gql/graphql';
const toast = useToast()

const onUpdateScene = async (scene: Scene) => {
  try {
    const res = await updateScene(scene)
    
    if (res.success) {
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully created', life: 3000 })
      window.localStorage.removeItem('mlwn-scene-form')
      router.push({
        name: 'project-scene-detail',
        params: {
          projectId: props.projectId,
          sceneId: res?.payload?.scene?.id
        }
      })
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating', life: 3000})
    }
  } catch (err: any) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating. ' + err?.message, life: 3000})
    console.error(err)
  }
}

const prevPage = (fromRouteName: String) => {
  activeIndex.value--;
  items.find(item => item.routeName === fromRouteName)?.commandBack()
}

const onCancel = () => {
  router.push({
    name: 'project-scene-detail',
    params: {
      projectId: props.projectId,
      sceneId: props.sceneId
    }
  })
}
</script>
