import { defineComponent as _defineComponent } from 'vue'
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'HintDialog',
  props: {
  hint: {
    type: String,
    required: false,
    default: `something important `
  },
  title: {
    type: String,
    required: false,
    default: `Hint`
  },
  showButton: {
    type: Boolean,
  }
},
  setup(__props) {

const props = __props

const hint = ref(props.hint)
const title = ref(props.title)
const visible = ref(false)

return (_ctx: any,_cache: any) => {
  const _component_MdPreview = _resolveComponent("MdPreview")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("span", null, [
    _withDirectives(_createElementVNode("i", {
      class: "fa-solid fa-question mlwn-hint-icon",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (visible.value = true))
    }, null, 512), [
      [
        _directive_tooltip,
        'hint',
        void 0,
        { bottom: true }
      ]
    ]),
    _createVNode(_component_Dialog, {
      class: "mlwn-hint-dialog",
      visible: visible.value,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((visible).value = $event)),
      modal: "",
      header: title.value,
      style: { width: '50vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_MdPreview, {
          modelValue: hint.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((hint).value = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ]))
}
}

})