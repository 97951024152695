import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 p-fluid" }

import VolumeForm from './components/VolumeForm.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'VolumeUpdate',
  props: {
  storageId: {
    required: true,
    type: String
  },
  volumeMountAlias: {
    required: true,
    type: String
  }
},
  setup(__props) {

const props = __props

return (_ctx: any,_cache: any) => {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", null, "Update volume", -1)
        ])),
        content: _withCtx(() => [
          _createVNode(VolumeForm, {
            storageId: props.storageId,
            volumeMountAlias: props.volumeMountAlias,
            update: true
          }, null, 8, ["storageId", "volumeMountAlias"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})