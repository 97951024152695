import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex gap-1" }
const _hoisted_2 = { class: "flex-1 flex flex-column align-items-center justify-content-center" }
const _hoisted_3 = { class: "flex-1 flex flex-column align-items-center justify-content-center" }
const _hoisted_4 = { class: "flex justify-content-left flex-wrap gap-1" }
const _hoisted_5 = { class: "flex justify-content-left flex-wrap gap-1 mt-3" }
const _hoisted_6 = { class: "flex justify-content-left flex-wrap gap-1" }
const _hoisted_7 = {
  key: 0,
  class: "error"
}

import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LayerFilter',
  props: {
  layer: {
    type: String,
    required: true
  },
  visible: {
    type: Boolean,
    default: false,
    required: true
  },
  update: {
    required: true
  },
  data: {
    type: Object,
    required: true
  }
},
  setup(__props) {


const props = __props

const onChange = (event: any) => {
  sceneData.value.key = event.value.key
  sceneData.value.expression += '[' + event.value.key + '] '
}

const onChangeValue = (event: any) => {
  const value = event.originalEvent.target.textContent
  if (!isNaN(value)) {
    sceneData.value.expression += value + ' '
  }
  else {
    sceneData.value.expression += '\'' + value + '\'' + ' '
  }
}

const click = (value: any) => {
  if (value === 'Clear') {
    sceneData.value.expression = ''
  }
  else
    sceneData.value.expression += value + ' '
}

const onDialogOK = () => {
  if (sceneData.value.expression.toUpperCase().indexOf('AND') > -1 && sceneData.value.expression.toUpperCase().indexOf('OR') > -1) {
    sceneData.value.error = 'Simultaneous use of AND and OR expressions is not allowed'
    return
  }
  sceneData.value.error = ''
  setExpressionString()
  sceneData.value.visible = false
  sceneData.value.file = ''
  if (sceneData.value.expression === '') {
    sceneData.value.data._filter.condition = null
  }
  sceneData.value.update(true)
}

const onDialogClose = () => {
  sceneData.value.visible = false
  sceneData.value.file = ''
  sceneData.value.update(false)
}

const sceneData: any = ref({
  zoom: 10,
  layer: props.layer,
  visible: props.visible,
  update: props.update,
  data: props.data,
  keys: [],
  value: null,
  expression: '',
  error: ''
})

for (const key of Object.keys(sceneData.value.data)) {
  if (key === '_filter') continue
  sceneData.value.keys.push({
    key: key
  })
}

const parseExpression = (expression: string, operator: string) => {
  const parts = expression.split(operator)
  if (parts.length == 2) {
    const key = parts[0].trim().replace('[', '').replace(']', '')
    const value = parts[1].trim().indexOf('\'') > -1 ? parts[1].trim().replaceAll('\'', '') : Number(parts[1].trim())
    return {
      key: key,
      value: value,
      operator: operator
    }
  } else return null
}

const setExpressionString = () => {
  const filter = sceneData.value.data._filter
  let expression = sceneData.value.expression
  expression = expression.replace('or', 'OR').replace('Or', 'OR').replace('and', 'AND').replace('And', 'AND').replace('like', 'LIKE').replace('Like', 'LIKE').replace('regex', 'REGEX').replace('Regex', 'REGEX')
  const condition: any = []
  let orIndex = 0
  for (let partOr of expression.split('OR')) {
    partOr = partOr.trim()
    condition[orIndex] = []
    let andIndex = 0
    for (let partAnd of partOr.split('AND')) {
      partAnd = partAnd.trim()
      condition[orIndex][andIndex] = []
      for (const operator of ['=', '!=', '<', '>', '>=', '<=', 'REGEX']) {
        const result = parseExpression(partAnd, operator)
        if (result) condition[orIndex][andIndex] = result
      }
      andIndex++
    }
    orIndex++
  }
  filter.condition = condition
}

const getExpressionString = () => {
  const filter = sceneData.value.data._filter
  if (filter) {
    const condition = filter.condition
    let text = ''
    if (condition) {
      let orIndex = 0
      for (const partOr of condition) {
        let andIndex = 0
        if (orIndex > 0) {
          text += ' OR '
        }
        for (const partAnd of partOr) {
          if (!partAnd.key)
            continue
          if (andIndex > 0) {
            text += ' AND '
          }
          text += '[' + partAnd.key + '] ' + partAnd.operator + ' ' + (isNaN(partAnd.value) ? '\'' + partAnd.value + '\'' : partAnd.value)
          andIndex++
        }
        orIndex++
      }
    }
    if (text === '') {
      filter.condition = null
    }
    sceneData.value.expression = text
  }
}
getExpressionString()


return (_ctx: any,_cache: any) => {
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(_component_Dialog, {
      visible: sceneData.value.visible,
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => ((sceneData.value.visible) = $event)),
      header: sceneData.value.layer,
      onHide: onDialogClose,
      style: { width: '700px' },
      position: 'center',
      modal: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[16] || (_cache[16] = _createElementVNode("strong", null, "Fields", -1)),
            _createVNode(_component_Listbox, {
              modelValue: sceneData.value.key,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((sceneData.value.key) = $event)),
              options: sceneData.value.keys,
              optionLabel: "key",
              onChange: _cache[1] || (_cache[1] = ($event: any) => (onChange($event))),
              listStyle: "max-height:290px",
              style: { width: '100%', height: '300px' }
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _cache[17] || (_cache[17] = _createElementVNode("strong", null, "Values", -1)),
            _createVNode(_component_Listbox, {
              modelValue: sceneData.value.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((sceneData.value.value) = $event)),
              options: sceneData.value.data[sceneData.value.key],
              onChange: _cache[3] || (_cache[3] = ($event: any) => (onChangeValue($event))),
              optionLabel: "",
              listStyle: "max-height:290px",
              style: { width: '100%', height: '300px' }
            }, null, 8, ["modelValue", "options"])
          ])
        ]),
        _cache[28] || (_cache[28] = _createElementVNode("h5", null, "Operators", -1)),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Button, {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (click('='))),
              title: "e.g. [DirId] = 2"
            }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("=")
              ])),
              _: 1
            }),
            _createVNode(_component_Button, {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (click('!='))),
              title: "e.g. [DirId] != 2"
            }, {
              default: _withCtx(() => _cache[19] || (_cache[19] = [
                _createTextVNode("!=")
              ])),
              _: 1
            }),
            _createVNode(_component_Button, {
              onClick: _cache[6] || (_cache[6] = ($event: any) => (click('<'))),
              title: "e.g. [DirId] < 2"
            }, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode("<")
              ])),
              _: 1
            }),
            _createVNode(_component_Button, {
              onClick: _cache[7] || (_cache[7] = ($event: any) => (click('>'))),
              title: "e.g. [DirId] > 2"
            }, {
              default: _withCtx(() => _cache[21] || (_cache[21] = [
                _createTextVNode(">")
              ])),
              _: 1
            }),
            _createVNode(_component_Button, {
              onClick: _cache[8] || (_cache[8] = ($event: any) => (click('<='))),
              title: "e.g. [DirId] <= 2"
            }, {
              default: _withCtx(() => _cache[22] || (_cache[22] = [
                _createTextVNode("<=")
              ])),
              _: 1
            }),
            _createVNode(_component_Button, {
              onClick: _cache[9] || (_cache[9] = ($event: any) => (click('>='))),
              title: "e.g. [DirId] >= 2"
            }, {
              default: _withCtx(() => _cache[23] || (_cache[23] = [
                _createTextVNode(">=")
              ])),
              _: 1
            }),
            _createVNode(_component_Button, {
              onClick: _cache[10] || (_cache[10] = ($event: any) => (click('AND'))),
              title: "e.g. [DirId] = 1  AND [ViewId] REGEX 't'"
            }, {
              default: _withCtx(() => _cache[24] || (_cache[24] = [
                _createTextVNode("AND")
              ])),
              _: 1
            }),
            _createVNode(_component_Button, {
              onClick: _cache[11] || (_cache[11] = ($event: any) => (click('OR'))),
              title: "e.g. [DirId] = 0 OR [DirId] = 1"
            }, {
              default: _withCtx(() => _cache[25] || (_cache[25] = [
                _createTextVNode("OR")
              ])),
              _: 1
            }),
            _createVNode(_component_Button, {
              onClick: _cache[12] || (_cache[12] = ($event: any) => (click('REGEX'))),
              title: "e.g. [ViewId] REGEX 'Right'"
            }, {
              default: _withCtx(() => _cache[26] || (_cache[26] = [
                _createTextVNode("REGEX")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Button, {
              severity: "danger",
              onClick: _cache[13] || (_cache[13] = ($event: any) => (click('Clear')))
            }, {
              default: _withCtx(() => _cache[27] || (_cache[27] = [
                _createTextVNode("Clear")
              ])),
              _: 1
            })
          ])
        ]),
        _cache[29] || (_cache[29] = _createElementVNode("h5", null, "Expression", -1)),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Textarea, {
            modelValue: sceneData.value.expression,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((sceneData.value.expression) = $event)),
            rows: "5",
            cols: "30",
            style: { width: '100%', minHeight: '120px' }
          }, null, 8, ["modelValue"]),
          (sceneData.value.error != '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(sceneData.value.error), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          severity: "success",
          onClick: onDialogOK
        }, {
          default: _withCtx(() => _cache[30] || (_cache[30] = [
            _createTextVNode("OK")
          ])),
          _: 1
        }),
        _createVNode(_component_Button, { onClick: onDialogClose }, {
          default: _withCtx(() => _cache[31] || (_cache[31] = [
            _createTextVNode("Close")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ]))
}
}

})