import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 md:col-6" }
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = { class: "col-12 col:md-12 formgrid" }
const _hoisted_5 = { class: "mlwn-row-toggle-fieldset mlwn-multiple-input-list-with-validation-row" }
const _hoisted_6 = { class: "flex flex-row flex-wrap justify-content-end" }

import { Form } from 'vee-validate';
import { computed, Ref, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Dropdown from 'primevue/dropdown';
import InfoInputGroup from '@/components/UI/Form/InfoInputGroup.vue';
import DataBrowseSelect from '@/components/DataBrowser/DataBrowseSelect.vue';
import { useTasks } from '@/composables/useTasks';
import { DataMgmtTaskTypeAlias } from '@/gql/graphql'
import { useTaskTypes } from '@/composables/useTaskTypes';
import { taskTypeMapper } from './ServiceTasksConfig';


export default /*@__PURE__*/_defineComponent({
  __name: 'ServiceTaskForm',
  setup(__props) {

/**
 * Load validation references, router, vue
 */
const router = useRouter()
const toast = useToast()

let taskTypeAlias = ref()
let collocationId = ref()
let logVolumeMountAlias = ref()
let outputVolumeMountAlias = ref()
let wmsVolumeMountAlias = ref()
let wmsVolumeMountAliasValue = ref()
let wmsOphotoPath = ref()
let imageSize = ref()
imageSize.value = 800

const { getTaskTypes } = useTaskTypes()
const { taskTypes, loading: loadingTaskTypes } = getTaskTypes()
const {
  createFileProviderServiceTask,
  createImagePoseServiceTask,
  createDataBrowserServiceTask,
  createGeoConversionServiceTask,
} = useTasks()

const availableCollocationId: Ref<Array<string>> = ref([])
const availableVolumeMountAliases: Ref<Array<{ mountAlias: string, name: string }>> = ref([])

watch(taskTypeAlias, () => {
  if (Object.keys(taskTypeMapper).includes(taskTypeAlias.value)) {
    // update available collocations
    const taskType = taskTypes.value.find((taskType: any) => taskType && taskType.alias === taskTypeAlias.value)
    if (taskType) {
      availableCollocationId.value = taskType?.capabilities.map((e: any) => e.collocation)
    }
  }
})

watch(collocationId, () => {
  const taskType = taskTypes.value.find((taskType: any) => taskType && taskType.alias === taskTypeAlias.value)
  if (taskType) {
    let volumeAliases: { mountAlias: string, name: string }[] = []
    const capabilities = taskType.capabilities.filter((capability: any) => capability?.collocation.id === collocationId.value)
    for (let capability of capabilities) {
      for (let connection of capability?.collocation.connections || []) {
        for (let volume of connection?.storage.volumes || []) {
          if (!volumeAliases.find((_volume) => _volume.mountAlias === volume.mountAlias)) {
            volumeAliases.push({
              mountAlias: volume.mountAlias,
              name: volume.name || volume.mountAlias,
            })
          }
        }
      }
    }
    availableVolumeMountAliases.value = [...volumeAliases]
  }
})

const availableTaskTypes = computed(() => {
  const availableTaskTypes = taskTypes.value.filter((taskType: any) => Object.keys(taskTypeMapper).includes(taskType!.alias))
  return availableTaskTypes
})

const onCreateTask = async () => {
  if (taskTypeAlias.value === DataMgmtTaskTypeAlias.FileProviderService) {
    try {
      await createFileProviderServiceTask({
        dmCollocationId: collocationId.value,
        logDmVolumeMountAlias: logVolumeMountAlias.value,
      })
      router.go(-1)
    } catch (err) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error while creating task', life: 3000 })
    }
  }
  if (taskTypeAlias.value === DataMgmtTaskTypeAlias.ImagePoseService) {
    try {
      await createImagePoseServiceTask({
        dmCollocationId: collocationId.value,
        outputDmVolumeMountAlias: outputVolumeMountAlias.value,
        logDmVolumeMountAlias: logVolumeMountAlias.value,
        wmsDmVolumeMountAlias: wmsVolumeMountAlias.value,
        wmsOphotoPath: wmsOphotoPath.value,
        imageSize: imageSize.value
      })
      router.go(-1)
    } catch (err) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error while creating task', life: 3000 })
    }
  }
  if (taskTypeAlias.value === DataMgmtTaskTypeAlias.DataBrowserService) {
    try {
      await createDataBrowserServiceTask({
        dmCollocationId: collocationId.value,
        logDmVolumeMountAlias: logVolumeMountAlias.value,
      })
      router.go(-1)
    } catch (err) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error while creating task', life: 3000 })
    }
  }
  if (taskTypeAlias.value === DataMgmtTaskTypeAlias.GeoConversionService) {
    try {
      await createGeoConversionServiceTask({
        dmCollocationId: collocationId.value,
        dmVolumeMountAlias: logVolumeMountAlias.value,
      })
      router.go(-1)
    } catch (err) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error while creating task', life: 3000 })
    }
  }
}

function onCancelClick() {
  router.go(-1)
}


function onSelectFile(target: any) {
  wmsVolumeMountAlias.value = target.volumeMountAlias
  wmsOphotoPath.value = target.volumeMountAlias + '/' + target.path
}

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Form), { class: "grid p-fluid" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(InfoInputGroup, null, {
                default: _withCtx(() => [
                  _createVNode(_unref(Dropdown), {
                    id: "taskTypeAlias",
                    "data-cy": "serviceTaskForm__selectTaskType",
                    modelValue: _unref(taskTypeAlias),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(taskTypeAlias) ? (taskTypeAlias).value = $event : taskTypeAlias = $event)),
                    options: availableTaskTypes.value,
                    optionValue: "alias",
                    optionLabel: "alias",
                    loading: _unref(loadingTaskTypes),
                    placeholder: "Select Task type",
                    "scroll-height": "400px"
                  }, null, 8, ["modelValue", "options", "loading"]),
                  _cache[7] || (_cache[7] = _createElementVNode("label", { for: "taskTypeAlias" }, "Task type", -1))
                ]),
                _: 1
              }),
              (_unref(taskTypeAlias))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(InfoInputGroup, null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Dropdown), {
                          id: "collocationId",
                          "data-cy": "serviceTaskForm__selectCollocationId",
                          modelValue: _unref(collocationId),
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(collocationId) ? (collocationId).value = $event : collocationId = $event)),
                          options: availableCollocationId.value,
                          optionValue: "id",
                          optionLabel: "id",
                          loading: _unref(loadingTaskTypes),
                          placeholder: "Select Collocation",
                          "scroll-height": "400px"
                        }, null, 8, ["modelValue", "options", "loading"]),
                        _cache[8] || (_cache[8] = _createElementVNode("label", { for: "collocationId" }, "Collocation", -1))
                      ]),
                      _: 1
                    }),
                    _createVNode(InfoInputGroup, null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Dropdown), {
                          id: "logVolumeMountAlias",
                          "data-cy": "serviceTaskForm__selectLogVolume",
                          modelValue: _unref(logVolumeMountAlias),
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(logVolumeMountAlias) ? (logVolumeMountAlias).value = $event : logVolumeMountAlias = $event)),
                          options: availableVolumeMountAliases.value,
                          optionValue: "mountAlias",
                          optionLabel: "name",
                          loading: _unref(loadingTaskTypes),
                          placeholder: "Select Log Volume",
                          "scroll-height": "400px"
                        }, null, 8, ["modelValue", "options", "loading"]),
                        _cache[9] || (_cache[9] = _createElementVNode("label", { for: "logVolumeMountAlias" }, "Log Volume", -1))
                      ]),
                      _: 1
                    }),
                    (_unref(taskTypeAlias) === _unref(DataMgmtTaskTypeAlias).ImagePoseService)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(InfoInputGroup, null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(Dropdown), {
                                id: "outputVolumeMountAlias",
                                "data-cy": "serviceTaskForm__selectOutputVolume",
                                modelValue: _unref(outputVolumeMountAlias),
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(outputVolumeMountAlias) ? (outputVolumeMountAlias).value = $event : outputVolumeMountAlias = $event)),
                                options: availableVolumeMountAliases.value,
                                optionValue: "mountAlias",
                                optionLabel: "name",
                                loading: _unref(loadingTaskTypes),
                                placeholder: "Select Output Volume",
                                "scroll-height": "400px"
                              }, null, 8, ["modelValue", "options", "loading"]),
                              _cache[10] || (_cache[10] = _createElementVNode("label", { for: "outputVolumeMountAlias" }, "Outpout Volume", -1))
                            ]),
                            _: 1
                          }),
                          _createElementVNode("fieldset", _hoisted_5, [
                            _cache[13] || (_cache[13] = _createElementVNode("legend", null, "Select ophoto file", -1)),
                            _createVNode(InfoInputGroup, null, {
                              default: _withCtx(() => [
                                _createVNode(DataBrowseSelect, {
                                  onSelectFile: onSelectFile,
                                  selected: _unref(wmsVolumeMountAliasValue),
                                  preselectedVolumeMountAliases: availableVolumeMountAliases.value,
                                  "button-text": 'Select path'
                                }, null, 8, ["selected", "preselectedVolumeMountAliases"])
                              ]),
                              _: 1
                            }),
                            _createVNode(InfoInputGroup, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_InputText, {
                                  "data-cy": "serviceTaskFormFieldset__wmsVolumeId",
                                  id: "wmsVolumeMountAlias",
                                  modelValue: _unref(wmsVolumeMountAlias),
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(wmsVolumeMountAlias) ? (wmsVolumeMountAlias).value = $event : wmsVolumeMountAlias = $event)),
                                  disabled: true,
                                  type: "text",
                                  placeholder: "WMS Volume Id",
                                  readonly: true
                                }, null, 8, ["modelValue"]),
                                _cache[11] || (_cache[11] = _createElementVNode("label", { for: "wmsVolumeMountAlias" }, "WMS Volume Id", -1))
                              ]),
                              _: 1
                            }),
                            _createVNode(InfoInputGroup, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_InputText, {
                                  id: "wmsOphotoPath",
                                  "data-cy": "serviceTaskFormFieldset__wmsOphotoPath",
                                  modelValue: _unref(wmsOphotoPath),
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(wmsOphotoPath) ? (wmsOphotoPath).value = $event : wmsOphotoPath = $event)),
                                  disabled: true,
                                  type: "text",
                                  placeholder: "WMS Ophoto Path",
                                  readonly: true
                                }, null, 8, ["modelValue"]),
                                _cache[12] || (_cache[12] = _createElementVNode("label", { for: "wmsOphotoPath" }, "WMS Ophoto Path", -1))
                              ]),
                              _: 1
                            })
                          ]),
                          _createVNode(InfoInputGroup, {
                            "hint-content": "800",
                            "hint-title": "Example"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_InputNumber, {
                                "data-cy": "serviceTasks__selectImageSize",
                                id: "imageSize",
                                modelValue: _unref(imageSize),
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(imageSize) ? (imageSize).value = $event : imageSize = $event)),
                                placeholder: "800"
                              }, null, 8, ["modelValue"]),
                              _cache[14] || (_cache[14] = _createElementVNode("label", { for: "imageSize" }, "imageSize", -1))
                            ]),
                            _: 1
                          })
                        ], 64))
                      : _createCommentVNode("", true),
                    _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_Button, {
                        onClick: onCreateTask,
                        "data-cy": "serviceTaskForm__createButton",
                        label: "Create",
                        icon: "fa-solid fa-check",
                        class: "p-button-success mr-2 mb-2 mlwn-button-submit"
                      }),
                      _createVNode(_component_Button, {
                        onClick: onCancelClick,
                        label: "Cancel",
                        icon: "fa-solid fa-xmark",
                        class: "p-button-secondary mr-2 mb-2 mlwn-button-submit"
                      })
                    ])
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})