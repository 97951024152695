<template>
  <div class="layout-menu-container">
    <AppSubmenu
      class="layout-menu mlwn-sidebar-menu"
      :items="model"
      :menuMode="menuMode"
      :parentMenuItemActive="true"
      :menuActive="active"
      :mobileMenuActive="mobileMenuActive"
      :root="true"
      @menuitem-click="onMenuItemClick"
      @root-menuitem-click="onRootMenuItemClick"
    />
  </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu.vue";
import { ref, watch } from "vue";
import { SIDEBAR_LINK_TYPES } from "./SidebarLinks";
import { useStorages } from "@/composables/useStorages";

export default {
  name: "AppMenu",
  emits: ["menu-click", "menuitem-click", "root-menuitem-click"],
  props: {
    model: Array,
    menuMode: String,
    active: Boolean,
    mobileMenuActive: Boolean,
  },
  setup(props, { emit }) {
    const items = ref(props.model);

    const { getStorages } = useStorages();
    const { storages } = getStorages();
    watch(storages, () => {
      for (const item of items.value) {
        if (item && item.type === SIDEBAR_LINK_TYPES.DATA_VOLUMES) {
          item.items = storages.value.map((item) => {
            return {
              label: item.name,
              icon: "fa-regular fa-hdd",
              to: {
                name: "storage-volume-list",
                params: {
                  storageId: item.id,
                },
              },
              role: "is-admin",
            };
          });
        }
      }
    });

    const onMenuItemClick = (event) => {
      emit("menuitem-click", event);
    };

    const onRootMenuItemClick = (event) => {
      emit("root-menuitem-click", event);
    };

    return {
      onMenuItemClick,
      onRootMenuItemClick,
      storages,
      items,
    };
  },
  components: {
    AppSubmenu: AppSubmenu,
  },
};
</script>
