<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
  <div class="grid">
    <div class="col-12 p-fluid">
      <Card>
        <template #title>
          <div class="flex justify-content-between flex-wrap">
            <div class="flex align-items-center justify-content-center">{{ volume?.name }}</div>
            <div class="flex align-items-center justify-content-center">
              <Button data-cy="volumeDetail__editVolume" icon="fa-solid fa-pen" severity="info" aria-label="Edit volume" v-tooltip.bottom="'Edit volume'" @click="onUpdateClick" />
              <Button data-cy="volumeDetail__unregisterVolume" v-if="volume && volume.volumeStatus?.alias === VolumeStatusEnum.Registered" icon="fa-solid fa-plug-circle-xmark" severity="danger" v-tooltip.bottom="'Unregister volume'" aria-label="Unregister volume" @click="onUnregisterClick" />
              <Button data-cy="volumeDetail__registerVolume" v-if="volume && volume.volumeStatus?.alias === VolumeStatusEnum.Unregistered"  severity="success" icon="fa-solid fa-plug" v-tooltip.bottom="'Register volume'" aria-label="Register volume" @click="onRegisterClick" />
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex">
            <div class="w-9 ">
              <attributes-list data-cy="volumeDetail__volumeStatus" :item="volume" :attributes="renderAttributes"></attributes-list>
            </div>
            <!--
            <div class="w-3">
              <span>Used space: {{ 100 - volumeFreeSpace }}%</span>
              <ProgressBar :value="volumeFreeSpace"></ProgressBar>
              <div class="input-chart-wrapper">
                <Chart type="pie" :data="chartData" :options="lightOptions" />
              </div>              
            </div>
            -->
          </div>
        </template>
      </Card>
    </div>
    <div class="col-12 p-fluid" v-if="volume && volume.volumeStatus?.alias === VolumeStatusEnum.Registered">
      <Card>
        <template #title>
          Data browse
        </template>
        <template #content>
          <template v-if="volume">
            <data-browser :storage-id="storageId" :volume-mount-alias="volumeMountAlias"></data-browser>
          </template>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useConfig } from '@/composables/useConfig'
import { useConfirm } from "primevue/useconfirm";
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { useVolumes } from '@/composables/useVolumes'
import { Volume, VolumeStatusEnum } from '@/gql/graphql'
import AttributesList from '@/components/UI/AttributesList.vue'
import DataBrowser from '@/components/DataBrowser/DataBrowser.vue'
import { ByteSize, useStrings } from '@/composables/helpers/useStrings';


const { getConfig } = useConfig()
const config = getConfig()

const props = defineProps({
  volumeMountAlias: {
    required: true,
    type: String
  },
  storageId: {
    required: true,
    type: String
  }
})
const { formatByteSize } = useStrings()
const router = useRouter()

const { getVolume, registerUnregisteredVolume, unregisterRegisteredVolume } = useVolumes()
const { volume } = getVolume(props.volumeMountAlias, config?.POLLINTERVAL)

const volumeFreeSpace = ref(0)
watch(volume, (newValue) => {
  if (newValue && newValue.storage?.id !== props.storageId) {
    router.push({
      name: '404'
    })
  }

  if (newValue) {
    try {
      if(typeof newValue.sizeReservedMB === 'number' && typeof newValue.sizeOccupiedMB === 'number')
        volumeFreeSpace.value = ((newValue.sizeReservedMB - newValue.sizeOccupiedMB) / newValue.sizeReservedMB) * 100
    } catch (e) {
      console.log(e)
    }
  }
})

/*
watch(volume, (newValue, oldValue) => {
  chartData.value = {
    labels: ['Occupied space', 'Free space'],
    datasets: [
      {
        data: [newValue.occupiedSpace, newValue.reservedSpace - newValue.occupiedSpace],
        backgroundColor: ["#FFA726", "#66BB6A"],
        hoverBackgroundColor: ["#FFB74D", "#81C784"]
      },
    ]
  }
})

const chartData = ref(null)

const lightOptions = ref({
  plugins: {
    legend: {
      labels: {
        color: '#495057'
      }
    }
  }
})
*/

const onUpdateClick = () => {
  router.push({
    name: 'storage-volume-update',
    params: {
      volumeMountAlias: props.volumeMountAlias,
      storageId: props.storageId
    }
  })
}

const toast = useToast()
const confirm = useConfirm()

const onUnregisterClick = () => {
  confirm.require({
    message: 'Are you sure you want to unregister volume? You can register this volume again.',
    header: 'Unregister volume ' + volume.value.name,
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      onUnregisterRegisteredVolume()
    },
    reject: () => {
      toast.add({ severity: 'info', summary: 'Rejected', detail: 'Do not unregister' });
    }
  })
}

const onUnregisterRegisteredVolume = async () => {
  const res = await unregisterRegisteredVolume(props.volumeMountAlias)

  if (res.success) {
    toast.add({ severity: 'success', summary: 'Success', detail: res.message ?? 'The volume will be unregistered soon', life: 3000 })
  } else {
    toast.add({ severity: 'error', summary: 'Error', detail: res.message ?? 'Error occured while unregistering', life: 3000 })
  }
}

const onRegisterClick = () => {
  confirm.require({
    message: 'Are you sure you want to register volume back?',
    header: 'Register volume ' + volume.value.name,
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      onRegisterUnregisteredVolume()
    },
    reject: () => {
      toast.add({ severity: 'info', summary: 'Rejected', detail: 'Do not unregister' });
    }
  })
}

const onRegisterUnregisteredVolume = async () => {
  const res = await registerUnregisteredVolume(props.volumeMountAlias)

  if (res.success) {
    toast.add({ severity: 'success', summary: 'Success', detail: res.message ?? 'Volume succesfully registred', life: 3000 })
  } else {
    toast.add({ severity: 'error', summary: 'Error', detail: res.message ?? 'Error occured while registering', life: 3000 })
  }
}


const renderAttributes = ref({
  sizeReservedBB:  { label: 'Reserved size',  value: (item: Volume) => formatByteSize(item?.sizeReservedMB, ByteSize.MB, ByteSize.GB) + ' GB' },
  sizeOccupiedGB:  { label: 'Occupied size',  value: (item: Volume) => formatByteSize(item?.sizeOccupiedMB, ByteSize.MB, ByteSize.GB) + ' GB'  },
  storageName: { label: 'Storage', value: (item: Volume) => item?.storage?.name },
  remotePath: { label: 'Remote path', value: (item: Volume) => item?.remotePath },
  mountAlias: { label: 'Mount alias', value: (item: Volume) => item?.mountAlias },
  volumeStatusAlias: { label: 'Status', value: (item: Volume) => item?.volumeStatus?.alias}
})
</script>

<style lang="scss" scoped>
.input-chart-wrapper {
  position: relative;
}

.input-chart-wrapper .p-chart canvas {
  width: 100% !important;
  max-height: 400px !important;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {

      .p-datatable-thead>tr>th,
      .p-datatable-tfoot>tr>td {
        display: none !important;
      }

      .p-datatable-tbody>tr {
        border-bottom: 1px solid #dee2e6;

        >td {
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: .4rem;
            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
          }
        }
      }
    }
  }
}
</style>
