<template>
  <div class="p-grid">
    <div v-if="loading"><ProgressSpinner /></div>

    <div v-else-if="presets">
      <DataTable 
          :value="presets" 
          :paginator="true"
          @page="onPage($event)"
          @sort="onSort($event)" 
          @filter="onFilter($event)" 
          :sortField="defaultSortField" 
          :sortOrder="defaultSortOrder" 
          class="p-datatable-presets p-datatable-sm" 
          :rows="rows"
          dataKey="id" 
          :rowHover="true" 
          v-model:filters="tableFilters" 
          filterDisplay="menu" 
          :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
          :rowsPerPageOptions="rowsPerPageOptions"
          :currentPageReportTemplate="'Showing {first} to {last} of {totalRecords} entries'"
          responsiveLayout="scroll">
        <template #empty>
            No presets found.
        </template>
        <template #loading>
            <ProgressSpinner />
        </template>
        
        <Column 
          field="key" 
          header="Unique preset key" 
          filterField="key" 
          filterMatchMode="contains" 
          :showFilterMatchModes="false"
          :maxConstraints="1" :showFilterOperator="false" 
          sortable style="min-width: 10rem">
            <template #body="{data}">
              <span v-html="makeStrong(data.key, 'key')"></span>
            </template>
            <template #filter="{filterModel, filterCallback}">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by key"/>
            </template>
        </Column>

        <Column 
          field="presetTypeAlias" 
          header="Preset type" 
          filterField="presetTypeAlias" 
          filterMatchMode="contains" 
          :showFilterMatchModes="false"
          :maxConstraints="1" :showFilterOperator="false" 
          sortable style="min-width: 10rem">
            <template #body="{data}">
              <span> {{ data.presetType?.name }}</span>
            </template>
            <template #filter="{filterModel, filterCallback}">
              <Dropdown v-model="filterModel.value" scroll-height="400px" :options="presetTypes" @change="filterCallback()" optionValue="alias" optionLabel="name" placeholder="Select preset type" />
            </template>
        </Column>
        
        <Column headerStyle="width: 8rem; text-align: right" bodyStyle="text-align: right; overflow: visible">
            <template #body="{data}">
                <span class="action-buttons">
                  <Button type="button" icon="fa-solid fa-pencil" @click="onEditClick(data)" v-tooltip.top="'Preset detail'"></Button>
                </span>
            </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watch } from 'vue';
import { usePresets } from '@/composables/usePresets';
import { useTables } from '@/composables/useTables';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { FilterMatchMode } from 'primevue/api';
import { Preset } from '@/gql/graphql';

const toast = useToast()
const { getPresets, getPresetTypes } = usePresets()
const { presets, loading, error: errorPresets } = getPresets()
const { presetTypes } = getPresetTypes()

const router = useRouter()
const _query = router.currentRoute.value.query

const {
  makeStrong,
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows
} = useTables({
  filters: {
    presetTypeAlias: {
      value: _query.typeAlias ? _query.typeAlias : null,
      matchMode: FilterMatchMode.CONTAINS, 
      pretty: "typeAlias"
    },
    key: {
      value: _query.key ? _query.key : null,
      matchMode: FilterMatchMode.CONTAINS, 
      pretty: "key"
    },
    value: {
      value: _query.value ? _query.value : null,
      matchMode: FilterMatchMode.CONTAINS, 
      pretty: "value"
    }
  }
})

const onEditClick = (preset: Preset) => {
  router.push({
    name: 'preset-detail',
    params: {
      presetKey: preset.key
    }
  })
}

// watches
watch(errorPresets, () => {
  if (errorPresets.value) {
    toast.add({ severity: 'error', summary: 'Error loading presets', detail: 'Try to log in again or contact administrator.' })
  }
})
</script>
