import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "p-fluid p-grid p-formgrid" }
const _hoisted_2 = { class: "p-col-6 p-md-4" }
const _hoisted_3 = {
  key: 0,
  class: "row"
}
const _hoisted_4 = { class: "columnRight" }
const _hoisted_5 = { class: "columnLeft border" }
const _hoisted_6 = { class: "columnRight border" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "columnLeft border" }
const _hoisted_9 = { class: "columnRight border" }

import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LayerInfo',
  props: {
  visible: {
    type: Boolean,
    default: false,
    required: true
  },
  hideInfo: {
    required: true
  },
  data: {
    type: Object,
    required: true
  },
  zoom: {
    type: Number,
    required: true
  },
  title: {
    type: String,
    required: true
  }
},
  setup(__props) {


const props = __props

const onDialogClose = () => {
  info.value.visible = false
  info.value.hideInfo()
}

const info: any = ref({
  hideInfo: props.hideInfo,
  data: props.data,
  visible: props.visible,
  zoom: props.zoom,
  title: props.title ? `${props.title}` : `Info`
})

const openMap = (name:any) => {
  const latLong = info.value.data.latLng.replace(' ','').split(',')
  switch (name) {
  case 'google':
    window.open(`https://www.google.com/maps/@${latLong[0]},${latLong[1]},${info.value.zoom}z`)
    break
  case 'mapy':   
    window.open(`https://mapy.cz/zakladni?x=${latLong[1]}&y=${latLong[0]}&z=${props.zoom}&pano=1`)
    break    
  case 'bing': 
    window.open(`https://bing.com/maps/default.aspx?cp=${latLong[0]}~${latLong[1]}&lvl=${props.zoom}`)
    break    
  case 'osm': 
    window.open(`https://www.openstreetmap.org/index.html?mlat=${latLong[0]}&mlon=${latLong[1]}&zoom=${props.zoom}`)    
    break    
  }     
  return false
}


return (_ctx: any,_cache: any) => {
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(_component_Dialog, {
      visible: info.value.visible,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((info.value.visible) = $event)),
      header: info.value.title,
      onHide: onDialogClose,
      position: 'center',
      modal: true,
      style: _normalizeStyle({ width: info.value.data.menuTabs && info.value.data.menuTabs.length > 0 ? '900px' : '600px' })
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (info.value.data.latLng)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "columnLeft" }, "Lat, long", -1)),
                  _createElementVNode("div", _hoisted_4, [
                    _createTextVNode(_toDisplayString(info.value.data.latLng) + " (", 1),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (openMap('google')))
                    }, "Google"),
                    _cache[5] || (_cache[5] = _createTextVNode(", ")),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (openMap('mapy')))
                    }, "Mapy.cz"),
                    _cache[6] || (_cache[6] = _createTextVNode(", ")),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (openMap('bing')))
                    }, "Bing"),
                    _cache[7] || (_cache[7] = _createTextVNode(", ")),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (openMap('osm')))
                    }, "OSM"),
                    _cache[8] || (_cache[8] = _createTextVNode(")"))
                  ])
                ]))
              : _createCommentVNode("", true),
            (info.value.data.menuTabs && info.value.data.menuTabs.length > 0)
              ? (_openBlock(), _createBlock(_component_TabView, {
                  key: 1,
                  style: { marginTop: '16px' }
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(info.value.data.menuTabs, (tab) => {
                      return (_openBlock(), _createBlock(_component_TabPanel, {
                        header: tab.filter((f) => f.key == 'ViewId')[0].value,
                        key: tab.ViewId
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tab, (item) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: item.key,
                              class: "row"
                            }, [
                              _createElementVNode("div", _hoisted_5, _toDisplayString(item.key), 1),
                              _createElementVNode("div", _hoisted_6, _toDisplayString(item.value), 1)
                            ]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["header"]))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (info.value.data.menuItems && info.value.data.menuItems.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(info.value.data.menuItems, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.key,
                      class: "row"
                    }, [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(item.key), 1),
                      _createElementVNode("div", _hoisted_9, _toDisplayString(item.value), 1)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Close",
          class: "mr-0",
          onClick: onDialogClose
        })
      ]),
      _: 1
    }, 8, ["visible", "header", "style"])
  ]))
}
}

})