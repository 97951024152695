<script setup lang="ts">
import { PopulationTypeAlias } from '@/gql/graphql';
import { ref } from 'vue';
import FileDownloadButton from '@/components/UI/FileDownloadButton.vue';

const props = defineProps({
  scene: {
    required: true,
    type: Object
  }
})

const menu = ref()

import { useLogFiles } from '@/composables/helpers/useLogFiles';

const { populationLogFilePath, inputQcLogFilePath } = useLogFiles()

const populationLogFile = populationLogFilePath(props.scene.populationType.alias as PopulationTypeAlias)
const inputQcLogFile = inputQcLogFilePath()

const itemsLogs = ref([
  {
    items: [
      {
        label: 'Input QC log file',
        icon: 'pi pi-refresh',
        path: inputQcLogFile,
        volumeMountAlias: props.scene.vadstenaInputDmVolumeMountAlias
      },
      {
        label: props.scene.populationType.name + ' log file',
        icon: 'pi pi-upload',
        path: populationLogFile,
        volumeMountAlias: props.scene.vadstenaInputDmVolumeMountAlias
      }
    ]
  }
]);

const toggleLogs = (event: MouseEvent) => {
  menu.value.toggle(event);
};
</script>

<template>
  <span>
    <Button v-tooltip.top="'Download log files'" type="button" icon="fa-solid fa-file-lines" @click="toggleLogs" aria-haspopup="true" aria-controls="overlay_menu" />
    <Menu ref="menu" id="overlay_menu" :model="itemsLogs" :popup="true">
      <template #item="{ item }">
        <FileDownloadButton
          v-if="item.path"         
          :volumeMountAlias="item.volumeMountAlias" 
          :path="item.path"
          :label="item?.label as string ?? 'Download input qc log'"
        ></FileDownloadButton>
      </template>
    </Menu>
  </span>
</template>