<script setup lang="ts">
/**
 * Load validation references, router, vue
 */
import { Form } from "vee-validate";
import * as yup from "yup";
import { ref } from "vue";

import InputTextWithValidation from "@/components/UI/Form/components/InputTextWithValidation.vue";
import InputTextAreaWithValidation from "@/components/UI/Form/components/InputTextAreaWithValidation.vue";
import CheckboxWithValidation from "@/components/UI/Form/components/CheckboxWithValidation.vue";

import { PopulationTypeAlias } from "@/gql/graphql";

const props = defineProps({
  scene: {
    type: Object,
    required: true,
  },
  saveCb: {
    type: Function,
    default: () => {},
  },
});

const initialValues = ref({
  id: props.scene.id + "-dilute",
  name: props.scene.id + "-dilute",
  preserveNadir: true,
  notes: "",
  diluteMode: 1,
  // Default population type - Ingest, to be set by combo
  populationTypeAlias: PopulationTypeAlias.Dilution,
});

/**
 * Validation setup
 */
const schema = yup.object({
  id: yup
    .string()
    .matches(
      /^[a-zA-Z0-9.\-\u00D8-\u00F6]+$/,
      "Is not in correct format, allowed characters [a-zA-Z0-9.\\-]"
    )
    .required()
    .min(3)
    .max(255)
    .label("Id"),
  name: yup.string().min(0).max(255).label("Name"),
  diluteMode: yup.number().min(0).max(100).label("Dilute mode"),
  preserveNadir: yup.bool().required('Preserve nadir required'),
  notes: yup.string().label("Notes"),
});

const form: any = ref({
  idExist: false,
});

import { useScenes } from "@/composables/useScenes";
const { sceneExist, createScene } = useScenes();
import { watch } from "vue";
import { useRouter } from "vue-router";
import DropdownWithValidation from "@/components/UI/Form/components/DropdownWithValidation.vue";

const checkSceneIdExist = (id: string) => {
  form.value.idExist = false;

  const { exist } = sceneExist(id);
  watch(exist, () => {
    if (exist.value) {
      form.value.idExist = exist.value;
    }
  });
};

const onKeyDown = (event: KeyboardEvent) => {
  const key = event.key.toLowerCase();

  if (key.length !== 1) {
    return;
  }

  checkSceneIdExist((event?.target?.value ?? "") + event.key);
};

checkSceneIdExist(initialValues.value.id);

const router = useRouter();
// Function
const onSubmit = (values: any) => {
  values.name = values.id;

  console.log(values);

  const newScene: Create_SceneMutationVariables = {
    id: values.id,
    name: values.name,
    projectId: props.scene.project.id,
    populationTypeAlias: values.populationTypeAlias,
    parentScenesId: [props.scene.id],
    populationConfig: {
      diluteMode: values.diluteMode,
      preserveNadir: values.preserveNadir,
    },
  };

  onCreateScene(newScene)
};

import { useToast } from "primevue/usetoast";
import { Create_SceneMutationVariables } from "@/gql/graphql";
import InputNumberWithValidation from "@/components/UI/Form/components/InputNumberWithValidation.vue";
const toast = useToast();

const onCreateScene = async (scene: Create_SceneMutationVariables) => {
  try {
    const res = await createScene(scene);

    if (res.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Succesfully created",
        life: 3000,
      });
      props.saveCb();
      const routeData = router.resolve({
        name: "project-scene-detail",
        params: {
          projectId: scene.projectId,
          sceneId: res?.payload?.scene?.id,
        },
      });
      window.open(routeData.href, "_blank");
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Error occured while creating",
        life: 3000,
      });
    }
  } catch (err: any) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error occured while creating. " + err?.message,
      life: 3000,
    });
  }
};

const diluteOptions = ref([
  {
    value: 1,
    label: "Preserve every 2nd take.",
  },
  {
    value: 2,
    label: "Preserve every 3rd take.",
  },
  {
    value: 3,
    label: "Preserve every 2nd take in every 2nd flight line.",
  },
  {
    value: 4,
    label: "Preserve every 3rd take in every 2nd flight line.",
  },
]);
</script>

<template>
  <Form
      :initial-values="initialValues"
      :validation-schema="schema"
      @submit="onSubmit"
      class="grid p-fluid"
  >
    <div class="field col-12 input">
      <InputTextWithValidation
          name="id"
          data-cy="dilutionForm__dilutedSceneId"
          label="Diluted scene id"
          @keydown="onKeyDown"
      ></InputTextWithValidation>
      <label for="id" v-if="form.idExist" class="p-error"
      >Scene Id already exist</label
      >
      <InputTextWithValidation
          name="name"
          label="Name"
          v-if="false"
      ></InputTextWithValidation>

      <DropdownWithValidation
          name="diluteMode"
          data-cy="dilutionForm__diluteMode"
          label="Dilute mode"
          :options="diluteOptions"
      ></DropdownWithValidation>

      <InputTextAreaWithValidation
          name="notes"
          label="Notes"
          data-cy="dilutionForm__notes"
          :rows="1"
          :cols="40"
      ></InputTextAreaWithValidation>

      <CheckboxWithValidation
          name="preserveNadir"
          label="Preserve Nadir"
          data-cy="dilutionForm__preserveNadir"
      ></CheckboxWithValidation>

      <div class="flex flex-row flex-wrap justify-content-end">
        <Button
            v-if="!form.idExist"
            label="Create new scene"
            data-cy="dilutionForm__createNewScene"
            type="submit"
            icon="fa-solid fa-arrow-right"
            class="p-button-success mr-2 mb-2 mlwn-button-submit"
        ></Button>
      </div>
    </div>
  </Form>
</template>
