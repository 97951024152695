import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_4 = { class: "flex align-items-center justify-content-center" }

import ProjectTable from './components/ProjectTable.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectList',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex align-items-center justify-content-center" }, "Projects", -1)),
            _createElementVNode("div", _hoisted_4, [
              _withDirectives((_openBlock(), _createBlock(_component_router_link, { to: {
                  name: 'project-create'
                } }, {
                default: _withCtx(() => [
                  _createVNode(_component_Button, {
                    "data-cy": "projectTable__createProjectButton",
                    type: "button",
                    icon: "fa-solid fa-plus"
                  })
                ]),
                _: 1
              })), [
                [
                  _directive_tooltip,
                  'Create new project',
                  void 0,
                  { bottom: true }
                ]
              ])
            ])
          ])
        ]),
        content: _withCtx(() => [
          _createVNode(ProjectTable)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})