<template>
  <Skeleton v-if="!sceneData.loading.done && !loadingHaveError" height="2rem" class="mb-2"></Skeleton>
  <!-- warn when map cannot load -->
  <div v-if="loadingHaveError">
    <!-- Image Pose Service URL is not available -->
    <Message :closable="false" severity="warn" v-if="sceneData.loading.errors.imagePoseUrlNotAvailable.value">
      Image Pose Service URL is not available!
    </Message>
    <!-- File Provider Service URL is not available -->
    <Message :closable="false" severity="warn" v-if="sceneData.loading.errors.fileProviderUrlNotAvailable.value">
      File Provider Service URL is not available!
    </Message>
    <!-- warn when ImagePose not running -->
    <Message :closable="false" severity="warn" v-if="sceneResult?.getScene && sceneData.loading.errors.imagePoseServiceNotRunning.value">
      Image pose service ({{ sceneResult.getScene.imagePoseCdnDataset?.id }}) is not available!
    </Message>
    <!-- warn when file provider not running -->
    <Message :closable="false" severity="warn" v-if="sceneResult?.getScene && sceneData.loading.errors.fileProviderServiceNotRunning.value">
      File provider service ({{ sceneResult.getScene.inputQcCdnDataset?.id }}) is not available!
    </Message>
    <!-- error file provider fetch for mapconfig fails - mapconfig not available / file provider not runnign wiht ids() -->
    <Message :closable="false" severity="warn" v-if="sceneData.loading.errors.mapConfigNotAvailable.value">
      Map config JSON at <a :href="sceneData.inputQcCdnDatasetUrl" target="_blank">{{ sceneData.inputQcCdnDatasetUrl.slice(0,30) + "..." }}</a> not available for this scene! Contact administrator.
    </Message>
  </div>
  <!-- map -->
  <div v-if="loadingDone">
    <div class="mlwn-map">
      <div class="page has-sidebar has-sidebar-expand-sm">
          <div id="map" class="page-inner mlwn-cross-pointer-cursor" @keydown.ctrl="shiftDown" @keyup="shiftUp"></div>
          <div class="page-sidebar">
            <!-- basemaps -->
            <div class="col-12 pb-0 pt-0">
              <h5 class="mb-1">Basemaps</h5>
              <div v-for="layer in sceneData.basemaps" :key="layer.id" class="flex align-items-center">
                <RadioButton v-model="sceneData.selectedMap" :value="layer.id"
                  @update:modelValue="changeSelectedMap(layer.id)" />
                <label class="radioButtonTop">{{ layer.title }}</label>
              </div>
            </div>
            <!-- layers -->
            <div class="col-12 pb-0">
              <h5 class="mb-0 mt-3">Layers</h5>
              <div v-for="subcat of sceneData.menuSubcategory" :key="subcat.id">
                <div class="menuCategoryTitle" v-if="sceneData.overlaysKeys[subcat.id] && sceneData.overlaysKeys[subcat.id].length > 0">{{ subcat.caption }}</div>
                <div v-for="layer of sceneData.overlaysKeys[subcat.id]" :key="sceneData.overlays[layer].id"
                  class="flex align-items-center menuCategory" data-cy="sceneMap__rightMenuItem">

                  <InputSwitch v-model="sceneData.selectedData[sceneData.overlays[layer].id]"
                    @update:modelValue="changeSelectedData(layer)" :true-value="true" :false-value="false" :disabled=!sceneData.overlays[layer].enabled                 
                    v-if="sceneData.overlays[layer].enabled && !sceneData.overlays[layer].failed" />

                  <Button
                    :icon="sceneData.overlays[layer].filter && sceneData.overlays[layer].filter.condition ? 'pi pi-filter-fill menuIconCenter' : 'pi pi-filter menuIconCenter'"
                    text rounded @click="showFilter(layer)" :value="layer" class="menuIcon"
                    v-if="sceneData.overlays[layer].enabled && sceneData.overlays[layer].filter && !sceneData.overlays[layer].failed"></Button>

                  <div v-if="!(sceneData.overlays[layer].enabled && sceneData.overlays[layer].filter) && !sceneData.overlays[layer].failed" class="menuIcon" 
                    :style="{ minWidth: (!sceneData.overlays[layer].enabled && layer === 'aoi') ? '0px' : '22px' }"></div>

                  <label v-if="!sceneData.overlays[layer].failed" :title="sceneData.overlays[layer].description" 
                    :style="sceneData.overlays[layer].label" @contextmenu="labelRightClick($event, sceneData.overlays[layer].id)"
                    :class="{radioButtonTop: true, radioButtonTopClick: sceneData.overlays[layer].type !== 'rasterTiles'}" 
                    @click="labelLeftClick($event, sceneData.overlays[layer].id)">{{ sceneData.overlays[layer].title }}<span class="loader"
                      v-if="!sceneData.overlays[layer].enabled && layer !== 'aoi'">Loading...</span></label>
                  
                  <label v-if="layer === 'aoi' && !sceneData.overlays[layer].failed" 
                    title="Add / update AOI" @click="labelLeftClick($event, sceneData.overlays[layer].id)"><Button icon="pi pi-plus" severity="info" text rounded aria-label="User" class="layersLabel" @click="addLayer" /></label>
                    
                </div>
              </div>
            </div>

            <!-- properties -->
            <div class="col-12 pb-0" v-if="sceneData.layerMenu.items && sceneData.layerMenu.items.length > 0">
              <h5 class="mb-1">Properties</h5>
              <ul class="list-none p-0 m-0">            
                <li v-for="item in sceneData.layerMenu.items" :key="item.key"
                  class="flex align-items-center surface-border flex-wrap">
                  <div class="text-500 w-6 md:w-5 font-medium">
                    {{ item.key }}
                  </div>
                  <div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                    {{ item.value }}
                  </div>
                </li>
              </ul>
            </div>

            <!-- scene legend -->
            <div v-if="sceneData.legend" class="col-12 pb-0 image-legend-wrapper">
              <h5 class="mb-1">Legend</h5>
              <img class="image-legend" :src="sceneData.legend"/>
              <div class="flex flex-row flex-nowrap justify-content-between">
                <span>{{ sceneData.layerMenu.legend ? sceneData.layerMenu.legend.min : 1 }}</span>
                <span>{{ sceneData.layerMenu.legend ? sceneData.layerMenu.legend.max : 255 }}</span>
              </div>
            </div>
          </div>
      </div>  
      <div class="bottomToolbar">Lat, Long: <span class="bottomToolbarLatLong">{{sceneData.layerMenu.latLng}}</span> | Zoom: <span data-cy="sceneMap__zoomLevel" class="bottomToolbarLatLong">{{ sceneData.zoom }}</span></div>

      <ImageCompare :cdn="sceneData.cdn" :update="updateImageCompareDialog" :getInfobyViewId="getInfobyViewId" :visible="sceneData.imageCompareVisible" :scene-id="sceneData.sceneId"
        :nearestFeatures="sceneData.overlays[sceneData.filterLayer].nearestFeatures" :file="sceneData.overlays[sceneData.filterLayer].selectedFile" v-if="sceneData.imageCompareVisible" />
      <LayerFilter :update="updateFilter" :data="sceneData.overlays[sceneData.filterLayer].filterData" :visible="sceneData.filterVisible" :layer="sceneData.filterLayer" v-if="sceneData.filterVisible" />
      <LayerInfo :visible="sceneData.infoVisible" v-if="sceneData.infoVisible" :hideInfo="hideInfo" :data="sceneData.layerMenu" :zoom="sceneData.map.getZoom()" :title="sceneData.layerMenu.title"/>
      <UploadLayer :visible="sceneData.uploadVisible" :sceneId="sceneId" v-if="sceneData.uploadVisible" :hide="hideUpload"/>
    </div>
  </div>
</template>

<script setup lang="ts">

import { computed, ref, watch } from 'vue'
import { createLegend } from './SceneLegend.js'
import { getGeoJson, loadGeoJson } from './Loaders/geoJson'
import { getPbf } from './Loaders/pbf'
import { evaluateFilter } from './SceneFilter.js'
import { getImage } from './SceneGetMapImage.js'
import "leaflet/dist/leaflet.css"
import L from 'leaflet'
import ImageCompare from './Dialogs/ImageCompare.vue'
import LayerFilter from './Dialogs/LayerFilter.vue'
import LayerInfo from './Dialogs/LayerInfo.vue'
import UploadLayer from './Dialogs/UploadLayer.vue'
import './Leaflet/Leaflet.VectorGrid'
import './Leaflet/Leaflet.VectorGrid.Protobuf'
import { rgb2hsv } from './Leaflet/convertColor'

import { UseQueryReturn, useQuery } from '@vue/apollo-composable'
import { GET_SCENE } from '@/apollo/queries/scene'
import { CdnOriginStatusAlias, GetSceneQuery, GetSceneQueryVariables } from '@/gql/graphql'

const props = defineProps({
  sceneId: {
    type: String,
    required: true
  }
})

const sceneData: any = ref({
  sceneId: props.sceneId,
  sceneName: '',
  zoom: 10,
  zoomPrev: 10,
  selectedMap: '',
  selectedData: [],
  basemaps: [],
  overlays: [],
  overlaysKeys: {},
  control: null,
  map: null,
  layer: null,
  initMapState: null,
  lastZoom: 1,
  dirId: 0.0,
  filterVisible: false,
  infoVisible: false,
  uploadVisible: false,
  imageCompareVisible: false,
  filterLayer: '',
  loader: false,
  shiftPressed: false,
  image: {},
  menuVisible: [],
  cdn: '',
  inputQcCdnDatasetUrl: '',
  imagePoseCdnDatasetUrl: '',
  layerMenu: {
    title: '',
    latLng: '',
    items: [],
    menuItems: [],
    menuTabs: []
  },
  legend: null,
  legendBytes: null,
  paneList: [],
  menuSubcategory: [
    { id: '', caption: ''}, 
    { id: 'aoi', caption: 'AOI'}, 
    { id: 'image', caption: 'Image'}, 
    { id: 'lidar', caption: 'LiDAR'}, 
  ],
  loading: {
    done: false, 
    errors: {
      imagePoseUrlNotAvailable: {
        value: false,
        necessary: false
      },
      imagePoseServiceNotRunning: {
        value: false,
        necessary: false
      },
      fileProviderUrlNotAvailable: {
        value: false,
        necessary: true
      },
      fileProviderServiceNotRunning: {
        value: false,
        necessary: true
      },
      mapConfigNotAvailable: {
        value: false,
        necessary: true
      },
    }
  }
})

const loadingHaveError = computed(() => {
  const loadingErrors = Object.keys(sceneData.value.loading.errors)
  return loadingErrors.length === 0 ? false : loadingErrors
    // .filter(key => !sceneData.value.loading.errors[key].necessary)
    .some(key => sceneData.value.loading.errors[key].value)
})

const loadingDone = computed(() => {
  const loadingErrors = Object.keys(sceneData.value.loading.errors)
    .filter(key => sceneData.value.loading.errors[key].necessary)
  return loadingErrors.length === 0 ? sceneData.value.loading.done : (sceneData.value.loading.done && loadingErrors
    .every(key => !sceneData.value.loading.errors[key].value))
})

const changeSelectedMap = (value: any) => {
  sceneData.value.selectedMap = value
  const layerNew = sceneData.value.basemaps.find((f: any) => f.id === value).tileLayer
  if (layerNew && sceneData.value.layer) {
    sceneData.value.map.removeLayer(sceneData.value.layer)
    sceneData.value.map.addLayer(layerNew)
    sceneData.value.layer = layerNew
  }
}
const updateLegend = () => {  
  const image = sceneData.value.image
  const layerMenu = sceneData.value.layerMenu
  const menuLayers = sceneData.value.menuLayers
  if (menuLayers && menuLayers.length > 0 && image.ctx) {
    for (const layer of menuLayers) {
      if (layer.legend) {
        layerMenu.legend = layer.legend
      }
    }
  }
}
const checkMenuVisible = () => {
  sceneData.value.menuLayers = []
  for (const key in sceneData.value.overlays) {
    const layer = sceneData.value.overlays[key]
    if (layer.menu && layer.tileLayer && sceneData.value.map.hasLayer(layer.tileLayer)) {
      sceneData.value.menuLayers.push(layer)
    }
  }
  if (sceneData.value.menuLayers.length > 0) {
    getImage(sceneData.value)
    updateLegend()
  }
}
const changeSelectedData = (key: any) => {
  const overlay = sceneData.value.overlays[key]
  //sceneData.value.legend = null
  let rasterOverlay = null
  for (const layerKey in sceneData.value.overlays) {
    const layer = sceneData.value.overlays[layerKey]
    if (layer.type === 'rasterTiles' && overlay.type === 'rasterTiles' && sceneData.value.map.hasLayer(layer.tileLayer) && layerKey !== key) {
      sceneData.value.selectedData[layerKey] = false
      sceneData.value.map.removeLayer(layer.tileLayer)
    }
    else 
    if (layer.type === 'rasterTiles' && sceneData.value.map.hasLayer(layer.tileLayer)) {
      rasterOverlay = layer
    }
  }

  const rasterLayer = overlay.tileLayer && overlay.type === 'rasterTiles' ? overlay : (rasterOverlay && rasterOverlay.tileLayer && rasterOverlay.type === 'rasterTiles' ? rasterOverlay : null)

  if (overlay) {
    sceneData.value.loader = true    
    setTimeout(() => {
      if (!sceneData.value.selectedData[overlay.id] && sceneData.value.map.hasLayer(overlay.tileLayer)) {
        sceneData.value.map.removeLayer(overlay.tileLayer)
      }
      else if (sceneData.value.selectedData[overlay.id] && !sceneData.value.map.hasLayer(overlay.tileLayer)) {
        sceneData.value.map.addLayer(overlay.tileLayer)
      }
      sceneData.value.loader = false
      checkMenuVisible()
      if (rasterLayer) {
        createLegend(rasterLayer, sceneData.value.layerMenu, sceneData.value.map).then(data => {
          sceneData.value.legend = data.legend
          const _data = data.rgbBytes.data
          const hsvArray = []          
          for (let i = 0; i < _data.length; i+=4) {
            const hsv = rgb2hsv(_data[i+0], _data[i+1], _data[i+2])
            hsvArray.push(hsv.h)
          }          
          sceneData.value.legendBytes = hsvArray
          updateLegend()
        } )        
      }
    }, 200)
  }
}

const hideInfo = () => {
  sceneData.value.layerMenu.title = null
  sceneData.value.layerMenu.menuItems = [] 
  sceneData.value.layerMenu.menuTabs = [] 
  sceneData.value.infoVisible = false
}

const setVectorLayerData = (key: string, data: JSON) => {
  const overlays = sceneData.value.overlays
  const menu = sceneData.value.layerMenu
  const overlay = overlays[key]
  if (!overlay)
    return
  const value = sceneData.value
  loadGeoJson(data, key, value, menu, getInfobyFeature)
  const keysValues = ['DirId', 'SourceId', 'Kind']
  overlay.filterData = {
    _filter: overlay.filter
  }   
  if (data?.features && data?.features.length > 0) {
    for (const key of Object.keys(data?.features[0].properties)) {
      if (keysValues.includes(key)) {
        overlay.filterData[key] = [...new Set(data?.features.map((item: any) => item.properties[key]))]
      }
      else {
        overlay.filterData[key] = []
      }
    }
  }
  sceneData.value.overlays[key].enabled = true
  sceneData.value.selectedData[key] = true
  changeSelectedData(key)
}

const createAoiLayer = () => {
  const layer = {
    alias: 'aoi',
    type: 'geojson',
    menuCategory: '',
    style: {
      click: true,
      value: {
        fill: false,
        color: '#000000',
        weight: 4,
        opacity: 1,
        lineJoin: 'square',
        lineCap: 'square',
        dashArray: '10, 10',
        fillColor: '#AA22AA',
        fillOpacity: 0.0
      },
      highlight: false
    },
    title: 'Area of interest (AOI)',
    infoPanelTitle: 'Area of interest (AOI)',
    sortId: 10,
    zIndex: 30,
    options: {
      tms: true,
      maxZoom: 20,
      minZoom: 7,
      opacity: 0.7,
      attribution: ' ',
      interactive: true
    },
    description: 'The area that will be used for the data processing.'
  }
  sceneData.value.overlays[layer.alias] = { infoPanelTitle: layer.infoPanelTitle ?? layer.alias, title: layer.title ?? layer.alias, zIndex: layer.zIndex ?? 3, menuCategory: layer.menuCategory ?? '', sortId: layer.sortId ?? 99, description: layer.description, id: layer.alias, legend: layer.legend, menu: layer.menu, enabled: false, filter: layer.filter, label: layer.label ? layer.label : '', style: layer.style, url: layer.url, type: layer.type, options: layer.options, selectedFile: '', nearestFeatures: [], scene : sceneData.value }
  if (!sceneData.value.overlaysKeys[layer.menuCategory ?? ''])
    sceneData.value.overlaysKeys[layer.menuCategory ?? ''] = []
  sceneData.value.overlaysKeys[layer.menuCategory ?? ''].push(layer.alias)
  const pane = layer.zIndex ?? 3
  if (!sceneData.value.paneList.includes(pane)) {
    sceneData.value.paneList.push(pane)
  }
}

const addVectorLayer = (layerKey: string, data: JSON) => {
  if (layerKey !== 'aoi') {
    return
  }
  if (!sceneData.value.overlays[layerKey]) {
    createAoiLayer()
  }
  setVectorLayerData(layerKey,data)
}

const loadAOI = (layerKey: string) => {
  if (
    sceneResult?.value?.getScene?.aoiGeojson
  ) {
    addVectorLayer(layerKey, JSON.parse(JSON.stringify(sceneResult?.value?.getScene?.aoiGeojson)))
  }
}

const hideUpload = (sceneResult:any, layerKey: string) => {
  sceneData.value.uploadVisible = false

  if (!layerKey) {
    return
  }

  if (sceneResult) {
    const overlay = sceneData.value.overlays[layerKey]
    const menu = sceneData.value.layerMenu
    const value = sceneData.value
    if (overlay) {    
      sceneData.value.map.removeLayer(overlay.tileLayer)
      loadGeoJson(sceneResult, layerKey, value, menu, getInfobyFeature)
      if (sceneData.value.selectedData[layerKey]) {
        sceneData.value.map.addLayer(overlay.tileLayer)
        sceneData.value.map.fitBounds(overlay.tileLayer.getBounds())
      }
    }
  }
  else {
    loadAOI(layerKey)
  }  
}

const updateFilter = (updateFilterFlag: any) => {
  sceneData.value.filterVisible = false
  const overlay = sceneData.value.overlays[sceneData.value.filterLayer]
  if (overlay.filter && updateFilterFlag) {
    const tileLayer = overlay.tileLayer
    const data = overlay.data
    const filter = overlay.filter
    if (tileLayer && data) { //geojson
      let counter = 0
      const currentZoom = sceneData.value.map.getZoom()
      const reductionZoom = filter.reductionCondition && filter.reductionCondition.zoom ? filter.reductionCondition.zoom : currentZoom
      const everyNthFeature = filter.reductionCondition && filter.reductionCondition.everyNthFeature ? filter.reductionCondition.everyNthFeature : null
      sceneData.value.loader = true
      setTimeout(() => {
        tileLayer.clearLayers()
        tileLayer.options.filter = (feature: any) => {
          return (everyNthFeature && currentZoom < reductionZoom ? counter++ % everyNthFeature === 0 : true) && evaluateFilter(feature, filter)
        }
        tileLayer.addData(data)
        sceneData.value.loader = false
      }
      , 200)
    }
    else if (tileLayer) { //pfb      
      sceneData.value.loader = true
      sceneData.value.selectedData[sceneData.value.filterLayer] = false
      changeSelectedData(sceneData.value.filterLayer)
      setTimeout(() => {
        sceneData.value.selectedData[sceneData.value.filterLayer] = true
        changeSelectedData(sceneData.value.filterLayer)
        sceneData.value.loader = false
      }
      , 300)
    }
  }
}

const showFilter = (value: any) => {
  sceneData.value.filterVisible = true
  sceneData.value.filterLayer = value
}

const updateImageCompareDialog = (value: any) => {
  sceneData.value.imageCompareVisible = false
  sceneData.value.layerMenu.title = null
  sceneData.value.layerMenu.menuItems = []
  sceneData.value.layerMenu.menuTabs = []
  if (sceneData.value.overlays['imageFootprints'])
    sceneData.value.overlays['imageFootprints'].selectedFile = value
}

const shiftDown = () => {
  sceneData.value.shiftPressed = true
}

const shiftUp = () => {
  sceneData.value.shiftPressed = false
}

const getInfobyFeature = (layerKey:string, feature:any, multiPoint:Boolean, layerArray:Array<any>) => {
  if (sceneData.value.overlays && sceneData.value.overlays[layerKey]) {
    const overlay = sceneData.value.overlays[layerKey]    
    const items = []
    let x = 0
    let y = 0  
    const title = overlay.infoPanelTitle ?? `${overlay.title} ${overlay.menuCategory}`
    /*
    items.push({
      key: 'Layer',
      value: title
    })
    */    
    if (multiPoint) {

      if (layerArray && layerArray.length > 0) {
        const list = layerArray
        
        if (list && list.length > 0) {
          const tabs:any = []
          let x = 1
          let y = 1          
          let index = 0
          for (const item of list) {
            const tabKey = item?.properties.ViewId ?? index + ''
            const _items = []
            index++
            if (item && item.properties) {
              for (const itemKey of Object.keys(item.properties)) {
                const value = item.properties[itemKey]
                _items.push({
                  key: itemKey,
                  value: value
                })
              }
            }
            tabs.push(_items)
          }
          if (tabs.length == 1) 
            return { latLng: x != 0 || y != 0 ? y.toFixed(5) + ', ' + x.toFixed(5) : null, menuTabs: [], title: title, menuItems: tabs[0] }
          else
            return { latLng: x != 0 || y != 0 ? y.toFixed(5) + ', ' + x.toFixed(5) : null, menuTabs: tabs, title: title, menuItems: [] }
        }
      }      
      else {
        const list = overlay.data.features.filter((f:any) => f && f.properties && f.properties.EyeX === feature.properties.EyeX && 
                                                                        f.properties.EyeY === feature.properties.EyeY)
        if (list && list.length > 0) {
          const tabs:any = []
          let x = 0
          let y = 0
          const _feature = list[0]
          if (_feature && _feature.geometry && _feature.geometry.coordinates && _feature.geometry.coordinates[0]) {
            for (let i = 0; i < _feature.geometry.coordinates[0].length - 1; i++) {
              const point = _feature.geometry.coordinates[0][i]
              x += point[0]
              y += point[1]
            }
            x/=(_feature.geometry.coordinates[0].length-1)
            y/=(_feature.geometry.coordinates[0].length-1)    
          }
          let index = 0
          for (const item of list) {
            const tabKey = item?.properties.ViewId ?? index + ''
            const _items = []
            index++
            if (item && item.properties) {
              for (const itemKey of Object.keys(item.properties)) {
                const value = item.properties[itemKey]
                _items.push({
                  key: itemKey,
                  value: value
                })
              }
            }
            tabs.push(_items)
          }
          if (tabs.length == 1) 
            return { latLng: x != 0 || y != 0 ? y.toFixed(5) + ', ' + x.toFixed(5) : null, menuTabs: [], title: title, menuItems: tabs[0] }
          else
            return { latLng: x != 0 || y != 0 ? y.toFixed(5) + ', ' + x.toFixed(5) : null, menuTabs: tabs, title: title, menuItems: [] }
        }
      }
    }
    else if (feature && feature.geometry && feature.geometry.coordinates && feature.geometry.coordinates[0]) {
      if (feature && feature.properties) {
        for (const itemKey of Object.keys(feature.properties)) {
          const value = feature.properties[itemKey]
          items.push({
            key: itemKey,
            value: value
          })
        }
      }
      for (let i = 0; i < feature.geometry.coordinates[0].length - 1; i++) {
        const point = feature.geometry.coordinates[0][i]
        x += point[0]
        y += point[1]
      }
      x/=(feature.geometry.coordinates[0].length-1)
      y/=(feature.geometry.coordinates[0].length-1)    
    }
    else if (feature?.properties) {
      for (const itemKey of Object.keys(feature.properties)) {
        const value = feature.properties[itemKey]
        items.push({
          key: itemKey,
          value: value
        })
      }      
    }
    return { latLng: x != 0 || y != 0 ? y.toFixed(5) + ', ' + x.toFixed(5)   : null, menuItems: items, menuTabs: [], title: title }
  }
  else
    return { latLng: null, menuItems: [], title: '', menuTabs: [] }
}

const labelLeftClick = (event:any, layerKey:any) => {  
  const overlay = sceneData.value.overlays[layerKey]
  if (overlay && overlay.tileLayer?.options?.bbox) {
    //const extent = getGeometryExtent(overlay.data.features)    
    sceneData.value.map.fitBounds(overlay.tileLayer?.options?.bbox)
  }
  else
  if (overlay && overlay.tileLayer && overlay.tileLayer.getBounds) {
    //const extent = getGeometryExtent(overlay.data.features)
    sceneData.value.map.fitBounds(overlay.tileLayer.getBounds())
  }
}

const addLayer = () => {
  sceneData.value.uploadVisible = true
}

const labelRightClick = (event:any, layerKey:any) => {  
  if (layerKey === 'aoi' || layerKey === 'validDataMask') {
    //sceneData.value.uploadVisible = true
  }
  event.preventDefault()
}

const getInfobyViewId = (layerKey:string, viewId:string) => {
  if (sceneData.value.overlays && sceneData.value.overlays[layerKey]) {  
    const feature = sceneData.value.overlays[layerKey].data.features.find((f:any) => f.properties && f.properties['ViewId'] === viewId)
    return getInfobyFeature(layerKey, feature, false, [])
  }
  else
    return { latLng: null, menuItems: [], title: '' }
}

const getRasterTilesLayer = (root: any, dataLayer: any, key: any) => {
  const layer = L.tileLayer(`${root}/${dataLayer.url}/{z}/{x}/{y}.png`, {
    ...dataLayer.options
  })
  layer.setZIndex(dataLayer.zIndex ? dataLayer.zIndex : 2)
  checkMenuVisible()
  return layer
}

let root = '/layers/qc/'

const init = (scene: any, fileProviderUrl: String, imagePoseUrl?: String) => {  
  sceneData.value.cdn = imagePoseUrl || ''
  sceneData.value.zoom = sceneData.value.zoomPrev = scene.zoom

  sceneData.value.basemaps = []
  sceneData.value.overlays = []
  sceneData.value.overlaysKeys = {}
  for (const key of Object.keys(scene.basemaps)) {
    const layer = scene.basemaps[key]
    sceneData.value.basemaps.push({ title: layer.title ? layer.title : layer.alias, id: layer.alias, enabled: true, tileLayer: L.tileLayer(layer.url, layer.options) })
  }
  for (const key of Object.keys(scene.overlays)) {
    if (key === 'imageFootprints') {
      const maxZoom = scene.overlays['imageFootprintsTau']?.options?.maxZoom
      if (maxZoom != 18 && maxZoom != 19) {
        continue
      }
    }
    const layer = scene.overlays[key]
    sceneData.value.overlays[layer.alias] = { 
      infoPanelTitle: layer.infoPanelTitle ?? layer.alias, 
      title: layer.title ?? layer.alias, 
      zIndex: layer.zIndex ?? 3, 
      menuCategory: layer.menuCategory ?? '', 
      sortId: layer.sortId ?? 99, 
      description: layer.description, 
      id: layer.alias, 
      legend: layer.legend, 
      menu: layer.menu, 
      enabled: false, filter: layer.filter, label: layer.label ? layer.label : '', style: layer.style, url: layer.url, type: layer.type, options: layer.options, selectedFile: '', nearestFeatures: [], scene : sceneData.value }

    if (!sceneData.value.overlaysKeys[layer.menuCategory ?? ''])
      sceneData.value.overlaysKeys[layer.menuCategory ?? ''] = []
    sceneData.value.overlaysKeys[layer.menuCategory ?? ''].push(layer.alias)
    const pane = layer.zIndex ?? 3
    if (!sceneData.value.paneList.includes(pane)) {
      sceneData.value.paneList.push(pane)
    }
  }

  if (!Object.keys(scene.overlays).includes('aoi')) {
    createAoiLayer()
  }

  for (const category of Object.keys(sceneData.value.overlaysKeys)) {
    sceneData.value.overlaysKeys[category] = sceneData.value.overlaysKeys[category].sort((a: any, b: any) => {
      return sceneData.value.overlays[a].sortId - sceneData.value.overlays[b].sortId
    })
  }  
  //var google = L.tileLayer('https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', { opacity: 1.0, minZoom: scene.zoomMin, maxZoom: scene.zoomMax, attribution: 'abc' })
  sceneData.value.layer = sceneData.value.basemaps.find((f: any) => f.id === scene.defaultMap).tileLayer
  sceneData.value.selectedMap = scene.defaultMap
  var zoomend = function zoomend(e: any) {
    sceneData.value.zoomPrev = sceneData.value.zoom
    sceneData.value.zoom = sceneData.value.map.getZoom()
    zoomBasedLayerchange()
  }
  var zoomBasedLayerchange = function zoomBasedLayerchange() {
    if (sceneData.value && sceneData.value.map) {
      var currentZoom = sceneData.value.map.getZoom()
      for (const overlayKey in sceneData.value.overlays) {
        const overlay = sceneData.value.overlays[overlayKey]
        if (overlay.filter) {
          const tileLayer = overlay.tileLayer
          const data = overlay.data
          const filter = overlay.filter
          if (tileLayer && data) {
            var counter = 0
            const reductionZoom = filter.reductionCondition && filter.reductionCondition.zoom ? filter.reductionCondition.zoom : currentZoom
            const everyNthFeature = filter.reductionCondition && filter.reductionCondition.everyNthFeature ? filter.reductionCondition.everyNthFeature : null
            if (((currentZoom >= reductionZoom && sceneData.value.lastZoom < reductionZoom) ||
              (currentZoom < reductionZoom && sceneData.value.lastZoom >= reductionZoom)
            ) && sceneData.value.map.hasLayer(overlay.tileLayer)) {
              sceneData.value.loader = true
              setTimeout(() => {
                tileLayer.clearLayers()
                tileLayer.options.filter = (feature: any) => {
                  return (everyNthFeature && (currentZoom < reductionZoom && sceneData.value.lastZoom >= reductionZoom) ? counter++ % everyNthFeature === 0 : true) && evaluateFilter(feature, filter)
                }
                tileLayer.addData(data)
                sceneData.value.loader = false
              }, 200)
            }
          }
          else if (tileLayer) { //pfb               
            const metadata = overlay?.tileLayer?.options?.metadata             
            let maxZoom = metadata?.maxzoom            
            if (metadata?.generator_options) {
              const generatorOptions = metadata?.generator_options.split(' ')
              const fullZommOption = generatorOptions.filter((f:any) => f.indexOf('--full-detail=') > -1)
              if (fullZommOption && fullZommOption.length > 0) {                
                const _maxZoom = Number(fullZommOption[0].replaceAll(`'`,'').replace(`--full-detail=`,''))
                if (_maxZoom < maxZoom)
                  maxZoom = _maxZoom
              }
            }
            if (sceneData.value.selectedData[overlayKey] && maxZoom && Number(maxZoom) === currentZoom && sceneData.value.zoomPrev < currentZoom) { 
              sceneData.value.loader = true
              sceneData.value.selectedData[overlayKey] = false
              changeSelectedData(overlayKey)
              setTimeout(() => {
                sceneData.value.selectedData[overlayKey] = true
                changeSelectedData(overlayKey)
                sceneData.value.loader = false
              }
              , 300)
            }
          }
        }
      }
      sceneData.value.lastZoom = currentZoom
    }
  }
  if (!sceneData.value.map) {
    sceneData.value.map = L.map('map', {
      center: scene.latLonCenter,
      zoom: scene.zoom,
      minZoom: scene.zoomMin,
      maxZoom: scene.zoomMax,
      layers: [sceneData.value.layer],
      attributionControl: false,
      zoomControl: true
      //,tms: true
    })    

    const aoiPane = 30
    if (!sceneData.value.paneList.includes(aoiPane)) {
      sceneData.value.paneList.push(aoiPane)
    }

    for (const pane of sceneData.value.paneList) {
      sceneData.value.map.createPane(`pane${400 + pane}`).style.zIndex = 400 + pane
    }      
  }

  sceneData.value.map.on('mousemove', (e: any) => {
    const layerMenu = sceneData.value.layerMenu
    layerMenu.latLng = e.latlng.lat.toFixed(5) + ', ' + e.latlng.lng.toFixed(5)  
    layerMenu.lat = e.latlng.lat
    layerMenu.lng = e.latlng.lng

    const image = sceneData.value.image
    const menuLayers = sceneData.value.menuLayers
    if (menuLayers && menuLayers.length > 0 && image.ctx) {
      layerMenu.items = []
      const point = e.containerPoint
      const data = image.ctx.getImageData(point.x, point.y, 1, 1).data
      if (data[3] == 255) {
        const hsv = rgb2hsv(data[0], data[1], data[2])
        const h = hsv.h
        const legendBytes = sceneData.value.legendBytes
        const max = legendBytes.length
        let dist = 9999
        let index = -1
        for (let i = 0; i < max; i++) {
          const _dist = Math.abs(legendBytes[i] - h)
          if (_dist < dist) {
            dist = _dist
            index = i
          }
        }
        for (const layer of menuLayers) {
          for (const item of layer.menu) {            
            const val = (index / max) * 256
            let value = item.value
            if (typeof value == "string") {
              value = value.replace('VALUE', (val + Number(sceneData.value.layerMenu.legend.min)).toFixed(0))
              if (item.value.indexOf('/') > -1) {
                value = ((val / (1 * item.value.split('/')[1])) + Number(sceneData.value.layerMenu.legend.min)).toFixed(2)
              }
              else
              if (item.value.indexOf('*') > -1) {
                value = ((val * (1 * item.value.split('*')[1])) + Number(sceneData.value.layerMenu.legend.min)).toFixed(2)
              }              
            }
            layerMenu.items.push({
              key: item.key,
              value: value
            })
          }
        }
      }
    }
    updateLegend()  
  })

  const rightClick = () => {  
    //sceneData.value.layerMenu.title = ''
    sceneData.value.infoVisible = true
  }

  sceneData.value.map.on('contextmenu', rightClick)
  sceneData.value.map.on('zoomend', zoomend)
  sceneData.value.map.on('moveend', checkMenuVisible)
  if (scene.latLonBoundingBox)
    sceneData.value.map.fitBounds(scene.latLonBoundingBox)

  for (const key in sceneData.value.overlays) {
    const overlays = sceneData.value.overlays
    const menu = sceneData.value.layerMenu
    const overlay = overlays[key]
    const value = sceneData.value
    const keysValues = ['DirId', 'SourceId', 'Kind']
    if (overlay.type !== 'geojson' && overlay.type !== 'rasterTiles' && overlay.type !== 'pbf') continue
    switch (overlay.type) {
    case 'geojson':
      if (!overlay.url) {
        continue
      }
      getGeoJson(root, overlay, key, value, menu, getInfobyFeature).then(() => {      
        if (!overlay.data) {
          sceneData.value.overlays[key].failed= true
          return
        }
        const data = overlay.data        
        overlay.filterData = {
          _filter: overlay.filter
        }   
        if (data.features.length > 0) {
          for (const key of Object.keys(data.features[0].properties)) {
            if (keysValues.includes(key)) {
              overlay.filterData[key] = [...new Set(data.features.map((item: any) => item.properties[key]))]
            }
            else {
              overlay.filterData[key] = []
            }
          }
        }
        sceneData.value.overlays[key].enabled = true
        if (scene.defaultLayers && scene.defaultLayers.includes(key)) {
          sceneData.value.selectedData[key] = true
          changeSelectedData(key)
        }
        
        sceneData.value.map.fitBounds(overlay.tileLayer.getBounds())
        
      })
      break
    case 'pbf':
      getPbf(root, overlay, key, value, menu, getInfobyFeature).then((layer) => {
        sceneData.value.overlays[key].tileLayer = layer

        if (layer)
          sceneData.value.overlays[key].enabled = true

        overlay.filterData = {
          _filter: overlay.filter
        }

        const fields = JSON.parse(layer?.options?.metadata?.json)["vector_layers"][0].fields
        for (const key of Object.keys(fields)) {
          overlay.filterData[key] = []
        }

        sceneData.value.map.fitBounds(layer.options.bbox)
      })     
      break
    case 'rasterTiles':
      sceneData.value.overlays[key].tileLayer = getRasterTilesLayer(root, overlay, key)
      if (sceneData.value.overlays[key].tileLayer)
        sceneData.value.overlays[key].enabled = true
      break
    }
  }  
  loadAOI('aoi')
}

const GetJSON = async (file: any) => {
  let response = await fetch(file, {
    //credentials: 'include'
  });
  let data = await response.json();
  return data;
}

const getLayers = async (inputQcCdnDatasetUrl: String, imagePoseCdnDatasetUrl?: String) => {
  root = inputQcCdnDatasetUrl + '/qc'
  const scene = await GetJSON(root + '/mapConfig.json').then((scene) => {
    sceneData.value.loading.done = true
    return scene
  }).catch(() => {
    sceneData.value.loading.errors.mapConfigNotAvailable.value = true
    return null
  })

  if (scene) {
    init(scene, inputQcCdnDatasetUrl, imagePoseCdnDatasetUrl)
  }
}

const { result: sceneResult } = useQuery(GET_SCENE, {
  sceneId: sceneData.value.sceneId
}, {
  errorPolicy: 'all'
}) as UseQueryReturn<GetSceneQuery, GetSceneQueryVariables>
watch(sceneResult, () => {
  if (sceneResult?.value?.getScene?.inputQcCdnDataset?.url) {
    sceneData.value.inputQcCdnDatasetUrl = sceneResult.value.getScene.inputQcCdnDataset.url
    sceneData.value.loading.errors.fileProviderServiceNotRunning.value = !(sceneResult?.value?.getScene?.inputQcCdnDataset?.cdnOrigin?.status.alias === CdnOriginStatusAlias.Running)
  } else {
    sceneData.value.loading.errors.fileProviderUrlNotAvailable.value = true
  }
  if (sceneResult?.value?.getScene?.imagePoseCdnDataset?.url) {
    sceneData.value.imagePoseCdnDatasetUrl = sceneResult.value.getScene.imagePoseCdnDataset.url
    sceneData.value.loading.errors.imagePoseServiceNotRunning.value = !(sceneResult?.value?.getScene?.imagePoseCdnDataset?.cdnOrigin?.status.alias === CdnOriginStatusAlias.Running)
  } else {
    sceneData.value.loading.errors.imagePoseUrlNotAvailable.value = true
  }

  if (sceneResult?.value?.getScene?.inputQcCdnDataset?.url) {
    getLayers(
      sceneResult.value.getScene.inputQcCdnDataset.url, 
      sceneResult.value.getScene.imagePoseCdnDataset?.url || undefined
    )
  }
})
</script>

<style lang="scss" scoped>
.image-legend-wrapper {
  position: relative;
  .image-legend {
    display: flex;
    min-width: 100%;
  }
}
</style>