<template>
  <multiple-input-list title="Definition of trajectory" :defaultRowValue="defaultRowValue" :save-preset-enable="savePresetEnable && !readonlyFlag"
    v-model:modelValue="data[PRESET_TYPES.TRAJECTORY_DEF]"  :minRowsCount="1" :rowClass="'float-label'"
    :toggleableRowTitleCb="toggleableRowTitleCb" :show-title="showTitle" :disableRowRemove="readonlyFlag"
    >
    <template #form="{ value }">
      <div class="p-fluid formgrid grid">
        <div class="field col-12">
          <span class="p-float-label">
            <InputText id="name" v-model="value.name" :disabled="readonlyFlag" />
            <label for="name">name*</label>
          </span>
          <small>User-defined trajectory name, trajectory files can be later mapped to a trajectory rule based on this name.</small><br>
        </div>
        <div class="field col-12">
          <multiple-input-list-simple title="Trajectory attributes" :defaultRowValue="defaultOptionRowValue" :disable-row-remove="readonlyFlag"
            v-model:modelValue="value.options"  :toggleableRowTitleCb="toggleableOptionRowTitleCb"  :rowClass="'float-label'">
            <template #form="{ value }">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-6">
                  <span :class="{ 'p-float-label': !readonlyFlag }">
                    <InputText v-model="value.key" :disabled="readonlyFlag" />
                    <label v-if="!readonlyFlag" for="key">Key</label>
                  </span>
                </div>
                <div class="field col-12 md:col-6">
                  <span :class="{ 'p-float-label': !readonlyFlag }">
                    <InputText v-model="value.value" :disabled="readonlyFlag" />
                    <label v-if="!readonlyFlag" for="value">Value</label>
                  </span>
                </div>
              </div>
            </template>
          </multiple-input-list-simple>
        </div>
      </div>
    </template>
  </multiple-input-list>
</template>

<script lang="ts" setup>
import MultipleInputList from '@/components/UI/Form/MultipleInputList.vue'
import MultipleInputListSimple from '@/components/UI/Form/MultipleInputListSimple.vue'
import { computed, ref, watch, Ref } from 'vue'
import { PRESET_TYPES } from '@/helpers/constants';

interface TrajectoryDefinitions {
  [PRESET_TYPES.TRAJECTORY_DEF]: [ TrajectoryDefModelType ]
}

interface TrajectoryOptionDefinition {
  key?: string,
  value?: string
}

interface TrajectoryDefModelType {
  name?: string,
  options?: [
    TrajectoryOptionDefinition
  ]
}

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  showTitle: {
    type: Boolean,
    required: false,
    default: true
  },
  savePresetEnable: {
    type: Boolean,
    required: false,
    default: false
  }
})

const defaultRowValue = () => {
  return {
    name: '',
    options: []
  }
}

const defaultOptionRowValue = () => {
  return {
    key: '',
    value: ''
  }
}

const toggleableRowTitleCb = (value: TrajectoryDefModelType) => {
  return `Trajectory: ${value.name}`
}

const toggleableOptionRowTitleCb = (value: TrajectoryOptionDefinition) => {
  return `Option: ${value.key}`
}


const changeEmitName = 'update:modelValue'
const emits = defineEmits<{(e: typeof changeEmitName, id: string): void }>()

const model = computed({
  get: () => props?.modelValue,
  set: (value: any) => {
    emits(changeEmitName, value)
  },
})

const readonlyFlag = ref(false)
const setReadonly = (readonly:boolean) => {
  readonlyFlag.value = readonly
}

defineExpose({
  setReadonly,
})

const data: Ref<any> = ref(model.value ?? {
  [PRESET_TYPES.TRAJECTORY_DEF]: []
})
watch(data, (newValue) => {
  model.value = newValue as TrajectoryDefinitions
}, { deep: true })
</script>

<style lang="scss" scoped>
.formgrid {
  width: 100% !important;
  margin-left: 0;
}
</style>