<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>
          <div class="flex justify-content-between flex-wrap">
              <div class="flex align-items-center justify-content-center">
                Definition of preset: <strong>{{ presetKey }}</strong>
              </div>
              <div class="flex align-items-center justify-content-center">
                <Button icon="fa-solid fa-trash" severity="danger" aria-label="Delete preset" v-tooltip.left="'Delete preset'" @click="onDeleteClick" />
              </div>
          </div>
        </template>
        <template #content>
          <preset-form
            :presetKey="presetKey"
            :update="true"
          >
          </preset-form>
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useMutation } from '@vue/apollo-composable'
import PresetForm from './components/PresetForm.vue'
import { DELETE_PRESET } from '@/apollo/mutations/presets'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'

const toast = useToast()
const router = useRouter()

const props = defineProps({
  presetKey: {
    type: String,
    required: true
  }
})

const { mutate: deletePresetMutations } = useMutation(DELETE_PRESET)

const onDeleteClick = () => {
  Swal.fire({
    title: 'Are you sure you want to delete preset "'+ props.presetKey +'"?',
    text: 'This can not be undone.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes'
  }).then(async function (result: any) {
    if (result.isConfirmed) {
      deletePreset(props.presetKey)
    }
  }.bind(this))
}

const deletePreset = async (presetKey: string) => {
  try {
    const res = await deletePresetMutations({
      key: presetKey
    })

    if (res && res.data && res.data.deletePreset.status === 200) {
      toast.add({ severity: 'success', summary: 'Success', detail: 'Class succesfully deleted', life: 3000 })
      router.push({
        name: 'presets'
      })
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while deleting', life: 3000})
    }
  } catch (err) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while deleting', life: 3000})
  }
}
</script>
