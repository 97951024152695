<template>
  <div class="grid">
    <div class="col-12 md:col-6">
      <div class="p-fluid">
        <Form :initial-values="initialValues" :validation-schema="schema" @submit="onSubmit" class="grid p-fluid">
          <div class="col-12 col:md-6 formgrid">
            <InputTextWithValidation
                name="name" label="Name" data-cy="projectForm__newProjectName"
            ></InputTextWithValidation>

            <InputTextWithValidation
                name="externalUri" label="External uri" data-cy="projectForm__newProjectUri"
            ></InputTextWithValidation>

            <InputTextAreaWithValidation
                name="notes" label="Notes" data-cy="projectForm__newProjectNotes"
            >
            </InputTextAreaWithValidation>

            <div class="flex flex-row flex-wrap justify-content-end">
              <Button label="Save" type="submit" icon="fa-solid fa-check" data-cy="projectForm__newProjectCreate"
                      class="p-button-success mr-2 mb-2 mlwn-button-submit"></Button>

              <Button data-cy="projectForm__newProjecCancel" @click="onCancelClick" label="Cancel" icon="fa-solid fa-xmark" class="p-button-secondary mr-2 mb-2 mlwn-button-submit" />
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * Load validation references, router, vue
 */
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { ComputedRef, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
const router = useRouter()
const toast = useToast()

/**
 * Setup props
 */
const props = defineProps({
  projectId: {
    type: String,
    required: false,
    default: ''
  },
  update: {
    type: Boolean,
    default: false
  }
})


/**
 * Load project composable
 */
import { useProject } from '@/composables/useProject';
import { Project } from '@/gql/graphql'
import InputTextWithValidation from '@/components/UI/Form/components/InputTextWithValidation.vue';
import InputTextAreaWithValidation from '@/components/UI/Form/components/InputTextAreaWithValidation.vue';
const { getProject, createProject, updateProject } = useProject()
let project: Ref<Project|{}> | ComputedRef<Project> | null = null
if (props.update) {
  project = getProject(parseInt(props.projectId)).project
} else {
  project = ref({
    name: '',
    externalUri: ''
  })
}

const initialValues = project

/**
 * Validation setup
 */
const schema = yup.object({
  name: yup.string().required().min(5).max(255).label('Name'),
  externalUri: yup.string().url().label('External uri'),
  // notes: yup.label('Notes'),
});

const onUpdateProject = async (projectForm: Project) => {
  try {
    let res = await updateProject(Object.assign(project?.value ?? {}, projectForm))
    if(res.success){
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully updated', life: 3000 })
      router.push({
        name: 'project-detail',
        params: {
          projectId: res?.payload?.project?.id
        }
      })
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while saving', life: 3000})
    }
  } catch (err) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while saving', life: 3000})
    console.error(err)
  }
}

const onCreateProject = async (project: Project) => {
  try {
    const res = await createProject(project)

    if (res.success) {
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully created', life: 3000 })
      router.push({
        name: 'project-detail',
        params: {
          projectId: res?.payload?.project?.id
        }
      })
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating', life: 3000})
    }
  } catch (err: any) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating. ' + err?.message, life: 3000})
    console.error(err)
  }
}

function onSubmit(project: Project) {
  if (props.update) {
    onUpdateProject(project)
  } else {
    onCreateProject(project)
  }
}

function onCancelClick() {
  router.go(-1)
}
</script>
