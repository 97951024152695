<script setup></script>
<template>
  <div class="login-body">
    <div class="login-wrapper">
      <div class="login-panel">
        <span class="logo">
          <img id="app-logo" :class="'dark'" src="@/assets/vadstena-logo.png" />
        </span>
        <div class="login-form">
          <h2>Account not activated</h2>
          <p>
            Your account is not activated. If you think this is an error,
            contact us.
          </p>
          <router-link to="/login">
            <Button
              icon="fa-solid fa-chevron-left"
              label="Back to login"
              type="button"
            ></Button>
          </router-link>
        </div>

        <p>
          A problem? <router-link to="/">Click here</router-link> and let us
          help you.
        </p>
        <Toast />
      </div>
    </div>
  </div>
</template>
