import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex flex-row flex-wrap justify-content-between" }

import AttributesList from '@/components/UI/AttributesList.vue';
import { ref } from 'vue'


import { useRouter } from 'vue-router'
import { PopulationTypeAlias, Scene, SceneStatusAlias } from '@/gql/graphql';

export default /*@__PURE__*/_defineComponent({
  __name: 'StepOverview',
  props: {
  formData: {
    required: true,
    type: Object
  },
  projectId: {
    required: true,
    type: String
  },
  update: {
    required: false,
    default: false
  },
  scene: {
    required: false,
    type: Object,
    default: null
  }
},
  emits: ['complete','next', 'prevPage', 'onCancel'],
  setup(__props, { emit: __emit }) {

const props = __props


const emit = __emit

const onCancel = () => {
  emit('onCancel', router.currentRoute.value.name)
}

const router = useRouter()

const stepBack = () => {
  emit('prevPage', router.currentRoute.value.name)
}

// Function
const onSubmit = () => {
  emit("complete", {
    ...props.formData
  })
}



const sceneRenderAttributes = ref({
  id: { label: "Scene id", value: (item: Scene) => item?.id},
  //name: { label: "Scene name", value: (item: Scene) => item?.name},
  notes: { label: "Scene notes", value: (item: Scene) => item?.notes},
})


return (_ctx: any,_cache: any) => {
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!__props.update)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 0,
              closable: false,
              severity: "info"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" All elements are in place, ready for scene creation."),
                _createElementVNode("br", null, null, -1),
                _createTextVNode("Before the ingest process is initiated, the setup of this scene can be adjusted as needed. ")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (__props.update)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 1,
              closable: false,
              severity: "info"
            }, {
              default: _withCtx(() => [
                (__props.scene && __props.scene.status.alias === _unref(SceneStatusAlias).New)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Update scene configuration. "))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, " The scene has been ingested. Changes to the scene configuration can no longer be made. "))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(AttributesList, {
          item: __props.formData,
          attributes: sceneRenderAttributes.value
        }, null, 8, ["item", "attributes"]),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Button, {
            label: "Back",
            icon: "fa-solid fa-arrow-left",
            class: "p-button-info mr-2 mb-2 mlwn-button-submit",
            onClick: stepBack
          }),
          false
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                label: "Cancel",
                icon: "fa-solid fa-xmark",
                onClick: onCancel,
                class: "p-button-secondary mr-2 mb-2 mlwn-button-submit"
              }))
            : _createCommentVNode("", true),
          (!__props.update || (__props.update && __props.scene && __props.scene.status.alias === _unref(SceneStatusAlias).New && __props.scene.populationType.alias === _unref(PopulationTypeAlias).Ingest))
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                label: __props.update ? 'Update scene' : 'Create scene',
                "data-cy": "stepOverview__createButton",
                onClick: onSubmit,
                type: "submit",
                icon: "fa-solid fa-check",
                class: "p-button-success mr-2 mb-2 mlwn-button-submit"
              }, null, 8, ["label"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})