import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 p-fluid" }
const _hoisted_3 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_4 = { class: "flex align-items-center justify-content-center" }
const _hoisted_5 = { class: "flex align-items-center justify-content-center" }
const _hoisted_6 = { class: "col-12 p-fluid" }
const _hoisted_7 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_8 = { class: "flex align-items-center justify-content-center" }

import SceneTable from '../Processing/Scene/components/SceneTable.vue'
import AttributesList from '@/components/UI/AttributesList.vue';

import { useProject } from '@/composables/useProject';
import { Project } from '@/gql/graphql';
import { ref } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectDetail',
  props: {
  projectId: {
    type: String,
    required: true
  }
},
  setup(__props) {

const props = __props

const { getProject } = useProject()
const { project } = getProject(parseInt(props.projectId))

const projectRenderAttributes = ref({
  // externalUri: { label: "External URI", value: (item: Project) => item.externalUri?.length ? `<a href='${item.externalUri}' target="_blank">link</a>` : '-',  html: true},
  notes: { label: "Notes", value: (item: Project) => item?.notes},
  archived: { label: "Archived", value: (item: Project) => item?.archived}
})

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(project)?.name ?? "Project"), 1),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                type: "button",
                to: {name: 'project-edit', params: {projectId: _unref(project)?.id}}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Button, {
                    type: "button",
                    icon: "fa-solid fa-pencil"
                  })
                ]),
                _: 1
              }, 8, ["to"])), [
                [
                  _directive_tooltip,
                  'Edit project',
                  void 0,
                  { bottom: true }
                ]
              ])
            ])
          ])
        ]),
        subtitle: _withCtx(() => [
          _createVNode(AttributesList, {
            item: _unref(project),
            attributes: projectRenderAttributes.value
          }, null, 8, ["item", "attributes"])
        ]),
        content: _withCtx(() => _cache[0] || (_cache[0] = [])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex align-items-center justify-content-center" }, " Scenes ", -1)),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                type: "button",
                icon: "fa-solid fa-plus",
                to: {
								name: 'create-scene',
								params: {
									projectId: _unref(project)?.id
								}
							}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Button, {
                    "data-cy": "projectDetail__createSceneButton",
                    type: "button",
                    icon: "fa-solid fa-plus"
                  })
                ]),
                _: 1
              }, 8, ["to"])), [
                [
                  _directive_tooltip,
                  'Create new scene',
                  void 0,
                  { bottom: true }
                ]
              ])
            ])
          ])
        ]),
        content: _withCtx(() => [
          _createVNode(SceneTable, {
            showProjectColumn: false,
            projectId: props.projectId.toString()
          }, null, 8, ["projectId"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})