import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

import { PopulationTypeAlias } from '@/gql/graphql';
import { ref } from 'vue';
import FileDownloadButton from '@/components/UI/FileDownloadButton.vue';

import { useLogFiles } from '@/composables/helpers/useLogFiles';


export default /*@__PURE__*/_defineComponent({
  __name: 'LogDownloadButtons',
  props: {
  scene: {
    required: true,
    type: Object
  }
},
  setup(__props) {

const props = __props

const menu = ref()

const { populationLogFilePath, inputQcLogFilePath } = useLogFiles()

const populationLogFile = populationLogFilePath(props.scene.populationType.alias as PopulationTypeAlias)
const inputQcLogFile = inputQcLogFilePath()

const itemsLogs = ref([
  {
    items: [
      {
        label: 'Input QC log file',
        icon: 'pi pi-refresh',
        path: inputQcLogFile,
        volumeMountAlias: props.scene.vadstenaInputDmVolumeMountAlias
      },
      {
        label: props.scene.populationType.name + ' log file',
        icon: 'pi pi-upload',
        path: populationLogFile,
        volumeMountAlias: props.scene.vadstenaInputDmVolumeMountAlias
      }
    ]
  }
]);

const toggleLogs = (event: MouseEvent) => {
  menu.value.toggle(event);
};

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("span", null, [
    _withDirectives(_createVNode(_component_Button, {
      type: "button",
      icon: "fa-solid fa-file-lines",
      onClick: toggleLogs,
      "aria-haspopup": "true",
      "aria-controls": "overlay_menu"
    }, null, 512), [
      [
        _directive_tooltip,
        'Download log files',
        void 0,
        { top: true }
      ]
    ]),
    _createVNode(_component_Menu, {
      ref_key: "menu",
      ref: menu,
      id: "overlay_menu",
      model: itemsLogs.value,
      popup: true
    }, {
      item: _withCtx(({ item }) => [
        (item.path)
          ? (_openBlock(), _createBlock(FileDownloadButton, {
              key: 0,
              volumeMountAlias: item.volumeMountAlias,
              path: item.path,
              label: item?.label as string ?? 'Download input qc log'
            }, null, 8, ["volumeMountAlias", "path", "label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})