import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  key: 0,
  class: "p-fluid"
}
const _hoisted_4 = { class: "col-12 col:md-6 formgrid" }
const _hoisted_5 = { class: "flex flex-row flex-wrap justify-content-end" }

import { Form } from 'vee-validate';
import * as yup from 'yup';
import { ref, watch } from 'vue';

import DropdownWithValidation from '@/components/UI/Form/components/DropdownWithValidation.vue';

import { useScenes } from '@/composables/useScenes'
import { useToast } from 'primevue/usetoast';
import { useCollocations } from '@/composables/useCollocations';
import { useStorages } from '@/composables/useStorages';

export default /*@__PURE__*/_defineComponent({
  __name: 'ScenePopulationForm',
  props: {
  sceneId: {
    required: true,
    type: String
  },
  afterSuccessCb: {
    required: false,
    type: Function,
    default: () => {}
  },
  afterErrorCb: {
    required: false,
    type: Function,
    default: () => {}
  }
},
  setup(__props) {

/**
 * Load validation references, router, vue
 */
const props = __props

const initialValues = ref({
  collocationId: null,
  outputVolumeMountAlias: null,
})

/**
 * Validation setup
 */
const schema = yup.object({
  collocationId: yup.string().required().label('Collocation'),
  outputVolumeMountAlias: yup.string().required().label('Output volume'),
});

// Function
const onSubmit = (values: any) => {
  // TODO - add log volume possibility
  onPopulationScene(values.collocationId, values.outputVolumeMountAlias, values.outputVolumeMountAlias)
}

const { populateScene } = useScenes()
const toast = useToast()

const onPopulationScene = async (collocationId: string, outputVolumeMountAlias: string, logVolumeMountAlias: string) => {
  try {
    const res = await populateScene(props.sceneId, collocationId, outputVolumeMountAlias, logVolumeMountAlias)

    console.log(res)

    if (res.success) {
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully created', life: 3000 })
      props.afterSuccessCb()
    } else {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error occured while creating', life: 3000 })
      props.afterErrorCb()
    }
  } catch (err: any) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating. ' + err?.message, life: 3000})
    console.error(err)
    props.afterErrorCb(err)
  }
}

// collocations
const { getCollocations } = useCollocations()
const { collocations } = getCollocations()

// storages
const { getStorages } = useStorages()
const storagesObject = getStorages()
const { storages } = storagesObject

// scenes
const { getScenes, getScene } = useScenes()

const { scene } = getScene(props.sceneId)

watch (storages, () => {
  if (storages) {
    const { scenes } = getScenes()
    watch(scenes, () => {
      if (scenes) {
        storages.value.forEach(s => {
          const list = s.volumes?.filter(v => v && scenes.value.find(f => f.vadstenaInputDmVolumeMountAlias === v.mountAlias))
          //const list = s.volumes?.filter(v => (v && scenes.value.find(f => f.vadstenaInputDMVolumeMountAlias === v.id)) || s.name === 'mlwn-ceph.sources' || (v && v?.mountAlias?.indexOf('cdn_file_provider') > -1) || (v && v.mountAlias?.indexOf('cdn_image_pose') > -1))
          if (list && list.length > 0) {
            for (let i = 0; i < list.length; i++) {
              const index = s.volumes?.findIndex(x => x.mountAlias === list[i].mountAlias)
              if (index || index === 0) {
                s.volumes?.splice(index, 1)
              }
            }
          }
        })
      }
    })
  }
})


return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_unref(scene))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_unref(Form), {
              "initial-values": initialValues.value,
              "validation-schema": _unref(schema),
              onSubmit: onSubmit,
              class: "grid p-fluid"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(DropdownWithValidation, {
                    "data-cy": "scenePopulationForm__collocationId",
                    name: "collocationId",
                    label: "Data center (collocation)",
                    options: _unref(collocations),
                    optionValue: "id",
                    optionLabel: "name",
                    placeholder: "Select data center"
                  }, null, 8, ["options"]),
                  _createVNode(DropdownWithValidation, {
                    "data-cy": "scenePopulationForm__outputVolume",
                    name: "outputVolumeMountAlias",
                    label: "Output volume",
                    options: _unref(storages),
                    optionValue: "mountAlias",
                    optionLabel: "name",
                    loading: _unref(storagesObject).loading.value,
                    placeholder: "Select volume where to store ingested data",
                    optionGroupLabel: "name",
                    optionGroupChildren: "volumes"
                  }, null, 8, ["options", "loading"]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_Button, {
                      "data-cy": "scenePopulationForm__startPopulation",
                      label: `Start population: ${_unref(scene).populationType.name}`,
                      type: "submit",
                      icon: "fa-solid fa-trowel",
                      class: "p-button-success mr-2 mb-2 mlwn-button-submit"
                    }, null, 8, ["label"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["initial-values", "validation-schema"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})