import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-fluid" }
const _hoisted_2 = { class: "flex justify-end gap-2" }

import { useAuth } from "@/composables/useAuth";
import * as yup from "yup";
import { Form } from "vee-validate";
import InputTextWithValidation from "@/components/UI/Form/components/InputTextWithValidation.vue";

import { useToast } from "primevue/usetoast";

export default /*@__PURE__*/_defineComponent({
  __name: 'LoginEmailPasswordForm',
  emits: ["onCancelClick"],
  setup(__props) {

const { loginWithEmailPassword, redirectAfterSuccessLogin } = useAuth();

const toast = useToast();



const onSubmit = async (data: any) => {
  const resp = await loginWithEmailPassword({
    email: data.email,
    password: data.password,
  });

  if (resp.success) {
    toast.add({ severity: "success", summary: "Login successful." });
    redirectAfterSuccessLogin();
  } else {
    toast.add({
      severity: "error",
      summary: "The email or password you entered is incorrect.",
    });
  }
};

const validationSchema = yup.object({
  email: yup.string().required().default(""),
  password: yup.string().required().default(""),
});

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(Form), { "validation-schema": _unref(validationSchema) }, {
      default: _withCtx(({ handleSubmit }) => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(InputTextWithValidation, {
            name: "email",
            label: "Email",
            class: "mb-3",
            id: "cy-login-email-input",
            fieldClass: "mb-5"
          }),
          _createVNode(InputTextWithValidation, {
            name: "password",
            label: "Password",
            type: "password",
            id: "cy-login-password-input",
            class: _normalizeClass('mb-3'),
            fieldClass: "mb-5"
          }),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Button, {
              type: "button",
              label: "Cancel",
              severity: "secondary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onCancelClick', 'cancel')))
            }),
            _createVNode(_component_Button, {
              type: "button",
              label: "Login",
              id: "cy-login-submit",
              onClick: ($event: any) => (handleSubmit(onSubmit))
            }, null, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["validation-schema"])
  ]))
}
}

})