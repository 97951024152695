<template>
  <div class="grid">
		<div class="col-12">
			<Card>
				<template #title>
					Create new scene: <strong>{{ items[activeIndex]?.label }}</strong>
				</template>
				<template #content>
          <Steps :model="items" :activeIndex="activeIndex" :readonly="true" />
          <Divider type="dotted" class="pb-4"/>
          <router-view v-slot="{Component}" :formData="formData" :projectId="projectId"
              @next="nextStep" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete">
              <component :is="Component"/>
          </router-view>
				</template>
			</Card>
		</div>
	</div>  
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import { useScenes } from '@/composables/useScenes';

const { createScene } = useScenes()

const props = defineProps({
  projectId: {
    required: true,
    type: String
  }
})

const router = useRouter();

const nextPage = (event) => {
  //console.log(event, 'nextpage')
}

const formData = ref({});

const items = [
  { label: 'Scene details', routeName: 'StepOne', step: 1, commandBack: () => { }, command: () => { router.push({ name: 'SensorDefinitions' })}, to: { name: 'StepOne'} },
  { label: 'Sensor definitions', routeName: 'SensorDefinitions', step: 2, commandBack: () => { router.push({ name: 'StepOne' })}, command: () => { router.push({ name: 'InputConfigurations' }) }, to: { name: 'SensorDefinitions'} },  
  { label: 'Input Configurations', routeName: 'InputConfigurations', step: 3, commandBack: () => { router.push({ name: 'SensorDefinitions' })}, command: () => { router.push({ name: 'StepOverview' }) }, to: { name: 'InputConfigurations'} },
  { label: 'Review & Submit', routeName: 'StepOverview', step: 4, commandBack: () => { router.push({ name: 'InputConfigurations' })}, command: () => { }, to: { name: 'StepOverview'}}
];

const activeIndex = ref(items.findIndex(item => router?.currentRoute?.value?.name === item.routeName));

function nextStep(data: any, fromRouteName: String, populationConfig = true, inputVolumesConfiguration = false) {
  if (populationConfig) {
    formData.value.populationConfig = { ...formData.value.populationConfig, ...data }
  } else {
    formData.value = { ...formData.value, ...data }
  }

  // window.localStorage.setItem('mlwn-scene-form', JSON.stringify(formData.value))

  activeIndex.value++;
  items.find(item => item.routeName === fromRouteName)?.command()
}

function complete(data: object) {
  onCreateScene(JSON.parse(JSON.stringify({
    projectId: parseInt(props.projectId),
    populationTypeAlias: PopulationTypeAlias.Ingest,
    ...data
  })))
}

const prevPage = (fromRouteName: String) => {
  activeIndex.value--;
  items.find(item => item.routeName === fromRouteName)?.commandBack()
}

import { useToast } from 'primevue/usetoast';
import { Create_SceneMutationVariables, PopulationTypeAlias, Scene } from '@/gql/graphql';
const toast = useToast()

const onCreateScene = async (scene: Create_SceneMutationVariables) => {
  try {
    const res = await createScene(scene)
    
    if (res.success) {
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully created', life: 3000 })
      window.localStorage.removeItem('mlwn-scene-form')
      router.push({
        name: 'project-scene-detail',
        params: {
          projectId: props.projectId,
          sceneId: res?.payload?.scene?.id
        }
      })
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating', life: 3000})
    }
  } catch (err: any) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating. ' + err?.message, life: 3000})
    console.error(err)
  }
}

</script>

