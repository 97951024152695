import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex align-items-center justify-content-center gap-1" }

import { Scene } from '@/gql/graphql';
import { PropType } from 'vue';
import LogDownloadButtons from '@/components/Admin/Project/Scene/Log/LogDownloadButtons.vue';
import SceneActionButton from './SceneActionButton.vue';

// component

export default /*@__PURE__*/_defineComponent({
  __name: 'SceneActionPanel',
  props: {
  scene: {
    required: true,
    type: Object as PropType<Scene>,
  },
  showOpenButton: {
    required: false,
    type: Boolean,
    default: true
  }
},
  emits: ['scene-updated'],
  setup(__props, { emit: __emit }) {

// imports
const props = __props
const emit = __emit

// composables


// functions
const onSceneUpdated = () => {
  emit('scene-updated')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LogDownloadButtons, { scene: __props.scene }, null, 8, ["scene"]),
    _createVNode(SceneActionButton, {
      scene: __props.scene,
      onSceneUpdated: onSceneUpdated,
      "show-open-button": props.showOpenButton
    }, null, 8, ["scene", "show-open-button"])
  ]))
}
}

})