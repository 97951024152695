import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "flex flex-wrap justify-content-between align-items-center flex-1 gap-2" }
const _hoisted_3 = { class: "flex gap-2" }
const _hoisted_4 = { class: "white-space-nowrap" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "flex flex-wrap p-0 sm:p-5 gap-5" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["alt", "src"]
const _hoisted_9 = { class: "font-semibold" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "flex flex-wrap p-0 sm:p-5 gap-5" }
const _hoisted_12 = ["alt", "src"]
const _hoisted_13 = { class: "font-semibold" }

import { ref, watch } from 'vue'
import { useScenes } from '@/composables/useScenes';
import { MutationUpdateSceneArgs } from '@/gql/graphql';

export default /*@__PURE__*/_defineComponent({
  __name: 'UploadLayer',
  props: {
  visible: {
    type: Boolean,
    default: false,
    required: true
  },
  hide: {
    required: true
  },
  sceneId: {
    type: String,
    required: true
  } 
},
  setup(__props) {


const { updateScene, getScene } = useScenes()

const props = __props

/*
const onFileChanged = ($event: Event) => {
  const target = $event.target as HTMLInputElement;
  const name = target.files[0].name
  if (target && target.files) {
    const reader = new FileReader();
    reader.onload = (e) => {      
      upload.value.file = JSON.parse(e.target?.result)
      upload.value.fileName = name
    }
    reader.readAsBinaryString(target.files[0])
  }
}
*/

const onOk = () => {
  upload.value.visible = false
  upload.value.hide(upload.value.file)
}

const onDialogClose = () => {
  upload.value.visible = false
  upload.value.hide()
}

const upload: any = ref({
  hide: props.hide,
  visible: props.visible,
  file: null,
  fileName: ''
})

const totalSize = ref(0);
const totalSizePercent = ref(0);
const files = ref([]);
const aoiData:any = ref({
  files: {},
  type: 'shp2geojson'
})

const onRemoveTemplatingFile = (file:any, removeFileCallback:any, index:any) => {
  removeFileCallback(index);
  totalSize.value -= parseInt(formatSize(file.size));
  totalSizePercent.value = totalSize.value / 10;
};

/*
const onClearTemplatingUpload = (clear:any) => {
  clear();
  totalSize.value = 0;
  totalSizePercent.value = 0;
};
*/

const onSelectedFiles = (event:any) => {
  files.value = event.files;
  files.value.forEach((file) => {
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = function () {
      if (fileReader?.result) {        
        const result = fileReader.result.substr(fileReader.result.indexOf(',') + 1); 
        aoiData.value["files"][file?.name] = result
      }
    };
    totalSize.value += parseInt(formatSize(file?.size));
  });
};

const callConversion = async (updateSceneArgs: MutationUpdateSceneArgs) => {
  const res = await updateScene(updateSceneArgs)
  if (res.success) {
    upload.value.file = res.payload?.scene.aoiGeojson
    upload.value.fileName = "aoi.geojson"
    upload.value.visible = false
    upload.value.hide(null, 'aoi')
  }  
}

const getAoiInputFileByExtension = (extension: string) => {
  const keys = Object.keys(aoiData.value["files"])
  const key = keys.find(f => f.toLowerCase().indexOf(extension.toLowerCase()) > -1)
  if (key) {
    return aoiData.value["files"][key]
  }
  else {
    return null
  }
}

const convert = async () => {
  const { scene } = getScene(props.sceneId, 0) 
  watch(scene, async () => {
    if (scene?.value){
      const sceneData = scene?.value
      const updateSceneArgs: MutationUpdateSceneArgs = {
        id: sceneData.id,
        aoiInputFiles: {
          shapeFile: getAoiInputFileByExtension('shp') ?? '',
          indexFile: getAoiInputFileByExtension('shx') ?? '',
          dbFile: getAoiInputFileByExtension('dbf') ?? '',
          prjFile: getAoiInputFileByExtension('prj') ?? ''
        }
      }
      await callConversion(updateSceneArgs)
    }
  })
};

/*
const uploadEvent = (callback:any) => {
  totalSizePercent.value = totalSize.value / 10;
  //callback();
  console.log(files)
};
*/

const onTemplatedUpload = () => {
  console.log('a')
};

const formatSize = (bytes:any) => {
  const k = 1024;
  const dm = 3;

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${formattedSize}`;
}

const formatSizeTemplate = (bytes:any) => {
  const k = 1024;
  const dm = 3;

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  let unit = 'bytes'
  switch (i){
  case 0:
    unit = 'bytes'
    break
  case 1:
    unit = 'kB'
    break
  case 2:
    unit = 'MB'
    break
  case 3:
    unit = 'GB'
    break    
  }

  return `${formattedSize} ${unit}`;
}


return (_ctx: any,_cache: any) => {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(_component_Dialog, {
      visible: upload.value.visible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((upload.value.visible) = $event)),
      header: "AOI Upload",
      onHide: onDialogClose,
      position: 'center',
      modal: true,
      style: { width: '960px' }
    }, _createSlots({
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Toast),
          _cache[6] || (_cache[6] = _createElementVNode("h5", null, "Please select AOI files (.shp,.shx,.dbf,.prj)", -1)),
          _createVNode(_component_FileUpload, {
            name: "demo[]",
            url: "#",
            onUpload: _cache[1] || (_cache[1] = ($event: any) => (onTemplatedUpload())),
            multiple: true,
            accept: ".shp,.shx,.dbf,.prj",
            maxFileSize: 10000000,
            onSelect: onSelectedFiles
          }, {
            header: _withCtx(({ chooseCallback, clearCallback, files }) => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_Button, {
                    onClick: ($event: any) => (chooseCallback()),
                    icon: "pi pi-images",
                    rounded: "",
                    outlined: ""
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_Button, {
                    "data-cy": "dialogAOI__upload",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (convert())),
                    icon: "pi pi-cloud-upload",
                    rounded: "",
                    outlined: "",
                    severity: "success",
                    disabled: !files || files.length === 0
                  }, null, 8, ["disabled"]),
                  _createVNode(_component_Button, {
                    onClick: ($event: any) => (clearCallback()),
                    icon: "pi pi-times",
                    rounded: "",
                    outlined: "",
                    severity: "danger",
                    disabled: !files || files.length === 0
                  }, null, 8, ["onClick", "disabled"])
                ]),
                _createVNode(_component_ProgressBar, {
                  value: totalSizePercent.value,
                  showValue: false,
                  class: _normalizeClass(['md:w-20rem h-1rem w-full md:ml-auto', { 'exceeded-progress-bar': totalSizePercent.value > 100 }])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(totalSize.value) + "B / 1Mb", 1)
                  ]),
                  _: 1
                }, 8, ["value", "class"])
              ])
            ]),
            content: _withCtx(({ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }) => [
              (files.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _cache[3] || (_cache[3] = _createElementVNode("h5", null, "Pending", -1)),
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files, (file, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: file.name + file.type + file.size,
                          class: "card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3"
                        }, [
                          false
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createElementVNode("img", {
                                  role: "presentation",
                                  alt: file.name,
                                  src: file.objectURL,
                                  width: "100",
                                  height: "50",
                                  class: "shadow-2"
                                }, null, 8, _hoisted_8)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_9, _toDisplayString(file.name), 1),
                          _createElementVNode("div", null, _toDisplayString(formatSizeTemplate(file.size)), 1),
                          _createVNode(_component_Badge, {
                            value: "Pending",
                            severity: "warning"
                          }),
                          _createVNode(_component_Button, {
                            icon: "pi pi-times",
                            onClick: ($event: any) => (onRemoveTemplatingFile(file, removeFileCallback, index)),
                            outlined: "",
                            rounded: "",
                            severity: "danger"
                          }, null, 8, ["onClick"])
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (uploadedFiles.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _cache[4] || (_cache[4] = _createElementVNode("h5", null, "Completed", -1)),
                    _createElementVNode("div", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(uploadedFiles, (file, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: file.name + file.type + file.size,
                          class: "card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3"
                        }, [
                          _createElementVNode("div", null, [
                            _createElementVNode("img", {
                              role: "presentation",
                              alt: file.name,
                              src: file.objectURL,
                              width: "100",
                              height: "50",
                              class: "shadow-2"
                            }, null, 8, _hoisted_12)
                          ]),
                          _createElementVNode("span", _hoisted_13, _toDisplayString(file.name), 1),
                          _createElementVNode("div", null, _toDisplayString(formatSizeTemplate(file.size)), 1),
                          _createVNode(_component_Badge, {
                            value: "Completed",
                            class: "mt-3",
                            severity: "success"
                          }),
                          _createVNode(_component_Button, {
                            icon: "pi pi-times",
                            onClick: ($event: any) => (removeUploadedFileCallback(index)),
                            outlined: "",
                            rounded: "",
                            severity: "danger"
                          }, null, 8, ["onClick"])
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            empty: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("div", { class: "flex align-items-center justify-content-center flex-column" }, [
                _createElementVNode("i", { class: "pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" }),
                _createElementVNode("p", { class: "mt-4 mb-0" }, "Drag and drop files to here to upload.")
              ], -1)
            ])),
            _: 1
          })
        ])
      ]),
      _: 2
    }, [
      false
        ? {
            name: "footer",
            fn: _withCtx(() => [
              _createVNode(_component_Button, {
                label: "OK",
                onClick: onOk
              }),
              _createVNode(_component_Button, {
                label: "Close",
                onClick: onDialogClose
              })
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["visible"])
  ]))
}
}

})