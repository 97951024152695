<template>
  <div class="grid">
    <div class="col-12">
      <Message :closable="false" severity="info"><strong>Scene status: {{ scene?.status.alias }}</strong> This may take a while... <br> You can see current vadstena input content in Data Browser below.</Message>

      <h5>Vadstena input is in volume {{ volume?.name }}</h5>
      <p v-if="scene && volume">
        <br>
        <DataBrowser
          v-if="scene.vadstenaInputDmVolumeMountAlias"
          :volumeMountAlias="volume.mountAlias"
          :storageId="volume.storage?.id"
        >
        </DataBrowser>
      </p>
		</div>
	</div>  
</template>

<script lang="ts" setup>

const props = defineProps({
  sceneId: {
    required: true,
    type: String
  }
})

import DataBrowser from '@/components/DataBrowser/DataBrowser.vue';
import { useScenes } from '@/composables/useScenes';
const { getScene } = useScenes()
const { scene } = getScene(props.sceneId) 

import { useVolumes } from '@/composables/useVolumes';
import { Ref, ref, watch } from 'vue';
import { PopulationTypeAlias, Volume } from '@/gql/graphql'
const { getVolume } = useVolumes()
let volume: Ref<Volume | null> = ref(null)

import { useLogFiles } from '@/composables/helpers/useLogFiles';
const { populationLogFilePath } = useLogFiles()

const logFilePath = ref('')
watch(scene, (sceneValue) => {
  if (sceneValue && sceneValue.vadstenaInputDmVolumeMountAlias) {
    volume = getVolume(sceneValue.vadstenaInputDmVolumeMountAlias).volume

    logFilePath.value = populationLogFilePath(sceneValue.populationType.alias as PopulationTypeAlias)
  }
})


</script>

<style lang="scss" scoped>
.code-block {
  overflow: auto;
  border: 1px solid;
  pre {
    padding: 0.5rem;
  }
}
</style>
