<script setup lang="ts">
import AttributesList from '@/components/UI/AttributesList.vue';
import { ref } from 'vue'


const props = defineProps({
  formData: {
    required: true,
    type: Object
  },
  projectId: {
    required: true,
    type: String
  },
  update: {
    required: false,
    default: false
  },
  scene: {
    required: false,
    type: Object,
    default: null
  }
})


const emit = defineEmits(['complete','next', 'prevPage', 'onCancel'])

const onCancel = () => {
  emit('onCancel', router.currentRoute.value.name)
}

import { useRouter } from 'vue-router'
const router = useRouter()

const stepBack = () => {
  emit('prevPage', router.currentRoute.value.name)
}

// Function
const onSubmit = () => {
  emit("complete", {
    ...props.formData
  })
}



const sceneRenderAttributes = ref({
  id: { label: "Scene id", value: (item: Scene) => item?.id},
  //name: { label: "Scene name", value: (item: Scene) => item?.name},
  notes: { label: "Scene notes", value: (item: Scene) => item?.notes},
})

import { PopulationTypeAlias, Scene, SceneStatusAlias } from '@/gql/graphql';
</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="p-fluid">
        <Message v-if="!update" :closable="false" severity="info">
          All elements are in place, ready for scene creation.<br>Before the ingest process is initiated, the setup of this scene can be adjusted as needed.
        </Message>

        <Message v-if="update" :closable="false" severity="info">
          <span v-if="scene && scene.status.alias === SceneStatusAlias.New">
            Update scene configuration.
          </span>
          <span v-else>
            The scene has been ingested. Changes to the scene configuration can no longer be made.
          </span>
        </Message>

        <AttributesList :item="formData" :attributes="sceneRenderAttributes"></AttributesList>

        <br>

        <div class="flex flex-row flex-wrap justify-content-between">
          <Button label="Back" icon="fa-solid fa-arrow-left"
            class="p-button-info mr-2 mb-2 mlwn-button-submit" @click="stepBack"></Button>
          <Button label="Cancel" icon="fa-solid fa-xmark" @click="onCancel" class="p-button-secondary mr-2 mb-2 mlwn-button-submit" v-if="false"/> 
          <Button v-if="!update || (update && scene && scene.status.alias === SceneStatusAlias.New && scene.populationType.alias === PopulationTypeAlias.Ingest)" :label="update ? 'Update scene' : 'Create scene'" data-cy="stepOverview__createButton" @click="onSubmit" type="submit" icon="fa-solid fa-check"
            class="p-button-success mr-2 mb-2 mlwn-button-submit"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.code-block {
  overflow: auto;
  border: 1px solid;
  pre {
    padding: 0.5rem;
  }
}
</style>