import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_4 = { class: "flex align-items-center justify-content-center" }
const _hoisted_5 = { class: "flex w-100 mb-2" }
const _hoisted_6 = { class: "flex w-100" }

import OverlayPanel from 'primevue/overlaypanel';
import SceneTable from './components/SceneTable.vue'
import { ref, watch } from 'vue';
import { useProject } from '@/composables/useProject';
import { useToast } from 'primevue/usetoast';


export default /*@__PURE__*/_defineComponent({
  __name: 'SceneList',
  setup(__props) {

const op = ref()
const selectedProjectId = ref()

const toast = useToast()
const { getProjects } = useProject()
const { projects, loading: loadingProjects, error: errorProjects } = getProjects()

watch(errorProjects, () => {
  if (errorProjects.value) {
    toast.add({ severity: 'error', summary: 'Error loading projects', detail: 'Try to log in again or contact administrator.' })
  }
})

watch(projects, () => {
  if (projects.value.length > 0) {
    selectedProjectId.value = projects.value[0].id
  }
})

const toggle = () => {
  op.value.toggle(event)
}


return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Card = _resolveComponent("Card")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex align-items-center justify-content-center" }, "Scenes", -1)),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Button, {
                type: "button",
                icon: "fa fa-plus",
                label: "New Scene",
                onClick: toggle
              }),
              _createVNode(_unref(OverlayPanel), {
                ref_key: "op",
                ref: op
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_Dropdown, {
                      class: "flex w-100",
                      loading: _unref(loadingProjects),
                      options: _unref(projects),
                      optionLabel: "name",
                      optionValue: "id",
                      placeholder: "Select a Project",
                      modelValue: selectedProjectId.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedProjectId).value = $event))
                    }, null, 8, ["loading", "options", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    (selectedProjectId.value)
                      ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          class: "w-100",
                          type: "button",
                          to: {
                        name: 'create-scene',
                        params: { 
                          projectId: selectedProjectId.value
                        }
                      }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Button, {
                              type: "button",
                              label: "Create scene",
                              disabled: !selectedProjectId.value
                            }, null, 8, ["disabled"])
                          ]),
                          _: 1
                        }, 8, ["to"])), [
                          [
                            _directive_tooltip,
                            'Create new project',
                            void 0,
                            { bottom: true }
                          ]
                        ])
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 512)
            ])
          ])
        ]),
        content: _withCtx(() => [
          _createVNode(SceneTable, { showProjectColumn: true })
        ]),
        _: 1
      })
    ])
  ]))
}
}

})