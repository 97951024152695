<template>
  <div>
    <multiple-input-list :title="title" :toggleableRow="false" :toggleableRowTitleCb="toggleableRowTitleCb" :minRowsCount="1" :defaultRowValue="defaultRowValue" v-model:modelValue="data[type]" :rowClass="'float-label'">
      <template #form="{ value }" >
          <div class="p-fluid formgrid grid">              
              <div class="field col-12 md:col-6">
                <span class="p-float-label">
                  <InputText v-model="value.key" />
                  <label for="key">Key</label>
                </span>
              </div>
              <div class="field col-12 md:col-6">
                <span class="p-float-label">
                  <InputText v-model="value.value"/>
                  <label for="value">Value</label>
                </span>
              </div>
            </div> 
      </template>
    </multiple-input-list>
  </div>
</template>

<script lang="ts" setup>
import MultipleInputList from '@/components/UI/Form/MultipleInputList.vue'
import { computed, ref, watch, Ref } from 'vue'
import { PRESET_TYPES } from '@/helpers/constants';

interface SceneOptions {
  [PRESET_TYPES.SCENE_OPTIONS]: [ SceneOptionModelType ]
}

interface SceneOptionModelType {
  key: string;
  value: string;
}

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  title: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'CameraRules'
  }
})

const defaultRowValue = () => {
  return {
    key: '',
    value: ''
  }
}

const toggleableRowTitleCb = (value: SceneOptionModelType) => {
  return `${props.title}: ${value.key}`
}

const changeEmitName = 'update:modelValue'
const emits = defineEmits<{(e: typeof changeEmitName, id: string): void }>()

const model = computed({
  get: () => props?.modelValue,
  set: (value: any) => {
    emits(changeEmitName, value)
  },
})

const data: Ref<any> = ref(model.value ?? {
  [PRESET_TYPES.SCENE_OPTIONS]: []
})
watch(data, (newValue) => {
  model.value = newValue as SceneOptions
}, { deep: true })
</script>

<style lang="scss" scoped>
.formgrid {
  width: 100% !important;
  margin-left: 0;
}
</style>