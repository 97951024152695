<template>
  <div class="login-body">
    <div class="login-wrapper">
      <div class="login-panel">
        <span class="logo">
          <img id="app-logo" class="dark" src="@/assets/vadstena-logo.png" />
        </span>
        <div class="login-form">
          <Card style="padding: 1rem; color: var('--text-color')">
            <template #header>
              <h3>Login</h3>
            </template>
            <template #content>
              <div style="padding-bottom: 1rem">
                You don't have an account?
                <a @click="onLoginClick('register')" style="cursor: pointer">
                  Sign up</a
                >
              </div>
              <Button
                icon="fa-brands fa-microsoft"
                label="Login with microsoft"
                type="button"
                @click="onLoginClick('login')"
              ></Button>
              <div style="padding-top: 0rem">
                <span
                  @click="showEmailPasswordLogin = !showEmailPasswordLogin"
                  style="cursor: pointer; font-size: 0.5rem; display: none"
                  id="cy-login-email-password"
                  >Login with email</span
                >
                <LoginEmailPasswordForm
                  v-if="showEmailPasswordLogin"
                  @onCancelClick="showEmailPasswordLogin = false"
                />
              </div>
            </template>
          </Card>
        </div>
        <p>
          A problem? <router-link to="/">Click here</router-link> and let us
          help you.
        </p>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useMsal } from "@/auth/composables/useMsal";
import { loginRequest } from "@/auth/authConfig";
import LoginEmailPasswordForm from "./components/LoginEmailPasswordForm.vue";

import { EventType } from "@azure/msal-browser";
import { useToast } from "primevue/usetoast";

import { useAuth } from "@/composables/useAuth";
const {
  isAppleDevice,
  loginWithMicrosoft,
  registerWithMicrosoft,
  redirectAfterSuccessLogin,
} = useAuth();
const toast = useToast();

const LOGIN_TYPE_KEY = "_hndw-login-type";
const loginType = ref(
  ["login", "register"].includes(window.localStorage.getItem(LOGIN_TYPE_KEY))
    ? window.localStorage.getItem(LOGIN_TYPE_KEY)
    : "login"
);

const tokenAcquired = async (payload) => {
  if (payload && payload.accessToken) {
    if (loginType.value === "login") {
      const loginRes = await loginWithMicrosoft(payload.accessToken);
      if (loginRes && loginRes.success) {
        window.localStorage.removeItem(LOGIN_TYPE_KEY);
        return redirectAfterSuccessLogin();
      } else {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: loginRes.message ?? "Unknown error occured...",
          life: 3000,
        });
        return;
      }
    } else if (loginType.value === "register") {
      const registerRes = await registerWithMicrosoft(payload.accessToken);
      if (registerRes && registerRes.success) {
        toast.add({
          severity: "success",
          summary: "Succes",
          detail: "Account successfully created. Contact us due to activation",
          life: 3000,
        });
        window.localStorage.removeItem(LOGIN_TYPE_KEY);
      } else {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: registerRes.message ?? "Unknown error occured...",
          life: 3000,
        });
        return;
      }
    }
  }
};

// Msal setup
const { instance } = useMsal();
instance.setEventCallback(EventType.ACQUIRE_TOKEN_SUCCESS, tokenAcquired);

const onLoginClick = (type) => {
  loginType.value = type;
  window.localStorage.setItem(LOGIN_TYPE_KEY, type);

  if (isAppleDevice()) {
    instance.loginRedirect(loginRequest);
  } else {
    instance.loginPopup(loginRequest);
  }
};

// Email password login
const showEmailPasswordLogin = ref(false);
</script>

<style lang="scss" scoped>
#app-logo {
  height: 72px;
  margin-top: 5px;
  &.dark {
    filter: invert(1);
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid #dee2e6;
        > td {
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4rem 1rem -0.4rem -0.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
