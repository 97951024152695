import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-fluid formgrid grid" }
const _hoisted_2 = { class: "field col-12 md:col-6" }
const _hoisted_3 = { class: "p-float-label" }
const _hoisted_4 = { class: "field col-12 md:col-6" }
const _hoisted_5 = { class: "p-float-label" }

import MultipleInputList from '@/components/UI/Form/MultipleInputList.vue'
import { computed, ref, watch, Ref } from 'vue'
import { PRESET_TYPES } from '@/helpers/constants';

interface SceneOptions {
  [PRESET_TYPES.SCENE_OPTIONS]: [ SceneOptionModelType ]
}

interface SceneOptionModelType {
  key: string;
  value: string;
}

const changeEmitName = 'update:modelValue'

export default /*@__PURE__*/_defineComponent({
  __name: 'RulesPresetForm',
  props: {
  modelValue: {
    type: Object,
    default: () => {}
  },
  title: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'CameraRules'
  }
},
  setup(__props, { emit: __emit }) {

const props = __props

const defaultRowValue = () => {
  return {
    key: '',
    value: ''
  }
}

const toggleableRowTitleCb = (value: SceneOptionModelType) => {
  return `${props.title}: ${value.key}`
}

const emits = __emit

const model = computed({
  get: () => props?.modelValue,
  set: (value: any) => {
    emits(changeEmitName, value)
  },
})

const data: Ref<any> = ref(model.value ?? {
  [PRESET_TYPES.SCENE_OPTIONS]: []
})
watch(data, (newValue) => {
  model.value = newValue as SceneOptions
}, { deep: true })

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(MultipleInputList, {
      title: __props.title,
      toggleableRow: false,
      toggleableRowTitleCb: toggleableRowTitleCb,
      minRowsCount: 1,
      defaultRowValue: defaultRowValue,
      modelValue: data.value[__props.type],
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((data.value[__props.type]) = $event)),
      rowClass: 'float-label'
    }, {
      form: _withCtx(({ value }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_component_InputText, {
                modelValue: value.key,
                "onUpdate:modelValue": ($event: any) => ((value.key) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              _cache[1] || (_cache[1] = _createElementVNode("label", { for: "key" }, "Key", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_InputText, {
                modelValue: value.value,
                "onUpdate:modelValue": ($event: any) => ((value.value) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              _cache[2] || (_cache[2] = _createElementVNode("label", { for: "value" }, "Value", -1))
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}
}

})