import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 md:col-6" }
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = { class: "col-12 col:md-12 formgrid" }
const _hoisted_5 = { class: "flex flex-row flex-wrap justify-content-end" }

import { useTaskTypes } from '@/composables/useTaskTypes';
import { TaskTypeAlias } from '@/helpers/constants';
import { Form } from 'vee-validate';
import { Ref, ref, watch } from 'vue';
import { useCollocations } from '@/composables/useCollocations';
import InfoInputGroup from '@/components/UI/Form/InfoInputGroup.vue';

import { useClusters } from '@/composables/useClusters'
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

export default /*@__PURE__*/_defineComponent({
  __name: 'ClusterForm',
  setup(__props) {

let collocationId = ref()
let id = ref('')

const router = useRouter()
const toast = useToast()

const { getTaskTypes } = useTaskTypes()
const { taskTypes, loading: loadingTaskTypes } = getTaskTypes()

const { getCollocations } = useCollocations()
const { collocations } = getCollocations()

const { startClusterService } = useClusters()

const availableCollocationId: Ref<Array<string>> = ref([])

watch(taskTypes, () => {
  const taskType = taskTypes.value.find((taskType: any) => taskType && taskType.alias === TaskTypeAlias.ClusterService)
  if (taskType) {
    availableCollocationId.value = taskType?.capabilities.map((e: any) => e.collocation)
  }
})

const availableVolumeMountAliases: Ref<Array<{ mountAlias: string, name: string }>> = ref([])

watch(collocations, () => {
  const taskType = taskTypes.value.find((taskType: any) => taskType && taskType.alias === TaskTypeAlias.ClusterProvisioning)
  if (taskType) {
    let volumeAliases: { mountAlias: string, name: string }[] = []
    const capabilities = taskType.capabilities.filter((capability: any) => capability?.collocation.id === collocationId.value)
    for (let capability of capabilities) {
      for (let connection of capability?.collocation.connections || []) {
        for (let volume of connection?.storage.volumes || []) {
          if (!volumeAliases.find((_volume) => _volume.mountAlias === volume.mountAlias)) {
            volumeAliases.push({
              mountAlias: volume.mountAlias,
              name: volume.name || volume.mountAlias,
            })
          }
        }
      }
    }
    availableVolumeMountAliases.value = [...volumeAliases]
  }
})

let logVolumeDmMountAlias = ref()
let workdirVolumeDmMountAlias = ref()

watch(collocationId, () => {
  const taskType = taskTypes.value.find((taskType: any) => taskType && taskType.alias === TaskTypeAlias.ClusterService)
  if (taskType) {
    let volumeAliases: { mountAlias: string, name: string }[] = []
    const capabilities = taskType.capabilities.filter((capability: any) => capability?.collocation.id === collocationId.value)
    for (let capability of capabilities) {
      for (let connection of capability?.collocation.connections || []) {
        for (let volume of connection?.storage.volumes || []) {
          if (!volumeAliases.find((_volume) => _volume.mountAlias === volume.mountAlias)) {
            volumeAliases.push({
              mountAlias: volume.mountAlias,
              name: volume.name || volume.mountAlias,
            })
          }
        }
      }
    }
    availableVolumeMountAliases.value = [...volumeAliases]
  }
})


const onCreateTask = async () => {
  try {
    await startClusterService({
      id: id.value,
      dmCollocationId: collocationId.value,
      workdirDmMountAlias: workdirVolumeDmMountAlias.value,
      logVolumeDmMountAlias: logVolumeDmMountAlias.value,
    })
    router.go(-1)
  } catch (err) {
    toast.add({ severity: 'error', summary: 'Error', detail: 'Error while creating task', life: 3000 })
  }

}

function onCancelClick() {
  router.go(-1)
}

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Form), { class: "grid p-fluid" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(InfoInputGroup, null, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    "data-cy": "cluserForm__cluserId",
                    id: "id",
                    modelValue: _unref(id),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(id) ? (id).value = $event : id = $event)),
                    optionValue: "id",
                    optionLabel: "id",
                    loading: _unref(loadingTaskTypes),
                    placeholder: "Cluster id"
                  }, null, 8, ["modelValue", "loading"]),
                  _cache[4] || (_cache[4] = _createElementVNode("label", { for: "collocationId" }, "Id", -1))
                ]),
                _: 1
              }),
              _createVNode(InfoInputGroup, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Dropdown, {
                    "data-cy": "cluserForm__cluserCollocation",
                    id: "collocationId",
                    modelValue: _unref(collocationId),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(collocationId) ? (collocationId).value = $event : collocationId = $event)),
                    options: _unref(collocations),
                    optionValue: "id",
                    optionLabel: "name",
                    loading: _unref(loadingTaskTypes),
                    placeholder: "Select Collocation",
                    "scroll-height": "400px"
                  }, null, 8, ["modelValue", "options", "loading"]),
                  _cache[5] || (_cache[5] = _createElementVNode("label", { for: "collocationId" }, "Collocation", -1))
                ]),
                _: 1
              }),
              _createVNode(InfoInputGroup, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Dropdown, {
                    "data-cy": "cluserForm__cluserLogVolume",
                    id: "logVolumeDmMountAlias",
                    modelValue: _unref(logVolumeDmMountAlias),
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(logVolumeDmMountAlias) ? (logVolumeDmMountAlias).value = $event : logVolumeDmMountAlias = $event)),
                    options: availableVolumeMountAliases.value,
                    optionValue: "mountAlias",
                    optionLabel: "name",
                    loading: _unref(loadingTaskTypes),
                    placeholder: "Select log volume",
                    "scroll-height": "400px"
                  }, null, 8, ["modelValue", "options", "loading"]),
                  _cache[6] || (_cache[6] = _createElementVNode("label", { for: "logVolumeDmMountAlias" }, "Log Volume", -1))
                ]),
                _: 1
              }),
              _createVNode(InfoInputGroup, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Dropdown, {
                    "data-cy": "cluserForm__cluserWorkdirVolume",
                    id: "workdirVolumeDmMountAlias",
                    modelValue: _unref(workdirVolumeDmMountAlias),
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(workdirVolumeDmMountAlias) ? (workdirVolumeDmMountAlias).value = $event : workdirVolumeDmMountAlias = $event)),
                    options: availableVolumeMountAliases.value,
                    optionValue: "mountAlias",
                    optionLabel: "name",
                    loading: _unref(loadingTaskTypes),
                    placeholder: "Select workdir volume",
                    "scroll-height": "400px"
                  }, null, 8, ["modelValue", "options", "loading"]),
                  _cache[7] || (_cache[7] = _createElementVNode("label", { for: "workdirVolumeDmMountAlias" }, "Workdir Volume", -1))
                ]),
                _: 1
              }),
              _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Button, {
                  "data-cy": "cluserForm__cluserCreateButton",
                  onClick: onCreateTask,
                  label: "Create",
                  icon: "fa-solid fa-check",
                  class: "p-button-success mr-2 mb-2 mlwn-button-submit"
                }),
                _createVNode(_component_Button, {
                  onClick: onCancelClick,
                  label: "Cancel",
                  icon: "fa-solid fa-xmark",
                  class: "p-button-secondary mr-2 mb-2 mlwn-button-submit"
                })
              ])
            ])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})