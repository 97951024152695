import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-fluid formgrid grid" }
const _hoisted_2 = { class: "field col-12" }
const _hoisted_3 = { class: "p-float-label" }
const _hoisted_4 = { class: "field col-12" }
const _hoisted_5 = { class: "p-fluid formgrid grid" }
const _hoisted_6 = { class: "field col-12 md:col-6" }
const _hoisted_7 = {
  key: 0,
  for: "key"
}
const _hoisted_8 = { class: "field col-12 md:col-6" }
const _hoisted_9 = {
  key: 0,
  for: "value"
}

import MultipleInputList from '@/components/UI/Form/MultipleInputList.vue'
import MultipleInputListSimple from '@/components/UI/Form/MultipleInputListSimple.vue'
import { computed, ref, watch, Ref } from 'vue'
import { PRESET_TYPES } from '@/helpers/constants';

interface LidarDefinitions {
  [PRESET_TYPES.LIDAR_DEF]: [ LidarDefModelType ]
}

interface LidarOptionDefinition {
  key?: string,
  value?: string
}

interface LidarDefModelType {
  name?: string,
  options?: [
    LidarOptionDefinition
  ]
}

const changeEmitName = 'update:modelValue'

export default /*@__PURE__*/_defineComponent({
  __name: 'LidarDefPresetForm',
  props: {
  modelValue: {
    type: Object,
    default: () => {}
  },
  savePreset: {
    type: Function,
    required: false,
    default: () => { return { } }
  },
  showTitle: {
    type: Boolean,
    required: false,
    default: true
  },
  savePresetEnable: {
    type: Boolean,
    required: false,
    default: false
  }
},
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props

const defaultRowValue = () => {
  return {
    name: '',
    options: []
  }
}

const defaultOptionRowValue = () => {
  return {
    key: '',
    value: ''
  }
}

const toggleableRowTitleCb = (value: LidarDefModelType) => {
  return `Lidar: ${value.name}`
}

const toggleableOptionRowTitleCb = (value: LidarOptionDefinition) => {
  return `Option: ${value.key}`
}

const emits = __emit

const model = computed({
  get: () => props?.modelValue,
  set: (value: any) => {
    emits(changeEmitName, value)
  },
})

const readonlyFlag = ref(false)
const setReadonly = (readonly:boolean) => {
  readonlyFlag.value = readonly
}

__expose({
  setReadonly,
})


const data: Ref<any> = ref(model.value ?? {
  [PRESET_TYPES.LIDAR_DEF]: []
})
watch(data, (newValue) => {
  model.value = newValue as LidarDefinitions
}, { deep: true })

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createBlock(MultipleInputList, {
    title: "Definition of lidar",
    defaultRowValue: defaultRowValue,
    "save-preset": __props.savePreset,
    "save-preset-enable": __props.savePresetEnable && !readonlyFlag.value,
    modelValue: data.value[_unref(PRESET_TYPES).LIDAR_DEF],
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((data.value[_unref(PRESET_TYPES).LIDAR_DEF]) = $event)),
    minRowsCount: 1,
    rowClass: 'float-label',
    toggleableRowTitleCb: toggleableRowTitleCb,
    "show-title": __props.showTitle,
    disableRowRemove: readonlyFlag.value
  }, {
    form: _withCtx(({ value }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_InputText, {
              id: "name",
              modelValue: value.name,
              "onUpdate:modelValue": ($event: any) => ((value.name) = $event),
              disabled: readonlyFlag.value
            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
            _cache[1] || (_cache[1] = _createElementVNode("label", { for: "name" }, "name*", -1))
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("small", null, "User-defined LiDAR name, point clouds can be later mapped to a LiDAR rule based on this name.", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(MultipleInputListSimple, {
            title: "LiDAR attributes",
            defaultRowValue: defaultOptionRowValue,
            modelValue: value.options,
            "onUpdate:modelValue": ($event: any) => ((value.options) = $event),
            rowClass: 'float-label',
            toggleableRowTitleCb: toggleableOptionRowTitleCb,
            "disable-row-remove": readonlyFlag.value
          }, {
            form: _withCtx(({ value }) => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", {
                    class: _normalizeClass({ 'p-float-label': !readonlyFlag.value })
                  }, [
                    _createVNode(_component_InputText, {
                      modelValue: value.key,
                      "onUpdate:modelValue": ($event: any) => ((value.key) = $event),
                      disabled: readonlyFlag.value
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
                    (!readonlyFlag.value)
                      ? (_openBlock(), _createElementBlock("label", _hoisted_7, "Key"))
                      : _createCommentVNode("", true)
                  ], 2)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", {
                    class: _normalizeClass({ 'p-float-label': !readonlyFlag.value })
                  }, [
                    _createVNode(_component_InputText, {
                      modelValue: value.value,
                      "onUpdate:modelValue": ($event: any) => ((value.value) = $event),
                      disabled: readonlyFlag.value
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
                    (!readonlyFlag.value)
                      ? (_openBlock(), _createElementBlock("label", _hoisted_9, "Value"))
                      : _createCommentVNode("", true)
                  ], 2)
                ])
              ])
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "disable-row-remove"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["save-preset", "save-preset-enable", "modelValue", "show-title", "disableRowRemove"]))
}
}

})