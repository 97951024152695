import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }

import ServiceTaskForm from './ServiceTaskForm.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'ServiceTaskCreate',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" Create new service task ")
        ])),
        content: _withCtx(() => [
          _createVNode(ServiceTaskForm)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})