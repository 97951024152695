<script lang="ts" setup>
import { useAuth } from "@/composables/useAuth";
import * as yup from "yup";
import { Form } from "vee-validate";
import InputTextWithValidation from "@/components/UI/Form/components/InputTextWithValidation.vue";

const { loginWithEmailPassword, redirectAfterSuccessLogin } = useAuth();

import { useToast } from "primevue/usetoast";
const toast = useToast();

defineEmits(["onCancelClick"]);

const onSubmit = async (data: any) => {
  const resp = await loginWithEmailPassword({
    email: data.email,
    password: data.password,
  });

  if (resp.success) {
    toast.add({ severity: "success", summary: "Login successful." });
    redirectAfterSuccessLogin();
  } else {
    toast.add({
      severity: "error",
      summary: "The email or password you entered is incorrect.",
    });
  }
};

const validationSchema = yup.object({
  email: yup.string().required().default(""),
  password: yup.string().required().default(""),
});
</script>

<template>
  <div>
    <Form v-slot="{ handleSubmit }" :validation-schema="validationSchema">
      <div class="p-fluid">
        <InputTextWithValidation
          name="email"
          label="Email"
          class="mb-3"
          id="cy-login-email-input"
          fieldClass="mb-5"
        />
        <InputTextWithValidation
          name="password"
          label="Password"
          type="password"
          id="cy-login-password-input"
          :class="'mb-3'"
          fieldClass="mb-5"
        />

        <div class="flex justify-end gap-2">
          <Button
            type="button"
            label="Cancel"
            severity="secondary"
            @click="$emit('onCancelClick', 'cancel')"
          ></Button>
          <Button
            type="button"
            label="Login"
            id="cy-login-submit"
            @click="handleSubmit(onSubmit)"
          ></Button>
        </div>
      </div>
    </Form>
  </div>
</template>
