<script setup lang="ts">
import { TrajectoryRule } from '@/helpers/types/PresetTypes';
import { useFieldArray } from 'vee-validate';
import { toRef, ref } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  maxItems: {
    type: Number,
    default: -1,
    required: false
  },
  defaultRowValue: {
    type: Function,
    required: false,
    default: () => { return { } }
  },
  disableRowRemove: {
    type: Boolean,
    required: false,
    default: false
  },
  savePreset: {
    type: Function,
    required: false,
    default: () => { return { } }
  }, 
  savePresetEnable: {
    type: Boolean,
    required: false,
    default: false
  },
  minimize: {
    type: Boolean,
    required: false,
    default: false
  }
})

const { remove, push, fields } = useFieldArray(toRef(props, 'name'))

const addRow = () => {
  if (props.maxItems === -1 ) {
    push(props.defaultRowValue()) 
  }
}
const removeRow = (index: number) => {
  remove(index)
}

const presetName = ref('')
const saveLabelVisible = ref(false)

const onShowPreset = () => {
  saveLabelVisible.value = !saveLabelVisible.value
  if (saveLabelVisible.value) {
    presetName.value = ''
  }
}

const onSave = (name: string) => {
  if (name.length > 0) {    
    props.savePreset(name)
    onShowPreset()
  }
}


const fillData = (data: any) => {
  const tr = fields.value
  data.forEach((element:any) => { 
    let i = tr.findIndex((item: TrajectoryRule) => item.value.name === element.key)
    if(i >= 0)
      tr[i].value.rule = element.value
  })
}

defineExpose({
  fillData,
})

</script>

<template>
  <div class="mlwn-multiple-input-list-with-validation">
    <p v-if="label && label.length > 0">{{ label }}</p>
    <fieldset
        class="mlwn-multiple-input-list-with-validation-row"
        v-for="(field, idx) in fields"
        :key="field.key"
      >
        <slot  :field="field" :fieldName="`${name}[${idx}]`" :index="idx" :removeRow="removeRow" name="form">
        </slot>

        <Button v-if="!disableRowRemove" text :class="{'mlwn-remove-item-button': !minimize, 'mlwn-remove-item-button-minimize': minimize}" severity="danger" icon="fa-solid fa-trash" @click="removeRow(idx)"></Button>
      </fieldset>

    <slot name="footer" :addItem="addRow"  class="mlwn-multiple-input-list-with-validation-footer" >
      <div class="flex justify-content-between flex-wrap">
        <div class="flex justify-content-start">
          <div class="save-preset" v-if="savePresetEnable">
            <div>
              <Button severity="secondary" v-if="fields.length > 0 && !saveLabelVisible" icon="fa-solid fa-save" v-tooltip.left="'Save preset'" class="button-margin" @click="onShowPreset" />
              <Button severity="secondary" v-if="saveLabelVisible && fields.length > 0" icon="fa-solid fa-eye-slash" v-tooltip.left="'Hide'" @click="onShowPreset" />
            </div>
            <div>
              <InputText v-model="presetName" v-if="saveLabelVisible" placeholder="New preset name" :size="30" class="input"/>
            </div>
            <div>
              <Button v-if="saveLabelVisible && fields.length > 0" icon="fa-solid fa-save" v-tooltip.left="'Save preset'" @click="onSave(presetName)" />
            </div>
          </div>
        </div>
        <div class="flex justify-content-end add-button">
          <Button v-if="maxItems === -1 || fields.length < maxItems" icon="fa-solid fa-plus" aria-label="Add" v-tooltip.bottom="'Add item'" @click="addRow"
          :class="{'addRowButton': fields.length == 0}" />
        </div>
      </div>
    </slot>

  </div>
</template>


<style lang="scss" scoped>
.mlwn-multiple-input-list-with-validation-row {
  border: 0;
  padding: 0;
  position: relative;
  .mlwn-remove-item-button {
    position: absolute;
    top: 2.6rem;
    right: 3rem;
    height: 1.5rem;
    width: 1.5rem !important;
  }
  .mlwn-remove-item-button-minimize {
    position: absolute;
    top: 0.6rem;
    right: 3rem;
    height: 1.5rem;
    width: 1.5rem !important;
  }
}
.addRowButton {
  margin-top: 2rem !important;
}
.mlwn-multiple-input-list-with-validation .button-margin {
  margin-right: 24px;
}

.mlwn-multiple-input-list-with-validation .save-preset {
  display: flex; 
  flex-direction: row; 
  width: 60%;
  margin-left: 0.2rem !important;
}

.mlwn-multiple-input-list-with-validation .add-button {
  padding-top: 0rem !important;
  padding-right: 0.15rem !important;
}
.mlwn-multiple-input-list-with-validation .input {
  width: 250px !important;
}

.mlwn-multiple-input-list-with-validation .footer {
  padding-top: 1.0rem !important;
}
</style>
