<template>
  <span>
    <Dialog v-model:visible="sceneData.visible" :header="sceneData.layer" @hide="onDialogClose"
      :style="{ width: '700px' }" :position="'center'" :modal="true">
      <template #default>
        <div class="flex  gap-1">
            <div class="flex-1 flex flex-column align-items-center justify-content-center">
              <strong>Fields</strong>
              <Listbox v-model="sceneData.key" :options="sceneData.keys" optionLabel="key" @change="onChange($event)"
                    listStyle="max-height:290px" :style="{ width: '100%', height: '300px' }" />
            </div>
            <div class="flex-1 flex flex-column align-items-center justify-content-center">
              <strong>Values</strong>
              <Listbox v-model="sceneData.value" :options="sceneData.data[sceneData.key]"
                    @change="onChangeValue($event)" optionLabel="" listStyle="max-height:290px"
                    :style="{ width: '100%', height: '300px' }" />
            </div>
        </div>

        <h5>Operators</h5>
        <div>
          <div class="flex justify-content-left flex-wrap gap-1">
            <Button @click="click('=')" title="e.g. [DirId] = 2">=</Button>
            <Button @click="click('!=')" title="e.g. [DirId] != 2">!=</Button>
            <Button @click="click('<')" title="e.g. [DirId] < 2">&#60;</Button>
            <Button @click="click('>')" title="e.g. [DirId] > 2">></Button>
            <Button @click="click('<=')" title="e.g. [DirId] <= 2">&#60;=</Button>
            <Button @click="click('>=')" title="e.g. [DirId] >= 2">>=</Button>
            <Button @click="click('AND')" title="e.g. [DirId] = 1  AND [ViewId] REGEX 't'">AND</Button>
            <Button @click="click('OR')" title="e.g. [DirId] = 0 OR [DirId] = 1">OR</Button>
            <Button @click="click('REGEX')" title="e.g. [ViewId] REGEX 'Right'">REGEX</Button>
          </div>
          <div class="flex justify-content-left flex-wrap gap-1 mt-3">
            <Button severity="danger" @click="click('Clear')">Clear</Button>
          </div>
        </div>

        <h5>Expression</h5>
        <div class="flex justify-content-left flex-wrap gap-1">
          <Textarea v-model="sceneData.expression" rows="5" cols="30" :style="{ width: '100%', minHeight: '120px' }" />
          <div v-if="sceneData.error != ''" class="error">
          {{ sceneData.error }}
        </div>
        </div>        
      </template>

      <template #footer>
        <Button severity="success" @click="onDialogOK">OK</Button>
        <Button @click="onDialogClose">Close</Button>
      </template>
    </Dialog>
  </span>
</template>


<script setup lang="ts">

import { ref } from 'vue'

const props = defineProps({
  layer: {
    type: String,
    required: true
  },
  visible: {
    type: Boolean,
    default: false,
    required: true
  },
  update: {
    required: true
  },
  data: {
    type: Object,
    required: true
  }
})

const onChange = (event: any) => {
  sceneData.value.key = event.value.key
  sceneData.value.expression += '[' + event.value.key + '] '
}

const onChangeValue = (event: any) => {
  const value = event.originalEvent.target.textContent
  if (!isNaN(value)) {
    sceneData.value.expression += value + ' '
  }
  else {
    sceneData.value.expression += '\'' + value + '\'' + ' '
  }
}

const click = (value: any) => {
  if (value === 'Clear') {
    sceneData.value.expression = ''
  }
  else
    sceneData.value.expression += value + ' '
}

const onDialogOK = () => {
  if (sceneData.value.expression.toUpperCase().indexOf('AND') > -1 && sceneData.value.expression.toUpperCase().indexOf('OR') > -1) {
    sceneData.value.error = 'Simultaneous use of AND and OR expressions is not allowed'
    return
  }
  sceneData.value.error = ''
  setExpressionString()
  sceneData.value.visible = false
  sceneData.value.file = ''
  if (sceneData.value.expression === '') {
    sceneData.value.data._filter.condition = null
  }
  sceneData.value.update(true)
}

const onDialogClose = () => {
  sceneData.value.visible = false
  sceneData.value.file = ''
  sceneData.value.update(false)
}

const sceneData: any = ref({
  zoom: 10,
  layer: props.layer,
  visible: props.visible,
  update: props.update,
  data: props.data,
  keys: [],
  value: null,
  expression: '',
  error: ''
})

for (const key of Object.keys(sceneData.value.data)) {
  if (key === '_filter') continue
  sceneData.value.keys.push({
    key: key
  })
}

const parseExpression = (expression: string, operator: string) => {
  const parts = expression.split(operator)
  if (parts.length == 2) {
    const key = parts[0].trim().replace('[', '').replace(']', '')
    const value = parts[1].trim().indexOf('\'') > -1 ? parts[1].trim().replaceAll('\'', '') : Number(parts[1].trim())
    return {
      key: key,
      value: value,
      operator: operator
    }
  } else return null
}

const setExpressionString = () => {
  const filter = sceneData.value.data._filter
  let expression = sceneData.value.expression
  expression = expression.replace('or', 'OR').replace('Or', 'OR').replace('and', 'AND').replace('And', 'AND').replace('like', 'LIKE').replace('Like', 'LIKE').replace('regex', 'REGEX').replace('Regex', 'REGEX')
  const condition: any = []
  let orIndex = 0
  for (let partOr of expression.split('OR')) {
    partOr = partOr.trim()
    condition[orIndex] = []
    let andIndex = 0
    for (let partAnd of partOr.split('AND')) {
      partAnd = partAnd.trim()
      condition[orIndex][andIndex] = []
      for (const operator of ['=', '!=', '<', '>', '>=', '<=', 'REGEX']) {
        const result = parseExpression(partAnd, operator)
        if (result) condition[orIndex][andIndex] = result
      }
      andIndex++
    }
    orIndex++
  }
  filter.condition = condition
}

const getExpressionString = () => {
  const filter = sceneData.value.data._filter
  if (filter) {
    const condition = filter.condition
    let text = ''
    if (condition) {
      let orIndex = 0
      for (const partOr of condition) {
        let andIndex = 0
        if (orIndex > 0) {
          text += ' OR '
        }
        for (const partAnd of partOr) {
          if (!partAnd.key)
            continue
          if (andIndex > 0) {
            text += ' AND '
          }
          text += '[' + partAnd.key + '] ' + partAnd.operator + ' ' + (isNaN(partAnd.value) ? '\'' + partAnd.value + '\'' : partAnd.value)
          andIndex++
        }
        orIndex++
      }
    }
    if (text === '') {
      filter.condition = null
    }
    sceneData.value.expression = text
  }
}
getExpressionString()

</script>

<style>

.error {
  color: red;
}

</style>