<template>
  <div class="grid">			
		<div class="col-12 p-fluid">
			<Card>
				<template #title>
          <span>Register volume into storage <strong>{{ storage?.name }}</strong></span>
				</template>
				<template #content>
					<div class="flex">
            <volume-form
              :storageId="storageId"
              :update="false"
            ></volume-form>
          </div>
				</template>
			</Card>
		</div>
	</div>            
</template>


<script setup lang="ts">
import VolumeForm from './components/VolumeForm.vue'
import { useStorages } from '@/composables/useStorages';

const props = defineProps({
  storageId: {
    required: true,
    type: String
  }
})

const { getStorage } = useStorages()
const { storage } = getStorage(props.storageId)


</script>
