import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }

import ProjectForm from './components/ProjectForm.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectEdit',
  props: {
  projectId: {
    type: String,
    required: true
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, null, {
        title: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" Edit project ")
        ])),
        content: _withCtx(() => [
          _createVNode(ProjectForm, {
            update: true,
            projectId: __props.projectId
          }, null, 8, ["projectId"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})