<template>
  <div class="field" :class="fieldClass">
    <InfoInputGroup :hint-content="hintContent" :hint-title="hintTitle">
      <Textarea
        v-model="value"
        :aria-describedby="`${name}-help`"
        :disabled="disabled"
        :class="{ 'p-invalid': errorMessage }"
        :type="type"
        :rows="rows"
        :cols="cols"
        />
      <label :for="name">{{ label }}</label>
    </InfoInputGroup>
    <small :id="`${name}-help`" class="p-error">{{ errorMessage }}</small>
  </div>
</template>

<script setup lang="ts">
import { toRef } from 'vue';
import { useField } from 'vee-validate';
import InfoInputGroup from '../InfoInputGroup.vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false
  },
  rows: {
    type: Number,
    default: 4
  },
  cols: {
    type: Number,
    default: 30
  },
  fieldClass: {
    type: String,
    required: false,
    default: ''
  },
  hintContent: {
    type: String,
    required: false,
    default: null
  },
  hintTitle: {
    type: String,
    required: false,
    default: `Hint`
  }
});

const { errorMessage, value } = useField(toRef(props, 'name'));
</script>

<style lang="scss" scoped>
textarea.p-inputtextarea.p-inputtext.p-component {
    max-width: 100%;
}
</style>