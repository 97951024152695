<template>
  <div>
    <DataTable class="p-datatable-sm"  :value="volumes" :paginator="true" v-model:filters="tableFilters" ref="dt" :loading="true"
               @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" filterDisplay="menu"
               :globalFilterFields="['name']" responsiveLayout="scroll" :sortField="defaultSortField" :sortOrder="defaultSortOrder"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rows="rows" :rowsPerPageOptions="rowsPerPageOptions"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
      <template #empty>
        No volumes found.
      </template>
      <template #loading>
        <span v-if="volumes.length">
          <ProgressSpinner />
        </span>
        <span v-else>
          No volumes found.
        </span>
      </template>

      <!--<Column field="id" header="Id" filterField="id" filterMatchMode="contains" :showFilterMatchModes="false"
        :maxConstraints="1" :showFilterOperator="false" sortable style="min-width: 10rem">
        <template #body="{ data }">
          <span v-html="makeStrong(data.id, 'id')"></span>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
            placeholder="Search by id" />
        </template>
      </Column>-->

      <Column field="name" header="Name" filterField="name" filterMatchMode="contains" :showFilterMatchModes="false"
        :maxConstraints="1" :showFilterOperator="false" sortable style="min-width: 10rem">
        <template #body="{ data }">
          <span data-cy="volumesTable__volumeName" v-html="makeStrong(data.name, 'name')"></span>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
            placeholder="Search by name" />
        </template>
      </Column>

      <Column field="volumeStatus" header="Status" filterField="volumeStatus" filterMatchMode="equals"
        :showFilterMatchModes="false" :maxConstraints="1" :showFilterOperator="false" sortable style="min-width: 10rem">
        <template #body="{ data }">
          <span data-cy="volumesTable__volumeStatus"> {{ data.volumeStatus?.alias }}</span>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown v-model="filterModel.value" @keydown.enter="filterCallback()" :options="volumeStatuses"
            optionValue="alias" optionLabel="alias" placeholder="Select status" />
        </template>
      </Column>

      <Column headerStyle="width: 8rem; text-align: right" bodyStyle="text-align: right; overflow: visible">
        <template #body="{ data }">
          <span class="action-buttons">
            <Button data-cy="volumesTable__detailButton" type="button" icon="fa-solid fa-pencil" @click="onEditClick(data)"
                    v-tooltip.top="'Volume detail'"></Button>
          </span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { FilterMatchMode } from 'primevue/api'
import { useRouter } from 'vue-router'
import { useVolumes } from '@/composables/useVolumes'
import { useTables } from '@/composables/useTables'

const props = defineProps({
  storageId: String,
})


const { getVolumes, getVolumeStatuses } = useVolumes()
const { volumes } = getVolumes({
  filter: {
    fields: [
      {
        name: "storageId",
        type: "equals",
        value: props.storageId
      }
    ]
  }
})

const { volumeStatuses } = getVolumeStatuses()

const router = useRouter()
const _query = router.currentRoute.value.query
const {
  makeStrong,
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows
} = useTables({
  filters: {
    /*
    id: {
      value: _query.id ? _query.id : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "id"
    },
    */
    name: {
      value: _query.name ? _query.name : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "name"
    },
    volumeStatus: {
      value: _query.volumeStatus ? _query.volumeStatus : null,
      matchMode: FilterMatchMode.EQUALS,
      pretty: "volumeStatus"
    },
    "storage.name": {
      value: _query.storageName ? _query.storageName : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "storageName"
    }
  }
})


const onEditClick = (input: any) => {
  router.push({
    name: 'storage-volume-detail',
    params: {
      volumeMountAlias: input.mountAlias,
      storageId: input.storage.id
    }
  })
}
</script>

<style lang="scss" scoped>
</style>
