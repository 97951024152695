<template>
  <div>
    <DataTable
        :value="sceneData.scenes"
        :paginator="true"
        @page="onPage($event)"
        @sort="onSort($event)"
        :sortField="defaultSortField"
        :sortOrder="defaultSortOrder"
        :lazy="lazy"
        class="p-datatable-sm"
        :rows="rows"
        dataKey="id"
        :rowHover="true"
        v-model:filters="tableFilters"
        filterDisplay="menu"
        :loading="sceneData.loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="rowsPerPageOptions"
        :currentPageReportTemplate="'Showing {first} to {last} of {totalRecords} entries'"
        responsiveLayout="scroll"
    >
      <template #empty>
        No scenes found.
      </template>
      <template #loading>
          <span v-if="sceneData.scenes.length">
            <ProgressSpinner />
          </span>
        <span v-else>
            No scenes found.
          </span>
      </template>

      <Column
          field="name"
          header="Name" 
          filterField="name"
          filterMatchMode="contains"
          :showFilterMatchModes="false"
          :maxConstraints="1" :showFilterOperator="false"
          sortable headerStyle="min-width: 3rem">
        <template #body="{data}">
              <span data-cy="sceneTable__nameCell" v-html="makeStrong(data.name, 'name')" @click="onEditClick(data)" class="mlwn-pointer-cursor mlwn-hyperlink"
                    v-tooltip="data.status.alias === 'InputQcReview' ? 'Show QC' : (data.status.alias === SceneStatusAlias.New ? 'Start population': 'Scene detail')"></span>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by name"/>
        </template>
      </Column>

      <Column 
          header="Project" 
          field="project"
          filterField="project.id" 
          filterMatchMode="in" 
          :showFilterMatchModes="false"
          :maxConstraints="1" :showFilterOperator="false"           
          sortable :headerStyle="props.showProjectColumn ? 'min-width: 3rem;' : 'display:none;'"
          :bodyStyle="props.showProjectColumn ? '' : 'display:none;'">
            <template #body="{data}">
              {{  data.project.name }}
            </template>
            <template #filter="{ filterModel, filterCallback}">
              <MultiSelect :loading="loadingProjects" v-model="filterModel.value" :options="projects" optionLabel="name" placeholder="Any" @update:model-value="filterCallback()" optionValue="id">
                <template #option="slotProps">
                  <div class="flex items-center gap-2">
                    <span>{{ slotProps.option.name }}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
        </Column>
        
        <Column 
          field="timeEdited" 
          header="Last change" 
          filterField="timeEdited" 
          filterMatchMode="contains" 
          :showFilterMatchModes="false"
          :maxConstraints="1" :showFilterOperator="false"           
          sortable headerStyle="min-width: 1rem">
            <template #body="{data}">
              <span v-html="formatDate(data.timeEdited)" class="mlwn-pointer-cursor" v-tooltip.top="'Last change'"></span>
            </template>
        </Column>

        <Column 
          field="vadstenaInputDmVolumeMountAlias" 
          header="Vadstena input"
          filterField="vadstenaInputDmVolumeMountAlias" 
          filterMatchMode="contains" 
          :showFilterMatchModes="false"
          :maxConstraints="1" :showFilterOperator="false"           
          sortable headerStyle="min-width: 1rem" bodyStyle="min-width: 1rem; overflow: hidden;">
            <template #body="{data}">
              <span v-html="makeStrong(data.vadstenaInputDmVolumeMountAlias, 'vadstenaInputDmVolumeMountAlias')" @click="onVolumeClick(data)" class="mlwn-pointer-cursor mlwn-hyperlink" style="word-break: break-all"  v-tooltip.top="'Vadstena input'"></span>
              <a href="#" v-if="data.vadstenaInputDmVolumeMountAlias" @click="copyToClipboard(data.vadstenaInputDmVolumeMountAlias)" v-tooltip.top="'Copy to clipboard'"><i class="fa fa-solid fa-fw fa-copy"></i></a>
            </template>
            <template #filter="{filterModel, filterCallback}">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by input volume path"/>
            </template>
        </Column>

        
        <Column 
          field="statusPredicted.alias" 
          header="Status"
          filterField="statusPredicted.alias" 
          filterMatchMode="in" 
          :showFilterMatchModes="false"
          :maxConstraints="1" 
          :showFilterOperator="false"           
          sortable
          headerStyle="min-width: 5rem; text-align: right" 
          bodyStyle="min-width: 5rem; text-align: right; overflow: visible; white-space: nowrap;">
            <template #body="{data}">
              <span class="action-buttons flex align-items-center justify-content-end gap-1">
                  <SceneActionPanel :scene="data" @scene-updated="onSceneUpdated()"/>
                </span>
            </template>
            <template #filter="{ filterModel, filterCallback}">
              <MultiSelect :loading="loadingSceneStatuses" v-model="filterModel.value" :options="sceneStatuses" optionLabel="name" placeholder="Any" @update:model-value="filterCallback()" optionValue="alias">
              </MultiSelect>
            </template>
        </Column>

    </DataTable>
  </div>
</template>

<script setup lang="ts">
// imports
import { ref, watch } from 'vue'
import { useTables } from '@/composables/useTables';
import { useRouter } from 'vue-router';
import { FilterMatchMode } from 'primevue/api';
import { useScenes } from '@/composables/useScenes';
import { useVolumes } from '@/composables/useVolumes';
import { Scene, SceneStatusAlias } from '@/gql/graphql';
import { useConfig } from '@/composables/useConfig';
import { useProject } from '@/composables/useProject';
import SceneActionPanel from '@/components/Admin/Project/Scene/SceneActions/SceneActionPanel.vue';
import { useToast } from 'primevue/usetoast';

// const SceneStatus

// component
const props = defineProps({
  showProjectColumn: {
    required: true,
    type: Boolean,
    default: true
  },
  projectId: {
    required: false,
    type: String
  }
})

// state
const sceneData: any = ref({
  scenes: null,
  loading: null,
})

// composables
const { getConfig } = useConfig()
const toast = useToast()
const router = useRouter()
const { getScenes, getSceneStatuses } = useScenes()
const { getVolumes } = useVolumes()
const { getProjects } = useProject()

// initializations
const config = getConfig()
const { scenes, loading, refetch: refetchScenes } = getScenes({}, config?.POLLINTERVAL)
const { sceneStatuses, loading: loadingSceneStatuses, error: errorSceneStatuses } = getSceneStatuses()
const { volumes, error: errorVolumes } = getVolumes()
const { projects, loading: loadingProjects, error: errorProjects } = getProjects()
const _query = router.currentRoute.value.query
const {
  formatDate,
  makeStrong,
  tableFilters,
  onPage,
  onFilter,
  onSort,
  defaultSortField,
  defaultSortOrder,
  rowsPerPageOptions,
  rows,
  lazy,
} = useTables({
  filters: {
    name: {
      value: _query.name ? _query.name : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "name"
    },
    "statusPredicted.alias": {
      value: _query.statusPredictedAlias ? _query.statusPredictedAlias : null,
      matchMode: FilterMatchMode.IN,
      pretty: "statusPredictedAlias"
    },
    "project.id": {
      value: props.projectId ? props.projectId.toString() : null,
      matchMode: props.projectId ? FilterMatchMode.EQUALS : FilterMatchMode.IN,
      pretty: "projectId",
    },
    vadstenaInputDmVolumeMountAlias: {
      value: _query.vadstenaInputDmVolumeMountAlias ? _query.vadstenaInputDmVolumeMountAlias : null,
      matchMode: FilterMatchMode.CONTAINS,
      pretty: "vadstenaInputDmVolumeMountAlias"
    }
  }
})

// watches

// errorsLoading
watch(errorProjects, () => {
  if (errorProjects.value) {
    toast.add({ severity: 'error', summary: 'Error loading projects', detail: 'Try to log in again or contact administrator.' })
  }
})
watch(errorVolumes, () => {
  if (errorVolumes.value) {
    toast.add({ severity: 'error', summary: 'Error loading volumes', detail: 'Try to log in again or contact administrator.' })
  }
})
watch(errorSceneStatuses, () => {
  if (errorSceneStatuses.value) {
    toast.add({ severity: 'error', summary: 'Error loading scene statuses', detail: 'Try to log in again or contact administrator.' })
  }
})

// success loading

watch(volumes, () => {
  if (volumes?.value) {
    //  
  }
})

watch(scenes, () => {
  scenes?.value.forEach(element => {
    element.vadstenaInputDmVolumeMountAlias = element.vadstenaInputDmVolumeMountAlias ?? ''
  });
  sceneData.value.scenes = scenes
  sceneData.value.loading = loading
})

// events

async function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text)
  toast.add({ severity: 'info', summary: 'Copied to clipboard', life: 3000 })
}

async function onSceneUpdated() {
  await refetchScenes()
  sceneData.value.scenes = scenes
}

const onEditClick = (scene: Scene) => {
  router.push({
    name: 'project-scene-detail',
    params: {
      projectId: scene.project.id,
      sceneId: scene.id
    }
  })
}

const onVolumeClick = (scene: Scene) => {
  router.push({
    name: 'storage-volume-detail',
    params: {
      storageId: scene.vadstenaInputDmVolumeMountAlias ? scene.vadstenaInputDmVolumeMountAlias.split('/')[0] : -1,
      volumeMountAlias: scene.vadstenaInputDmVolumeMountAlias ?? ''
    }
  })
}
</script>
