<template>
  <div class="grid">
    <div v-if="scene" class="col-12">
      <Message v-if="!ingestTaskCreated" :closable="false" severity="info">
        <strong>Scene was created, with type of population: {{ scene.populationType.name }}</strong><br>
        You can proceed to population with the following form.<br>
        Select <strong>data center</strong> where population will be performed and <strong>empty</strong> output volume.
      </Message>
      <Message v-else-if="ingestTaskCreated && dmTaskStatus === TaskStatusAlias.Created" :closable="false" severity="success">
        <strong>Awaits {{ scene.populationType.name }} processing...</strong><br>
        {{ scene.populationType.name }} processing in queue... 
      </Message>
      <Message v-else-if="ingestTaskCreated && dmTaskStatus === TaskStatusAlias.Processing" :closable="false" severity="success" icon="fa-solid fa-cog">
        <strong>{{ scene.populationType.name }} processing...</strong><br>
        {{ scene.populationType.name }} is processing... 
      </Message>

      <Panel toggleable collapsed>
        <template #header>
          <span>Serialized <strong>config</strong></span>
        </template>
        <p class="m-0 code-block">
          <pre>{{ populationConfig }}</pre>
        </p>
      </Panel>

      <div v-if="!ingestTaskCreated">
        <ScenePopulationForm 
        :sceneId="sceneId"
        :afterSuccessCb="refetch"
        ></ScenePopulationForm>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  sceneId: {
    required: true,
    type: String
  }
})

import { useScenes } from '@/composables/useScenes';
const { getSerializedPopulationConfig, getScene } = useScenes()
const { scene, refetch } = getScene(props.sceneId)
const { populationConfig } = getSerializedPopulationConfig(props.sceneId)

// 
import { TaskStatusAlias } from '@/helpers/constants'
import ScenePopulationForm from './components/ScenePopulationForm.vue';

//@ts-expect-error
const ingestTaskCreated = computed(() => scene?.value?.ingestTask)
//@ts-expect-error
const dmTaskStatus = computed(() => scene?.value?.ingestTask?.taskStatus?.alias)
</script>

<style lang="scss" scoped>
.code-block {
  overflow: auto;
  pre {
    margin: 0;
  }
}
</style>
